// Performance configuration
export const preconnectUrls = [
  'https://firebase.googleapis.com',
  'https://fonts.googleapis.com',
  'https://fonts.gstatic.com'
];

// Add preconnect links to document head
export const setupPreconnect = () => {
  preconnectUrls.forEach(url => {
    const link = document.createElement('link');
    link.rel = 'preconnect';
    link.href = url;
    link.crossOrigin = 'anonymous';
    document.head.appendChild(link);

    // Also add dns-prefetch as fallback
    const dnsPrefetch = document.createElement('link');
    dnsPrefetch.rel = 'dns-prefetch';
    dnsPrefetch.href = url;
    document.head.appendChild(dnsPrefetch);
  });
};

// WebSocket reconnection strategy
export const setupWebSocketReconnection = (socket) => {
  let reconnectAttempts = 0;
  const maxReconnectAttempts = 5;
  const baseDelay = 1000; // Start with 1 second delay

  const reconnect = () => {
    if (reconnectAttempts >= maxReconnectAttempts) {
      console.error('Max reconnection attempts reached');
      return;
    }

    const delay = baseDelay * Math.pow(2, reconnectAttempts);
    setTimeout(() => {
      try {
        socket.connect();
        reconnectAttempts = 0;
      } catch (error) {
        console.error('Reconnection failed:', error);
        reconnectAttempts++;
        reconnect();
      }
    }, delay);
  };

  socket.on('disconnect', reconnect);
  
  // Handle page visibility changes
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible' && !socket.connected) {
      reconnectAttempts = 0;
      reconnect();
    }
  });
};

// Debounce function for performance optimization
export const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

// Throttle function for performance optimization
export const throttle = (func, limit) => {
  let inThrottle;
  return function executedFunction(...args) {
    if (!inThrottle) {
      func(...args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  };
}; 