/**
 * Feature Flags Configuration
 * 
 * This module provides a centralized way to manage feature flags across the application.
 * Each flag can be configured with environment-specific values and metadata.
 * 
 * For production changes, update the .env.production file with the appropriate flags.
 */

// Default feature flags
const defaultFlags = {
  // Process visibility flags
  SHOW_GLOW_04: false,
  SHOW_GLOW_63: false,
  
  // Feature-specific flags
  ENABLE_AUTO_VIDEO_FARM: true,
  ENABLE_ADVERTISEMENT_CHAIN: false,
  
  // UI flags
  SHOW_DEVELOPER_TOOLS: process.env.NODE_ENV !== 'production'
};

// Load environment-specific overrides
const loadEnvironmentFlags = () => {
  const envFlags = {};
  
  // Look for any environment variables prefixed with REACT_APP_FEATURE_
  Object.keys(process.env).forEach(key => {
    if (key.startsWith('REACT_APP_FEATURE_')) {
      // Convert to proper flag name by removing prefix and converting to uppercase
      const flagName = key.replace('REACT_APP_FEATURE_', '').toUpperCase();
      
      // Convert string boolean values to actual booleans
      const flagValue = process.env[key] === 'true' ? true : 
                         process.env[key] === 'false' ? false : 
                         process.env[key];
      
      envFlags[flagName] = flagValue;
    }
  });
  
  return envFlags;
};

// Metadata for feature flags (used for documentation and UI)
const flagMetadata = {
  SHOW_GLOW_04: {
    name: 'Accessibility Features',
    description: 'Enable the accessibility features in the video chain process',
    category: 'Process'
  },
  SHOW_GLOW_63: {
    name: 'Video Style Customization',
    description: 'Enable the video style customization step in the video chain process',
    category: 'Process'
  },
  ENABLE_AUTO_VIDEO_FARM: {
    name: 'Auto Video Farm',
    description: 'Enable the auto video farm feature for generating videos automatically',
    category: 'Feature'
  },
  ENABLE_ADVERTISEMENT_CHAIN: {
    name: 'Market Response Testing',
    description: 'Enable the advertisement chain for market response testing',
    category: 'Process'
  },
  SHOW_DEVELOPER_TOOLS: {
    name: 'Developer Tools',
    description: 'Show developer tools in the UI',
    category: 'UI'
  }
};

// Combine default and environment flags
const flags = {
  ...defaultFlags,
  ...loadEnvironmentFlags()
};

/**
 * Check if a feature flag is enabled
 * @param {string} flagName - The name of the flag to check
 * @param {boolean} defaultValue - The default value if the flag is not defined
 * @returns {boolean} - Whether the feature is enabled
 */
export const isFeatureEnabled = (flagName, defaultValue = false) => {
  return flagName in flags ? flags[flagName] : defaultValue;
};

/**
 * Get metadata for a specific feature flag
 * @param {string} flagName - The name of the flag
 * @returns {Object|null} - Metadata for the flag or null if not found
 */
export const getFeatureFlagMetadata = (flagName) => {
  return flagMetadata[flagName] || null;
};

/**
 * Get all feature flags with their current values
 * @returns {Object} - All feature flags
 */
export const getAllFeatureFlags = () => {
  return { ...flags };
};

/**
 * Get all feature flags with their metadata
 * @returns {Array} - Array of feature flags with metadata
 */
export const getAllFeatureFlagsWithMetadata = () => {
  return Object.keys(flags).map(flagName => ({
    name: flagName,
    enabled: flags[flagName],
    ...getFeatureFlagMetadata(flagName)
  }));
};

export default {
  isFeatureEnabled,
  getAllFeatureFlags,
  getFeatureFlagMetadata,
  getAllFeatureFlagsWithMetadata
}; 