export const VIDEO_INDEX = {
  "header": {
    "version": "1.0",
    "status_codes": {
      "0": "not_available",
      "1": "coming_soon",
      "2": "available"
    }
  },
  "categories": {
    "dyn": {
      "name": "Dynamic/Automated",
      "types": {
        "auto": { "name": "Auto Generated", "abbr": "AUTO", "dur": "custom", "status": 2 },
        "news": { "name": "News Video", "abbr": "NEWS", "dur": "custom", "status": 2 },
        "sum": { "name": "Content Summary", "abbr": "SUM", "dur": "custom", "status": 2 }
      }
    },
    "ent": {
      "name": "Entertainment",
      "types": {
        "se": { "name": "Story Explanation", "abbr": "SE", "dur": "60s", "status": 1 },
        "ca": { "name": "Character Analysis", "abbr": "CA", "dur": "45s", "status": 1 },
        "pl": { "name": "Plot Summary", "abbr": "PL", "dur": "30s", "status": 2 },
        "tx": { "name": "Theme Exploration", "abbr": "TX", "dur": "60s", "status": 1 },
        "ls": { "name": "Live Shopping", "abbr": "LS", "dur": "90s", "status": 1 },
        "pd": { "name": "Podcast Video", "abbr": "PD", "dur": "90s", "status": 1 },
        "tv": { "name": "Travel Showcase", "abbr": "TV", "dur": "60s", "status": 2 },
        "hw": { "name": "Holiday Wishes", "abbr": "HW", "dur": "20s", "status": 2 },
        "ck": { "name": "Children's Book", "abbr": "CK", "dur": "60s", "status": 2 },
        "cs": { "name": "Children's Story", "abbr": "CS", "dur": "60s", "status": 2 },
        "bt": { "name": "Bedtime Story", "abbr": "BT", "dur": "75s", "status": 2 },
        "is": { "name": "Interactive Story", "abbr": "IS", "dur": "60s", "status": 1 },
        "ff": { "name": "Feature Film", "abbr": "FF", "dur": "90s", "status": 1 }
      }
    },
    "edu": {
      "name": "Educational",
      "types": {
        "st": { "name": "Software Tutorial", "abbr": "ST", "dur": "60s", "status": 1 },
        "cb": { "name": "Concept Breakdown", "abbr": "CB", "dur": "30s", "status": 1 },
        "ht": { "name": "How-to Guide", "abbr": "HT", "dur": "60s", "status": 1 },
        "te": { "name": "Theory Explanation", "abbr": "TE", "dur": "60s", "status": 2 },
        "ev": { "name": "Explainer Video", "abbr": "EV", "dur": "30s", "status": 2 },
        "bs": { "name": "Book Summary", "abbr": "BS", "dur": "60s", "status": 2 },
        "ea": { "name": "Educational Animation", "abbr": "EA", "dur": "30s", "status": 2 },
        "ql": { "name": "Quick Learning", "abbr": "QL", "dur": "20s", "status": 2 },
        "ps": { "name": "Problem Solution", "abbr": "PS", "dur": "45s", "status": 2 },
        "me": { "name": "Methodology Explanation", "abbr": "ME", "dur": "60s", "status": 1 },
        "sk": { "name": "Skill Development", "abbr": "SK", "dur": "60s", "status": 2 },
        "ce": { "name": "Children's Educational", "abbr": "CE", "dur": "30s", "status": 2 },
        "sl": { "name": "Social Learning", "abbr": "SL", "dur": "45s", "status": 2 }
      }
    },
    "mkt": {
      "name": "Marketing/Commercial",
      "types": {
        "pv": { "name": "Product Validation", "abbr": "PV", "dur": "30s", "status": 2 },
        "vp": { "name": "Value Proposition", "abbr": "VP", "dur": "30s", "status": 2 },
        "pc": { "name": "Product Comparison", "abbr": "PC", "dur": "45s", "status": 1 },
        "pcu": { "name": "Product Comparison Updates", "abbr": "PCU", "dur": "30s", "status": 1 },
        "fe": { "name": "Feature Explanation", "abbr": "FE", "dur": "30s", "status": 2 },
        "uc": { "name": "Use Case Scenarios", "abbr": "UC", "dur": "45s", "status": 1 },
        "br": { "name": "Brand Story", "abbr": "BR", "dur": "45s", "status": 2 },
        "ad": { "name": "Service Advertisement", "abbr": "AD", "dur": "20s", "status": 2 },
        "sh": { "name": "Sell Homepage", "abbr": "SH", "dur": "30s", "status": 1 },
        "bo": { "name": "Brand Outro", "abbr": "BO", "dur": "20s", "status": 1 },
        "wvp": { "name": "Website Value Proposition", "abbr": "WVP", "dur": "30s", "status": 1 },
        "ps": { "name": "Property Showcase", "abbr": "PS", "dur": "45s", "status": 2 },
        "tc": { "name": "TV Commercial", "abbr": "TC", "dur": "20s", "status": 2 }
      }
    },
    "per": {
      "name": "Personal/Social",
      "types": {
        "ss": { "name": "Social Stories", "abbr": "SS", "dur": "20s", "status": 2 },
        "rv": { "name": "Review Video", "abbr": "RV", "dur": "60s", "status": 2 },
        "diy": { "name": "DIY Tutorial", "abbr": "DIY", "dur": "90s", "status": 2 },
        "wt": { "name": "Wellness Tips", "abbr": "WT", "dur": "30s", "status": 2 },
        "mg": { "name": "Mindfulness Guide", "abbr": "MG", "dur": "60s", "status": 1 },
        "lh": { "name": "Life Hack Tips", "abbr": "LH", "dur": "30s", "status": 2 }
      }
    },
    "pro": {
      "name": "Professional/Technical",
      "types": {
        "ag": { "name": "API Guide", "abbr": "AG", "dur": "60s", "status": 1 },
        "ae": { "name": "Architecture Explanation", "abbr": "AE", "dur": "75s", "status": 2 },
        "cw": { "name": "Code Walkthrough", "abbr": "CW", "dur": "90s", "status": 0 },
        "so": { "name": "System Overview", "abbr": "SO", "dur": "60s", "status": 0 },
        "ig": { "name": "Integration Guide", "abbr": "IG", "dur": "90s", "status": 1 },
        "sg": { "name": "Security Guidelines", "abbr": "SG", "dur": "60s", "status": 1 },
        "pg": { "name": "Process Guide", "abbr": "PG", "dur": "60s", "status": 1 },
        "pt": { "name": "Platform Tutorial", "abbr": "PT", "dur": "60s", "status": 1 },
        "tm": { "name": "Tool Mastery", "abbr": "TM", "dur": "90s", "status": 1 },
        "pb": { "name": "Professional Best Practices", "abbr": "PB", "dur": "60s", "status": 2 },
        "smh": { "name": "Smart Home Guide", "abbr": "SMH", "dur": "60s", "status": 1 },
        "fn": { "name": "Financial Education", "abbr": "FN", "dur": "60s", "status": 1 }
      }
    },
    "jou": {
      "name": "Journalistic/Documentary",
      "types": {
        "cd": { "name": "Career Development", "abbr": "CD", "dur": "60s", "status": 1 },
        "news": { "name": "NEWS", "abbr": "NEWS", "dur": "30s", "status": 1 },
        "ld": { "name": "Leadership Skills", "abbr": "LD", "dur": "60s", "status": 1 },
        "ei": { "name": "Expert Interview", "abbr": "EI", "dur": "90s", "status": 1 },
        "ii": { "name": "Industry Insights", "abbr": "II", "dur": "60s", "status": 2 },
        "sa": { "name": "Success Archive", "abbr": "SA", "dur": "75s", "status": 1 },
        "af": { "name": "Analysis Framework", "abbr": "AF", "dur": "75s", "status": 2 },
        "rs": { "name": "Research Summary", "abbr": "RS", "dur": "60s", "status": 2 },
        "cs": { "name": "Case Study", "abbr": "CS", "dur": "90s", "status": 2 },
        "ha": { "name": "Historical Analysis", "abbr": "HA", "dur": "75s", "status": 1 }
      }
    }
  }
};

// Helper functions to optimize data access
export const getCategoryById = (id) => VIDEO_INDEX.categories[id];
export const getTypeById = (categoryId, typeId) => VIDEO_INDEX.categories[categoryId]?.types[typeId];
export const getAvailableTypes = () => {
  const available = {};
  Object.entries(VIDEO_INDEX.categories).forEach(([catId, category]) => {
    Object.entries(category.types).forEach(([typeId, type]) => {
      if (type.status === 2) {
        if (!available[catId]) available[catId] = {};
        available[catId][typeId] = type;
      }
    });
  });
  return available;
}; 