import processIndex from '../config/processIndex.json';
import axios from 'axios';
import Cookies from 'js-cookie';

import AWS from 'aws-sdk';

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
});

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CACHE_VERSION = '1';
const CACHE_KEY = 'chat2movie_image_cache';

let imageCache = new Map();
try {
  const savedCache = localStorage.getItem(CACHE_KEY);
  if (savedCache) {
    const { version, data } = JSON.parse(savedCache);
    if (version === CACHE_VERSION) {
      imageCache = new Map(Object.entries(data));
      console.log('Loaded persistent cache:', imageCache.size, 'items');
    }
  }
} catch (error) {
  console.warn('Failed to load cache from localStorage:', error);
}

const persistCache = () => {
  try {
    const cacheData = Object.fromEntries(imageCache);
    localStorage.setItem(CACHE_KEY, JSON.stringify({
      version: CACHE_VERSION,
      data: cacheData
    }));
  } catch (error) {
    console.warn('Failed to persist cache:', error);
  }
};

export const getProcessById = (processId) => {
  console.log('getProcessById called with:', processId);
  if (processId === 'idea_description') {
    return processIndex.initialProcess;
  }
  const process = processIndex.processes.find(p => p.id === processId);
  console.log('Found process:', process);
  return process;
};

export const getProcessByOrder = (order) => {
  return processIndex.processes.find(process => process.displayOrder === order);
};

export const getDisplayName = (id) => {
  const process = getProcessById(id);
  return process ? process.displayName : id;
};

export const canRunProcess = (processId, completedProcesses) => {
  console.log('canRunProcess check for:', processId);
  console.log('Completed processes:', completedProcesses);
  const process = getProcessById(processId);
  if (!process) return false;
  
  if (process.dependsOn) {
    return process.dependsOn.every(dep => completedProcesses[dep]);
  }
  return true;
};

export const getProcessResponse = (processId, sessionDetails) => {
  if (!sessionDetails) return null;
  const responseKey = `${processId}_data`;
  const processResponse = `${processId}_response`;
  return sessionDetails[responseKey] ? sessionDetails[responseKey][processResponse] : null;  
};

export const getProcessStatus = (processId, sessionDetails) => {
  if (!sessionDetails) return false;
  
  switch (processId) {
    case 'glow_08':
      return sessionDetails.glow_08_data?.glow_08_response !== undefined;
    case 'glow_09':
      return sessionDetails.glow_09_data?.glow_09_response !== undefined;
    case 'glow_10':
      return sessionDetails.glow_10_data?.glow_10_response !== undefined;
    case 'glow_21':
      return sessionDetails.glow_21_data?.glow_21_response !== undefined;
    case 'glow_11':
      return sessionDetails.glow_11_data?.glow_11_response !== undefined;
    case 'glow_30':
      return true;
    default:
      return false;
  }
};

export const getEndpoint = (processId, type = 'run') => {
  const process = getProcessById(processId);
  if (!process || !process.endpoints || !process.endpoints[type]) {
    console.error(`No ${type} endpoint found for process ${processId}`);
    return null;
  }
  return process.endpoints[type];
};

export const getAllProcesses = () => {
  return [...processIndex.processes].sort((a, b) => a.displayOrder - b.displayOrder);
};

export const updateSessionDetails = async (sessionId, updatedData) => {
  try {
    console.log('Updating session details with:', updatedData);
    
    const response = await axios.post(
      `${processIndex.apiBaseUrl}/session/update_session_details`,
      {
        session_id: sessionId,
        session_details: updatedData
      },
      { 
        headers: { 
          Authorization: `Bearer ${Cookies.get('token')}` 
        } 
      }
    );

    console.log('Update response:', response.data);

    if (response.data.status_code === 200) {
      return response.data;
    } else {
      throw new Error(response.data.message || 'Failed to update session details');
    }
  } catch (error) {
    console.error('Error updating session details:', error);
    throw error;
  }
};

export const getUpdateEndpoint = (processId) => {
  const process = getProcessById(processId);
  return process ? `${processIndex.apiBaseUrl}/${process.endpoints.update}` : null;
};

export const updateProcessData = async (processId, sessionId, updatedData, type = 'full') => {
  try {
    const endpoint = getUpdateEndpoint(processId);
    if (!endpoint) {
      throw new Error(`No update endpoint found for process ${processId}`);
    }

    const process = getProcessById(processId);
    const payload = {
      session_id: sessionId,
      update_type: type,
      [process.responseKey]: {
        [`${process.id}_response`]: updatedData
      }
    };

    const response = await axios.post(
      endpoint,
      payload,
      { 
        headers: { 
          Authorization: `Bearer ${Cookies.get('token')}` 
        } 
      }
    );

    if (response.data.status_code === 200) {
      return response.data;
    } else {
      throw new Error(response.data.message || `Failed to update ${process.displayName}`);
    }
  } catch (error) {
    console.error(`Error updating ${processId}:`, error);
    throw error;
  }
};

export const validateProcessUpdate = (processId, data) => {
  const process = getProcessById(processId);
  if (!process) return false;

  // Get validation rules for this process
  const { validations } = process;
  if (!validations) return true;

  try {
    // Check required fields
    if (validations.required) {
      for (const field of validations.required) {
        if (!data[field]) {
          throw new Error(`Missing required field: ${field}`);
        }
      }
    }

    // Check max lengths
    if (validations.maxLength) {
      for (const [field, maxLength] of Object.entries(validations.maxLength)) {
        if (data[field] && data[field].length > maxLength) {
          throw new Error(`${field} exceeds maximum length of ${maxLength}`);
        }
      }
    }

    return true;
  } catch (error) {
    console.error('Validation error:', error);
    return false;
  }
};

export const updateProcessField = async (processId, sessionId, fieldPath, value) => {
  try {
    const currentData = await getProcessResponse(processId, await getSessionDetails(sessionId));
    if (!currentData) {
      throw new Error('No existing data found to update');
    }

    // Create updated data by modifying only the specified field
    const updatedData = {...currentData};
    const pathArray = fieldPath.split('.');
    let target = updatedData;
    
    // Navigate to the nested property
    for (let i = 0; i < pathArray.length - 1; i++) {
      target = target[pathArray[i]];
    }
    
    // Update the value
    target[pathArray[pathArray.length - 1]] = value;

    // Validate the update
    if (!validateProcessUpdate(processId, updatedData)) {
      throw new Error('Invalid update data');
    }

    // Send the update
    return await updateProcessData(processId, sessionId, updatedData, 'partial');
  } catch (error) {
    console.error('Error updating field:', error);
    throw error;
  }
};

export const batchUpdateProcess = async (processId, sessionId, updates) => {
  try {
    const currentData = await getProcessResponse(processId, await getSessionDetails(sessionId));
    if (!currentData) {
      throw new Error('No existing data found to update');
    }

    const updatedData = {...currentData};
    
    // Apply all updates
    for (const [fieldPath, value] of Object.entries(updates)) {
      const pathArray = fieldPath.split('.');
      let target = updatedData;
      
      for (let i = 0; i < pathArray.length - 1; i++) {
        target = target[pathArray[i]];
      }
      
      target[pathArray[pathArray.length - 1]] = value;
    }

    // Validate the complete update
    if (!validateProcessUpdate(processId, updatedData)) {
      throw new Error('Invalid update data');
    }

    // Send the update
    return await updateProcessData(processId, sessionId, updatedData, 'full');
  } catch (error) {
    console.error('Error batch updating process:', error);
    throw error;
  }
};

export const getSessionDetails = async (sessionId) => {
  try {
    const response = await axios.get(
      `${processIndex.apiBaseUrl}/session/get_session_details`,
      {
        params: { session_id: sessionId },
        headers: { Authorization: `Bearer ${Cookies.get('token')}` }
      }
    );

    if (response.data.status_code === 200) {
      return response.data.session_details;
    } else {
      throw new Error(response.data.message || 'Failed to get session details');
    }
  } catch (error) {
    console.error('Error getting session details:', error);
    throw error;
  }
};

export const uploadToS3 = async (blob, key) => {
  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
    Key: key,
    Body: blob,
    ContentType: 'image/jpeg'
  };

  return await s3.upload(params).promise();
};

export const checkImageExists = async (key) => {
  
  // Check frontend cache first
  if (imageCache.has(key)) {
    // console.log('Cache hit for:', key);
    return {
      exists: true,
      path: imageCache.get(key) //`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${key}`
    };
  }
  // console.log('Cache miss for:', key);

  try {
    await s3.headObject({
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: key
    }).promise();
    
    // Cache the S3 result
    const path = `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${key}`;
    imageCache.set(key, path);
    persistCache();
    console.log('Added to cache from S3:', key);
    
    return { exists: true, path };
  } catch (error) {
    if (error.code === 'NotFound') {
      return { exists: false };
    }
    throw error;
  }
};

export const generateAndSaveTargetGroupImage = async (visualDescription, targetGroupName, force = false) => {
  try {
    const fileName = `${targetGroupName}.jpeg`;
    const key = `target_groups/${fileName}`;
    
    if(!force) {
      // Check cache and S3 in one go
      const existingImage = await checkImageExists(key);
      if (existingImage.exists) {
        return { s3Path: existingImage.path };
      }
    }
    
    // Generate new image
    const formData = new FormData();
    formData.append('prompt', visualDescription);
    formData.append('output_format', 'jpeg');
    formData.append('width', '512');
    formData.append('height', '512');
    formData.append('steps', '30');
    formData.append('cfg_scale', '7');

    const response = await axios.post(
      'https://api.stability.ai/v2beta/stable-image/generate/sd3',
      formData,
      {
        headers: { 
          Authorization: `Bearer ${process.env.REACT_APP_STABILITY_API_KEY}`,
          'Content-Type': 'multipart/form-data',
          Accept: 'image/*'
        },
        responseType: 'arraybuffer'
      }
    );

    if (response.status === 200) {
      const s3Response = await uploadToS3(
        new Blob([response.data], { type: 'image/jpeg' }), 
        key
      );

      // Cache the new image
      imageCache.set(key, s3Response.Location);
      persistCache();
      console.log('New image generated and cached:', key);
      
      return { s3Path: s3Response.Location };
    } else {
      throw new Error(`Failed to generate image: ${response.status}`);
    }
  } catch (error) {
    console.error('Error details:', error.response?.data ? new TextDecoder().decode(error.response.data) : error);
    throw error;
  }
};

export const generateTargetGroupImage = async (payload, targetGroupName, force = false, isOnlyCache = false) => {  
  try {
    const fileName = `${targetGroupName}.webp`;
    const key = `target_groups/${fileName}`;
    if(!force) {
      // Check cache and S3 in one go
      const existingImage = await checkImageExists(key);
      if (existingImage.exists) {
        return { s3Path: existingImage.path };
      }
      if (isOnlyCache) {
        return;
      }
    }

    const response = await axios.post(
      `${API_BASE_URL}/api/v1/workflow/text_to_image`,
      payload,
      { headers: { Authorization: `Bearer ${Cookies.get('token')}` }}
    );
    
    if (response.data && response.data.status && response.data.data && response.data.data.image) {
      // Cache the new image
      imageCache.set(key, response.data.data.image);
      persistCache();
      console.log('New image generated and cached:', key);
      
      return { s3Path: response.data.data.image };
    } else {
      throw new Error(`Failed to generate image: ${response.data.message || 'Unknown error'}`);
    }
  } catch (error) {
    console.error('Error details:', error.response?.data ? new TextDecoder().decode(error.response.data) : error);
    throw error;
  }
};

export const generateImageToAnimation = async (payload, targetGroupName, force = false, isOnlyCache = false) => {  
  try {
    const fileName = `${targetGroupName}.mp4`;
    const key = `target_groups/${fileName}`;
    if(!force) {
      // Check cache and S3 in one go
      const existingImage = await checkImageExists(key);
      if (existingImage.exists) {
        return { s3Path: existingImage.path };
      }
      if (isOnlyCache) {
        return;
      }
    }

    const response = await axios.post(
      `${API_BASE_URL}/api/v1/animation/image_to_animation`,
      payload,
      { headers: { Authorization: `Bearer ${Cookies.get('token')}` }}
    );
    
    if (response.data && response.data.status && response.data.data) {
      // Cache the new image
      imageCache.set(key, response.data.data.s3_url);
      persistCache();
      console.log('New image generated and cached:', key);
      if (response.data.data?.estimated_time) {
        const savedCache = localStorage.getItem(CACHE_KEY);
        const { data } = JSON.parse(savedCache);
        const frameAnimationEstimates = data.frameAnimationEstimates || {};
        frameAnimationEstimates[targetGroupName] = response.data.data.estimated_time;
        localStorage.setItem("frameAnimationEstimates", JSON.stringify(frameAnimationEstimates));
      }
      
      return { message: response.data?.message, estimated_time: response.data.data?.estimated_time, s3_url: response.data.data?.s3_url };
    } else {
      throw new Error(`Failed to generate image: ${response.data.message || 'Unknown error'}`);
    }
  } catch (error) {
    console.error('Error details:', error.response?.data ? new TextDecoder().decode(error.response.data) : error);
    throw error;
  }
};

export const generateAnimationToClip = async (payload, targetGroupName, force = false, isOnlyCache = false) => {  
  try {
    const fileName = `${targetGroupName}.webp`;
    const key = `target_groups/${fileName}`;
    if(!force) {
      // Check cache and S3 in one go
      const existingImage = await checkImageExists(key);
      if (existingImage.exists) {
        return { s3Path: existingImage.path };
      }
      if (isOnlyCache) {
        return;
      }
    }

    const response = await axios.post(
      `${API_BASE_URL}/api/v1/workflow/animation_to_clip`,
      payload,
      { headers: { Authorization: `Bearer ${Cookies.get('token')}` }}
    );
    
    if (response.data && response.data.status && response.data.data && response.data.data.image) {
      // Cache the new image
      imageCache.set(key, response.data.data.image);
      persistCache();
      console.log('New image generated and cached:', key);
      
      return { s3Path: response.data.data.image };
    } else {
      throw new Error(`Failed to generate image: ${response.data.message || 'Unknown error'}`);
    }
  } catch (error) {
    console.error('Error details:', error.response?.data ? new TextDecoder().decode(error.response.data) : error);
    throw error;
  }
};

export const generateClipToVideo = async (payload, targetGroupName, force = false, isOnlyCache = false) => {  
  try {
    const fileName = `${targetGroupName}.webp`;
    const key = `target_groups/${fileName}`;
    if(!force) {
      // Check cache and S3 in one go
      const existingImage = await checkImageExists(key);
      if (existingImage.exists) {
        return { s3Path: existingImage.path };
      }
      if (isOnlyCache) {
        return;
      }
    }

    const response = await axios.post(
      `${API_BASE_URL}/api/v1/workflow/clip_to_video`,
      payload,
      { headers: { Authorization: `Bearer ${Cookies.get('token')}` }}
    );
    
    if (response.data && response.data.status && response.data.data && response.data.data.image) {
      // Cache the new image
      imageCache.set(key, response.data.data.image);
      persistCache();
      console.log('New image generated and cached:', key);
      
      return { s3Path: response.data.data.image };
    } else {
      throw new Error(`Failed to generate image: ${response.data.message || 'Unknown error'}`);
    }
  } catch (error) {
    console.error('Error details:', error.response?.data ? new TextDecoder().decode(error.response.data) : error);
    throw error;
  }
};

export const clearImageCache = () => {
  imageCache.clear();
  localStorage.removeItem(CACHE_KEY);
  console.log('Cache cleared');
};

export const getCacheStats = () => ({
  size: imageCache.size,
  keys: Array.from(imageCache.keys())
});

export const submitContactRequest = async (formData) => {
  try {
    const response = await axios.post(
      `${processIndex.apiBaseUrl}/contact/submit`,
      {
        name: formData.name,
        email: formData.email,
        company: formData.company,
        role: formData.role,
        video_type: formData.videoType,
        message: formData.message
      },
      { 
        headers: { 
          Authorization: `Bearer ${Cookies.get('token')}` 
        } 
      }
    );

    if (response.data.status_code === 200) {
      return response.data;
    } else {
      throw new Error(response.data.message || 'Failed to submit contact request');
    }
  } catch (error) {
    console.error('Error submitting contact request:', error);
    throw error;
  }
};

export const imageToAnimationConfig = async () => {  
  try {

    const response = await axios.get(
      `${API_BASE_URL}/api/v1/config/get_image_to_animation_config`,
      { headers: { Authorization: `Bearer ${Cookies.get('token')}` }}
    );
    
    if (response.data && response.data.status_code === 200) {      
      return response.data?.data;
    }
  } catch (error) {
    console.error('Error details:', error.response?.data ? new TextDecoder().decode(error.response.data) : error);
    throw error;
  }
};