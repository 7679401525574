import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile, mySubscription, handleLogout } from '../../auth/authenticateSlide';
import ProfilePage from './account_profiles';
import MonetizationDialog from './monetization';
import { FaCrown } from 'react-icons/fa';


export default function AccountMenu({ accountInfo, auth }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [profileOpen, setProfileOpen] = React.useState(false);
    const [userProfileLoading, setUserProfileLoading] = React.useState(false);
    const [userTypeLoading, setUserTypeLoading] = React.useState(false);
    const [monetizationOpen, setMonetizationOpen] = React.useState(false);
    const [openedProfileTab, setOpenedProfileTab] = React.useState(false);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleShowProfile = () => {
        setProfileOpen(true);
        setUserTypeLoading(true);
        setUserProfileLoading(true);
        dispatch(getUserProfile()).then((response) => {
            setUserProfileLoading(false);
        });
        dispatch(mySubscription()).then((response) => {  
            setUserTypeLoading(false);          
        });
    };
    const handleCloseProfile = () => {
        setProfileOpen(false);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleLogoutTrigger = () => {
        dispatch (handleLogout({auth:auth}))
    }

    const toggleMonetizationDialog = () => {
      setMonetizationOpen(!monetizationOpen);
    };

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                    <Avatar src={accountInfo.picture ? accountInfo.picture : undefined}>
                        {!accountInfo.picture && accountInfo.initials}
                    </Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => {
                    setOpenedProfileTab(0)
                    handleShowProfile()
                }}>
                    <Avatar /> Profile
                </MenuItem>
                <Divider />
                {/* <MenuItem onClick={() => {
                    setOpenedProfileTab(1)
                    handleShowProfile()
                }}>
                    <ListItemIcon>
                        <FaCrown />
                    </ListItemIcon>
                    My Plan
                </MenuItem> */}
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>
                <MenuItem onClick={handleLogoutTrigger}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
            <MonetizationDialog
                accountInfo={accountInfo}
                open={monetizationOpen}
                onClose={toggleMonetizationDialog}
            />
            <ProfilePage 
                accountInfo={accountInfo}
                open={profileOpen}
                onClose={handleCloseProfile}
                userProfileLoading={userProfileLoading}
                userTypeLoading={userTypeLoading}
                openedProfileTab={openedProfileTab}
            />
        </React.Fragment>
    );
}