import React, { useState, useEffect } from 'react';
import { 
  Stepper, 
  Step, 
  StepButton,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Tooltip,
  Snackbar,
  Alert,
  CircularProgress
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Check, TipsAndUpdates, ExpandMore, ExpandLess, Warning } from '@mui/icons-material';
import processConfig from '../../config/processIndex.json';
import stepperConfig from '../../config/MultiLevelStepper.json';
import { ProcessChainManager } from '../../utils/processChain';
import { isFeatureEnabled, getFeatureFlagMetadata } from '../../config/featureFlags';
import { loadingGlowStatus, readyGlowStatus, selectGlowStatus } from '../session/sessionSlide';
import { useSelector } from 'react-redux';

const getCustomTheme = (isDarkMode) => createTheme({
  palette: {
    mode: isDarkMode ? 'dark' : 'light',
    primary: {
      main: '#FD9800',
      light: 'rgba(253, 152, 0, 0.12)',
    },
    success: {
      main: '#28a745',
    },
    background: {
      paper: isDarkMode ? '#1C1C1E' : '#FFFFFF',
      default: isDarkMode ? '#000000' : '#F5F5F5',
    },
    text: {
      primary: isDarkMode ? '#FFFFFF' : '#1C1C1E',
      secondary: isDarkMode ? '#8E8E93' : '#6C757D',
    },
    divider: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.875rem',
          padding: '8px 12px',
          maxWidth: 300,
          backgroundColor: isDarkMode ? '#2C2C2E' : '#FFFFFF',
          color: isDarkMode ? '#FFFFFF' : '#1C1C1E',
          border: `1px solid ${isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'}`,
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
        labelContainer: {
          color: 'inherit',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: '#28a745',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '@keyframes pulse': {
            '0%': {
              boxShadow: '0 0 0 0 rgba(253, 152, 0, 0.4)',
            },
            '70%': {
              boxShadow: '0 0 0 10px rgba(253, 152, 0, 0)',
            },
            '100%': {
              boxShadow: '0 0 0 0 rgba(253, 152, 0, 0)',
            },
          },
          '@keyframes spin': {
            '0%': {
              transform: 'rotate(0deg)',
            },
            '100%': {
              transform: 'rotate(360deg)',
            },
          },
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'DM Sans',
      'sans-serif'
    ].join(','),
    h1: { fontWeight: 700 },
    h2: { fontWeight: 700 },
    h3: { fontWeight: 700 },
    h4: { fontWeight: 500 },
    h5: { fontWeight: 500 },
    h6: { fontWeight: 500 },
    subtitle1: { fontWeight: 400 },
    subtitle2: { fontWeight: 400 },
    body1: { fontWeight: 400 },
    body2: { fontWeight: 400 },
    button: {
      fontWeight: 500,
      textTransform: 'none',
    },
  },
});

const StepContent = ({ process, isCompleted, isLoading, isReady, index, chainId }) => {
  // Find the process configuration to get the devReference
  const processDetail = processConfig.processes.find(p => p.id === process.id);
  const devReference = processDetail?.devReference;
  const isActive = process.id === processDetail?.id;
  const theme = useTheme();
  
  // Different categories will number from 1 for each category
  const stepNumber = index + 1;
  
  // Check if this is the initial idea input process
  const isIdeaInput = process.id === processConfig.initialProcess.id;
  const needsAttention = isIdeaInput && !isCompleted;

  // Determine if this is part of the video chain for styling
  const isVideoChain = chainId === 'videoChain';
  const primaryColor = isVideoChain ? '#4B70E2' : '#FD9800';

  // Use loadingProcesses as the primary name
  const loadingProcesses = useSelector((state) => loadingGlowStatus(state));

  return (
    <Box position="relative" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
      {/* Step number indicator */}
      <Box
        sx={{
          position: 'absolute',
          left: -28, // Keeping the horizontal position
          top: '50%', // Center vertically
          transform: 'translateY(-50%)', // Ensure perfect vertical centering
          width: 22, 
          height: 22,
          borderRadius: '50%',
          backgroundColor: isActive ? primaryColor : 'transparent',
          border: '1px solid',
          borderColor: isActive ? primaryColor : 'rgba(0,0,0,0.15)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: isActive 
            ? (theme.palette.mode === 'dark'
                ? 'rgba(0, 0, 0, 0.85)'
                : 'white')
            : theme.palette.text.secondary,
          fontWeight: 'bold',
          fontSize: '0.7rem', // Slightly smaller font
          boxShadow: isActive ? '0 1px 3px rgba(0,0,0,0.1)' : 'none',
          transition: 'all 0.3s ease',
        }}
      >
        {stepNumber}
      </Box>
    
      {/* Status icon - conditional rendering based on state */}
      <Box minWidth={28} display="flex" justifyContent="center" alignItems="center" mr={1}>
        {/* Loading state takes priority over all others */}
        {loadingProcesses[process?.id] && (
          <CircularProgress
            size={16}
            thickness={6}
            sx={{
              color: primaryColor,
              // Add debug information as data attribute
              '&::after': {
                content: `"loading: ${isLoading}, completed: ${isCompleted}, ready: ${isReady}"`,
                display: 'none' // Only for debugging with dev tools
              }
            }} 
          />
        )}
        
        {/* Priority 1: Completed state - only when a user has actively completed the step */}
        {!loadingProcesses[process?.id] && isCompleted && (
          <Check 
            fontSize="small" 
            sx={{ 
              color: 'success.main',
              // Add debug information as data attribute
              '&::after': {
                content: `"completed: ${isCompleted}, ready: ${isReady}"`,
                display: 'none' // Only for debugging with dev tools
              }
            }} 
          />
        )}
        
        {/* Priority 2: Ready-to-be-interacted-with state - when API response is loaded but not completed yet */}
        {!loadingProcesses[process?.id] && !isCompleted && isReady && (
          <TipsAndUpdates 
            fontSize="small" 
            sx={{ 
              color: primaryColor,
              // Add debug information as data attribute
              '&::after': {
                content: `"completed: ${isCompleted}, ready: ${isReady}"`,
                display: 'none' // Only for debugging with dev tools
              }
            }} 
          />
        )}
        
        {/* Priority 3: Warning for idea input that hasn't been filled */}
        {!loadingProcesses[process?.id] && !isCompleted && !isReady && needsAttention && (
          <Warning
            fontSize="small"
            sx={{
              color: primaryColor,
              animation: 'pulse 2s infinite',
              '@keyframes pulse': {
                '0%': { opacity: 0.6 },
                '50%': { opacity: 1 },
                '100%': { opacity: 0.6 }
              }
            }}
          />
        )}
        
        {/* Priority 4: Not started yet - show empty checkbox */}
        {!loadingProcesses[process?.id] && !isCompleted && !isReady && !needsAttention && (
          <Box sx={{ 
            width: 16,
            height: 16,
            border: '1px solid', 
            borderColor: 'divider', 
            borderRadius: '2px',
            opacity: 0.8,
            // Add debug information as data attribute
            '&::after': {
              content: `"completed: ${isCompleted}, ready: ${isReady}"`,
              display: 'none' // Only for debugging with dev tools
            }
          }} />
        )}
      </Box>
      
      {/* Content container for process name and reference */}
      <Box sx={{
        position: 'relative',
        flexGrow: 1,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        pl: 0.5, // Add small left padding
      }}>
        {/* Process name */}
        <Typography
          variant="body2"
          noWrap
          sx={{
            fontSize: '0.95rem',
            fontWeight: isActive ? 500 : 400,
            lineHeight: 1.3,
            flexGrow: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            pr: devReference ? 3 : 1, // Space for reference number
            color: needsAttention ? primaryColor : 'inherit',
            fontWeight: needsAttention ? 600 : (isActive ? 500 : 400),
          }}
        >
          {process.displayName}
          {needsAttention && (
            <Typography
              component="span"
              sx={{
                display: 'inline',
                fontSize: '0.8rem',
                color: primaryColor,
                ml: 1,
                fontStyle: 'italic'
              }}
            >
              (required)
            </Typography>
          )}
        </Typography>

        {/* Reference number */}
        {devReference && (
          <Typography
            variant="caption"
            sx={{
              color: theme.palette.mode === 'dark' 
                ? 'rgba(180, 180, 180, 0.7)'
                : 'rgba(0, 0, 0, 0.55)',
              fontSize: '0.7rem',
              fontWeight: 500,
              opacity: theme.palette.mode === 'dark' ? 0.7 : 0.7,
              fontFamily: 'monospace',
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              width: '22px',
              textAlign: 'right',
            }}
          >
            {devReference}
          </Typography>
        )}
      </Box>
      
      {/* Loading indicator */}
      {loadingProcesses[process?.id] && (
        <Box
          sx={{
            position: 'absolute',
            right: -20,
            top: '50%',
            transform: 'translateY(-50%)',
            color: 'text.primary',
            fontSize: '0.75rem',
            display: 'flex',
            alignItems: 'center',
            '&::after': {
              content: '""',
              display: 'inline-block',
              width: '1em',
              height: '1em',
              border: '2px solid currentColor',
              borderRadius: '50%',
              borderTopColor: 'transparent',
              animation: 'spin 1s linear infinite'
            }
          }}
        />
      )}
    </Box>
  );
};

const MultiLevelStepper = ({ 
  onProcessSelect,
  activeProcessId,
  isProcessRunning = false,
  isDarkTheme = false,
}) => {
  const theme = useTheme();
  // eslint-disable-next-line no-unused-vars
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeStep, setActiveStep] = useState(0);
  const [toast, setToast] = useState({ open: false, message: '' });
  // eslint-disable-next-line no-unused-vars
  const [highlightedStep, setHighlightedStep] = useState(null);
  // Add state for highlighted section
  const [highlightedSection, setHighlightedSection] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [processResponses, setProcessResponses] = useState({});
  // Track expanded/collapsed state of categories
  const [expandedCategories, setExpandedCategories] = useState({});


  const completedProcesses = useSelector((state) => 
    ProcessChainManager.processOrder.reduce((acc, glowId) => {
      acc[glowId] = selectGlowStatus(state, glowId);
      return acc;
    }, {})
  );
  // console.log('%ccompletedProcesses', 'font-size: 50px; color: red;', completedProcesses);
  
  // Use readyProcesses as the primary name
  const readyProcesses = useSelector((state) => 
    ProcessChainManager.processOrder.reduce((acc, glowId) => {
      acc[glowId] = readyGlowStatus(state, glowId);
      return acc;
    }, {})
  );
  // console.log('%creadyProcesses', 'font-size: 50px; color: red;', readyProcesses);
  
  // Use loadingProcesses as the primary name
  const loadingProcesses = useSelector((state) => loadingGlowStatus(state));
  // console.log('%cloadingProcesses', 'font-size: 50px; color: red;', loadingProcesses);


  // Add event listener for navigation between processes
  useEffect(() => {
    const handleNavigateToProcess = (event) => {
      const { processId } = event.detail;
      if (processId) {
        console.log('MultiLevelStepper received navigate-to-process event:', processId);
        handleStepClick(processId);
      }
    };

    window.addEventListener('navigate-to-process', handleNavigateToProcess);
    
    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('navigate-to-process', handleNavigateToProcess);
    };
  }, [onProcessSelect]); // Re-establish listener if onProcessSelect changes

  // Add event listener for highlighting sections
  useEffect(() => {
    const handleHighlightSection = (event) => {
      const { section } = event.detail;
      if (section) {
        // console.log('MultiLevelStepper received highlight-stepper-section event:', section);
        setHighlightedSection(section);
      }
    };

    const handleResetHighlight = () => {
      setHighlightedSection(null);
    };

    window.addEventListener('highlight-stepper-section', handleHighlightSection);
    window.addEventListener('reset-stepper-highlight', handleResetHighlight);
    
    return () => {
      window.removeEventListener('highlight-stepper-section', handleHighlightSection);
      window.removeEventListener('reset-stepper-highlight', handleResetHighlight);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  // Get configuration options
  const config = stepperConfig.config;
  const collapsible = config.collapsibleSections;
  const defaultExpanded = config.defaultExpandedSections;

  const getStepperCategories = () => {
    const chainIds = ProcessChainManager.getChainIds();
    
    return chainIds.map(chainId => {
      const metadata = ProcessChainManager.getChainMetadata(chainId);
      const processes = ProcessChainManager.getChainProcesses(chainId)
        .map(processId => {
          const process = processConfig.processes.find(p => p.id === processId);
          
          if (!process) {
            console.warn(`Process ${processId} not found in configuration`);
            return null;
          }
          
          // Add disabled status to processes with missing feature flags
          let disabled = false;
          let disabledReason = '';
          
          // Special case for glow_04
          if (processId === 'glow_04' && !isFeatureEnabled('SHOW_GLOW_04')) {
            disabled = true;
            const metadata = getFeatureFlagMetadata('SHOW_GLOW_04');
            disabledReason = metadata ? metadata.description : 'Feature temporarily disabled';
          }
          
          // Special case for glow_63
          if (processId === 'glow_63' && !isFeatureEnabled('SHOW_GLOW_63')) {
            disabled = true;
            const metadata = getFeatureFlagMetadata('SHOW_GLOW_63');
            disabledReason = metadata ? metadata.description : 'Feature temporarily disabled';
          }
          
          return {
            id: process.id,
            displayName: process.displayName,
            description: process.description + (disabledReason ? `\n\nNote: ${disabledReason}` : ''),
            component: process.viewerComponent,
            disabled
          };
        })
        .filter(Boolean);

      return {
        chainId,
        title: metadata.title,
        description: metadata.description,
        disabled: metadata.disabled,
        processes
      };
    });
  };

  const categories = getStepperCategories();
  
  // Initialize all categories based on configuration
  useEffect(() => {
    const initialExpandedState = {};
    categories.forEach(category => {
      initialExpandedState[category.chainId] = defaultExpanded;
    });
    setExpandedCategories(initialExpandedState);
  }, [defaultExpanded]);

  const toggleCategoryExpansion = (chainId) => {
    if (!collapsible) return; // Don't toggle if collapsible is disabled
    
    setExpandedCategories(prev => ({
      ...prev,
      [chainId]: !prev[chainId]
    }));
  };

  const handleNext = () => {
    const nextStep = Math.min(activeStep + 1, categories.length - 1);
    setActiveStep(nextStep);
    if (categories[nextStep]) {
      onProcessSelect(categories[nextStep].id);
    }
  };

  const handleBack = () => {
    const prevStep = Math.max(activeStep - 1, 0);
    setActiveStep(prevStep);
    if (categories[prevStep]) {
      onProcessSelect(categories[prevStep].id);
    }
  };

  const getProcessReadiness = () => {
    return { ready: true };
  };

  const handleStepClick = (processId) => {
    onProcessSelect(processId);
    setActiveStep(categories.findIndex(p => p.id === processId));
  };

  // Load cache when component mounts
  useEffect(() => {
    ProcessChainManager.loadCache();
    
    // // Debug logging for processes and completion states
    // console.log('MultiLevelStepper - Process Chains:', ProcessChainManager.getChainIds().map(chainId => ({
    //   chainId,
    //   processes: ProcessChainManager.getChainProcesses(chainId)
    // })));
    // console.log('MultiLevelStepper - Completed Processes:', completedProcesses);
    // console.log('MultiLevelStepper - Ready Processes:', readyProcesses);
  }, [completedProcesses, readyProcesses]);

  useEffect(() => {
    if (activeProcessId) {
      // Find the category containing the active process and ensure it's expanded
      const category = categories.find(category => 
        category.processes.some(process => process.id === activeProcessId)
      );
      
      if (category) {
        setExpandedCategories(prev => ({
          ...prev,
          [category.chainId]: true
        }));
        
        setActiveStep(
          categories.findIndex(p => p.id === activeProcessId)
        );
      }
    }
  }, [activeProcessId]);

  // Process state visualization:
  // We use three visual states for a process in the stepper:
  // 1. Empty box = Not started (neither completed nor ready)
  // 2. Eye icon = Ready (API data loaded but not completed)
  // 3. Checkmark = Completed (user clicked Next/Continue)

  return (
    <ThemeProvider theme={getCustomTheme(isDarkTheme)}>
      <Box sx={{ 
        width: '280px',
        height: '100%',
        borderRight: '1px solid',
        borderColor: 'divider',
        bgcolor: 'background.paper',
        overflowY: 'auto',
        position: { xs: 'relative', sm: 'fixed' },
        left: { xs: 'auto', sm: 0 },
        top: { xs: 'auto', sm: '60px' },
        '& .MuiStepper-root': {
          padding: { xs: '0px 0px 0px 40px', sm: '0px 0px 0px 40px' },
          position: 'relative',
        },
        '& .MuiStepConnector-line': {
          display: 'none', // Hide connector lines completely
        },
        '& .MuiStepConnector-root': {
          display: 'none', // Hide connector root completely
        },
        '& .MuiStepLabel-root': {
          padding: 0,
          minHeight: '32px',
          height: '32px',
          marginTop: 1, // Less vertical spacing
          marginBottom: 1,
        },
        '& .MuiStep-root': {
          marginBottom: 0,
          minHeight: 0,
          padding: 0,
        },
        '& .MuiStepIcon-root': {
          display: 'none', // Hide the default step icons
        },
        '& .MuiStepLabel-label': {
          color: 'text.primary',
          fontSize: '0.95rem',
          lineHeight: 1.3,
          '&.Mui-active': {
            color: 'primary.main',
            fontWeight: 600
          },
          '&.Mui-completed': {
            color: 'text.primary'
          }
        }
      }}>
        {/* Handle special case for idea_description if hideIdeaInputChainCategory is true */}
        {config.hideIdeaInputChainCategory && ProcessChainManager.getChainProcesses('ideaInputChain').map(processId => {
          const process = processConfig.processes.find(p => p.id === processId);
          if (process) {
            return (
              <Box key={process.id} sx={{ mt: 3, mb: 3 }}>
                <Stepper orientation="vertical" nonLinear>
                  <Step key={process.id} active={process.id === activeProcessId}>
                    <Tooltip title={process.description || ''} placement="right">
                      <StepButton
                        onClick={() => handleStepClick(process.id)}
                        completed={completedProcesses[process.id]?.toString()}
                        sx={{
                          'padding': '4px 12px 4px 0px',
                          'height': '32px',
                          'borderRadius': '0px',
                          'my': 0,
                          'marginLeft': '-18px', // Extend to the left edge
                          'paddingLeft': '12px', // Add padding back for content
                          'width': 'calc(100% + 18px)', // Ensure full width
                          'backgroundColor': process.id === activeProcessId 
                            ? theme => theme.palette.mode === 'dark'
                              ? 'rgba(253, 152, 0, 0.15)' // Increased opacity for better visibility
                              : 'rgba(253, 152, 0, 0.08)' // Increased opacity slightly
                            : 'transparent',
                          'boxShadow': process.id === activeProcessId 
                            ? '0 0 0 1px rgba(253, 152, 0, 0.3) inset' // Add subtle orange border
                            : 'none',
                          '&:hover': {
                            bgcolor: theme => theme.palette.mode === 'dark'
                              ? 'rgba(255, 255, 255, 0.08)'
                              : 'rgba(0, 0, 0, 0.03)'
                          },
                          '& .MuiStepLabel-label': {
                            width: '100%',
                            padding: 0,
                            margin: 0,
                            color: completedProcesses[process.id] ? 'text.primary' : 'text.secondary',
                            transition: 'all 0.2s ease',
                            '&.Mui-active': {
                              color: '#FD9800',
                              fontWeight: 600,
                            },
                          }
                        }}
                      >
                        <StepContent 
                          process={process} 
                          isCompleted={completedProcesses[process.id] || false} 
                          isLoading={isProcessRunning && process.id === activeProcessId} 
                          isReady={readyProcesses[process.id] || false}
                          index={0} 
                          chainId="ideaInputChain"
                        />
                      </StepButton>
                    </Tooltip>
                  </Step>
                </Stepper>
              </Box>
            );
          }
          return null;
        })}
        
        {/* Render all categories except ideaInputChain if it's set to be hidden */}
        {categories.map((category) => {
          // Skip ideaInputChain if hideIdeaInputChainCategory is true
          if (config.hideIdeaInputChainCategory && category.chainId === 'ideaInputChain') {
            return null;
          }
          
          return (
            <Box key={category.chainId} sx={{ mb: 0.5 }}>
              <Typography
                variant="h6"
                onClick={() => toggleCategoryExpansion(category.chainId)}
                sx={{
                  px: 0,
                  pl: '10px',
                  py: 1.5,
                  color: highlightedSection === category.chainId 
                    ? category.chainId === 'videoChain' ? '#4B70E2' : 'primary.main' 
                    : 'text.primary',
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                  opacity: category.disabled ? 0.5 : 1,
                  fontSize: '0.95rem',
                  fontWeight: highlightedSection === category.chainId ? 700 : 600,
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  bgcolor: highlightedSection === category.chainId 
                    ? theme => theme.palette.mode === 'dark' 
                      ? category.chainId === 'videoChain'
                        ? 'rgba(75, 112, 226, 0.2)' 
                        : 'rgba(253, 152, 0, 0.2)'
                      : category.chainId === 'videoChain'
                        ? 'rgba(75, 112, 226, 0.1)'
                        : 'rgba(253, 152, 0, 0.1)'
                    : theme => theme.palette.mode === 'dark' 
                      ? 'rgba(255,255,255,0.05)' 
                      : 'rgba(0,0,0,0.03)',
                  letterSpacing: '0.01em',
                  marginBottom: 1.5,
                  textAlign: 'left',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  cursor: collapsible ? 'pointer' : 'default',
                  transition: 'all 0.3s ease',
                  boxShadow: highlightedSection === category.chainId 
                    ? category.chainId === 'videoChain'
                      ? '0 0 0 1px rgba(75, 112, 226, 0.5) inset'
                      : '0 0 0 1px rgba(253, 152, 0, 0.5) inset'
                    : 'none',
                  '&:hover': collapsible ? {
                    bgcolor: theme => theme.palette.mode === 'dark' 
                      ? category.chainId === 'videoChain'
                        ? 'rgba(75, 112, 226, 0.15)'
                        : 'rgba(255,255,255,0.08)'
                      : category.chainId === 'videoChain'
                        ? 'rgba(75, 112, 226, 0.08)'
                        : 'rgba(0,0,0,0.05)',
                  } : {},
                }}
              >
                <span>{category.title}</span>
                {collapsible && (
                  expandedCategories[category.chainId] ? 
                    <ExpandLess fontSize="small" sx={{ ml: 1 }} /> : 
                    <ExpandMore fontSize="small" sx={{ ml: 1 }} />
                )}
              </Typography>
              {(!collapsible || expandedCategories[category.chainId]) && (
                <Stepper orientation="vertical" nonLinear>
                  {category.processes.map((process, index) => (
                    <Step key={process.id} active={process.id == activeProcessId ? true : false}>
                      <Tooltip title={process.description || ''} placement="right">
                        <StepButton
                          onClick={() => handleStepClick(process.id)}
                          completed={completedProcesses[process.id]?.toString()}
                          disabled={category.disabled}
                          sx={{
                            'padding': '4px 12px 4px 0px',
                            'height': '32px',
                            'borderRadius': '0px',
                            'my': 0,
                            'marginLeft': '-18px', // Extend to the left edge
                            'paddingLeft': '12px', // Add padding back for content
                            'width': 'calc(100% + 18px)', // Ensure full width
                            'backgroundColor': process.id === activeProcessId 
                              ? theme => theme.palette.mode === 'dark'
                                ? category.chainId === 'videoChain'
                                  ? 'rgba(75, 112, 226, 0.15)' // Blue for video chain
                                  : 'rgba(253, 152, 0, 0.15)' // Orange for other chains
                                : category.chainId === 'videoChain'
                                  ? 'rgba(75, 112, 226, 0.08)' // Light blue for video chain
                                  : 'rgba(253, 152, 0, 0.08)' // Light orange for other chains
                              : 'transparent',
                            'boxShadow': process.id === activeProcessId 
                              ? category.chainId === 'videoChain'
                                ? '0 0 0 1px rgba(75, 112, 226, 0.3) inset' // Blue border for video chain
                                : '0 0 0 1px rgba(253, 152, 0, 0.3) inset' // Orange border for other chains
                              : 'none',
                            '&:hover': {
                              bgcolor: theme => theme.palette.mode === 'dark'
                                ? category.chainId === 'videoChain'
                                  ? 'rgba(75, 112, 226, 0.1)' // Blue hover for video chain
                                  : 'rgba(255, 255, 255, 0.08)' // White hover for other chains
                                : category.chainId === 'videoChain'
                                  ? 'rgba(75, 112, 226, 0.05)' // Light blue hover for video chain
                                  : 'rgba(0, 0, 0, 0.03)' // Light black hover for other chains
                            },
                            '& .MuiStepLabel-label': {
                              width: '100%',
                              padding: 0,
                              margin: 0,
                              color: completedProcesses[process.id] ? 'text.primary' : 'text.secondary',
                              transition: 'all 0.2s ease',
                              '&.Mui-active': {
                                color: category.chainId === 'videoChain' ? '#4B70E2' : '#FD9800',
                                fontWeight: 600,
                              },
                            }
                          }}
                        >
                          <StepContent 
                            process={process} 
                            isCompleted={completedProcesses[process.id] || false} 
                            isLoading={isProcessRunning && process.id === activeProcessId} 
                            isReady={readyProcesses[process.id] || false}
                            index={index} 
                            chainId={category.chainId}
                          />
                        </StepButton>
                      </Tooltip>
                    </Step>
                  ))}
                </Stepper>
              )}
            </Box>
          );
        })}
        
        <Snackbar
          open={toast.open}
          autoHideDuration={4000}
          onClose={() => setToast({ ...toast, open: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={() => setToast({ ...toast, open: false })} 
            severity="info" 
            sx={{ width: '100%' }}
          >
            {toast.message}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default MultiLevelStepper;