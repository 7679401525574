import { createSlice } from '@reduxjs/toolkit';

// Session-specific structure that will be used for each session
const emptySessionState = {
  userType: null, // 'business' for Entrepreneur or 'creators' for Video Producer
  glow_61: null,
  glow_62: null,
  glow_63: null,
  glow_102: null,
  glow_08: {
    answers: {
      stage: null,
      validation: null,
      team_expertise: null,
      team: null,
      budget: null,
      goal: null
    },
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
  },
  glow_33: {
    answers: {
      amount: null,
      groupNames: [],
      goal: null
    },
    status: 'idle',
    error: null
  },
  // Add other Glow steps here as needed
  glow_91: null,
  glow_92: null,
};

// New initial state with sessions
const initialState = {
  // Sessions object will hold data for each session
  sessions: {
    // Default session with ID 'current' for backward compatibility
    current: { ...emptySessionState }
  },
  currentSessionId: 'current',
  status: 'idle',
  error: null
};

const glowStepsSlice = createSlice({
  name: 'glowSteps',
  initialState,
  reducers: {
    // Set the current active session
    setCurrentSession: (state, action) => {
      const sessionId = action.payload;
      console.log(`Setting current session to: ${sessionId}`);
      
      // Create the session if it doesn't exist
      if (!state.sessions[sessionId]) {
        state.sessions[sessionId] = { ...emptySessionState };
      }
      
      state.currentSessionId = sessionId;
    },
    
    // Update a Glow step for the current session
    updateGlowStep: (state, action) => {
      const { stepId, data, sessionId = state.currentSessionId } = action.payload;
      console.log(`Updating ${stepId} for session ${sessionId} with:`, data);
      
      // Ensure the session exists
      if (!state.sessions[sessionId]) {
        state.sessions[sessionId] = { ...emptySessionState };
      }
      
      state.sessions[sessionId][stepId] = data;
    },
    
    // Clear all Glow steps for a specific session
    clearGlowSteps: (state, action) => {
      const sessionId = action.payload || state.currentSessionId;
      console.log(`Clearing all glow steps for session: ${sessionId}`);
      
      if (state.sessions[sessionId]) {
        state.sessions[sessionId] = { ...emptySessionState };
      }
    },
    
    // Update Glow 08 answer for the current session
    updateGlow08Answer: (state, action) => {
      const { data, sessionId = state.currentSessionId } = action.payload;
      
      // Ensure the session and glow_08 exist
      if (!state.sessions[sessionId]) {
        state.sessions[sessionId] = { ...emptySessionState };
      }
      
      if (!state.sessions[sessionId].glow_08) {
        state.sessions[sessionId].glow_08 = { ...emptySessionState.glow_08 };
      }
      
      state.sessions[sessionId].glow_08.answers = {
        ...state.sessions[sessionId].glow_08.answers,
        ...data
      };
    },
    
    // Set Glow 08 status for the current session
    setGlow08Status: (state, action) => {
      const { status, sessionId = state.currentSessionId } = action.payload;
      
      // Ensure the session and glow_08 exist
      if (!state.sessions[sessionId]) {
        state.sessions[sessionId] = { ...emptySessionState };
      }
      
      if (!state.sessions[sessionId].glow_08) {
        state.sessions[sessionId].glow_08 = { ...emptySessionState.glow_08 };
      }
      
      state.sessions[sessionId].glow_08.status = status;
    },
    
    // Clear Glow 08 for the current session
    clearGlow08: (state, action) => {
      const sessionId = action.payload || state.currentSessionId;
      
      if (state.sessions[sessionId] && state.sessions[sessionId].glow_08) {
        state.sessions[sessionId].glow_08 = { ...emptySessionState.glow_08 };
      }
    },
    
    // Update Glow 33 answer for the current session
    updateGlow33Answer: (state, action) => {
      const { data, sessionId = state.currentSessionId } = action.payload;
      console.log('Updating glow_33 answer:', data);
      
      // Ensure the session and glow_33 exist
      if (!state.sessions[sessionId]) {
        state.sessions[sessionId] = { ...emptySessionState };
      }
      
      if (!state.sessions[sessionId].glow_33) {
        state.sessions[sessionId].glow_33 = { ...emptySessionState.glow_33 };
      }
      
      state.sessions[sessionId].glow_33.answers = {
        ...state.sessions[sessionId].glow_33.answers,
        ...data
      };
    },
    
    // Set Glow 33 status for the current session
    setGlow33Status: (state, action) => {
      const { status, sessionId = state.currentSessionId } = action.payload;
      
      // Ensure the session and glow_33 exist
      if (!state.sessions[sessionId]) {
        state.sessions[sessionId] = { ...emptySessionState };
      }
      
      if (!state.sessions[sessionId].glow_33) {
        state.sessions[sessionId].glow_33 = { ...emptySessionState.glow_33 };
      }
      
      state.sessions[sessionId].glow_33.status = status;
    },
    
    // Clear Glow 33 for the current session
    clearGlow33: (state, action) => {
      const sessionId = action.payload || state.currentSessionId;
      
      if (state.sessions[sessionId] && state.sessions[sessionId].glow_33) {
        state.sessions[sessionId].glow_33 = { ...emptySessionState.glow_33 };
      }
    },
    
    // Set the user type for the current session
    setUserType: (state, action) => {
      const { userType, sessionId = state.currentSessionId } = action.payload;
      console.log(`Setting user type to ${userType} for session ${sessionId}`);
      
      // Ensure the session exists
      if (!state.sessions[sessionId]) {
        state.sessions[sessionId] = { ...emptySessionState };
      }
      
      state.sessions[sessionId].userType = userType;
    }
  }
});

export const {
  setCurrentSession,
  updateGlowStep: updateGlowStepInternal,
  clearGlowSteps: clearGlowStepsInternal,
  updateGlow08Answer: updateGlow08AnswerInternal,
  setGlow08Status: setGlow08StatusInternal,
  clearGlow08: clearGlow08Internal,
  updateGlow33Answer: updateGlow33AnswerInternal,
  setGlow33Status: setGlow33StatusInternal,
  clearGlow33: clearGlow33Internal,
  setUserType
} = glowStepsSlice.actions;

// Backward compatibility wrappers for existing components
export const updateGlowStep = (payload) => {
  // If the incoming payload uses the old format, transform it
  if (payload.stepId && !payload.sessionId) {
    return updateGlowStepInternal(payload);
  }
  // For legacy components using old format with 'step' instead of 'stepId'
  if (payload.step && !payload.stepId) {
    return updateGlowStepInternal({
      ...payload,
      stepId: payload.step
    });
  }
  return updateGlowStepInternal(payload);
};

export const clearGlowSteps = (sessionId) => {
  return clearGlowStepsInternal(sessionId);
};

export const updateGlow08Answer = (payload) => {
  // Transform from old format that just passed the data directly
  if (typeof payload !== 'object' || !payload.data) {
    return updateGlow08AnswerInternal({ data: payload });
  }
  return updateGlow08AnswerInternal(payload);
};

export const setGlow08Status = (status) => {
  // Transform from old format that just passed the status directly
  if (typeof status !== 'object' || !status.status) {
    return setGlow08StatusInternal({ status });
  }
  return setGlow08StatusInternal(status);
};

export const clearGlow08 = (sessionId) => {
  return clearGlow08Internal(sessionId);
};

export const updateGlow33Answer = (payload) => {
  // Transform from old format that just passed the data directly
  if (typeof payload !== 'object' || !payload.data) {
    return updateGlow33AnswerInternal({ data: payload });
  }
  return updateGlow33AnswerInternal(payload);
};

export const setGlow33Status = (status) => {
  // Transform from old format that just passed the status directly
  if (typeof status !== 'object' || !status.status) {
    return setGlow33StatusInternal({ status });
  }
  return setGlow33StatusInternal(status);
};

export const clearGlow33 = (sessionId) => {
  return clearGlow33Internal(sessionId);
};

// Updated selectors to work with the session-based structure
export const selectCurrentSessionId = (state) => state.glowSteps.currentSessionId;

export const selectSessionGlowSteps = (state, sessionId) => {
  const id = sessionId || state.glowSteps.currentSessionId;
  return state.glowSteps.sessions[id] || emptySessionState;
};

export const selectGlowStep = (state, stepId, sessionId) => {
  const id = sessionId || state.glowSteps.currentSessionId;
  console.log(`Selecting ${stepId} from session ${id}:`, state.glowSteps.sessions[id]?.[stepId]);
  return state.glowSteps.sessions[id]?.[stepId] || null;
};

export const selectAllGlowSteps = (state, sessionId) => {
  const id = sessionId || state.glowSteps.currentSessionId;
  return state.glowSteps.sessions[id] || emptySessionState;
};

export const selectGlow08Answers = (state, sessionId) => {
  const id = sessionId || state.glowSteps.currentSessionId;
  return state.glowSteps.sessions[id]?.glow_08?.answers || emptySessionState.glow_08.answers;
};

export const selectGlow08Status = (state, sessionId) => {
  const id = sessionId || state.glowSteps.currentSessionId;
  return state.glowSteps.sessions[id]?.glow_08?.status || 'idle';
};

export const selectGlow33Answers = (state, sessionId) => {
  const id = sessionId || state.glowSteps.currentSessionId;
  return state.glowSteps.sessions[id]?.glow_33?.answers || emptySessionState.glow_33.answers;
};

export const selectGlow33Status = (state, sessionId) => {
  const id = sessionId || state.glowSteps.currentSessionId;
  return state.glowSteps.sessions[id]?.glow_33?.status || 'idle';
};

// Selector to get the user type from the current session
export const selectUserType = (state, sessionId) => {
  // Add defensive checks to prevent "Cannot read properties of undefined" error
  if (!state || !state.glowSteps) {
    return null;
  }
  
  const id = sessionId || state.glowSteps.currentSessionId;
  return state.glowSteps.sessions?.[id]?.userType || null;
};

export default glowStepsSlice.reducer; 