import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Switch, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemSecondaryAction,
  Divider,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  useTheme
} from '@mui/material';
import { ExpandMore, SettingsOutlined } from '@mui/icons-material';
import { getAllFeatureFlagsWithMetadata, isFeatureEnabled } from '../../config/featureFlags';

/**
 * A panel for developers to view and manage feature flags during development
 */
const FeatureFlagsPanel = () => {
  const [flags, setFlags] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  
  useEffect(() => {
    // Load all feature flags with their metadata
    setFlags(getAllFeatureFlagsWithMetadata());
  }, []);
  
  const handleToggle = (flagName) => {
    // In this implementation, toggles only affect the UI and are not persisted
    // Changes are lost on page refresh
    setFlags(prev => 
      prev.map(flag => 
        flag.name === flagName ? { ...flag, enabled: !flag.enabled } : flag
      )
    );
    
    // In a real implementation, we would update localStorage or a backend API
    alert(`Flag '${flagName}' toggled. Note: This is for demonstration only.\nActual values are controlled by environment variables and will reset on page refresh.`);
  };
  
  const getCategoryColor = (category) => {
    switch (category) {
      case 'Process':
        return theme.palette.primary.main;
      case 'Feature':
        return theme.palette.success.main;
      case 'UI':
        return theme.palette.info.main;
      default:
        return theme.palette.grey[500];
    }
  };
  
  if (!isFeatureEnabled('SHOW_DEVELOPER_TOOLS')) {
    return null;
  }
  
  return (
    <Accordion 
      expanded={expanded} 
      onChange={() => setExpanded(!expanded)}
      sx={{ 
        position: 'fixed',
        bottom: 0,
        right: 0,
        width: expanded ? 400 : 'auto',
        zIndex: 9999,
        opacity: 0.95,
        boxShadow: 3,
        m: 0
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box display="flex" alignItems="center">
          <SettingsOutlined sx={{ mr: 1 }} />
          <Typography variant="subtitle2">
            Feature Flags {!expanded && `(${flags.filter(f => f.enabled).length} active)`}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Paper sx={{ maxHeight: 300, overflow: 'auto', p: 0 }}>
          <List dense sx={{ p: 0 }}>
            {flags.map((flag, index) => (
              <React.Fragment key={flag.name}>
                {index > 0 && <Divider component="li" />}
                <ListItem>
                  <ListItemText
                    primary={
                      <Box display="flex" alignItems="center">
                        <Typography variant="body2">{flag.name}</Typography>
                        {flag.category && (
                          <Chip
                            label={flag.category}
                            size="small"
                            sx={{ 
                              ml: 1, 
                              height: 20, 
                              fontSize: '0.65rem',
                              backgroundColor: getCategoryColor(flag.category),
                              color: 'white'
                            }}
                          />
                        )}
                      </Box>
                    }
                    secondary={flag.description}
                    secondaryTypographyProps={{ variant: 'caption' }}
                  />
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      size="small"
                      checked={flag.enabled}
                      onChange={() => handleToggle(flag.name)}
                      color="primary"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Paper>
        <Box p={1} bgcolor="rgba(0,0,0,0.04)">
          <Typography variant="caption" color="textSecondary">
            Note: Toggles here are for demonstration purposes only. Actual values are controlled by environment variables.
          </Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default FeatureFlagsPanel; 