import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import AppWrapper from './AppWrapper';
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import { setupPreconnect } from './config/performance';
// import { ThemeProvider } from './ThemeContext';

// Setup performance optimizations
setupPreconnect();

// Create root with options for better hydration
const root = ReactDOM.createRoot(document.getElementById('root'), {
  onRecoverableError: (error) => {
    console.warn('Recovered from error:', error);
  },
  identifierPrefix: 'glow-app-' // Avoid ID conflicts
});

// Render with strict mode for development checks
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme} defaultMode="dark">
      <Provider store={store}>
        <AppWrapper />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);