/* eslint-disable react-hooks/rules-of-hooks */
// Force rebuild - all functions are defined but not being recognized
import React, { useState, useEffect, useRef, useCallback } from 'react';
// Disable ESLint rule for hooks - we have complex component structure
// eslint-disable-next-line react-hooks/rules-of-hooks
import Authenticate from '../../auth/authenticate'; // Import the Authenticate class
import AccountMenu from '../../components/ui/account_menu';
import { initializeApp } from "firebase/app";
import { getFirestore }  from "firebase/firestore";
import axios from 'axios';
import Cookies from 'js-cookie';
import './Journey.css';
import SEO from '../../components/SEO';
import { 
  FaList, 
  FaTools, 
  FaTimes,
  FaEdit,
  FaTrash,
  FaBars,
  FaBuilding 
} from 'react-icons/fa';
import { useColorScheme } from '@mui/material/styles';
import MultiLevelStepper from '../../components/stepper/MultiLevelStepper';
import processConfig from '../../config/processIndex.json';
import IdeaInputCard from '../../components/IdeaInputCard';
import { 
  Typography, 
  Button, 
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Alert,
  useMediaQuery
} from '@mui/material';
import ProcessCache from '../../utils/processCache';
import { ProcessChainManager } from '../../utils/processChain';
import ContrastIcon from '@mui/icons-material/Contrast';
import CreditFlag from '../../components/ui/credit_flag'; // Import the CreditFlag component
import theme from '../../theme';
import { getAuth  } from "firebase/auth";

// Import our analytics utilities
import { 
  initAnalytics, 
  trackButtonClick, 
  trackPageView, 
  trackEngagement,
  trackFeatureUsage,
  trackRetention,
  setUserAnalyticsProperties,
  setAnalyticsUserId
} from '../../utils/analyticsUtils';

import { 
  useDispatch, useSelector } from 'react-redux';
import { selectNotification, showNotification, closeNotification } from '../../components/notification/notificationSlide';
import { fetchCompanyList, selectSelectedCompany, companySelected, selectCompaniesStatus, selectCompaniesMessage } from '../../components/company/companySlide'
import { signInAnonymous, selectAccountInfo, selectToken, selectAuthenticateStatus, selectAuthenticateMessage, handleValidCredentials, loadCachedAccountInfo, getRemainingCredit, getUserProfile, mySubscription } from '../../auth/authenticateSlide';
import { 
  getSessionData, 
  selectSessionData, 
  selectSessionStatus, 
  selectSessionId, 
  selectGlowData, 
  selectGlowStatus,
  readyGlowStatus,
  updateGlowTextResponse,
  setSessionId as setSessionIdAction,
  setInputPrompt,
  clearSessionData,
  runProcess,
  selectSessionMessage,
  updateGlowReady,
  updateGlowStatus,
  updateGlowStep,
  loadingGlowStatus,
  updateGlowData
} from '../../components/session/sessionSlide';
import { setCurrentSession } from '../../components/session/sessionSlide';

//import Glow01Processor from '../../components/viewers/glow_01_processor_customer';
import Glow02Processor from '../../components/viewers/glow_02_processor_scene';
import Glow03Processor from '../../components/viewers/glow_03_processor_script';
import Glow04Processor from '../../components/viewers/glow_04_processor_icicle';
import Glow08Processor from '../../components/viewers/glow_08_processor_questions';
import Glow09Processor from '../../components/viewers/glow_09_processor_overview';
import Glow10Processor from '../../components/viewers/glow_10_processor_tamsamsom';
import Glow21Processor from '../../components/viewers/glow_21_market-comp-matrix';
import Glow11Processor from '../../components/viewers/glow_11_processor_prodprice';
import Glow30Processor from '../../components/viewers/glow_30_final_sales_pitch';
import Glow62Processor from '../../components/viewers/glow_62_processor_customer';
import Glow63Processor from '../../components/viewers/glow_63_processor_customer';
import Glow33Processor from '../../components/viewers/glow_33_processor_goal_base_analysis';
import Glow91Processor from '../../components/viewers/glow_91_processor_video_request_manager';
import Glow61Processor from '../../components/viewers/glow_61_processor_video_format';
import Glow102ProcessorVoiceNarration from '../../components/viewers/glow_102_processor_voice_narration';
import VideoQueueManager from '../../components/viewers/glow_92_processor_video_queue';
import Glow38ProcessorNewsVideo from '../../components/viewers/glow_38_processor_news_video';
import Companies from '../../components/company/company';
import MonetizationDialog from '../../components/ui/monetization'; // Import the monetization component
import { isFeatureEnabled } from '../../config/featureFlags';
import FeatureFlagsPanel from '../../components/developer/FeatureFlagsPanel';

const processComponents = {
  'glow_08': Glow08Processor,
  'glow_09': Glow09Processor,
  'glow_10': Glow10Processor,
  'glow_21': Glow21Processor,
  'glow_11': Glow11Processor,
  //'glow_01': Glow01Processor,
  'glow_02': Glow02Processor,
  'glow_03': Glow03Processor,
  'glow_04': Glow04Processor,
  'glow_30': Glow30Processor,
  'glow_33': Glow33Processor,
  'glow_38': Glow38ProcessorNewsVideo,
  'glow_61': Glow61Processor,
  'glow_102': Glow102ProcessorVoiceNarration,
  'glow_62': Glow62Processor,
  'glow_63': Glow63Processor,
  'glow_91': Glow91Processor,
  'glow_92': VideoQueueManager,
};

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
function Journey() {    
  // Add the useColorScheme hook at the beginning of the function
  const { mode, setMode } = useColorScheme();
  
  // Remove local sessionId state since we'll use Redux
  // const [sessionId, setSessionId] = useState("");
  const [currentProcess, setCurrentProcess] = useState("");
  const [inputText, setInputText] = useState("");
  const [devToolOpen, setDevToolOpen] = useState(false);
  const [apiResponses, setApiResponses] = useState([]);
  const [responseDetailsOpen, setResponseDetailsOpen] = useState([]);
  const [showResponseDetailsModal, setShowResponseDetailsModal] = useState(false);
  const [responseDetailsContent, setResponseDetailsContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [initializationAttempted, setInitializationAttempted] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    return localStorage.getItem('glow_dark_theme') === 'true';
  });
  const [toastMessage, setToastMessage] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteSessionId, setDeleteSessionId] = useState(null);
  const [deleteSessionName, setDeleteSessionName] = useState(null);
  const [isAnyProcessRunning, setIsAnyProcessRunning] = useState(false);
  const [showSessionList, setShowSessionList] = useState(false);
  const [sessionList, setSessionList] = useState([]);
  const [editingSessionId, setEditingSessionId] = useState(null);
  const [editedSessionName, setEditedSessionName] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [prettyJson, setPrettyJson] = useState(false);
  const [notice, setNotice] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [showSessionOptions, setShowSessionOptions] = useState(false);
  const [newSessionName, setNewSessionName] = useState('');
  const [dropdownRef, setDropdownRef] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);
  const [isJsonView, setIsJsonView] = useState(true);
  const [isDarkTheme, setIsDarkTheme] = useState((true));
  const [isProcessViewCollapsed, setIsProcessViewCollapsed] = useState({});
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [highlightedButton, setHighlightedButton] = useState(null);
  const [initialAnswers, setInitialAnswers] = useState(null)
  const [companyDrawerOpen, setCompanyDrawerOpen] = useState(false);
  const [toast, setToast] = useState({ show: false, message: '', type: '' });
  const modalRef = useRef(null);
  const [loadingButtons, setLoadingButtons] = useState({});
  const [processApiStatus, setProcessApiStatus] = useState({});
  const [monetizationOpen, setMonetizationOpen] = useState(false); // Add state for monetization dialog
  const [isLoadingUrlSession, setIsLoadingUrlSession] = useState(false);
  const location = window.location;

  const dispatch = useDispatch()

  const showNotice = (message, severity = 'success') => {
    dispatch(showNotification({ message:message, severity: severity}));
  }
  
  const handleNoticeClose = () => {
    dispatch(closeNotification())
  };

  const companyStatus = useSelector(selectCompaniesStatus)
  const companyMessage = useSelector(selectCompaniesMessage)
  const selectedCompany = useSelector(selectSelectedCompany) || ''  
  const authenticateStatus = useSelector(selectAuthenticateStatus)
  const authenticateMessage = useSelector(selectAuthenticateMessage)
  const accountInfo = useSelector(selectAccountInfo)
  const activeToken = useSelector(selectToken)
  const noticeState = useSelector(selectNotification)

  // Redux selectors for session data
  const sessionId = useSelector(selectSessionId) // Get sessionId from Redux
  const sessionStatus = useSelector(selectSessionStatus)
  const sessionMessage = useSelector(selectSessionMessage)
  const sessionData = useSelector(selectSessionData)
  
  const glowData = useSelector((state) => 
    ProcessChainManager.processOrder.reduce((acc, glowId) => {
      acc[glowId] = selectGlowData(state, glowId);
      return acc;
    }, {})
  );

  const completedProcesses = useSelector((state) => 
    ProcessChainManager.processOrder.reduce((acc, glowId) => {
      acc[glowId] = selectGlowStatus(state, glowId);
      return acc;
    }, {})
  );
  
  // Use readyProcesses as the primary name
  const readyProcesses = useSelector((state) => 
    ProcessChainManager.processOrder.reduce((acc, glowId) => {
      acc[glowId] = readyGlowStatus(state, glowId);
      return acc;
    }, {})
  );
  
  // Use loadingProcesses as the primary name
  const loadingProcesses = useSelector((state) => loadingGlowStatus(state));  
  
  // Button highlighting effect
  useEffect(() => {
    // This effect handles applying highlights when highlightedButton changes
    if (highlightedButton) {
      const button = document.getElementById(highlightedButton.buttonId);
      if (button) {
        // Add highlight class for styled-components
        button.classList.add('highlight');
        
        // Add direct styles
        button.style.animation = `pulse ${highlightedButton.highlight.duration}ms infinite`;
        button.style.backgroundColor = highlightedButton.highlight.color;
        button.style.boxShadow = '0 0 10px rgba(253, 152, 0, 0.5)';
      }
    } else {
      // Clear all button highlights
      ['start-analysis-button', ...[...document.querySelectorAll('[id^="glow-"]')].map(el => el.id)]
        .forEach(id => {
          const button = document.getElementById(id);
          if (button) {
            button.classList.remove('highlight');
            button.style.animation = '';
            button.style.backgroundColor = '';
            button.style.boxShadow = '';
          }
        });
    }
  }, [highlightedButton]);
  
  // Add a ref to track if session list is already being fetched
  const isSessionListFetching = useRef(false);
  const sessionListFetchTimeout = useRef(null);
  
  // Company status effect - update to use debounced fetch
  useEffect(() => {
    if (companyStatus === 'succeeded') {
      console.log("Company status available, scheduling sessions fetch...")
      debouncedFetchSessionList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyStatus]);
  
  useEffect(() => {
    if (authenticateStatus === 'idle') {
      // Check cookies if existed authenticate info
      const cookieAccountInfo = Cookies.get('accountInfo');
      const token = Cookies.get('token');
      const parsedAccountInfo = cookieAccountInfo ? JSON.parse(cookieAccountInfo) : {};
      if (token && (parsedAccountInfo.userToken || parsedAccountInfo.guestToken)) {
        dispatch(loadCachedAccountInfo(parsedAccountInfo));
        if(parsedAccountInfo.userToken) {
          dispatch(getUserProfile(parsedAccountInfo))
          dispatch(mySubscription(parsedAccountInfo))
        }
      } else {
        // Else dispatch signin as Guest
        dispatch(signInAnonymous())
      }      
    } else if( authenticateStatus === 'succeeded' && activeToken) {
      setShowAuthForm(false)
      
      // Use a single-time flag to prevent redundant initialization
      if (!window.__SESSION_INITIALIZATION_STARTED__) {
        window.__SESSION_INITIALIZATION_STARTED__ = true;
        
        // Fetch company list first - this will trigger the company status effect
        dispatch(fetchCompanyList());
        
        // Fetch credit balance independently
        dispatch(getRemainingCredit({token:activeToken}));
        
        // We don't need to call initializeSessionSelection here
        // It will be triggered by the company status effect once company list is loaded
        console.log("Authentication successful, company list fetching will trigger session initialization");
      }
    } else if( authenticateStatus === 'error') {
      showNotice(authenticateMessage, 'error')
    }
  }, [authenticateStatus, activeToken]);

  useEffect(() => {
    if( companyStatus === 'error') {
      showNotice(companyMessage, 'error')
    }
  }, [companyStatus])

  useEffect(() => {
    if(sessionData) setInputText(sessionData.inputPrompt ?? '');
  }, [sessionData.inputPrompt])

  useEffect(() => {
    if(sessionData && sessionData.companyId) dispatch(companySelected(sessionData.companyId))
  }, [sessionData?.companyId])

  useEffect(() => {
    if( sessionStatus === 'error') {
      showNotice(sessionMessage ?? 'Failed to load session data', 'error')
    } else if( sessionStatus === 'completed') {
      dispatch(getRemainingCredit({token:activeToken}))
    }
  }, [sessionStatus])

  useEffect(() => {
    if(sessionData && sessionData.companyId && sessionData.companyId != selectedCompany) {
      dispatch(clearSessionData())
    }
  }, [selectedCompany])

  // ... existing state variables ...
  const [showAuthForm, setShowAuthForm] = useState(false); // State to manage auth form visibility

  // Function to toggle the authentication form
  const toggleAuthForm = () => {
    setShowAuthForm(prev => !prev);
  };
  
  const processCache = new ProcessCache();
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };  
  
  const firebaseApp = initializeApp(firebaseConfig);
  // Initialize analytics using our utility
  const analytics = initAnalytics(firebaseApp, true);
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  // Track page view on component mount
  useEffect(() => {
    trackPageView('Journey Dashboard', window.location.pathname, {
      user_type: accountInfo?.userType || 'unknown'
    });
  }, []);

  // Track user ID when available
  useEffect(() => {
    if (accountInfo?.userToken) {
      setAnalyticsUserId(accountInfo.userToken);
      
      // Set user properties for better segmentation
      setUserAnalyticsProperties({
        user_type: accountInfo.userType,
        subscription_type: accountInfo.subscription_type || 'none',
        has_company: !!selectedCompany,
        credits_available: accountInfo.credit
      });
      
      // Track retention event
      trackRetention('session_start', {
        user_type: accountInfo.userType,
        session_id: sessionId
      });
    }
  }, [accountInfo?.userToken, accountInfo?.userType, selectedCompany, sessionId]);

  useEffect(() => {
    if(sessionData && sessionData.companyId && sessionData.companyId != selectedCompany) {
      dispatch(clearSessionData())
    }
  }, [selectedCompany])

  // Add these handlers at the top of your App component
  const handleSessionListClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowSessionList(true);
    
    // Track button click
    trackButtonClick('session_list_button', 'Session List', {
      location: 'top_nav'
    });
  };

  const handleDevToolsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDevToolOpen(!devToolOpen);
    
    // Track button click
    trackButtonClick('dev_tools_button', 'Developer Tools', {
      is_opening: !devToolOpen,
      location: 'top_nav'
    });
  };

  useEffect(() => {
    const handleResize = () => {
      // Remove these since we're not using icicle anymore
      // setWidth(window.innerWidth * 0.8);
      // setHeight(window.innerHeight * 0.6);
    };
    
    let savedMode = localStorage.getItem('mui-mode');
    if(!savedMode) savedMode = 'dark'
    setMode(savedMode)
    setIsDarkTheme(savedMode === 'dark')
    document.body.className = savedMode === 'dark' ? 'theme-dark' : 'theme-light';

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleTheme = () => {
    const newMode = !isDarkTheme;
    setIsDarkTheme(newMode);
    setMode(newMode ? 'dark' : 'light');
    document.body.className = newMode ? 'theme-dark' : 'theme-light';
    
    // Track theme toggle
    trackButtonClick('theme_toggle', 'Theme Toggle', {
      new_theme: newMode ? 'dark' : 'light'
    });
  };

  const showToast = (message, type = 'info') => {
    setToast({ show: true, message, type });
    showNotice(message, type)
    setTimeout(() => {
      setToast({ show: false, message: '', type: '' });
    }, 3000);
  };

  const fetchSessionList = async () => {
    // Return if already fetching to prevent duplicate calls
    if (isSessionListFetching.current) {
      console.log("Session list fetch already in progress, skipping...");
      return null;
    }
    
    // Clear any pending timeout
    if (sessionListFetchTimeout.current) {
      clearTimeout(sessionListFetchTimeout.current);
      sessionListFetchTimeout.current = null;
    }
    
    // Set fetching flag
    isSessionListFetching.current = true;
    
    try {
      const token = Cookies.get('token');
      if (!token) {
        throw new Error('Token is required');
      }
      console.log("Fetching session list from API...");
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/session/get_session_list`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setSessionList(response.data.data.sessions || []);
      return response.data;
    } catch (error) {
      console.error('Error fetching session list:', error);
      showToast('Failed to fetch session list');
      return { status_code: 400, data: [] };
    } finally {
      // Reset fetching flag after a short delay to prevent immediate re-fetch
      setTimeout(() => {
        isSessionListFetching.current = false;
      }, 1000);
    }
  };

  // Create a debounced version of fetchSessionList
  const debouncedFetchSessionList = () => {
    // Clear any pending timeout
    if (sessionListFetchTimeout.current) {
      clearTimeout(sessionListFetchTimeout.current);
    }
    
    // Set a new timeout
    sessionListFetchTimeout.current = setTimeout(() => {
      fetchSessionList();
    }, 300); // 300ms debounce time
  };

  const getSessionDetails = async (id) => {
    try {
      const token = Cookies.get('token');
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/session/get_session_details`,
        { session_id: id },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      
      // Also dispatch to Redux store
      dispatch(getSessionData(id));
      
      return response.data;
    } catch (error) {
      console.error('Error getting session details:', error);
      return { status: false, message: 'Failed to get session details' };
    }
  };

  // Add a ref to track initialization
  const isInitialized = useRef(false);

  const initializeSessionSelection = async () => {
    try {
      // Only fetch if no sessions loaded yet or we're forced to refresh
      if (sessionList.length === 0 || isSessionListFetching.current === false) {
        const listResponse = await fetchSessionList();
        
        // If fetch failed or returned no data, exit early
        if (!listResponse || listResponse.status_code === 400 || !listResponse.data?.sessions?.length) {
          dispatch(clearSessionData());
          return;
        }

        const savedSessionId = Cookies.get('session_id');
        const sessionExists = listResponse.data.sessions.some(
          session => session.session_id === savedSessionId
        );
        
        const targetSessionId = (savedSessionId && sessionExists) ? 
          savedSessionId : 
          listResponse.data.sessions[0].session_id;

        Cookies.set('session_id', targetSessionId);
        
        // Set the current session in Redux
        dispatch(setCurrentSession(targetSessionId));
        
        // Use existing getSessionDetails function
        await getSessionDetails(targetSessionId);
      } else {
        console.log("Using existing session list data");
        
        // Use existing session data
        const savedSessionId = Cookies.get('session_id');
        const sessionExists = sessionList.some(
          session => session.session_id === savedSessionId
        );
        
        const targetSessionId = (savedSessionId && sessionExists) ? 
          savedSessionId : 
          (sessionList.length > 0 ? sessionList[0].session_id : null);
          
        if (targetSessionId) {
          Cookies.set('session_id', targetSessionId);
          dispatch(setCurrentSession(targetSessionId));
          await getSessionDetails(targetSessionId);
        }
      }
    } catch (error) {
      console.error('Initialization error:', error);
    }
  };
  
  /**
   * Check if all required APIs for a process have been successfully completed
   * @param {string} processId - The process ID to check
   * @returns {boolean} - Whether all required APIs are complete
   */
  const isProcessApiComplete = (processId) => {
    if (!processId || !processApiStatus[processId]) return false;
    return processApiStatus[processId].allRequired;
  };
  
  // Memoize the process status initialization to prevent recreating it on every render
  const initializeProcessStatus = useCallback(() => {
    // List of processes that should always be excluded from automatic "ready" status
    const excludedProcesses = ['glow_82', 'glow_102'];
    
    // Only process those that need updates to avoid unnecessary re-renders
    ProcessChainManager.processOrder.forEach(processId => {
      // Skip processing if we don't have session data yet
      if (!glowData || !processApiStatus) return;
      
      if (!excludedProcesses.includes(processId)) {
        const hasApiData = !!glowData[processId] && Object.keys(glowData[processId]).length > 0;
        const currentReadyState = readyProcesses[processId];
        const newReadyState = hasApiData && isProcessApiComplete(processId);
        
        // Only dispatch if the state is actually changing
        if (currentReadyState !== newReadyState) {
          // dispatch(updateGlowReady({ 
          //   glowId: processId, 
          //   ready: newReadyState
          // }));
        }
      } else if (readyProcesses[processId]) {
        // Only update excluded processes if they're currently marked as ready
        // dispatch(updateGlowReady({ glowId: processId, ready: false }));
      }
    });
  }, [dispatch, glowData, processApiStatus, readyProcesses, isProcessApiComplete]);
  
  // Add useEffect to safely initialize process status when session data changes
  useEffect(() => {
    // Only try to initialize processes if we have session data loaded
    if (sessionData && sessionData.sessionId && Object.keys(glowData).length > 0) {
      // Wait a moment for session data to be fully processed
      const timer = setTimeout(() => {
        initializeProcessStatus();
      }, 500);
      
      return () => clearTimeout(timer); // Clean up the timer
    }
  }, [sessionData, glowData, initializeProcessStatus]);
  
  // Generate dynamic SEO data based on current process
  const getSeoData = () => {
    // Default SEO data for Journey page
    const defaultSeo = {
      title: 'Glowtest - AI Video Creator & Business Validation Dashboard',
      description: 'Create videos and validate business ideas in 24 hours. Perfect for startups, content creators, product teams to test concepts before investing. No filming needed.',
      openGraph: {
        type: 'website',
        title: 'Glowtest - Video Creator & Idea Validation Tool',
        description: 'Create professional videos from text for social media, marketing, and presentations. No filming or editing skills required.',
        image: '/images/og-journey-image.jpg',
      },
      meta: [
        { name: 'keywords', content: 'business idea validation, idea testing, AI video creator, text to video, startup validation, content testing, product validation, social media content, video without filming, market fit testing' },
        { property: 'og:site_name', content: 'Glowtest.it' },
        { name: 'twitter:creator', content: '@glowtest' },
        { name: 'audience', content: 'startups, entrepreneurs, content creators, product managers, marketing teams' }
      ]
    };

    // If we have a current process, customize SEO data with an emphasis on specific user needs
    if (currentProcess && processConfig) {
      const processInfo = processConfig.processes.find(p => p.id === currentProcess);
      if (processInfo) {
        // Special handling for video-specific processes
        if (currentProcess.includes('video') || currentProcess.includes('glow_61') || currentProcess.includes('glow_38') || currentProcess.includes('glow_92')) {
          return {
            ...defaultSeo,
            title: `AI Video Creator - ${processInfo.displayName || 'Video Generation'} for Content Creators`,
            description: `Create professional ${processInfo.displayName?.toLowerCase() || 'videos'} from text for social media, marketing, and presentations. No filming or editing skills required.`,
            openGraph: {
              ...defaultSeo.openGraph,
              title: `AI Video Creator - ${processInfo.displayName || 'Video Generation'} | Glowtest`,
              description: `Create professional ${processInfo.displayName?.toLowerCase() || 'videos'} from text for content creators and marketing teams. No filming required.`
            },
            meta: [
              ...defaultSeo.meta,
              { name: 'target-audience', content: 'content creators, marketing teams, social media managers' }
            ]
          };
        }
        
        // Special handling for business validation processes
        if (currentProcess.includes('idea') || currentProcess.includes('glow_08') || currentProcess.includes('glow_09') || currentProcess.includes('glow_11') || currentProcess.includes('glow_21')) {
          return {
            ...defaultSeo,
            title: `Business Idea Validation - ${processInfo.displayName || 'Idea Testing'} for Startups`,
            description: `Validate your ${processInfo.displayName?.toLowerCase() || 'business idea'} before investing. Get market feedback and data-driven insights within 24 hours.`,
            openGraph: {
              ...defaultSeo.openGraph,
              title: `Business Idea Validation - ${processInfo.displayName || 'Idea Testing'} | Glowtest`,
              description: `Validate your ${processInfo.displayName?.toLowerCase() || 'business idea'} with AI-powered analysis and market feedback before investing time and money.`
            },
            meta: [
              ...defaultSeo.meta,
              { name: 'target-audience', content: 'startups, entrepreneurs, product managers, investors' }
            ]
          };
        }
        
        // Default handling for other processes
        return {
          ...defaultSeo,
          title: `${processInfo.displayName || 'Process'} - Glowtest Business & Video Tool`,
          description: processInfo.description || defaultSeo.description,
          openGraph: {
            ...defaultSeo.openGraph,
            title: `${processInfo.displayName || 'Process'} - Glowtest`,
            description: processInfo.description || defaultSeo.openGraph.description
          }
        };
      }
    }

    return defaultSeo;
  };

  // Use the existing mode and setMode from useColorScheme
  if (!mode) {
    return null;
  }
  
  // Check credit balance before proceeding
  const checkCredits = (callback) => {
    if (accountInfo?.credit < 0) {
      showNotice('Insufficient credits. Please add more credits to continue.', 'error');
      setMonetizationOpen(true); // Open the monetization dialog when out of credits
      
      // Track credit insufficient event
      trackEngagement('credit_insufficient', {
        user_type: accountInfo?.userType,
        prompted_upgrade: true
      });
      
      return false;
    }
    if (callback) callback();
    return true;
  };

  // Render the user avatar or login button
  const renderUserAvatar = () => {
    // Check if accountInfo.userToken
    if(accountInfo.userToken !== '') {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button 
            onClick={() => setMonetizationOpen(true)} 
            variant="contained"
            sx={{ 
              bgcolor: '#FD9800',
              color: 'white',
              '&:hover': {
                bgcolor: '#e68a00',
              },
              mr: 2,
              fontWeight: 'bold'
            }}
          >
            Upgrade
          </Button>
          <AccountMenu accountInfo={accountInfo} auth={auth} />
        </Box>
      )
    }
    {/* Render the authentication form conditionally */}
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button 
          onClick={() => setMonetizationOpen(true)} 
          variant="contained"
          sx={{ 
            bgcolor: '#FD9800',
            color: 'white',
            '&:hover': {
              bgcolor: '#e68a00',
            },
            mr: 2,
            fontWeight: 'bold'
          }}
        >
          Pricing
        </Button>
        <IconButton onClick={toggleAuthForm} disabled={isAnyProcessRunning}>
          <Typography variant="button">Login</Typography>
        </IconButton>
      </Box>
    )
  };
  
  // Handle button highlighting for UI guidance
  const handleButtonHighlight = (highlightInfo) => {
    // Set highlighted button state for UI effects
    setHighlightedButton(highlightInfo);
  };
  
  // Handle selection of a process from the stepper
  const handleProcessSelect = async (processId) => {
    if (!processId) return;
    
    // First check if it's already the current process to avoid unnecessary updates
    if (processId === currentProcess) {
      console.log(`Process ${processId} is already the current process`);
      return;
    }
    
    // Track process selection
    trackButtonClick('process_select', 'Process Selected', {
      process_id: processId,
      previous_process: currentProcess,
      is_ready: readyProcesses[processId] ? 'yes' : 'no'
    });
    
    // Check if this process is ready to be viewed
    const isProcessReady = readyProcesses[processId];
    
    if (isProcessReady) {
      console.log(`Process ${processId} is already cached and ready to view`);
      dispatch(updateGlowStatus({ glowId: processId, status: true }));      
      // Set the current process
      setCurrentProcess(processId);
      
      // No need to update ready state since it's already ready
    } else {
      console.log(`Process ${processId} is not cached, fetching data...`);
      
      // Set the current process
      setCurrentProcess(processId);
    }
  };
  
  // Main handler for running processes
  const handleRunMainProcess = async (targetProcess = 'glow_08') => {
    // Check credit balance before proceeding
    if (!checkCredits()) {
      return;
    }

    // Track process start
    trackButtonClick('run_main_process', 'Start Process', {
      target_process: targetProcess,
      input_length: inputText.length,
      company_id: selectedCompany
    });

    try {
      setIsAnyProcessRunning(true);
      
      // IMMEDIATE UI UPDATE: Switch to target process view first
      setCurrentProcess(targetProcess);
      
      // Show appropriate toast message immediately
      const toastMessage = targetProcess === 'glow_61' 
        ? 'Moving to Video Creation' 
        : 'Moving to Initial Questions';
      showToast(toastMessage, 'success');

      // BACKGROUND OPERATIONS: Handle session creation and data updates
      const isNew = hasInputChanged(inputText);
      
      // Enhanced glow_61 preloading with caching
      if (!glowData['glow_61'] || !glowData['glow_61']?.config) {
        console.log('Preloading glow_61 data...');
        try {
          const result = await dispatch(runProcess({ 
            processId: 'glow_61',
            option: {
              loadingState: false,
              cacheResponse: true,
              preload: true
            }
          }));

          if (result?.payload?.data) {
            // Cache the response in Redux
            dispatch(updateGlowStep({
              stepId: 'glow_61',
              data: {
                glow_61_response: result.payload.data,
                config: result.payload.data.config,
                cached: true
              }
            }));
          }
        } catch (error) {
          console.error('Error preloading glow_61:', error);
        }
      } else {
        console.log('Using cached glow_61 data');
      }

      // Initialize session in the background
      const targetSessionId = await initializeSessionWithIdea(isNew);
      
      // Track new session creation if applicable
      if (isNew) {
        trackEngagement('new_session_created', {
          session_id: targetSessionId,
          input_length: inputText.length,
          company_id: selectedCompany
        });
      }
      
      // Mark idea_description as completed
      dispatch(updateGlowStatus({ glowId: 'idea_description', status: true }));

      // Only set the specific targetProcess to ready after it's initialized
      if (targetProcess === 'glow_08') {
        dispatch(updateGlowReady({glowId: 'glow_08', ready: true}));
        dispatch(runProcess({ 
          processId: 'glow_10',
          option: {
            payload: {
              session_id: targetSessionId,
              input_prompt: inputText,
            }
          }
        })).then((response) => {
          if(response.payload.data) {
            dispatch(updateGlowStep({
              stepId: 'glow_10',
              data: response.payload.data
            }));
          }
        });
      }
      
      // Track successful process transition
      trackEngagement('process_transition', {
        from_process: 'idea_description',
        to_process: targetProcess,
        session_id: targetSessionId
      });
      
    } catch (error) {
      console.error('Error transitioning to process:', error);
      showToast(error.message || 'Failed to transition', 'error');
      
      // Track error
      trackEngagement('process_transition_error', {
        target_process: targetProcess,
        error_message: error.message || 'Unknown error'
      });
    } finally {
      setIsAnyProcessRunning(false);
    }
  };

  const handleOnCompleteProcess = async (updateData) => {
    const currentChainIndex = ProcessChainManager.processOrder.indexOf(updateData?.processId || currentProcess);
    const nextProcess = ProcessChainManager.processOrder[currentChainIndex + 1];
    const nextProcessToNavigateTo = updateData?.nextStep || nextProcess;

    // Check credit balance before proceeding
    if (!checkCredits()) {
      return;
    }
    if (!nextProcessToNavigateTo) {
      console.log('No next process found');
      return;      
    }

    try {
      dispatch(updateGlowStatus({ glowId: updateData?.processId || currentProcess, status: true }));
      if (updateData?.data) {
        dispatch(updateGlowStep({
          stepId: updateData?.processId,
          data: updateData?.data
        }));
      }

      let payload = {}
      payload = {
        session_id: sessionId,
        input_prompt: inputText,
      };
      // Check if the next process already has data or is running
      const nextProcessData = Object.keys(glowData[nextProcessToNavigateTo] || {}).length > 0 && readyProcesses[nextProcessToNavigateTo];
      const isNextProcessRunning = loadingProcesses[nextProcessToNavigateTo];
      const preLoadNextProcess = async (selectedProcess) => {
        const selectedIndex = ProcessChainManager.processOrder.findIndex((process) => process === selectedProcess);
        const nextPreloadProcess = ProcessChainManager.processOrder[selectedIndex + 1];
        
        // Check if the next process already has data or is running
        const nextPreloadProcessData = Object.keys(glowData[nextPreloadProcess] || {}).length > 0;
        const isNextPreloadProcessRunning = loadingProcesses[nextPreloadProcess];
        let preLoadPayload = {}
        preLoadPayload = {
          session_id: sessionId,
          input_prompt: inputText,
        };
        if (!nextPreloadProcessData && !isNextPreloadProcessRunning) {
          await dispatch(runProcess({
            processId: nextPreloadProcess,
            option: {
              payload: preLoadPayload,
            }
          }));
          dispatch(getRemainingCredit({token:activeToken}));
        }
      }
      if (processConfig.processChains.ideaChain.includes(nextProcessToNavigateTo) && preLoadNextProcess(nextProcessToNavigateTo)) {
        if (!nextProcessData && !isNextProcessRunning) {
          await dispatch(runProcess({
            processId: nextProcessToNavigateTo,
            option: {
              payload: payload,
            }
          }));
          dispatch(getRemainingCredit({token:activeToken}));
          dispatch(updateGlowData({ sessionId, data: payload }));
          setCurrentProcess(nextProcessToNavigateTo);
          preLoadNextProcess(nextProcessToNavigateTo);
        } else {
          setCurrentProcess(nextProcessToNavigateTo);
        }
      } else {
        setCurrentProcess(nextProcessToNavigateTo);
        if (processConfig.processChains.videoChain.includes(nextProcessToNavigateTo || updateData?.processId || currentProcess)) {
          await dispatch(updateGlowTextResponse({
            session_id: sessionId,
            processId: updateData?.processId || currentProcess,
            updatedResponse: updateData?.data?.status !== undefined ? updateData?.data : glowData[updateData?.processId || currentProcess]
          }));
          if (nextProcessToNavigateTo === 'glow_04') {   
            if (isFeatureEnabled('SHOW_GLOW_04')) {
              const glow04Response = {
                input_prompt: inputText,
                session_id: sessionId,
                video_type: glowData['glow_62']['glow_62_response']['selectedType']['id'],
                target_video_duration: glowData['glow_62']['glow_62_response']['selectedType']['dur'] ? parseFloat(glowData['glow_62']['glow_62_response']['selectedType']['dur']?.replace('s', '')) : 0,
              };
              await dispatch(runProcess({
                processId: nextProcessToNavigateTo,
                option: {
                  payload: glow04Response,
                }
              }));
              dispatch(getRemainingCredit({token:activeToken}));
            }
          }          
        }
      }
      
    } catch (error) {
      console.error('Error completing process:', error);
    }
  }
  
  // Render the current process content
  const renderProcessContent = (processId) => {
    const currentChainIndex = ProcessChainManager.processOrder.indexOf(processId);
    const prevProcess = ProcessChainManager.processOrder[currentChainIndex - 1];
    const nextProcess = ProcessChainManager.processOrder[currentChainIndex + 1];
    const prevData = glowData[prevProcess]?.[`${prevProcess}_response`] || {};

    const isGlow04 = processId === 'glow_04';
    const glow04ResponseExists = glowData['glow_04']?.['glow_04_response'] || false;
    const glow01ResponseExists = glowData['glow_04']?.['glow_01_response'] || false;

    const data = isGlow04 && glow01ResponseExists ? glowData[processId]?.[`glow_01_response`] : glowData[processId]?.[`${processId}_response`] || {};
    
    const ProcessComponent = processComponents[processId];
    if (!ProcessComponent) return null;
    // console.log(`Process data ${processId}_response`, data);
    
    return (
      <ProcessComponent
        prevData={prevData}
        data={data}
        toggleAuthForm={toggleAuthForm}
        isAnyProcessRunning={isAnyProcessRunning}
        onComplete={handleOnCompleteProcess}
        sessionId={sessionId}
        showNotice={showNotice}
        // eslint-disable-next-line no-undef
        handleFeatureRequest={handleFeatureRequest}
      />
    );
  };
  
  // Handle session selection
  const handleSessionSelect = async (selectedSessionId) => {
    try {
      // Skip if already selected
      if (selectedSessionId === sessionId) {
        console.log("Session already selected, skipping");
        setShowSessionList(false);
        return;
      }
      
      Cookies.set('session_id', selectedSessionId);
      // Set the current session in Redux
      dispatch(setCurrentSession(selectedSessionId));
      await getSessionDetails(selectedSessionId);
      setShowSessionList(false);
      setCurrentProcess('idea_description');
      
      // Track session selection
      trackButtonClick('session_select', 'Session Selected', {
        session_id: selectedSessionId
      });
    } catch (error) {
      console.error('Error selecting session:', error);
      showToast('Failed to load session', 'error');
    }
  };
  
  // Start the session deletion process
  const initiateDeleteSession = (sessionId, sessionName) => {
    setDeleteConfirmation({ id: sessionId, name: sessionName });
  };
  
  // Check for API dependencies
  const checkProcessDependencies = async (processId, primaryData) => {
    // Get process definition
    const process = processConfig.processes.find(p => p.id === processId);
    if (!process) return;
    
    // Check if process depends on other processes
    if (!process.dependsOn || process.dependsOn.length === 0) return;
    
    // For each dependency, check if it's available or feature-flagged
    for (const dependencyId of process.dependsOn) {
      // If dependency is glow_04 and it's feature-flagged, skip it
      if (dependencyId === 'glow_04' && !isFeatureEnabled('SHOW_GLOW_04')) {
        console.log(`Skipping dependency check for ${dependencyId} as it's disabled by feature flag`);
        continue;
      }
      
      // Check if dependency exists in current process order
      if (!ProcessChainManager.processOrder.includes(dependencyId)) {
        console.log(`Dependency ${dependencyId} is not in current process flow, skipping`);
        continue;
      }
      
      // Check if we have data for this dependency
      const dependencyData = ProcessChainManager.getCachedResponse(dependencyId);
      if (!dependencyData) {
        console.log(`Missing dependency data for ${dependencyId}`);
        
        // Decide if we should load this dependency
        const shouldLoad = processId !== 'glow_04' || isFeatureEnabled('SHOW_GLOW_04');
        
        if (shouldLoad) {
          // Run the process in the background to get dependency data
          try {
            if (!completedProcesses['idea_description']) {
              return;
            }
            await dispatch(runProcess({ 
              processId: dependencyId,
              option: {
                loadingState: false // Don't show loading state for dependency
              }
            }));
            
          } catch (error) {
            console.error(`Error loading dependency ${dependencyId}:`, error);
          }
        }
      }
    }
    
    return true;
  };
  
  /**
   * Check if input has changed compared to saved input
   */
  const hasInputChanged = (currentInput) => {
    const savedInput = sessionData?.inputPrompt;
    return !savedInput || currentInput.trim() !== savedInput.trim();
  };
  
  /**
   * Initialize or update session with the idea input
   */
  const initializeSessionWithIdea = async (isNew) => {
    if (!isNew) return sessionId;
    
    try {
      setIsCreating(true);
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/company_project/create_idea`,
        { company_id: selectedCompany, input_prompt: inputText },
        { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
      );
      
      if (response.data.status) {
        const newSessionId = response.data.data.session_id;
        
        // Clear the cache before setting new session
        ProcessChainManager.clearAllCache();
        getSessionDetails(newSessionId); 
        Cookies.set('session_id', newSessionId);
        // eslint-disable-next-line no-undef
        addApiResponse(response.data);
        
        // Save the idea description to Redux store
        dispatch(updateGlowStep({
          stepId: 'idea_description',
          data: {
            idea_description_response: {
              input_prompt: inputText,
              company_id: selectedCompany
            }
          }
        }));
        
        // Mark the idea_description as ready
        dispatch(updateGlowReady({ 
          glowId: 'idea_description', 
          ready: true 
        }));
                    
        // Use debounced fetch to prevent multiple calls
        debouncedFetchSessionList();
        showToast('New session created successfully', 'success');
        return newSessionId;
      }
    } catch (error) {
      console.error('Error creating session:', error);
      // eslint-disable-next-line no-undef
      addApiResponse({ status: false, message: 'Error creating session' });
      showToast('Failed to create new session', 'error');
      throw error;
    } finally {
      setIsCreating(false);
    }
  };
  
  // Enhanced feature request handler with analytics
  const handleFeatureRequest = (featureId, featureType) => {
    // Track which features were requested
    const requestData = {
      featureId,
      featureType, // 'resolution', 'ai_model', etc.
      timestamp: new Date(),
      sessionId: sessionId
    };
    
    // Track feature request in analytics
    trackFeatureUsage(featureId, featureType, {
      session_id: sessionId,
      user_type: accountInfo?.userType || 'unknown'
    });
    
    // You could send this to your backend
    console.log('Feature requested:', requestData);
    
    // Show consistent feedback
    showToast(`Thank you for your interest! We'll notify you when this feature becomes available.`, 'success');
    
    return true; // Return true to indicate request was recorded
  };

  /**
   * Add an API response to the apiResponses state array
   * @param {Object} response - The API response to add
   */
  const addApiResponse = (response) => {
    setApiResponses(prev => [
      {
        timestamp: new Date().toISOString(),
        data: response
      },
      ...prev
    ]);
  };

  // After the existing useEffect blocks, add a new one to handle URL parameters
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionIdFromUrl = queryParams.get('session');
    
    if (sessionIdFromUrl && sessionId !== sessionIdFromUrl) {
      console.log(`Found session ID in URL: ${sessionIdFromUrl}`);
      loadSessionFromUrl(sessionIdFromUrl);
    }
  }, [location.search]); // Run when the URL changes

  // Add a new function to load a session from the URL parameter
  const loadSessionFromUrl = async (sessionIdFromUrl) => {
    try {
      setIsLoadingUrlSession(true);
      
      // First load session metadata
      const sessionDetailsResponse = await getSessionDetails(sessionIdFromUrl);
      
      if (sessionDetailsResponse && sessionDetailsResponse.status) {
        console.log('Session details from URL:', sessionDetailsResponse);
        
        // Set the session ID in Redux
        dispatch(setSessionIdAction(sessionIdFromUrl));
        
        // Load the session data into Redux
        dispatch(setCurrentSession({
          session_id: sessionIdFromUrl,
          ...sessionDetailsResponse.data
        }));
        
        // Set the input prompt if available
        if (sessionDetailsResponse.data.session_details.input_prompt) {
          setInputText(sessionDetailsResponse.data.session_details.input_prompt);
          dispatch(setInputPrompt(sessionDetailsResponse.data.session_details.input_prompt));
        }
        
        // Set the company if available
        if (sessionDetailsResponse.data.session_details.company_id) {
          dispatch(companySelected(sessionDetailsResponse.data.session_details.company_id));
        }
        
        // Show confirmation message
        showNotice(`Session "${sessionDetailsResponse.data.session_details.session_name || sessionIdFromUrl}" loaded successfully`, 'success');
      } else {
        console.error('Failed to load session from URL:', sessionDetailsResponse?.message || 'Unknown error');
        showNotice('Failed to load the session. Please try again.', 'error');
      }
    } catch (error) {
      console.error('Error loading session from URL:', error);
      showNotice('Error loading session. Please try again.', 'error');
    } finally {
      setIsLoadingUrlSession(false);
    }
  };

  // Add this code near the other useEffect hooks that handle URL parameters
  useEffect(() => {
    // Listen for popstate events (browser back/forward, or manual pushState calls)
    const handlePopState = () => {
      const queryParams = new URLSearchParams(window.location.search);
      const sessionIdFromUrl = queryParams.get('session');
      
      if (sessionIdFromUrl && sessionId !== sessionIdFromUrl) {
        console.log(`URL changed to session: ${sessionIdFromUrl}`);
        loadSessionFromUrl(sessionIdFromUrl);
      }
    };

    // Add the event listener
    window.addEventListener('popstate', handlePopState);
    
    // Clean up
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [sessionId]); // Depends on sessionId to avoid unnecessary reloads

  // Add Hotjar initialization
  useEffect(() => {
    // Hotjar Tracking Code for Site 5349923
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:5349923,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  }, []);

  return (
      <div className="App" onClick={null}>
        <SEO {...getSeoData()} />
        <AppBar 
          position="fixed" 
          sx={{ 
            zIndex: 1200,
            backgroundImage: 'unset',
            bgcolor: isDarkTheme ? '#1C1C1E' : '#FFFFFF',
            borderBottom: `1px solid var(--color-border)`,
            boxShadow: 'none',
            height: '60px',
            // color: isDarkTheme ? '#FFFFFF' : '#1C1C1E',
            '& .MuiIconButton-root': { 
              color: isDarkTheme ? '#FFFFFF' : '#1C1C1E'
            }
          }}
        >
          <Toolbar sx={{ height: '60px', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {isMobile && (
                <IconButton 
                  edge="start" 
                  onClick={() => setMobileOpen(!mobileOpen)}
                  sx={{ display: { sm: 'none' } }}
                >
                  <FaBars />
                </IconButton>
              )}
              <IconButton 
                onClick={handleSessionListClick}
                sx={{ display: { xs: 'none', sm: 'block' } }}
                disabled={isAnyProcessRunning || isCreating}
              >
                <FaList /> 
              </IconButton>
              <IconButton onClick={handleDevToolsClick} disabled={isAnyProcessRunning}>
                <FaTools />
              </IconButton>
              <IconButton 
                onClick={toggleTheme} 
                disabled={isAnyProcessRunning}
                sx={{ 
                  color: isDarkTheme ? '#FFFFFF' : '#1C1C1E',
                  '&:hover': {
                    color: 'var(--color-primary)'
                  }
                }}
              >
                <ContrastIcon />
              </IconButton>
              <IconButton 
                onClick={() => setCompanyDrawerOpen(true) }
                sx={{ display: { xs: 'none', sm: 'block' } }}
                disabled={isAnyProcessRunning || isCreating}
              >
                <FaBuilding /> 
              </IconButton>
            </Box>
            
            {/* Logo - centered on mobile */}
            <Box sx={{ 
              position: 'absolute', 
              margin: '0 auto',
              left: '0',
              right: '0',
              width: { xs: 'fit-content', sm: 'fit-content' }  // Changed fixed width to fit-content
            }}>
              <a href="/" style={{ display: 'block', textDecoration: 'none' }}>
                <img 
                  src={isDarkTheme ? '/images/logo-dark-desktop.png' : '/images/logo-light-desktop.png'} 
                  alt="Glowtest AI-Powered Validation Platform - Back to Home" 
                  style={{ 
                    height: isMobile ? '35px' : '63px',
                    width: 'auto',
                    objectFit: 'contain',
                    maxWidth: 'none'
                  }}
                />
              </a>
            </Box>
            { renderUserAvatar() }
          </Toolbar>
        </AppBar>

        <CreditFlag 
          accountInfo={accountInfo}
        />

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={noticeState.open}
          onClose={handleNoticeClose}
          key={noticeState.vertical + noticeState.horizontal}
          autoHideDuration={noticeState.duration}
        >
          <Alert
            onClose={handleNoticeClose}
            severity={noticeState.severity}
            variant='filled'
            sx={{ width: '100%' }}
          >
            { noticeState.message ?? ''}
          </Alert>
        </Snackbar>
        <Box sx={{ display: 'flex', pt: '60px' }}>
          {/* Desktop Stepper */}
          <Box sx={{ 
            display: { xs: 'none', sm: 'block' },
            width: '280px',
            flexShrink: 0,
            position: 'fixed',
            left: 0,
            top: '60px',
            height: 'calc(100vh - 60px)',
            overflowY: 'auto',
            borderRight: `1px solid ${theme.palette.divider}`
          }}>
            <MultiLevelStepper 
              onProcessSelect={handleProcessSelect}
              activeProcessId={currentProcess}
              isProcessRunning={isAnyProcessRunning}
              isDarkTheme={isDarkTheme}
              onButtonHighlight={handleButtonHighlight}
              sessionId={sessionId}
            />
          </Box>

          {/* Main Content */}
          <Box 
            component="main" 
            sx={{ 
              flexGrow: 1,
              width: { xs: '100%', sm: `calc(100% - 280px)` },
              ml: { xs: 0, sm: '280px' },
              p: { xs: 2, sm: 3 },  // Adjusted padding
              height: 'calc(100vh - 60px)',
              overflow: 'auto',
              '& .process-view-container': {  // Add this
                width: '100%',
                maxWidth: '100%',
                '& .MuiFormControl-root': {
                  width: '100%'
                }
              }
            }}
          >
            {(!currentProcess || currentProcess === 'idea_description') ? (
              <IdeaInputCard 
                inputText={inputText}
                setInputText={setInputText}
                hasUnsavedChanges={hasUnsavedChanges}
                setHasUnsavedChanges={setHasUnsavedChanges}
                onRun={handleRunMainProcess}
                config={processConfig.initialProcess}
                isDarkTheme={isDarkTheme}
                onFetchSessionList={fetchSessionList}
              />
            ) : (
              renderProcessContent(currentProcess)
            )}
          </Box>
        </Box>

        {/* Session List Dialog */}
        {showSessionList && (
          <Dialog 
            open={showSessionList} 
            onClose={() => setShowSessionList(false)}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>
              Sessions
              <IconButton
                onClick={() => setShowSessionList(false)}
                sx={{ position: 'absolute', right: 8, top: 8 }}
              >
                <FaTimes />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <List>
                {sessionList.filter(session => session.company_id === selectedCompany).map((session) => (
                  <ListItem
                    key={session.session_id}
                    selected={session.session_id === sessionId}
                    onClick={() => handleSessionSelect(session.session_id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <ListItemText 
                      primary={session.session_name || `Session ${session.session_id}`} 
                      secondary={new Date(session.created_at).toLocaleString()}
                    />
                    <Box>
                      <IconButton 
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditingSessionId(session.session_id);
                        }}
                        disabled={isRenaming}
                      >
                        <FaEdit />
                      </IconButton>
                      <IconButton 
                        onClick={(e) => {
                          e.stopPropagation();
                          initiateDeleteSession(session.session_id, session.session_name);
                        }}
                        disabled={isDeleting}
                      >
                        <FaTrash />
                      </IconButton>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </DialogContent>
          </Dialog>
        )}

        {/* Dev Tools Panel */}
        {devToolOpen && (
          <Dialog 
            open={devToolOpen} 
            onClose={() => setDevToolOpen(false)}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>
              Developer Tools
              <IconButton
                onClick={() => setDevToolOpen(false)}
                sx={{ position: 'absolute', right: 8, top: 8 }}
              >
                <FaTimes />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box sx={{ mb: 2 }}>
                <h3>Session Details</h3>
                <pre style={{ overflow: 'auto' }}>
                  {JSON.stringify(sessionData, null, 2)}
                </pre>
              </Box>
              <Box>
                <h3>API Responses</h3>
                <pre style={{ overflow: 'auto' }}>
                  {JSON.stringify(apiResponses, null, 2)}
                </pre>
              </Box>
            </DialogContent>
          </Dialog>
        )}
        
        {/* Pass firebaseApp to AuthForms */}
        {showAuthForm && 
          <Dialog 
            open={showAuthForm} 
            maxWidth="sm"
            fullWidth
          >
            <DialogContent sx={{backgroundColor: 'background.default'}}>
              <Authenticate 
              auth={auth}
              showNotice={showNotice}
              setAuthFormVisible={setShowAuthForm}
              />
            </DialogContent>
        </Dialog>}

        {/* MonetizationDialog */}
        <MonetizationDialog 
          open={monetizationOpen} 
          onClose={() => setMonetizationOpen(false)} 
          accountInfo={accountInfo}
        />

        {/* Mobile Drawer - only add this */}
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { 
              width: 280,
              backgroundColor: theme.palette.background.default
            },
          }}
        >
          <MultiLevelStepper 
            onProcessSelect={handleProcessSelect}
            activeProcessId={currentProcess}
            completedProcesses={completedProcesses}
            readyProcesses={readyProcesses}
            isProcessRunning={isAnyProcessRunning}
            isDarkTheme={isDarkTheme}
            onButtonHighlight={handleButtonHighlight}
            sessionId={sessionId}
            loadingButtons={loadingButtons}
          />
        </Drawer>

        {/* Company management */}
        <Drawer
          sx={{
            '& .MuiDrawer-paper': { 
              width: 400,
            },
          }}
          anchor="left"
          open={companyDrawerOpen}
          onClose={() => setCompanyDrawerOpen(false)} 
        >
          <Companies />
        </Drawer>

        {/* Playano attribution footer - add this near the end, before the closing div */}
        <div className="fixed bottom-0 right-0 p-4 z-10">
          <a 
            href="https://www.playanoedu.com" 
            target="_blank"
            rel="noopener noreferrer"
            className="block hover:opacity-80 transition-opacity duration-300"
          >
            <div className={`${isDarkTheme ? 'bg-gray-800' : 'bg-white'} bg-opacity-90 rounded-lg shadow-md p-2 flex flex-col items-center`}>
              <p className={`text-xs ${isDarkTheme ? 'text-gray-300' : 'text-gray-500'} mb-1`}>Powered by</p>
              <img 
                src={isDarkTheme ? "/playano_mobile_on_dark_background.png" : "/playano_mobile_on_bright_background.png"} 
                alt="Playano Education Limited - AI-Powered Learning Platform" 
                className="h-6"
              />
            </div>
          </a>
        </div>

        {/* Feature Flags Panel for developers */}
        <FeatureFlagsPanel />
      </div>
  );
}

export default Journey;
