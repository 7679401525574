import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Stack,
  styled,
  createTheme,
  ThemeProvider,
  Chip,
  Slider,
  Grid,
  IconButton,
  CircularProgress,
  Alert,
  Button,
  FormControlLabel,
  Checkbox,
  Paper
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import processConfig from '../../config/processIndex.json';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon
} from '@mui/icons-material';
import { 
  FaYoutube, 
  FaInstagram, 
  FaTiktok, 
  FaFacebookF, 
  FaLinkedinIn, 
  FaPinterestP, 
  FaTwitter 
} from 'react-icons/fa';
import { 
  SiSnapchat,
  SiYoutubeshorts
} from 'react-icons/si';
import { 
  MdPresentToAll, 
  MdTv, 
  MdMovie, 
  MdPhotoCamera 
} from 'react-icons/md';
import axios from 'axios';
import Cookies from 'js-cookie';
import SelectionCard from './glow_61_selection_card';
import { useDispatch, useSelector } from 'react-redux';
import { updateGlowStep, selectGlowStep, updateGlowReady } from '../../components/session/sessionSlide';
import { generateImagePath, DEFAULT_THUMBNAIL, DEFAULT_FULL_IMAGE } from '../../utils/imagePathUtils';

// Add debug logging for environment variables
console.log('Environment Variables:', {
  bucketName: process.env.REACT_APP_DEV_AWS_BUCKET_NAME,
  region: process.env.REACT_APP_DEV_AWS_REGION
});

// Construct S3_BASE_URL using environment variables with fallbacks
const S3_BASE_URL = `https://${process.env.REACT_APP_DEV_AWS_BUCKET_NAME || 'chat2movie'}.s3.${process.env.REACT_APP_DEV_AWS_REGION || 'us-east-1'}.amazonaws.com`;

// Map style names to their simplified versions for file paths (moved to top level)
const styleNameMap = {
  'realistic_image': 'realistic',
  'digital_illustration': 'digital',
  'vector_illustration': 'vector',
  'icon': 'icon',
  'logo_raster': 'logo'
};

// Helper function to construct S3 URLs with correct path
const getS3Url = (path) => {
  // Map the correct filenames for styles
  const styleNameMap = {
    'realistic_image': 'realistic',
    'digital_illustration': 'digital',
    'vector_illustration': 'vector',
    'icon': 'icon',
    'logo_raster': 'logo'
  };

  let url;
  const cleanPath = path.replace(/^(thumbnails\/|full\/)/, '');

  if (path.startsWith('thumbnails/')) {
    if (cleanPath.includes('substyles/')) {
      // For substyles, use the direct path
      url = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/thumbnails/substyles/${cleanPath.split('/').pop()}`;
    } else if (cleanPath.includes('styles/')) {
      const styleName = cleanPath.split('/').pop().replace('.webp', '');
      const mappedName = styleNameMap[styleName] || styleName;
      url = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/thumbnails/styles/${mappedName}`;
    } else if (cleanPath.includes('providers/') || cleanPath.includes('models/')) {
      url = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/thumbnails/${cleanPath}`;
    }
  } else if (path.startsWith('full/')) {
    if (cleanPath.includes('substyles/')) {
      // For substyles, use the direct path
      url = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/full/substyles/${cleanPath.split('/').pop()}`;
    } else if (cleanPath.includes('styles/')) {
      const styleName = cleanPath.split('/').pop().replace('.webp', '');
      const mappedName = styleNameMap[styleName] || styleName;
      url = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/full/styles/${mappedName}`;
    } else if (cleanPath.includes('providers/') || cleanPath.includes('models/')) {
      url = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/full/${cleanPath}`;
    }
  }

  // Ensure .webp extension is added if not present
  if (url && !url.endsWith('.webp')) {
    url += '.webp';
  }

  // Add console logging for debugging
  // console.log('Original path:', path);
  // console.log('Generated URL:', url);
  
  return url;
};

// Instead, use the direct URL
const API_BASE_URL = 'https://glowtest-dev-service-325458756816.asia-east2.run.app';

// Create orange theme only for the primary color
// The main theme with dark mode will be handled in the component
const orangeTheme = {
  palette: {
    primary: {
      main: '#FD9800',
      light: '#FDB544',
      dark: '#D68100',
      contrastText: '#FFFFFF',
    }
  }
};

const FormatPreview = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isSupported'
})(({ theme, isSupported = true }) => ({
  width: '120px',
  height: '120px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  border: `2px solid ${isSupported ? theme.palette.primary.main : theme.palette.error.main}`,
  overflow: 'hidden',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  '& .inner-box': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: isSupported ? theme.palette.primary.main : theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: '0.8rem',
    transition: 'all 0.3s ease',
    borderRadius: '4px',
  }
}));

const formatOptions = [
  { 
    value: 0, 
    label: "1:2", 
    ratio: 0.5,
    description: "Perfect for full-screen mobile content on TikTok, Instagram Stories, and YouTube Shorts",
    platforms: ["TikTok", "Instagram Stories", "YouTube Shorts"]
  },
  { 
    value: 1, 
    label: "9:16", 
    ratio: 0.5625,
    description: "Most popular vertical format! Optimized for vertical social media content and mobile-first viewing",
    popularityNote: "Most popular vertical format for social media",
    platforms: ["Instagram Reels", "Snapchat", "Facebook Stories"]
  },
  { 
    value: 2, 
    label: "3:4", 
    ratio: 0.75,
    description: "Ideal for Instagram feed posts and Pinterest, maximizing vertical space while maintaining readability",
    platforms: ["Instagram Feed", "Pinterest", "Facebook Feed"]
  },
  { 
    value: 3, 
    label: "4:5", 
    ratio: 0.8,
    description: "Instagram's preferred vertical format, maximizing feed space while maintaining quality",
    platforms: ["Instagram Feed", "Facebook Feed"]
  },
  { 
    value: 4, 
    label: "1:1", 
    ratio: 1,
    description: "Perfect square format for social media feeds, especially Instagram and Facebook",
    platforms: ["Instagram Feed", "Facebook Feed", "LinkedIn"]
  },
  { 
    value: 5, 
    label: "5:4", 
    ratio: 1.25,
    description: "Classic photography format, great for landscape and portrait content",
    platforms: ["Photography Sites", "Professional Portfolios"]
  },
  { 
    value: 6, 
    label: "4:3", 
    ratio: 1.33,
    description: "Traditional presentation format, ideal for business content and older displays",
    platforms: ["Business Presentations", "LinkedIn", "Traditional TV"]
  },
  { 
    value: 7, 
    label: "16:9", 
    ratio: 1.77,
    description: "Most popular horizontal format! Standard widescreen format, perfect for YouTube videos and modern displays",
    popularityNote: "Most popular horizontal format for video content",
    platforms: ["YouTube", "Presentations", "Desktop Viewing"]
  },
  { 
    value: 8, 
    label: "2:1", 
    ratio: 2,
    description: "Cinematic format popular for website headers and landscape social media content",
    platforms: ["Website Headers", "Twitter Cards", "LinkedIn Banners"]
  },
  { 
    value: 9, 
    label: "21:9", 
    ratio: 2.33,
    description: "Ultra-wide cinematic format for dramatic visual storytelling",
    platforms: ["Cinema", "Premium Video Content", "Ultrawide Displays"]
  }
];

// Platform icon mapping
const PLATFORM_ICONS = {
  'YouTube': FaYoutube,
  'YouTube Shorts': SiYoutubeshorts,
  'TikTok': FaTiktok,
  'Instagram Stories': FaInstagram,
  'Instagram Feed': FaInstagram,
  'Instagram Reels': FaInstagram,
  'Snapchat': SiSnapchat,
  'Facebook Stories': FaFacebookF,
  'Facebook Feed': FaFacebookF,
  'LinkedIn': FaLinkedinIn,
  'Pinterest': FaPinterestP,
  'Twitter Cards': FaTwitter,
  'Business Presentations': MdPresentToAll,
  'Traditional TV': MdTv,
  'Cinema': MdMovie,
  'Photography Sites': MdPhotoCamera,
  'Professional Portfolios': MdPhotoCamera,
  'Desktop Viewing': MdPresentToAll,
  'Premium Video Content': MdMovie,
  'Ultrawide Displays': MdTv,
  'Website Headers': MdPresentToAll,
  'LinkedIn Banners': FaLinkedinIn
};

const StyledChip = styled(Chip)(({ theme }) => ({
  '& .MuiChip-icon': {
    color: theme.palette.primary.main,
    marginLeft: '8px'
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main + '1A', // 10% opacity
  },
  transition: 'all 0.2s ease-in-out',
  cursor: 'default',
  // Position styling
  position: 'relative'
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 500,
  color: theme.palette.text.primary
}));

// Update the SelectionGrid component
const SelectionGrid = ({ children }) => (
  <Grid 
    container 
    spacing={3}  // Increased spacing between cards
    justifyContent="center"
    sx={{
      maxWidth: '100%',  // Use full width of container
      margin: '0 auto',
      padding: theme => theme.spacing(2)
    }}
  >
    {React.Children.map(children, child => (
      <Grid 
        item 
        xs={6}      // Full width on mobile (< 600px)
        sm={4}       // 2 cards per row on small tablets (600px+)
        md={3}       // 3 cards per row on medium screens (900px+)
        lg={2}       // 4 cards per row on large screens (1200px+)
        sx={{
          display: 'flex',
          justifyContent: 'center',
          '& > *': {  // Target the card component
            width: {
              xs: '100%',          // Full width on mobile
              sm: '280px',         // Fixed width on small screens
              md: '300px',         // Slightly wider on medium screens
              lg: '320px',         // Even wider on large screens
            },
            maxWidth: '100%',      // Ensure card doesn't overflow container
            margin: '0 auto',      // Center the card within its grid item
          }
        }}
      >
        {child}
      </Grid>
    ))}
  </Grid>
);

// Add the StyleSelection component
const StyleSelection = ({ config, provider, model, selected, onChange }) => {
  if (!config?.providers?.[provider]) {
    console.warn('StyleSelection: Missing provider data', { provider });
    return null;
  }

  console.log('StyleSelection for provider/model:', provider, model);

  // Get the available styles for the selected provider
  const availableStyles = config.providers[provider]?.styles?.available || [];
  const stylesData = config.providers[provider]?.styles?.data || {};
  
  console.log('Available styles:', availableStyles);
  
  // Filter to only include styles that are active for the selected model
  const styles = availableStyles
    .filter(style => {
      // Check if the style is active for the selected model
      const isActive = stylesData[style]?.status?.[model] === 'active';
      if (!isActive) {
        console.log(`Filtering out inactive style: ${style} for model ${model}`);
      }
      return isActive;
    })
    .map(style => {
      // Check if style has model-specific substyles
      const substyleKey = `${model}_substyles`;
      const hasSubstyles = stylesData[style] && 
                          (Array.isArray(stylesData[style][substyleKey]) && 
                           stylesData[style][substyleKey].length > 0);
      
      return {
        id: style,
        name: style.split('_').map(word => 
          word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' '),
        hasSubstyles: hasSubstyles,
        labelText: ''
      };
    });

  if (styles.length === 0) {
    console.warn('StyleSelection: No styles found for provider/model', provider, model);
    return (
      <Box>
        <Alert severity="warning">
          No styles available for this model.
        </Alert>
      </Box>
    );
  }

  return (
    <Box>
      <SectionHeader variant="h6">
        Select Style
      </SectionHeader>
      <SelectionGrid>
        {styles.map(({ id, name, hasSubstyles, labelText }) => {
          // Map the style name for the file path
          const styleForPath = styleNameMap[id] || id;
          
          // Generate image paths using the utility function
          const thumbnailSrc = generateImagePath({
            provider,
            model,
            style: styleForPath,
            substyle: 'null',
            isThumbnail: true
          });
          
          const fullImageSrc = generateImagePath({
            provider,
            model,
            style: styleForPath,
            substyle: 'null',
            isThumbnail: false
          });
          
          return (
            <SelectionCard
              key={id}
              title={name}
              description={hasSubstyles ? `${name} (with substyles)` : name}
              selected={selected === id}
              onClick={() => onChange(id)}
              thumbnailSrc={thumbnailSrc}
              fullImageSrc={fullImageSrc}
              labelText={labelText}
            />
          );
        })}
      </SelectionGrid>
    </Box>
  );
};

const SubstyleSelection = ({ config, provider, model, style, selected, onChange }) => {
  if (!config?.providers?.[provider]?.styles?.data?.[style]) {
    console.warn('SubstyleSelection: Missing style data', { provider, style });
    return null;
  }

  // Check if the style is active for this model
  const isStyleActive = config.providers[provider]?.styles?.data?.[style]?.status?.[model] === 'active';
  if (!isStyleActive) {
    console.warn(`SubstyleSelection: Style '${style}' is not active for model '${model}'`);
    return null;
  }

  console.log('SubstyleSelection for style:', style);
  
  const stylesData = config.providers[provider].styles.data;
  const substyleKey = `${model}_substyles`;
  let substyles = stylesData[style]?.[substyleKey] || [];
  
  console.log('Substyles for', substyleKey, ':', substyles);

  // If there are no substyles, create a default "null" substyle to enable selection of this style
  if (!Array.isArray(substyles) || substyles.length === 0) {
    console.log('No substyles found for', { provider, model, style }, '- creating default null substyle');
    substyles = ['null'];
  }

  // Get the style name for the path
  const styleForPath = styleNameMap[style] || style.replace('_image', '');

  // Handle null substyles correctly
  const mappedSubstyles = substyles.map(substyle => {
    const isNull = substyle === null || substyle === 'null';
    return {
      id: isNull ? 'null' : substyle,
      name: isNull ? 'Default' : substyle.split('_').map(word => 
        word.charAt(0).toUpperCase() + word.slice(1)
      ).join(' ')
    };
  });

  return (
    <Box>
      <SectionHeader variant="h6">
        Select Substyle
      </SectionHeader>
      <SelectionGrid>
        {mappedSubstyles.map(({ id, name }) => {
          // Generate image path using the new utility function
          const thumbnailSrc = generateImagePath({
            provider,
            model,
            style: styleForPath,
            substyle: id,
            isThumbnail: true
          });
          
          const fullImageSrc = generateImagePath({
            provider,
            model,
            style: styleForPath,
            substyle: id,
            isThumbnail: false
          });
          
          return (
            <SelectionCard
              key={id}
              title={name}
              description=""
              selected={selected === id}
              onClick={() => onChange(id)}
              thumbnailSrc={thumbnailSrc}
              fullImageSrc={fullImageSrc}
            />
          );
        })}
      </SelectionGrid>
    </Box>
  );
};

// Helper function to get all active models across all providers
const getAllActiveModels = (config) => {
  if (!config || !config.providers) {
    console.warn('No config or providers available');
    return [];
  }
  
  const allModels = [];
  
  // Iterate through all providers and collect active models
  Object.entries(config.providers).forEach(([providerId, providerData]) => {
    // Skip inactive providers
    if (providerData.status !== 'active') {
      console.log(`Skipping inactive provider: ${providerId}`);
      return;
    }
    
    // Check if models are available
    if (!providerData.models?.available || !providerData.models?.status) {
      console.log(`Provider ${providerId} has no available models or status info`);
      return;
    }
    
    // Get all active models for this provider
    const models = (providerData.models.available || [])
      .filter(modelId => {
        const isActive = providerData.models.status[modelId] === 'active';
        if (!isActive) {
          console.log(`Skipping inactive model: ${providerId}/${modelId}`);
        }
        return isActive;
      })
      .map(modelId => {
        // Get pricing info for the model
        const pricing = providerData.pricing?.services?.text_to_image?.[modelId] || 
                        providerData.pricing?.services?.raster_image_generation?.[modelId];
        
        // Create a display name that includes provider
        const provider_name = providerId.charAt(0).toUpperCase() + providerId.slice(1);
        const model_name = modelId.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        const displayName = `${provider_name} - ${model_name}`;
        
        return {
          provider: providerId,
          id: modelId,
          name: displayName,
          description: pricing?.description || 'AI Image Generation Model',
          apiUnits: pricing?.api_units
        };
      });
    
    // Add models to the aggregate list
    allModels.push(...models);
  });
  
  // Sort models by provider name first, then model name
  allModels.sort((a, b) => {
    if (a.provider !== b.provider) {
      return a.provider.localeCompare(b.provider);
    }
    return a.id.localeCompare(b.id);
  });
  
  console.log(`Total active models found: ${allModels.length}`);
  return allModels;
};

// Add a helper function to filter formats based on model selection
const getAvailableFormatsForModel = (config, provider, model) => {
  if (!config?.providers?.[provider]?.supported_formats?.allowed_screen_formats?.[model]) {
    // If no specific formats are defined, use all from supported_formats
    return config?.providers?.[provider]?.supported_formats?.screen_formats || [];
  }
  
  // Return the formats specifically allowed for this model
  return config.providers[provider].supported_formats.allowed_screen_formats[model];
};

// Add a function to find the closest available format when switching models
const findClosestAvailableFormat = (currentFormat, availableFormats) => {
  if (!availableFormats || availableFormats.length === 0) return 7; // Default to 16:9
  
  // Get the current format label
  const currentLabel = formatOptions[currentFormat].label;
  
  // Check if current format is available
  if (availableFormats.includes(currentLabel)) {
    return currentFormat;
  }
  
  // Find closest match by index
  const formatIndices = formatOptions.map((opt, index) => ({ 
    index, 
    label: opt.label 
  })).filter(item => availableFormats.includes(item.label));
  
  if (formatIndices.length === 0) return 7; // Default to 16:9 if nothing matches
  
  // Find the closest format by index position
  formatIndices.sort((a, b) => 
    Math.abs(a.index - currentFormat) - Math.abs(b.index - currentFormat)
  );
  
  return formatIndices[0].index;
};

// Add a function to check if a format is supported
const isFormatSupported = (config, provider, model, formatIndex) => {
  if (!config || !provider || !model) return true; // Default to true if not enough info
  
  const availableFormats = getAvailableFormatsForModel(config, provider, model);
  const formatLabel = formatOptions[formatIndex]?.label;
  
  return availableFormats.includes(formatLabel);
};

const Glow61Processor = ({ data, onComplete, showNotice, trackProcessApiStatus, updateProcessState }) => {
  const process = processConfig.processes.find(p => p.id === 'glow_61');
  const dispatch = useDispatch();
  const savedState = useSelector(state => selectGlowStep(state, 'glow_61'));
  const mainTheme = useTheme();
  
  // Create a combined theme that includes both the orange theme and inherits dark/light mode
  const theme = useMemo(() => createTheme({
    ...orangeTheme,
    palette: {
      ...orangeTheme.palette,
      mode: mainTheme.palette.mode,
      background: mainTheme.palette.background,
      text: mainTheme.palette.text,
    }
  }), [mainTheme.palette.mode]);

  // Initialize loading as false if we have cached data
  const [loading, setLoading] = useState(!savedState?.config);
  const [error, setError] = useState(null);
  const [config, setConfig] = useState(savedState?.config || null);
  
  const [answers, setAnswers] = useState(() => {
    // Initialize from Redux state if available, otherwise from props data, and finally use defaults
    const initialState = savedState?.glow_61_response || data || {};
    const formatValue = initialState.format || 7;
    
    return {
      format: formatValue,
      formatLabel: formatOptions[formatValue].label,
      provider: initialState.provider || '',
      model: initialState.model || '',
      style: initialState.style || '',
      substyle: initialState.substyle || '',
      size: initialState.size || '',
      resolution: initialState.resolution || ''
    };
  });
  
  // Simplified state for feature requests
  const [requestedFeatures, setRequestedFeatures] = useState(new Set());

  // Add refs for sections to control scrolling
  const modelSelectionRef = useRef(null);
  const styleSelectionRef = useRef(null);
  const substyleSelectionRef = useRef(null);
  const formatSelectionRef = useRef(null);
  
  // Define isComplete before using it in useEffect
  const isComplete = () => {
    const complete = answers.provider && answers.model && answers.style && answers.substyle;
    return complete;
  };
  
  // Update Redux state whenever answers change
  useEffect(() => {
    // Always save to Redux in a consistent format
    console.log("Saving glow_61 answers to Redux:", answers);
    dispatch(updateGlowStep({
      stepId: 'glow_61',
      data: {
        // Save in BOTH formats to ensure backward compatibility
        // 1. As glow_61_response (the format expected elsewhere)
        glow_61_response: answers,
        // 2. As direct properties on the step data (to fix the issue)
        ...answers,
        // Also save config to ensure it's available to other components
        config: config
      }
    }));
  }, [answers, dispatch, config]);

  // Add a ref to track if the API has been called
  const apiCalledRef = useRef(false);

  // Fetch configuration on component mount, but check Redux first
  useEffect(() => {
    const fetchConfig = async () => {
      // Skip if already called
      if (apiCalledRef.current) {
        return;
      }
      
      try {
        if (!loading) setLoading(true); // Only set loading if not already loading
        apiCalledRef.current = true;
        
        const response = await axios.get(
          `${API_BASE_URL}/api/v1/config/get_text_to_image_config`,
          {
            headers: { 
              Authorization: `Bearer ${Cookies.get('token')}`,
              accept: 'application/json'
            }
          }
        );
        
        setConfig(response.data.data);
        
        // Notify parent component that this process is ready after API load
        dispatch(updateGlowStep({
          stepId: 'glow_61',
          data: { ...answers },
          status: false, // not completed yet
          ready: true // but ready for interaction
        }));        
        updateProcessState('glow_61', 'ready', { forceReady: true });
      } catch (err) {
        setError(err.message);
        apiCalledRef.current = false;
      } finally {
        setLoading(false);
      }
    };

    // If we have cached config, use it immediately
    if (savedState?.config && !config) {
      setConfig(savedState.config);
      setLoading(false);
      
      // Notify that we're ready from cache
      dispatch(updateGlowStep({
        stepId: 'glow_61',
        data: { ...answers },
        status: false,
        ready: true
      }));
      
      if (trackProcessApiStatus) {
        trackProcessApiStatus('glow_61', 'configLoaded', true, true);
        if (updateProcessState) {
          updateProcessState('glow_61', 'ready', { forceReady: true });
        }
      }
    } 
    // Only fetch if we don't have config and haven't called API
    else if (!config && !apiCalledRef.current) {
      console.log('No cached config found, fetching from API...');
      fetchConfig();
    }
  }, [savedState, dispatch, answers, trackProcessApiStatus, updateProcessState, config, loading]);

  const getFormatPreview = (format) => {
    const option = formatOptions[format];
    const ratio = option.ratio;
    let width, height;
    
    if (ratio >= 1) {
      width = 160;
      height = width / ratio;
    } else {
      height = 160;
      width = height * ratio;
    }

    return { width, height, ratio: option.label };
  };

  const getFormatInfo = (format) => {
    const option = formatOptions[format];
    return {
      ...getFormatPreview(format),
      description: option.description,
      platforms: option.platforms
    };
  };

  // Add format selection to the render
  const formatInfo = getFormatInfo(answers.format);  // Uncommented as it's needed for the format selection
  
  // Add handleFeatureRequest function
  const handleFeatureRequest = (feature) => {
    console.log('Feature requested:', feature);
    showNotice('This feature will be available soon!', 'info');
  };

  // Update handleModelChange to reset format based on available formats
  const handleModelChange = (provider, modelId) => {
    console.log(`Selected provider: ${provider}, model: ${modelId}`);
    
    // Check if this model has any active styles
    const providerData = config.providers[provider];
    const stylesData = providerData?.styles?.data || {};
    const hasActiveStyles = Object.entries(stylesData).some(([style, data]) => {
      return data?.status?.[modelId] === 'active';
    });
    
    // Get available formats for this model
    const availableFormats = getAvailableFormatsForModel(config, provider, modelId);
    console.log(`Available formats for ${provider}/${modelId}:`, availableFormats);
    
    // Find closest available format to current selection
    const closestFormat = findClosestAvailableFormat(answers.format, availableFormats);
    
    // Update answers regardless of whether there are active styles
    setAnswers(prev => ({
      ...prev,
      provider: provider,
      model: modelId,
      // If stability with no styles, set defaults to allow proceeding
      style: (!hasActiveStyles && provider === 'stability') ? 'realistic_image' : '',
      substyle: (!hasActiveStyles && provider === 'stability') ? 'null' : '',
      // Update format to a supported one
      format: closestFormat,
      formatLabel: formatOptions[closestFormat].label
    }));
    
    // If it's stability with no active styles, scroll to format selection
    if (!hasActiveStyles && provider === 'stability') {
      console.log('Using default style for Stability model without active styles');
      setTimeout(() => {
        formatSelectionRef.current?.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }, 100);
    }
    // Otherwise scroll to style selection as normal
    else {
      setTimeout(() => {
        styleSelectionRef.current?.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }, 100);
    }
  };

  // Update handleStyleChange to include scrolling and verify style is active
  const handleStyleChange = (newStyle) => {
    console.log('Selected style:', newStyle);
    
    // Verify the style is active for the selected model
    const isStyleActive = config.providers[answers.provider]?.styles?.data?.[newStyle]?.status?.[answers.model] === 'active';
    if (!isStyleActive) {
      console.warn(`Attempted to select inactive style '${newStyle}' for model '${answers.model}'`);
      return; // Don't allow selecting inactive styles
    }
    
    // Only update state if the style is active
    setAnswers(prev => ({
      ...prev,
      style: newStyle,
      substyle: ''
    }));
    
    // Scroll to substyle selection after a short delay
    setTimeout(() => {
      substyleSelectionRef.current?.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }, 100);
  };

  // Update handleSubstyleChange to include scrolling
  const handleSubstyleChange = (newSubstyle) => {
    setAnswers(prev => ({
      ...prev,
      substyle: newSubstyle
    }));
    
    // Scroll to format selection after a short delay
    setTimeout(() => {
      formatSelectionRef.current?.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }, 100);
  };

  const handleComplete = () => {
    if (!answers.provider || !answers.model || !answers.style) {
      showNotice('Please complete all selections', 'error');
      return;
    }

    // Save final state to Redux
    dispatch(updateGlowStep({
      stepId: 'glow_61',
      data: {
        glow_61_response: answers,
        config: config
      }
    }));

    onComplete({
      processId: 'glow_61',
      data: {
        status: true,
        glow_61_response: {
          provider: answers.provider,
          model: answers.model,
          style: answers.style,
          substyle: answers.substyle,
          format: answers.format,
          formatLabel: answers.formatLabel,
          resolution: answers.resolution
        },
        config: config // Include config in the final data
      }
    });
  };

  const handleFormatChange = (event, newValue) => {
    const selectedFormat = formatOptions[newValue];
    setAnswers(prev => ({
      ...prev,
      format: newValue,
      formatLabel: selectedFormat.label
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      <ViewerHeader 
        title={process.info.title}
        subtitle={process.info.description}
        processId="glow_61"
        showRerun={!!process.endpoints.run}
      />
      <Box sx={{ p: 3, backgroundColor: 'var(--color-surface)' }}>
        {loading ? (
          <React.Fragment>
            <CircularProgress />
            <Typography>Loading configuration data...</Typography>
          </React.Fragment>
        ) : error ? (
          <React.Fragment>
            <Alert severity="error">{error}</Alert>
            <Typography variant="body2" sx={{ mt: 2 }}>Error details logged to console</Typography>
          </React.Fragment>
        ) : (
          <Stack spacing={3} sx={{ width: '100%', mx: 'auto' }}>
            {/* Step 1: Show all active AI models directly */}
            <Box ref={modelSelectionRef}>
              <SectionHeader variant="h6">
                Select AI Model
              </SectionHeader>
              {config ? (
                <>
                  {getAllActiveModels(config).length > 0 ? (
                    <SelectionGrid>
                      {getAllActiveModels(config).map(({ provider, id, name, description }) => {
                        // Generate image paths for the model
                        const thumbnailSrc = generateImagePath({
                          provider,
                          model: id,
                          style: 'realistic',
                          substyle: 'null',
                          isThumbnail: true
                        });
                        
                        const fullImageSrc = generateImagePath({
                          provider,
                          model: id,
                          style: 'realistic',
                          substyle: 'null',
                          isThumbnail: false
                        });
                        
                        const isSelected = answers.provider === provider && answers.model === id;
                        
                        return (
                          <SelectionCard
                            key={`${provider}-${id}`}
                            title={name}
                            description={description}
                            selected={isSelected}
                            onClick={() => handleModelChange(provider, id)}
                            thumbnailSrc={thumbnailSrc}
                            fullImageSrc={fullImageSrc}
                          />
                        );
                      })}
                    </SelectionGrid>
                  ) : (
                    <Alert severity="warning" sx={{ mt: 2 }}>
                      No active AI models found. Please contact support.
                    </Alert>
                  )}
                </>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                  <CircularProgress />
                </Box>
              )}
            </Box>

            {/* Step 2: Once model is selected, show Style selection (unless it's Stability with no active styles) */}
            {answers.provider && answers.model && !(answers.provider === 'stability' && answers.style === 'realistic_image' && answers.substyle === 'null') && (
              <Box ref={styleSelectionRef}>
                <StyleSelection 
                  config={config}
                  provider={answers.provider}
                  model={answers.model}
                  selected={answers.style}
                  onChange={handleStyleChange}
                />
              </Box>
            )}

            {/* Step 3: Once style is selected, show Substyle selection */}
            {answers.style && !(answers.provider === 'stability' && answers.style === 'realistic_image' && answers.substyle === 'null') && (
              <Box ref={substyleSelectionRef}>
                <SubstyleSelection
                  config={config}
                  provider={answers.provider}
                  model={answers.model}
                  style={answers.style}
                  selected={answers.substyle}
                  onChange={handleSubstyleChange}
                />
              </Box>
            )}

            {/* Step 4: Format selection (shown either after substyle selection or directly after model selection for Stability) */}
            {((answers.substyle && answers.style) || (answers.provider === 'stability' && answers.style === 'realistic_image' && answers.substyle === 'null')) && (
              <Box ref={formatSelectionRef}>
                <Card elevation={0} sx={{ borderRadius: 2, backgroundColor: 'var(--card-bg)' }}>
                  <CardHeader 
                    title="Choose Format"
                    sx={{ 
                      '& .MuiCardHeader-title': {
                        fontSize: '1.1rem',
                        fontWeight: 500
                      }
                    }}
                  />
                  <CardContent>
                    {/* Add info about limited formats if needed */}
                    {answers.provider && answers.model && (
                      <Box sx={{ mb: 2 }}>
                        {getAvailableFormatsForModel(config, answers.provider, answers.model).length < formatOptions.length && (
                          <Alert severity="info" sx={{ mb: 2 }}>
                            This model only supports specific formats.
                          </Alert>
                        )}
                      </Box>
                    )}
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={2} alignItems="center">
                          <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 2,
                            justifyContent: 'center'
                          }}>
                            <IconButton 
                              onClick={() => {
                                // Find previous available format
                                const availableFormats = getAvailableFormatsForModel(config, answers.provider, answers.model);
                                const availableIndices = formatOptions
                                  .map((opt, idx) => ({ idx, label: opt.label }))
                                  .filter(item => availableFormats.includes(item.label))
                                  .map(item => item.idx)
                                  .sort((a, b) => a - b);
                                
                                const currentIndex = availableIndices.indexOf(answers.format);
                                if (currentIndex > 0) {
                                  handleFormatChange(null, availableIndices[currentIndex - 1]);
                                }
                              }}
                              disabled={!getAvailableFormatsForModel(config, answers.provider, answers.model)
                                .map(label => formatOptions.findIndex(opt => opt.label === label))
                                .filter(idx => idx < answers.format)
                                .length
                              }
                              sx={{ 
                                color: 'primary.main',
                                '&.Mui-disabled': {
                                  color: 'action.disabled'
                                }
                              }}
                            >
                              <ChevronLeftIcon />
                            </IconButton>
                            
                            <FormatPreview isSupported={isFormatSupported(config, answers.provider, answers.model, answers.format)}>
                              <Box 
                                className="inner-box"
                                sx={{
                                  width: getFormatPreview(answers.format).width * 0.75,
                                  height: getFormatPreview(answers.format).height * 0.75,
                                }}
                              >
                                {getFormatPreview(answers.format).ratio}
                              </Box>
                            </FormatPreview>
                            
                            <IconButton 
                              onClick={() => {
                                // Find next available format
                                const availableFormats = getAvailableFormatsForModel(config, answers.provider, answers.model);
                                const availableIndices = formatOptions
                                  .map((opt, idx) => ({ idx, label: opt.label }))
                                  .filter(item => availableFormats.includes(item.label))
                                  .map(item => item.idx)
                                  .sort((a, b) => a - b);
                                
                                const currentIndex = availableIndices.indexOf(answers.format);
                                if (currentIndex < availableIndices.length - 1) {
                                  handleFormatChange(null, availableIndices[currentIndex + 1]);
                                }
                              }}
                              disabled={!getAvailableFormatsForModel(config, answers.provider, answers.model)
                                .map(label => formatOptions.findIndex(opt => opt.label === label))
                                .filter(idx => idx > answers.format)
                                .length
                              }
                              sx={{ 
                                color: 'primary.main',
                                '&.Mui-disabled': {
                                  color: 'action.disabled'
                                }
                              }}
                            >
                              <ChevronRightIcon />
                            </IconButton>
                          </Box>
                        </Stack>
                      </Grid>
                      
                      <Grid item xs={12} md={8}>
                        <Stack spacing={3}>
                          <Box sx={{ width: '100%', px: 2 }}>
                            <Slider
                              value={answers.format}
                              onChange={handleFormatChange}
                              step={null}
                              marks={formatOptions.map((option, index) => {
                                const isAvailable = getAvailableFormatsForModel(config, answers.provider, answers.model)
                                  .includes(option.label);
                                return {
                                  value: index,
                                  label: isAvailable ? option.label : '',
                                  style: isAvailable ? {} : { display: 'none' }
                                };
                              })}
                              min={0}
                              max={9}
                              disabled={getAvailableFormatsForModel(config, answers.provider, answers.model).length <= 1}
                              sx={{
                                '& .MuiSlider-mark': {
                                  height: '10px'
                                },
                                '& .MuiSlider-markLabel': {
                                  fontSize: '0.75rem'
                                },
                                '& .MuiSlider-track': {
                                  color: theme => 
                                    isFormatSupported(config, answers.provider, answers.model, answers.format) 
                                    ? theme.palette.primary.main 
                                    : theme.palette.error.main
                                },
                                '& .MuiSlider-thumb': {
                                  color: theme => 
                                    isFormatSupported(config, answers.provider, answers.model, answers.format) 
                                    ? theme.palette.primary.main 
                                    : theme.palette.error.main,
                                  '&:hover': {
                                    boxShadow: theme => `0px 0px 0px 8px ${
                                      isFormatSupported(config, answers.provider, answers.model, answers.format) 
                                        ? theme.palette.primary.main + '1A' 
                                        : theme.palette.error.main + '1A'
                                    }`
                                  }
                                }
                              }}
                            />
                          </Box>
                          
                          <Box sx={{ textAlign: 'center' }}>
                            <Typography variant="subtitle1" color="primary" gutterBottom>
                              {formatOptions[answers.format].label} Format
                            </Typography>
                            <Typography 
                              variant="subtitle2" 
                              sx={{ 
                                color: 'success.main',
                                fontWeight: 'bold',
                                mb: 1,
                                height: '24px', // Fixed height for this section
                                visibility: formatOptions[answers.format].popularityNote ? 'visible' : 'hidden'
                              }}
                            >
                              {formatOptions[answers.format].popularityNote ? 
                                `★ ${formatOptions[answers.format].popularityNote} ★` : 
                                "Placeholder text for consistent height"}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                              {formatOptions[answers.format].description}
                            </Typography>
                            
                            <Stack 
                              direction="row" 
                              spacing={1} 
                              justifyContent="center" 
                              flexWrap="wrap" 
                              sx={{ gap: 1, mt: 2 }}
                            >
                              {formatOptions[answers.format].platforms.map((platform, index) => {
                                const IconComponent = PLATFORM_ICONS[platform];
                                return (
                                  <StyledChip 
                                    key={index} 
                                    icon={IconComponent && <IconComponent />}
                                    label={platform}
                                    size="small" 
                                    color="primary" 
                                    variant="outlined"
                                  />
                                );
                              })}
                            </Stack>
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>
                    
                    {/* Warning message moved to bottom of card */}
                    {answers.provider && answers.model && !isFormatSupported(config, answers.provider, answers.model, answers.format) && (
                      <Box sx={{ mt: 3 }}>
                        <Alert severity="warning">
                          The currently selected format "{formatOptions[answers.format].label}" is not supported by this model.
                          Please select one of the highlighted formats.
                        </Alert>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Box>
            )}
          </Stack>
        )}
      </Box>
      <ViewFooter
        processId="glow_61"
        onComplete={handleComplete}
        isComplete={isComplete}
        isCompleteDisabled={loading || !!error}
        completeButtonText="Continue to Video Type"
        loadingStates={{ 'glow_61': loading }}
      />
    </ThemeProvider>
  );
};

export default Glow61Processor;