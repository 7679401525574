import React from 'react';
import { getApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getPerformance, trace } from 'firebase/performance';

/**
 * Comprehensive error monitoring utility for Glowtest web application
 * Combines Firebase Performance Monitoring and Analytics to track errors
 * since Crashlytics is not available for web platforms
 */

let performance = null;
let analytics = null;

/**
 * Initialize the error monitoring service
 * Must be called after Firebase is initialized
 */
export const initErrorMonitoring = () => {
  try {
    const app = getApp();
    performance = getPerformance(app);
    analytics = getAnalytics(app);
    
    // Set up global error handler
    setupGlobalErrorHandlers();
    
    console.log('Error monitoring initialized');
  } catch (error) {
    console.error('Failed to initialize error monitoring:', error);
  }
};

/**
 * Set up global error handlers to catch unhandled errors
 */
const setupGlobalErrorHandlers = () => {
  // Handle uncaught exceptions
  window.addEventListener('error', (event) => {
    captureError(event.error || new Error(event.message), {
      source: 'window.onerror',
      filename: event.filename,
      lineno: event.lineno,
      colno: event.colno
    });
    
    // Don't prevent default to allow browser to show the error
    // return false;
  });
  
  // Handle unhandled promise rejections
  window.addEventListener('unhandledrejection', (event) => {
    const error = event.reason instanceof Error 
      ? event.reason 
      : new Error(String(event.reason));
    
    captureError(error, {
      source: 'unhandledrejection',
      promise_label: event.promise.name || 'unknown'
    });
  });
  
  // For React error boundaries
  if (typeof window.__REACT_ERROR_OVERLAY_GLOBAL_HOOK__ !== 'undefined') {
    const originalReportRuntimeError = window.__REACT_ERROR_OVERLAY_GLOBAL_HOOK__.reportRuntimeError;
    window.__REACT_ERROR_OVERLAY_GLOBAL_HOOK__.reportRuntimeError = (error) => {
      captureError(error, { source: 'react_error_boundary' });
      originalReportRuntimeError(error);
    };
  }
};

/**
 * Capture and report an error
 * @param {Error} error - The error object
 * @param {Object} context - Additional context about the error
 */
export const captureError = (error, context = {}) => {
  if (!error) return;
  
  try {
    // Create a structured error report
    const errorReport = {
      name: error.name || 'Unknown Error',
      message: error.message || 'No message available',
      stack: error.stack || 'No stack trace available',
      timestamp: new Date().toISOString(),
      url: window.location.href,
      user_agent: navigator.userAgent,
      ...context
    };
    
    // Log to Firebase Analytics
    if (analytics) {
      logEvent(analytics, 'app_exception', {
        fatal: context.fatal || false,
        error_name: errorReport.name,
        error_message: errorReport.message.substring(0, 100), // Truncate for Analytics limits
        error_source: context.source || 'manual',
        error_component: context.component || 'unknown',
        error_action: context.action || 'unknown',
        screen_name: window.location.pathname,
      });
    }
    
    // Create a trace for performance monitoring to track error details
    if (performance) {
      const errorTrace = trace(performance, `error_${errorReport.name.replace(/[^a-zA-Z0-9]/g, '_')}`);
      errorTrace.putAttribute('error_message', errorReport.message.substring(0, 100));
      errorTrace.putAttribute('error_source', context.source || 'manual');
      errorTrace.putAttribute('error_component', context.component || 'unknown');
      errorTrace.putAttribute('url_path', window.location.pathname);
      
      // You can add metrics like how many times this error has occurred
      errorTrace.putMetric('occurrence', 1);
      errorTrace.start();
      
      // Stop the trace after a brief delay to ensure it's recorded
      setTimeout(() => {
        errorTrace.stop();
      }, 100);
    }
    
    // Log to console in development
    if (process.env.NODE_ENV === 'development') {
      console.error('Error captured:', errorReport);
    }
    
    return errorReport;
  } catch (reportingError) {
    // If reporting itself fails, log to console
    console.error('Error reporting failed:', reportingError);
    console.error('Original error:', error);
  }
};

/**
 * Manually report an error with component context
 * Use this in try/catch blocks or error boundaries
 * 
 * @param {Error} error - The error that occurred
 * @param {string} componentName - The component where the error occurred
 * @param {string} action - The action being performed when the error occurred
 * @param {boolean} fatal - Whether this error is fatal to the application
 */
export const reportError = (error, componentName, action, fatal = false) => {
  return captureError(error, {
    source: 'manual',
    component: componentName,
    action: action,
    fatal: fatal
  });
};

/**
 * Create an error boundary HOC
 * Usage: export default withErrorBoundary(YourComponent, 'YourComponentName');
 * 
 * @param {React.Component} Component - The component to wrap
 * @param {string} componentName - The name of the component (for error reporting)
 */
export const withErrorBoundary = (Component, componentName) => {
  return class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
      return { hasError: true };
    }

    componentDidCatch(error, info) {
      captureError(error, {
        source: 'error_boundary',
        component: componentName,
        react_info: info
      });
    }

    render() {
      if (this.state.hasError) {
        return <p>Something went wrong in {componentName}.</p>;
      }
      return <Component {...this.props} />;
    }
  };
};

// Create a named object for the default export
const ErrorMonitoring = {
  initErrorMonitoring,
  captureError,
  reportError,
  withErrorBoundary
};

export default ErrorMonitoring; 