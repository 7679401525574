import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useColorScheme } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import ContrastIcon from '@mui/icons-material/Contrast';
import { Close } from '@mui/icons-material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Building, Video, ArrowRight, ChartBar, CheckCircle, PlayCircle, Briefcase } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserType, selectAllGlowSteps } from '../../redux/glowStepsSlice';
import MonetizationDialog from '../../components/ui/monetization';
import SEO from '../../components/SEO';
import './tailwind.min.css';
import './styles.css';
import { trackGTMButtonClick, trackGTMEvent } from '../../utils/gtmUtils';

// Import our components
import AudienceShowcase from './components/landing_AudienceShowcase';
import StartupPathsComparison from './components/landing_StartupPathsComparison';
import ValidationMethodsTable from './components/landing_ValidationMethodsTable';
import HowToGetStarted from './components/landing_HowToGetStarted';
import CollapsibleSection from './components/landing_CollapsibleSection';
import CustomTooltip from './components/landing_CustomTooltip';
import IterationCycleTooltip from './components/landing_IterationCycleTooltip';
import BusinessROIMetrics from './components/landing_BusinessROIMetrics';
import VideoProductionCapabilities from './components/landing_VideoProductionCapabilities';
import EnterpriseForm from './components/landing_EnterpriseForm';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function LandingPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mode, setMode } = useColorScheme();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  
  // Initialize Hotjar
  useEffect(() => {
    // Hotjar Tracking Code for Site 5349923
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:5349923,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  }, []);
  
  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  // Get session data from Redux
  const sessionData = useSelector(selectAllGlowSteps);
  
  // State management
  const [isDarkTheme, setIsDarkTheme] = useState(() => {
    const savedTheme = localStorage.getItem('mui-mode');
    return savedTheme ? savedTheme === 'dark' : true;
  });

  const savedUserType = sessionData?.userType || localStorage.getItem('userType') || null;
  const [activeSection, setActiveSection] = useState(savedUserType);
  const [contentVisible, setContentVisible] = useState(!!savedUserType);
  const [animating, setAnimating] = useState(false);
  const [monetizationOpen, setMonetizationOpen] = useState(false);
  const [accountInfo, setAccountInfo] = useState({ email: '' });
  
  const initialViewRef = useRef(null);
  
  // SEO data - dynamic based on user type
  const seoData = {
    title: activeSection === 'business' 
      ? 'Glowtest - Business Idea Validation for Startups & Entrepreneurs' 
      : activeSection === 'creators'
      ? 'Glowtest - AI Video Creator for Content Producers'
      : 'Glowtest - Professional Business Video Creation Tool',
    description: activeSection === 'business'
      ? 'Validate business ideas in 24 hours. For startups, entrepreneurs, and product teams to test concepts before investing.'
      : activeSection === 'creators'
      ? 'Create professional videos without filming. Turn text into complete videos with AI for promotions, explainers, and social media.'
      : 'Create high-quality videos for business communications. Easy video creation for training, presentations, and internal communications.',
    openGraph: {
      type: 'website',
      title: activeSection === 'business'
        ? 'Glowtest - Business Validation Tool for Startups'
        : activeSection === 'creators'
        ? 'Glowtest - AI-Powered Video Creation Without Filming'
        : 'Glowtest - Professional Business Video Creator',
      description: activeSection === 'business'
        ? 'Validate your business ideas in 24 hours. No technical expertise needed. For startups, entrepreneurs, and product teams.'
        : activeSection === 'creators'
        ? 'Create professional videos from text without filming or video skills. Perfect for content creators, marketers, and educators.'
        : 'Create professional business videos without filming or video expertise. Perfect for training, presentations, and business communications.',
      image: '/images/og-landing-image.jpg',
    },
    meta: [
      { name: 'keywords', content: activeSection === 'business'
        ? 'business idea validation, startup validation, product testing, market fit analysis, startup idea testing, validation before investing'
        : activeSection === 'creators'
        ? 'AI video creator, content creator tools, video without filming, social media content creation, video production, text to video'
        : 'business video creation, corporate video, training videos, presentation videos, internal communications, business presentations'
      },
      { property: 'og:site_name', content: 'Glowtest.it' },
      { name: 'twitter:creator', content: '@glowtest' },
      { name: 'audience', content: activeSection === 'business'
        ? 'startups, entrepreneurs, product managers'
        : activeSection === 'creators'
        ? 'content creators, marketing teams, educators'
        : 'business professionals, corporate trainers, communication specialists, HR teams'
      }
    ]
  };

  // Handlers
  const handleShowPricing = (email = '') => {
    setAccountInfo({ email });
    setMonetizationOpen(true);
    
    // Track pricing dialog open in GTM
    trackGTMEvent('monetization', 'pricing_dialog_open', 'landing_page', {
      userType: activeSection
    });
  };

  const handleSectionSelect = (section) => {
    dispatch(setUserType({ userType: section }));
    localStorage.setItem('userType', section);
    setActiveSection(section);
    setAnimating(true);
    setTimeout(() => {
      setAnimating(false);
      setContentVisible(true);
    }, 300);
    
    // Track section selection in GTM
    trackGTMEvent('user_preference', 'user_type_selection', section);
  };

  const handleSectionSwitch = (section) => {
    if (section === activeSection) return;
    dispatch(setUserType({ userType: section }));
    localStorage.setItem('userType', section);
    setContentVisible(false);
    setTimeout(() => {
      setActiveSection(section);
      setContentVisible(true);
    }, 300);
  };

  const toggleTheme = () => {
    const newMode = !isDarkTheme;
    setIsDarkTheme(newMode);
    setMode(newMode ? 'dark' : 'light');
    document.body.className = newMode ? 'theme-dark' : 'theme-light';
  };

  // Render button helper with GTM tracking
  const renderButton = ({ text, icon, primary = true, className, onClick, eventName }) => (
    <button
      className={`${className} px-6 py-3 rounded-lg font-semibold flex items-center gap-2 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500`}
      style={{
        backgroundColor: primary ? '#FD9800' : 'transparent',
        borderColor: primary ? 'transparent' : '#FD9800',
        borderWidth: primary ? 0 : '1px',
        color: primary ? 'white' : '#FD9800'
      }}
      onClick={(e) => {
        // Track button click in GTM if eventName is provided
        if (eventName) {
          trackGTMButtonClick(eventName, text, {
            userType: activeSection || 'none',
            isPrimary: primary
          });
        }
        // Call the original onClick handler
        onClick(e);
      }}
      aria-label={text}
    >
      {text}
      {icon}
    </button>
  );

  // Handle clicks outside of the buttons on the initial view
  const handleOutsideClick = (e) => {
    // If we're not in the initial view state, do nothing
    if (activeSection) return;
    
    // Get the button container
    const buttonContainer = document.querySelector('.initial-view-buttons');
    
    // If the click is outside the button container, navigate to professional
    if (buttonContainer && !buttonContainer.contains(e.target)) {
      handleSectionSelect('professional');
    }
  };

  if (!mode) {
    return null;
  }

  return (
    <>
      <SEO {...seoData} />
      
      {/* Theme Toggle - Positioned absolutely on the page */}
      <div className="fixed top-12 left-12 z-50" style={{ position: 'fixed', top: '21px', left: '48px' }}>
        <IconButton 
          onClick={toggleTheme} 
          sx={{ 
            color: isDarkTheme ? '#FFFFFF' : '#1C1C1E',
            bgcolor: isDarkTheme ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
            '&:hover': {
              bgcolor: isDarkTheme ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)',
              color: '#FD9800'
            }
          }}
        >
          <ContrastIcon />
        </IconButton>
      </div>
      
      {/* Navigation - Only show after selection */}
      {activeSection && (
        <nav className="sticky top-0 z-40 backdrop-blur-md bg-black/70 border-b border-zinc-800/80">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex-shrink-0 flex items-center">
                <a href="/" className="flex items-center gap-2">
                  <img 
                    src={isDarkTheme ? '/images/logo-dark-desktop.png' : '/images/logo-light-desktop.png'} 
                    alt="Glowtest AI-Powered Validation Platform"
                    className="h-8"
                  />
                </a>
                      </div>
              <div className="flex items-center">
                <button 
                  onClick={() => {
                    handleShowPricing();
                    trackGTMButtonClick('nav_pricing_button', 'Pricing', {
                      userType: activeSection || 'none',
                      location: 'navigation'
                    });
                  }}
                  className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium mr-4 transition-colors"
                >
                  Pricing
                </button>
            <button
                  onClick={() => {
                    navigate('/journey');
                    trackGTMButtonClick('nav_journey_button', activeSection === 'business' ? 'Validate My Idea' : 'Create Video', {
                      userType: activeSection || 'none',
                      location: 'navigation'
                    });
                  }}
                  className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-md text-sm font-medium transition-colors"
                >
                  {activeSection === 'business' ? 'Validate My Idea' : activeSection === 'creators' ? 'Create Video' : 'Create Business Video'}
            </button>
          </div>
        </div>
      </div>
        </nav>
      )}

      {/* Main Content */}
      <div className={`min-h-screen bg-zinc-900 text-white ${isDarkTheme ? 'dark' : 'light'} ${!activeSection ? 'flex items-center justify-center' : ''}`}>
        {/* Audience Selection - Only show after initial selection */}
        {activeSection && (
          <div 
            className="fixed top-0 left-0 right-0 bg-zinc-900 z-40 transition-all duration-300 ease-in-out"
            style={{
              transform: animating ? 'translateY(-100%)' : 'translateY(0)',
            }}
          >
            <div className="container mx-auto px-2 sm:px-4 py-1 sm:py-2 flex justify-between items-center">
              <div className="flex-1"></div>
              <div className="flex items-center justify-center">
                <span className="text-zinc-300 text-xs sm:text-sm mr-1 sm:mr-2">I am a</span>
                <div className="bg-zinc-800/30 p-0.5 sm:p-1 rounded-lg inline-flex">
                  <button
                    className={`px-2 sm:px-4 py-1 sm:py-2 text-xs sm:text-sm rounded-lg transition-all flex items-center gap-1 sm:gap-2 ${activeSection === 'business' ? 'text-white' : 'text-zinc-300 hover:text-white'}`}
                    onClick={() => handleSectionSwitch('business')}
                    style={{ backgroundColor: activeSection === 'business' ? '#FD9800' : 'transparent' }}
                  >
                    <Building size={14} className="hidden xs:inline" />
                    Entrepreneur
                  </button>
                  <button 
                    className={`px-2 sm:px-4 py-1 sm:py-2 text-xs sm:text-sm rounded-lg transition-all flex items-center gap-1 sm:gap-2 ${activeSection === 'creators' ? 'text-white' : 'text-zinc-300 hover:text-white'}`}
                    onClick={() => handleSectionSwitch('creators')}
                    style={{ backgroundColor: activeSection === 'creators' ? '#FD9800' : 'transparent' }}
                  >
                    <Video size={14} className="hidden xs:inline" />
                    Video Producer
                  </button>
                  <button 
                    className={`px-2 sm:px-4 py-1 sm:py-2 text-xs sm:text-sm rounded-lg transition-all flex items-center gap-1 sm:gap-2 ${activeSection === 'professional' ? 'text-white' : 'text-zinc-300 hover:text-white'}`}
                    onClick={() => handleSectionSwitch('professional')}
                    style={{ backgroundColor: activeSection === 'professional' ? '#FD9800' : 'transparent' }}
                  >
                    <Briefcase size={14} className="hidden xs:inline" />
                    Professional
                  </button>
                </div>
              </div>
              <div className="flex-1 flex justify-end">
                <a href="/">
                  <img 
                    src="/images/logo-light-desktop.png" 
                    alt="Glowtest Logo" 
                    className="h-12 sm:h-16 w-auto" 
                    style={{ filter: 'drop-shadow(0 0 5px rgba(253, 152, 0, 0.2))' }}
                  />
                </a>
              </div>
            </div>
          </div>
        )}

        <main className={`container mx-auto px-3 sm:px-4 ${activeSection ? 'pt-24 sm:pt-28' : 'py-0'} transition-all duration-300 ${!activeSection ? 'flex items-center justify-center h-full' : ''}`}>
          {/* Initial View */}
          <div 
            id="initial-view-container"
            ref={initialViewRef}
            onClick={handleOutsideClick}
            className={`flex flex-col items-center justify-center transition-opacity duration-300 ease-in-out ${activeSection ? 'opacity-0 h-0 overflow-hidden' : 'opacity-100 w-full'}`} 
            style={{ minHeight: activeSection ? '0' : 'auto' }}
          >
            <div className="mb-8 sm:mb-12 flex justify-center">
              <a href="/">
                <picture>
                  <source 
                    media="(min-width: 768px)" 
                    srcSet="/images/logo-light-desktop.png" 
                  />
                  <source 
                    media="(max-width: 767px)" 
                    srcSet="/images/logo-light-mobile.png" 
                  />
                  <img
                    src="/images/logo-light-desktop.png"
                    alt="GlowTest Logo"
                    className="h-24 sm:h-32 w-auto"
                    style={{
                      filter: 'drop-shadow(0 0 10px rgba(253, 152, 0, 0.3))'
                    }}
                  />
                </picture>
              </a>
            </div>

            <div className="text-center">
              <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 sm:mb-8">
                I am a...
              </h2>
              <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 justify-center initial-view-buttons">
                {renderButton({ 
                  text: 'Entrepreneur', 
                  icon: <Building className="ml-2" size={20} />, 
                  onClick: () => handleSectionSelect('business'),
                  className: 'px-4 sm:px-6 py-2.5 sm:py-3 text-sm sm:text-base md:text-lg',
                  eventName: 'user_type_selection'
                })}
                
                {renderButton({ 
                  text: 'Video Producer', 
                  icon: <Video className="ml-2" size={20} />, 
                  onClick: () => handleSectionSelect('creators'),
                  className: 'px-4 sm:px-6 py-2.5 sm:py-3 text-sm sm:text-base md:text-lg',
                  eventName: 'user_type_selection'
                })}
                
                {renderButton({ 
                  text: 'Professional', 
                  icon: <Briefcase className="ml-2" size={20} />, 
                  onClick: () => handleSectionSelect('professional'),
                  className: 'px-4 sm:px-6 py-2.5 sm:py-3 text-sm sm:text-base md:text-lg',
                  eventName: 'user_type_selection'
                })}
              </div>
            </div>
          </div>

          {/* Content after selection with enhanced conditional rendering */}
          {activeSection && (
            <div 
              className={`transition-all duration-500 ease-in-out ${contentVisible ? 'opacity-100 transform translate-y-0' : 'opacity-0 transform translate-y-10'}`}
            >
              {/* Logo */}
              <div className="flex justify-center mb-8 sm:mb-12">
                <img 
                  src="/images/logo-light-desktop.png" 
                  alt="Glowtest AI-Powered Business and Video Validation Platform" 
                  className="h-20 sm:h-24 md:h-32 w-auto"
                  style={{
                    filter: 'drop-shadow(0 0 8px rgba(253, 152, 0, 0.3))'
                  }}
                />
              </div>

              {/* Hero Section - Enhanced with more specific messaging */}
              <section className="text-center mb-10 sm:mb-16" aria-label="Hero">
                <h1 className="text-3xl sm:text-4xl md:text-6xl font-bold mb-3 sm:mb-4">
                  {activeSection === 'business' 
                    ? "Validate Your Business Ideas With 24-Hour Testing" 
                    : activeSection === 'creators'
                    ? "Create Professional Videos Without Filming Anything"
                    : "Create Professional Videos For Business Communication"}
                </h1>
                <p className="text-base sm:text-xl text-zinc-300 mb-6 sm:mb-8 max-w-3xl mx-auto">
                  {activeSection === 'business'
                    ? "Eliminate the risk of launching unsuccessful products. Test your ideas and get real market feedback before investing time and money."
                    : activeSection === 'creators'
                    ? "Transform text into complete 30+ second videos with AI. Perfect for promotions, explainers, tutorials, and social media content."
                    : "Easily create professional business videos for training, presentations, and internal communication without filming equipment or expertise."}
                </p>
                <div className="flex flex-wrap justify-center gap-3 sm:gap-4">
                  {renderButton({ 
                    text: activeSection === 'business' 
                      ? 'Validate My Idea - Start for Free' 
                      : activeSection === 'creators'
                      ? 'Create My Video - Start for Free'
                      : 'Create Business Video - Start for Free', 
                    icon: <ArrowRight size={18} />, 
                    onClick: () => navigate('/journey'),
                    className: 'px-5 sm:px-8 py-3 sm:py-4 text-sm sm:text-base md:text-lg',
                    eventName: 'user_type_selection'
                  })}
                  {renderButton({ 
                    text: 'See How It Works', 
                    icon: <ChartBar size={18} />, 
                    primary: false,
                    onClick: () => navigate('/journey'),
                    className: 'px-5 sm:px-8 py-3 sm:py-4 text-sm sm:text-base md:text-lg',
                    eventName: 'user_type_selection'
                  })}
                </div>
              </section>

              {/* User-type specific highlight banner */}
              <div className="mb-10 sm:mb-16 bg-gradient-to-r from-orange-700/20 to-amber-600/30 p-4 sm:p-6 rounded-xl">
                {activeSection === 'business' ? (
                  <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="mb-4 md:mb-0 md:mr-8">
                      <h3 className="text-xl sm:text-2xl font-bold text-orange-400 mb-2">For Entrepreneurs & Startups</h3>
                      <p className="text-sm sm:text-base text-zinc-200">
                        Don't waste months and thousands on untested ideas. Get real market validation for your business concept in just 24 hours.
                      </p>
                    </div>
                    <button
                      onClick={() => navigate('/journey')}
                      className="whitespace-nowrap bg-orange-500 hover:bg-orange-600 text-white px-4 sm:px-6 py-2.5 sm:py-3 rounded-lg font-semibold inline-flex items-center gap-2 transition-colors"
                    >
                      Start Validation
                      <ArrowRight size={18} />
                    </button>
                  </div>
                ) : activeSection === 'creators' ? (
                  <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="mb-4 md:mb-0 md:mr-8">
                      <h3 className="text-xl sm:text-2xl font-bold text-orange-400 mb-2">For Content Creators & Marketers</h3>
                      <p className="text-sm sm:text-base text-zinc-200">
                        No filming equipment, video editing skills, or actors needed. Create professional videos directly from your script or text description.
                      </p>
                    </div>
                    <button
                      onClick={() => navigate('/journey')}
                      className="whitespace-nowrap bg-orange-500 hover:bg-orange-600 text-white px-4 sm:px-6 py-2.5 sm:py-3 rounded-lg font-semibold inline-flex items-center gap-2 transition-colors"
                    >
                      Create Video
                      <ArrowRight size={18} />
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="mb-4 md:mb-0 md:mr-8">
                      <h3 className="text-xl sm:text-2xl font-bold text-orange-400 mb-2">For Business Professionals</h3>
                      <p className="text-sm sm:text-base text-zinc-200">
                        Create polished business presentations, training videos, and communications without specialized skills or external agencies.
                      </p>
                    </div>
                    <button
                      onClick={() => navigate('/journey')}
                      className="whitespace-nowrap bg-orange-500 hover:bg-orange-600 text-white px-4 sm:px-6 py-2.5 sm:py-3 rounded-lg font-semibold inline-flex items-center gap-2 transition-colors"
                    >
                      Create Business Video
                      <ArrowRight size={18} />
                    </button>
                  </div>
                )}
              </div>

              {/* Components with conditional rendering */}
              <HowToGetStarted 
                section={activeSection}
                handleShowPricing={handleShowPricing}
              />

              {/* Only show validation methods table for business users */}
              {activeSection === 'business' && (
                <ValidationMethodsTable 
                  handleShowPricing={handleShowPricing}
                />
              )}

              {/* Only show startup paths comparison for business users */}
              {activeSection === 'business' && (
              <StartupPathsComparison />
              )}

              {/* Only show ROI metrics for business users */}
              {activeSection === 'business' && (
                <BusinessROIMetrics />
              )}

              {/* Content creator specific section - show only for creators */}
              {activeSection === 'creators' && (
                <section className="py-8 sm:py-12 md:py-16 mb-12 sm:mb-16 md:mb-20">
                  <div className="text-center mb-8 sm:mb-12">
                    <h2 className="text-2xl sm:text-3xl font-bold mb-3 sm:mb-4">
                      Create Any Type of Video Content
                    </h2>
                    <p className="text-zinc-300 max-w-2xl mx-auto text-sm sm:text-base">
                      From social media to educational videos - all without filming
                    </p>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 sm:gap-6">
                    <div className="bg-zinc-800/30 p-4 sm:p-6 rounded-xl">
                      <div className="mb-3 sm:mb-4 text-orange-500">
                        <PlayCircle size={isMobile ? 32 : 40} />
                      </div>
                      <h3 className="text-lg sm:text-xl font-bold mb-2">Social Media Videos</h3>
                      <p className="text-zinc-400 mb-3 sm:mb-4 text-sm sm:text-base">
                        Create engaging content for Instagram, TikTok, YouTube Shorts and more from just a text description.
                      </p>
                      <button
                        onClick={() => navigate('/journey')}
                        className="text-orange-500 hover:text-orange-400 font-medium flex items-center gap-1 text-sm sm:text-base"
                      >
                        Try it free <ArrowRight size={16} />
                      </button>
                    </div>
                    <div className="bg-zinc-800/30 p-4 sm:p-6 rounded-xl">
                      <div className="mb-3 sm:mb-4 text-orange-500">
                        <PlayCircle size={isMobile ? 32 : 40} />
                      </div>
                      <h3 className="text-lg sm:text-xl font-bold mb-2">Explainer Videos</h3>
                      <p className="text-zinc-400 mb-3 sm:mb-4 text-sm sm:text-base">
                        Turn complex concepts into easy-to-understand explainer videos for products, services or educational content.
                      </p>
                      <button
                        onClick={() => navigate('/journey')}
                        className="text-orange-500 hover:text-orange-400 font-medium flex items-center gap-1 text-sm sm:text-base"
                      >
                        Try it free <ArrowRight size={16} />
                      </button>
                    </div>
                    <div className="bg-zinc-800/30 p-4 sm:p-6 rounded-xl">
                      <div className="mb-3 sm:mb-4 text-orange-500">
                        <PlayCircle size={isMobile ? 32 : 40} />
                      </div>
                      <h3 className="text-lg sm:text-xl font-bold mb-2">Training & Tutorials</h3>
                      <p className="text-zinc-400 mb-3 sm:mb-4 text-sm sm:text-base">
                        Create professional training videos, tutorials and educational content without expensive production.
                      </p>
                      <button
                        onClick={() => navigate('/journey')}
                        className="text-orange-500 hover:text-orange-400 font-medium flex items-center gap-1 text-sm sm:text-base"
                      >
                        Try it free <ArrowRight size={16} />
                      </button>
                    </div>
                  </div>
                </section>
              )}

              {/* Professional user specific section */}
              {activeSection === 'professional' && (
                <section className="py-8 sm:py-12 md:py-16 mb-12 sm:mb-16 md:mb-20">
                  <div className="text-center mb-8 sm:mb-12">
                    <h2 className="text-2xl sm:text-3xl font-bold mb-3 sm:mb-4">
                      Professional Business Video Creation
                    </h2>
                    <p className="text-zinc-300 max-w-2xl mx-auto text-sm sm:text-base">
                      Boost internal communications and training with professional videos
                    </p>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 sm:gap-6">
                    <div className="bg-zinc-800/30 p-4 sm:p-6 rounded-xl">
                      <div className="mb-3 sm:mb-4 text-orange-500">
                        <PlayCircle size={isMobile ? 32 : 40} />
                      </div>
                      <h3 className="text-lg sm:text-xl font-bold mb-2">Training Materials</h3>
                      <p className="text-zinc-400 mb-3 sm:mb-4 text-sm sm:text-base">
                        Create engaging onboarding and training videos that improve employee learning outcomes.
                      </p>
                      <button
                        onClick={() => navigate('/journey')}
                        className="text-orange-500 hover:text-orange-400 font-medium flex items-center gap-1 text-sm sm:text-base"
                      >
                        Try it free <ArrowRight size={16} />
                      </button>
                    </div>
                    <div className="bg-zinc-800/30 p-4 sm:p-6 rounded-xl">
                      <div className="mb-3 sm:mb-4 text-orange-500">
                        <PlayCircle size={isMobile ? 32 : 40} />
                      </div>
                      <h3 className="text-lg sm:text-xl font-bold mb-2">Executive Updates</h3>
                      <p className="text-zinc-400 mb-3 sm:mb-4 text-sm sm:text-base">
                        Create professional company announcements and executive communications with polished visuals.
                      </p>
                      <button
                        onClick={() => navigate('/journey')}
                        className="text-orange-500 hover:text-orange-400 font-medium flex items-center gap-1 text-sm sm:text-base"
                      >
                        Try it free <ArrowRight size={16} />
                      </button>
                    </div>
                    <div className="bg-zinc-800/30 p-4 sm:p-6 rounded-xl">
                      <div className="mb-3 sm:mb-4 text-orange-500">
                        <PlayCircle size={isMobile ? 32 : 40} />
                      </div>
                      <h3 className="text-lg sm:text-xl font-bold mb-2">Presentation Videos</h3>
                      <p className="text-zinc-400 mb-3 sm:mb-4 text-sm sm:text-base">
                        Transform PowerPoint presentations into dynamic, engaging video content with narration.
                      </p>
                      <button
                        onClick={() => navigate('/journey')}
                        className="text-orange-500 hover:text-orange-400 font-medium flex items-center gap-1 text-sm sm:text-base"
                      >
                        Try it free <ArrowRight size={16} />
                      </button>
                    </div>
                  </div>
                </section>
              )}

              {/* Only show video production capabilities for creators and professionals */}
              {(activeSection === 'creators' || activeSection === 'professional') && (
                <VideoProductionCapabilities />
              )}

              <AudienceShowcase 
                filteredAudiences={activeSection === 'business' 
                  ? ['Investors', 'Small Business Owners', 'Product Managers']
                  : activeSection === 'creators'
                  ? ['Content Creators', 'Marketing Teams', 'Communities', 'Marketers']
                  : ['Marketing Teams', 'Product Managers', 'Marketers', 'Communities']
                }
              />

              {/* Enterprise Form */}
              <EnterpriseForm activeSection={activeSection} />

              {/* Testimonials section */}
              <section className="py-8 sm:py-12 md:py-16 mb-12 sm:mb-16 md:mb-20">
                <div className="text-center mb-8 sm:mb-12">
                  <h2 className="text-2xl sm:text-3xl font-bold mb-3 sm:mb-4">
                    {activeSection === 'business' 
                      ? "What Our Startup Clients Say" 
                      : activeSection === 'creators'
                      ? "What Content Creators Say"
                      : "What Business Professionals Say"}
                  </h2>
                  <p className="text-zinc-300 max-w-2xl mx-auto text-sm sm:text-base">
                    {activeSection === 'business'
                      ? "Real results from entrepreneurs who validated their ideas with Glowtest"
                      : activeSection === 'creators'
                      ? "Real feedback from creators who use our AI video technology"
                      : "Real feedback from professionals using our business video solution"}
                  </p>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 sm:gap-8">
                  {activeSection === 'business' ? (
                    <>
                      <div className="bg-zinc-800/30 p-4 sm:p-6 rounded-xl">
                        <div className="flex items-center mb-3 sm:mb-4">
                          <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-orange-500/20 flex items-center justify-center text-base sm:text-xl font-bold text-orange-400">JD</div>
                          <div className="ml-3 sm:ml-4">
                            <h4 className="font-semibold text-sm sm:text-base">John Davies</h4>
                            <p className="text-xs sm:text-sm text-zinc-400">SaaS Founder</p>
                          </div>
                        </div>
                        <p className="text-sm sm:text-base text-zinc-300">
                          "Glowtest saved us from investing $50K in the wrong direction. In just two days, we identified that our target market needed a completely different solution."
                        </p>
                      </div>
                      <div className="bg-zinc-800/30 p-4 sm:p-6 rounded-xl">
                        <div className="flex items-center mb-3 sm:mb-4">
                          <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-orange-500/20 flex items-center justify-center text-base sm:text-xl font-bold text-orange-400">SH</div>
                          <div className="ml-3 sm:ml-4">
                            <h4 className="font-semibold text-sm sm:text-base">Sarah Hughes</h4>
                            <p className="text-xs sm:text-sm text-zinc-400">E-commerce Startup</p>
                          </div>
                        </div>
                        <p className="text-sm sm:text-base text-zinc-300">
                          "The market insights we got were incredibly detailed. We discovered a pricing strategy that doubled our projected margins before we even launched."
                        </p>
                      </div>
                      <div className="bg-zinc-800/30 p-4 sm:p-6 rounded-xl">
                        <div className="flex items-center mb-3 sm:mb-4">
                          <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-orange-500/20 flex items-center justify-center text-base sm:text-xl font-bold text-orange-400">MR</div>
                          <div className="ml-3 sm:ml-4">
                            <h4 className="font-semibold text-sm sm:text-base">Mark Rodriguez</h4>
                            <p className="text-xs sm:text-sm text-zinc-400">Tech Entrepreneur</p>
                          </div>
                        </div>
                        <p className="text-sm sm:text-base text-zinc-300">
                          "After three failed startups, I finally used Glowtest to validate first. Now we're growing 25% month-over-month because we built exactly what customers wanted."
                        </p>
                      </div>
                    </>
                  ) : activeSection === 'creators' ? (
                    <>
                      <div className="bg-zinc-800/30 p-4 sm:p-6 rounded-xl">
                        <div className="flex items-center mb-3 sm:mb-4">
                          <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-orange-500/20 flex items-center justify-center text-base sm:text-xl font-bold text-orange-400">AL</div>
                          <div className="ml-3 sm:ml-4">
                            <h4 className="font-semibold text-sm sm:text-base">Amy Lee</h4>
                            <p className="text-xs sm:text-sm text-zinc-400">Content Creator</p>
                          </div>
                        </div>
                        <p className="text-sm sm:text-base text-zinc-300">
                          "I create 10x more content now without hiring a video team. My engagement rates are up 45% and I've cut production costs by 80%."
                        </p>
                      </div>
                      <div className="bg-zinc-800/30 p-4 sm:p-6 rounded-xl">
                        <div className="flex items-center mb-3 sm:mb-4">
                          <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-orange-500/20 flex items-center justify-center text-base sm:text-xl font-bold text-orange-400">DP</div>
                          <div className="ml-3 sm:ml-4">
                            <h4 className="font-semibold text-sm sm:text-base">David Parker</h4>
                            <p className="text-xs sm:text-sm text-zinc-400">Marketing Director</p>
                          </div>
                        </div>
                        <p className="text-sm sm:text-base text-zinc-300">
                          "We needed videos for 12 different product variations. Glowtest helped us create all of them in two days, when it would have taken weeks with traditional production."
                        </p>
                      </div>
                      <div className="bg-zinc-800/30 p-4 sm:p-6 rounded-xl">
                        <div className="flex items-center mb-3 sm:mb-4">
                          <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-orange-500/20 flex items-center justify-center text-base sm:text-xl font-bold text-orange-400">ES</div>
                          <div className="ml-3 sm:ml-4">
                            <h4 className="font-semibold text-sm sm:text-base">Emma Smith</h4>
                            <p className="text-xs sm:text-sm text-zinc-400">Online Educator</p>
                          </div>
                        </div>
                        <p className="text-sm sm:text-base text-zinc-300">
                          "Creating educational videos used to take days of filming and editing. Now I can produce a full lesson in an hour, and my students love the professional quality."
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="bg-zinc-800/30 p-4 sm:p-6 rounded-xl">
                        <div className="flex items-center mb-3 sm:mb-4">
                          <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-orange-500/20 flex items-center justify-center text-base sm:text-xl font-bold text-orange-400">RJ</div>
                          <div className="ml-3 sm:ml-4">
                            <h4 className="font-semibold text-sm sm:text-base">Robert Johnson</h4>
                            <p className="text-xs sm:text-sm text-zinc-400">HR Director</p>
                          </div>
                        </div>
                        <p className="text-sm sm:text-base text-zinc-300">
                          "Our onboarding process needed updating, but we couldn't afford a professional video team. Glowtest let us create 15 training videos in just one week. New staff feedback has improved 60%."
                        </p>
                      </div>
                      <div className="bg-zinc-800/30 p-4 sm:p-6 rounded-xl">
                        <div className="flex items-center mb-3 sm:mb-4">
                          <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-orange-500/20 flex items-center justify-center text-base sm:text-xl font-bold text-orange-400">LT</div>
                          <div className="ml-3 sm:ml-4">
                            <h4 className="font-semibold text-sm sm:text-base">Lisa Thompson</h4>
                            <p className="text-xs sm:text-sm text-zinc-400">Corporate Trainer</p>
                          </div>
                        </div>
                        <p className="text-sm sm:text-base text-zinc-300">
                          "I used to spend hours preparing presentation videos for each client. Now I can generate customized training materials in minutes, which has transformed our business model."
                        </p>
                      </div>
                      <div className="bg-zinc-800/30 p-4 sm:p-6 rounded-xl">
                        <div className="flex items-center mb-3 sm:mb-4">
                          <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-orange-500/20 flex items-center justify-center text-base sm:text-xl font-bold text-orange-400">MC</div>
                          <div className="ml-3 sm:ml-4">
                            <h4 className="font-semibold text-sm sm:text-base">Michael Chen</h4>
                            <p className="text-xs sm:text-sm text-zinc-400">Communications Manager</p>
                          </div>
                        </div>
                        <p className="text-sm sm:text-base text-zinc-300">
                          "Our executive updates needed a more professional touch but we had no video budget. Glowtest helped us create engaging internal communications that increased employee engagement by 40%."
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </section>

              {/* Monetization Dialog */}
              <MonetizationDialog 
                open={monetizationOpen} 
                onClose={() => setMonetizationOpen(false)} 
                accountInfo={accountInfo}
              />
            </div>
          )}
        </main>
        
        {/* Footer - Only show after selection */}
        {activeSection && (
          <div className="w-full py-6 sm:py-8 mt-6 sm:mt-8 border-t border-gray-800 bg-gray-900">
            <div className="container mx-auto px-3 sm:px-4">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-8 mb-6 sm:mb-8">
                <div>
                  <h3 className="text-white text-base sm:text-lg font-semibold mb-3 sm:mb-4">About Glow</h3>
                  <p className="text-sm sm:text-base text-gray-400">
                    {activeSection === 'business'
                      ? "Glow helps entrepreneurs validate business ideas with AI-powered market testing before investing significant time and money."
                      : activeSection === 'creators'
                      ? "Glow helps content creators produce professional videos from text without filming, equipment, or technical expertise."
                      : "Glow helps business professionals create high-quality videos for training, presentations, and internal communications."}
                  </p>
                </div>
                <div>
                  <h3 className="text-white text-base sm:text-lg font-semibold mb-3 sm:mb-4">Resources</h3>
                  <ul className="text-gray-400">
                    <li className="mb-2"><a href="#" className="text-sm sm:text-base hover:text-white transition-colors">Documentation</a></li>
                    <li className="mb-2"><a href="#" className="text-sm sm:text-base hover:text-white transition-colors">API Reference</a></li>
                    <li className="mb-2"><a href="#" className="text-sm sm:text-base hover:text-white transition-colors">Blog</a></li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-white text-base sm:text-lg font-semibold mb-3 sm:mb-4">Contact</h3>
                  <ul className="text-gray-400">
                    <li className="mb-2"><a href="mailto:info@playanoedu.com" className="text-sm sm:text-base hover:text-white transition-colors">info@playanoedu.com</a></li>
                    <li className="mb-2"><a href="https://www.playanoedu.com" target="_blank" rel="noopener noreferrer" className="text-sm sm:text-base hover:text-white transition-colors">www.playanoedu.com</a></li>
                  </ul>
                </div>
              </div>
              
              <div className="border-t border-gray-800 pt-4 sm:pt-6 flex flex-col md:flex-row justify-between items-center">
                <p className="text-xs sm:text-sm text-gray-500 mb-4 md:mb-0">© 2025 Glow. All rights reserved.</p>
                <a 
                  href="https://www.playanoedu.com" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="flex items-center"
                  aria-label="Visit Playano Education Limited website"
                >
                  <span className="text-xs sm:text-sm text-gray-500 mr-2">Created by</span>
                  <span className="text-xs sm:text-sm text-gray-300 mr-2">Playano Education Limited</span>
                  <img 
                    src="/playano_mobile_on_dark_background.png" 
                    alt="Playano Education Limited logo" 
                    className="h-6 sm:h-8"
                  />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
} 