import { getAnalytics, logEvent, setUserProperties, setUserId, setAnalyticsCollectionEnabled } from 'firebase/analytics';

/**
 * Analytics utility for tracking user events and engagement
 * @module analyticsUtils
 */

// Store the analytics instance to avoid re-initialization
let analyticsInstance = null;

/**
 * Initialize Firebase Analytics with the provided Firebase app instance
 * @param {Object} firebaseApp - The initialized Firebase app instance
 * @param {boolean} enabled - Whether analytics collection should be enabled
 * @returns {Object} The Analytics instance
 */
export const initAnalytics = (firebaseApp, enabled = true) => {
  try {
    analyticsInstance = getAnalytics(firebaseApp);
    
    // Set analytics collection enabled/disabled based on parameter
    setAnalyticsCollectionEnabled(analyticsInstance, enabled);
    
    return analyticsInstance;
  } catch (error) {
    console.error('Error initializing analytics:', error);
    return null;
  }
};

/**
 * Track a button click event
 * @param {string} buttonId - Identifier for the button
 * @param {string} buttonName - Human-readable name of the button
 * @param {Object} [additionalParams={}] - Additional parameters to log with the event
 */
export const trackButtonClick = (buttonId, buttonName, additionalParams = {}) => {
  if (!analyticsInstance) return;
  
  try {
    logEvent(analyticsInstance, 'button_click', {
      button_id: buttonId,
      button_name: buttonName,
      timestamp: new Date().toISOString(),
      ...additionalParams
    });
  } catch (error) {
    console.error('Error tracking button click:', error);
  }
};

/**
 * Track a page view event
 * @param {string} pageName - Name of the page
 * @param {string} pageUrl - URL of the page
 * @param {Object} [additionalParams={}] - Additional parameters to log with the event
 */
export const trackPageView = (pageName, pageUrl, additionalParams = {}) => {
  if (!analyticsInstance) return;
  
  try {
    logEvent(analyticsInstance, 'page_view', {
      page_name: pageName,
      page_url: pageUrl,
      timestamp: new Date().toISOString(),
      ...additionalParams
    });
  } catch (error) {
    console.error('Error tracking page view:', error);
  }
};

/**
 * Track a user engagement event (e.g., time spent, scroll depth)
 * @param {string} engagementType - Type of engagement (e.g., 'scroll', 'time_spent')
 * @param {Object} [additionalParams={}] - Additional parameters to log with the event
 */
export const trackEngagement = (engagementType, additionalParams = {}) => {
  if (!analyticsInstance) return;
  
  try {
    logEvent(analyticsInstance, 'user_engagement', {
      engagement_type: engagementType,
      timestamp: new Date().toISOString(),
      ...additionalParams
    });
  } catch (error) {
    console.error('Error tracking engagement:', error);
  }

  // Also push to dataLayer for GTM
  pushToDataLayer(engagementType, additionalParams);
};

/**
 * Track a monetization event (purchases, subscriptions)
 * @param {string} monetizationType - Type of monetization event
 * @param {number} amount - Amount involved in the transaction
 * @param {string} currency - Currency code (e.g., 'USD')
 * @param {Object} [additionalParams={}] - Additional parameters to log with the event
 */
export const trackMonetization = (monetizationType, amount, currency, additionalParams = {}) => {
  if (!analyticsInstance) return;
  
  try {
    logEvent(analyticsInstance, 'monetization_event', {
      monetization_type: monetizationType,
      amount: amount,
      currency: currency,
      timestamp: new Date().toISOString(),
      ...additionalParams
    });
  } catch (error) {
    console.error('Error tracking monetization:', error);
  }
};

/**
 * Track feature usage
 * @param {string} featureId - Identifier for the feature
 * @param {string} featureName - Human-readable name of the feature
 * @param {Object} [additionalParams={}] - Additional parameters to log with the event
 */
export const trackFeatureUsage = (featureId, featureName, additionalParams = {}) => {
  if (!analyticsInstance) return;
  
  try {
    logEvent(analyticsInstance, 'feature_use', {
      feature_id: featureId,
      feature_name: featureName,
      timestamp: new Date().toISOString(),
      ...additionalParams
    });
  } catch (error) {
    console.error('Error tracking feature usage:', error);
  }
};

/**
 * Track user retention events
 * @param {string} actionType - Type of retention action
 * @param {Object} [additionalParams={}] - Additional parameters to log with the event
 */
export const trackRetention = (actionType, additionalParams = {}) => {
  if (!analyticsInstance) return;
  
  try {
    logEvent(analyticsInstance, 'retention_event', {
      action_type: actionType,
      timestamp: new Date().toISOString(),
      ...additionalParams
    });
  } catch (error) {
    console.error('Error tracking retention:', error);
  }
};

/**
 * Set user properties for better segmentation
 * @param {Object} properties - Object containing user properties
 */
export const setUserAnalyticsProperties = (properties) => {
  if (!analyticsInstance) return;
  
  try {
    setUserProperties(analyticsInstance, properties);
  } catch (error) {
    console.error('Error setting user properties:', error);
  }
};

/**
 * Set user ID for cross-platform analytics
 * @param {string} userId - Unique user identifier
 */
export const setAnalyticsUserId = (userId) => {
  if (!analyticsInstance || !userId) return;
  
  try {
    setUserId(analyticsInstance, userId);
  } catch (error) {
    console.error('Error setting user ID:', error);
  }
};

/**
 * Push data to Google Tag Manager data layer
 * @param {string} eventName - Name of the event
 * @param {Object} params - Parameters to include in the data layer
 */
export const pushToDataLayer = (eventName, params) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': eventName,
    ...params
  });
}; 