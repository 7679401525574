import { getPerformance, trace } from 'firebase/performance';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getApp } from 'firebase/app';

/**
 * Utility for monitoring performance of critical user flows in Glowtest
 * Focuses on login, registration, idea input, video creation, and monetization
 */

// Cache performance and analytics instances
let performanceInstance = null;
let analyticsInstance = null;

// Cache active traces by ID to prevent multiple starts
const activeTraces = {};

/**
 * Initialize performance monitoring
 * Call this after Firebase is initialized
 */
export const initPerformanceMonitoring = () => {
  try {
    const app = getApp();
    performanceInstance = getPerformance(app);
    analyticsInstance = getAnalytics(app);
    console.log('Performance monitoring initialized');
    
    // Add polyfill for First Input Delay (FID)
    addFIDPolyfill();
    
    // Apply patches to fix Firebase Performance issues
    applyPerformancePatches();
    
    return performanceInstance;
  } catch (error) {
    console.error('Failed to initialize performance monitoring:', error);
    return null;
  }
};

/**
 * Add polyfill for First Input Delay measurement
 * As recommended in Firebase documentation
 */
const addFIDPolyfill = () => {
  // Only add polyfill if it's not already loaded
  if (typeof window !== 'undefined' && !window.perfMetrics) {
    // Create script element
    const script = document.createElement('script');
    script.src = 'https://unpkg.com/web-vitals@3/dist/web-vitals.iife.js';
    script.async = true;
    
    // On load, initialize the metrics
    script.onload = () => {
      // Check for the correct global object - webVitals is exposed as window.webVitals
      if (window.webVitals) {
        window.webVitals.getFID((metric) => {
          if (performanceInstance) {
            try {
              // Create and START the trace before adding metrics
              const fidTrace = trace(performanceInstance, 'first_input_delay');
              fidTrace.start();
              fidTrace.putMetric('fid_ms', metric.value);
              fidTrace.putAttribute('fid_rating', metric.rating);
              fidTrace.stop();
              
              // Also log to analytics
              if (analyticsInstance) {
                logEvent(analyticsInstance, 'performance_metric', {
                  metric_name: 'fid',
                  metric_value: metric.value,
                  metric_rating: metric.rating
                });
              }
            } catch (error) {
              // Catch any errors to prevent app crashing
              console.error('Error recording FID metric:', error);
            }
          }
        });
      } else {
        console.warn('Web Vitals library loaded but webVitals object not found');
      }
    };
    
    document.head.appendChild(script);
  }
};

/**
 * Apply patches to fix Firebase Performance issues with long attributes
 */
const applyPerformancePatches = () => {
  if (!performanceInstance) return;
  
  try {
    // Monkey patch the Firebase Performance trace.putAttribute method if it exists
    if (window.firebase && window.firebase.performance) {
      const originalPutAttribute = window.firebase.performance.Trace.prototype.putAttribute;
      
      // Replace with a safer version that filters out problematic values
      if (originalPutAttribute) {
        window.firebase.performance.Trace.prototype.putAttribute = function(name, value) {
          // Skip attributes containing long class names or Tailwind classes
          if (typeof value === 'string' && 
              (value.includes('MuiButtonBase') || 
               value.includes('MuiButton-') || 
               value.includes('css-') ||
               value.includes('div.flex') ||
               value.includes('flex flex-col') ||
               value.includes('button.px-') ||
               value.includes('initial-view-container') ||
               value.includes('px-') ||
               value.includes('py-') ||
               value.includes('text-') ||
               value.includes('rounded-') ||
               value.includes('font-') ||
               value.includes('flex') ||
               value.includes('items-') ||
               value.includes('gap-') ||
               value.includes('transition-') ||
               value.includes('focus:') ||
               value.includes('hover:') ||
               value.startsWith('button.') ||
               value.length > 100)) {
            console.log('Skipping problematic attribute:', name);
            return this;
          }
          
          // For other attributes, use the original method
          return originalPutAttribute.call(this, name, value);
        };
        
        console.log('Applied Firebase Performance patch for long attribute values');
      }
      
      // Add global error handler for Firebase Performance errors
      const originalErrorHandler = window.onerror;
      window.onerror = function(message, source, lineno, colno, error) {
        // Check if this is a Firebase Performance error related to attribute values
        if (error && error.message && error.message.includes('Performance: Attribute value')) {
          console.warn('Intercepted Firebase Performance error:', error.message);
          return true; // Prevents the error from propagating
        }
        
        // Call the original handler for other errors
        if (typeof originalErrorHandler === 'function') {
          return originalErrorHandler.apply(this, arguments);
        }
        return false;
      };
      
      // Patch addWebVitalMetric which is often the source of errors
      try {
        if (window.firebase.performance.Trace.prototype.addWebVitalMetric) {
          const originalAddWebVitalMetric = window.firebase.performance.Trace.prototype.addWebVitalMetric;
          
          window.firebase.performance.Trace.prototype.addWebVitalMetric = function(metricName, value) {
            try {
              // Check if value is a DOM element or selector with long class name
              if (typeof value === 'string' && (
                value.includes('div.flex') ||
                value.includes('flex flex-col') ||
                value.includes('transition-opacity') ||
                value.includes('duration-300') ||
                value.includes('items-center') ||
                value.includes('initial-view-container') ||
                value.includes('justify-center') ||
                value.startsWith('div.') ||
                value.startsWith('button.') ||
                value.includes('button.px-') ||
                value.includes('py-') ||
                value.includes('text-lg') ||
                value.length > 50)) {
                // Skip or use a simplified value
                console.log('Intercepted problematic WebVitalMetric value');
                
                // Skip addWebVitalMetric for problematic values
                return this;
              }
              
              return originalAddWebVitalMetric.call(this, metricName, value);
            } catch (e) {
              console.warn('Protected app from WebVital metric error:', e.message);
              return this;
            }
          };
          
          console.log('Applied Firebase Performance patch for WebVitalMetric handling');
        }
      } catch (vitalError) {
        console.warn('Could not patch WebVitalMetric:', vitalError);
      }
    }
  } catch (error) {
    console.warn('Failed to apply performance patches:', error);
  }
};

/**
 * Utility to safely truncate attribute values to prevent errors
 * Firebase Performance has a limit on attribute value length
 * 
 * @param {string} value - The attribute value to sanitize
 * @param {number} maxLength - Maximum allowed length (default 100 chars)
 * @returns {string} Sanitized attribute value
 */
export const sanitizeAttributeValue = (value, maxLength = 100) => {
  if (value === null || value === undefined) {
    return '';
  }
  
  const stringValue = String(value);
  
  // Check for CSS class names (which can be very long)
  if (stringValue.includes('MuiButtonBase') || 
      stringValue.includes('MuiButton-') ||
      stringValue.includes('-MuiButtonBase-') ||
      stringValue.includes('css-')) {
    // For CSS class names, return a simplified version
    return 'button-element';
  }
  
  // For normal values, truncate if needed
  return stringValue.length > maxLength ? stringValue.substring(0, maxLength) : stringValue;
};

/**
 * Start tracking a critical user flow
 * 
 * @param {string} flowName - Name of the flow (login, registration, idea_input, video_creation, monetization)
 * @param {Object} attributes - Optional attributes to add to the trace
 * @returns {string} Trace ID for stopping the trace later
 */
export const startUserFlowTrace = (flowName, attributes = {}) => {
  if (!performanceInstance) return null;
  
  try {
    const traceId = `flow_${flowName}_${Date.now()}`;
    const userFlowTrace = trace(performanceInstance, `flow_${flowName}`);
    
    // Add all attributes with sanitization
    Object.entries(attributes).forEach(([key, value]) => {
      userFlowTrace.putAttribute(key, sanitizeAttributeValue(value));
    });
    
    // Add standard attributes
    userFlowTrace.putAttribute('timestamp', new Date().toISOString());
    userFlowTrace.putAttribute('url_path', window.location.pathname);
    
    // Start the trace
    userFlowTrace.start();
    
    // Cache the trace for later stopping
    activeTraces[traceId] = userFlowTrace;
    
    // Log start event to analytics
    if (analyticsInstance) {
      logEvent(analyticsInstance, 'flow_start', {
        flow_name: flowName,
        ...attributes
      });
    }
    
    return traceId;
  } catch (error) {
    console.error('Error starting user flow trace:', error);
    return null;
  }
};

/**
 * Add a checkpoint to an ongoing user flow trace
 * Useful for marking key milestones within a flow
 * 
 * @param {string} traceId - ID returned from startUserFlowTrace
 * @param {string} checkpointName - Name of this checkpoint
 * @param {Object} metrics - Optional metrics to record at this checkpoint
 */
export const addFlowCheckpoint = (traceId, checkpointName, metrics = {}) => {
  if (!performanceInstance || !traceId || !activeTraces[traceId]) return;
  
  try {
    const userFlowTrace = activeTraces[traceId];
    
    // Mark the checkpoint time
    userFlowTrace.putAttribute(`checkpoint_${checkpointName}`, new Date().toISOString());
    
    // Add any metrics provided, with sanitization
    Object.entries(metrics).forEach(([key, value]) => {
      if (typeof value === 'number') {
        userFlowTrace.putMetric(key, value);
      } else {
        userFlowTrace.putAttribute(key, sanitizeAttributeValue(value));
      }
    });
  } catch (error) {
    console.error('Error adding flow checkpoint:', error);
  }
};

/**
 * Stop tracking a user flow and record final result
 * 
 * @param {string} traceId - ID returned from startUserFlowTrace
 * @param {boolean} success - Whether the flow completed successfully
 * @param {Object} finalMetrics - Final metrics to record for this flow
 */
export const stopUserFlowTrace = (traceId, success, finalMetrics = {}) => {
  if (!performanceInstance || !traceId || !activeTraces[traceId]) return;
  
  try {
    const userFlowTrace = activeTraces[traceId];
    
    // Add success/failure attribute
    userFlowTrace.putAttribute('flow_success', success.toString());
    
    // Add completion time
    userFlowTrace.putAttribute('completed_at', new Date().toISOString());
    
    // Add any final metrics with sanitization
    Object.entries(finalMetrics).forEach(([key, value]) => {
      if (typeof value === 'number') {
        userFlowTrace.putMetric(key, value);
      } else {
        userFlowTrace.putAttribute(key, sanitizeAttributeValue(value));
      }
    });
    
    // Stop the trace
    userFlowTrace.stop();
    
    // Log completion to analytics
    if (analyticsInstance) {
      logEvent(analyticsInstance, 'flow_complete', {
        flow_id: traceId,
        success: success,
        ...finalMetrics
      });
    }
    
    // Remove from active traces
    delete activeTraces[traceId];
  } catch (error) {
    console.error('Error stopping user flow trace:', error);
  }
};

/**
 * Monitor a specific component's render time
 * 
 * @param {string} componentName - Name of the component
 * @param {function} callback - Function to be measured
 * @returns {any} Result of the callback function
 */
export const measureComponentRender = (componentName, callback) => {
  if (!performanceInstance) return callback();
  
  try {
    const componentTrace = trace(performanceInstance, `component_render_${componentName}`);
    componentTrace.start();
    
    const result = callback();
    
    componentTrace.stop();
    return result;
  } catch (error) {
    console.error(`Error measuring component render for ${componentName}:`, error);
    return callback();
  }
};

/**
 * Measure a network request performance
 * This supplements Firebase's automatic HTTP monitoring
 * 
 * @param {string} requestName - Name of the request (e.g., 'fetch_user_data')
 * @param {string} endpoint - API endpoint
 * @param {function} requestFn - Async function that makes the request
 * @returns {Promise<any>} Result of the request function
 */
export const measureNetworkRequest = async (requestName, endpoint, requestFn) => {
  if (!performanceInstance) return requestFn();
  
  const networkTrace = trace(performanceInstance, `network_${requestName}`);
  networkTrace.putAttribute('endpoint', endpoint);
  networkTrace.start();
  
  try {
    const startTime = Date.now();
    const result = await requestFn();
    const duration = Date.now() - startTime;
    
    networkTrace.putMetric('duration_ms', duration);
    networkTrace.putAttribute('success', 'true');
    
    // Record response size if available
    if (result && result.data) {
      const responseSize = JSON.stringify(result.data).length;
      networkTrace.putMetric('response_size_bytes', responseSize);
    }
    
    networkTrace.stop();
    return result;
  } catch (error) {
    // Record the error
    networkTrace.putAttribute('success', 'false');
    networkTrace.putAttribute('error_type', error.name || 'unknown');
    networkTrace.putAttribute('error_message', error.message?.substring(0, 100) || 'unknown');
    networkTrace.stop();
    throw error;
  }
};

// Pre-configured flow monitors for key user journeys

/**
 * Monitor the login flow performance
 */
export const LoginFlowMonitor = {
  start: (attributes = {}) => startUserFlowTrace('login', attributes),
  
  inputCredentials: (traceId) => 
    addFlowCheckpoint(traceId, 'input_credentials'),
  
  submitAttempt: (traceId) => 
    addFlowCheckpoint(traceId, 'submit_attempt'),
  
  authenticationComplete: (traceId, success, authTime) => 
    addFlowCheckpoint(traceId, 'authentication_complete', { 
      auth_time_ms: authTime,
      success: success.toString() 
    }),
  
  complete: (traceId, success, metrics = {}) => 
    stopUserFlowTrace(traceId, success, metrics)
};

/**
 * Monitor the registration flow performance
 */
export const RegistrationFlowMonitor = {
  start: (attributes = {}) => startUserFlowTrace('registration', attributes),
  
  formStart: (traceId) => 
    addFlowCheckpoint(traceId, 'form_start'),
  
  formComplete: (traceId) => 
    addFlowCheckpoint(traceId, 'form_complete'),
  
  validationStart: (traceId) => 
    addFlowCheckpoint(traceId, 'validation_start'),
  
  validationComplete: (traceId, errors = 0) => 
    addFlowCheckpoint(traceId, 'validation_complete', { validation_errors: errors }),
  
  submissionAttempt: (traceId) => 
    addFlowCheckpoint(traceId, 'submission_attempt'),
  
  complete: (traceId, success, metrics = {}) => 
    stopUserFlowTrace(traceId, success, metrics)
};

/**
 * Monitor the idea input flow performance
 */
export const IdeaInputFlowMonitor = {
  start: (attributes = {}) => startUserFlowTrace('idea_input', attributes),
  
  ideaEntered: (traceId, charCount) => 
    addFlowCheckpoint(traceId, 'idea_entered', { char_count: charCount }),
  
  ideaProcessingStart: (traceId) => 
    addFlowCheckpoint(traceId, 'processing_start'),
  
  ideaProcessingComplete: (traceId, processingTime) => 
    addFlowCheckpoint(traceId, 'processing_complete', { 
      processing_time_ms: processingTime 
    }),
  
  complete: (traceId, success, metrics = {}) => 
    stopUserFlowTrace(traceId, success, metrics)
};

/**
 * Monitor the video creation flow performance
 */
export const VideoCreationFlowMonitor = {
  start: (attributes = {}) => startUserFlowTrace('video_creation', attributes),
  
  assetsLoaded: (traceId, assetCount) => 
    addFlowCheckpoint(traceId, 'assets_loaded', { asset_count: assetCount }),
  
  renderingStart: (traceId, videoLength) => 
    addFlowCheckpoint(traceId, 'rendering_start', { video_length_sec: videoLength }),
  
  renderingProgress: (traceId, percentComplete) => 
    addFlowCheckpoint(traceId, 'rendering_progress', { percent_complete: percentComplete }),
  
  renderingComplete: (traceId, renderTime) => 
    addFlowCheckpoint(traceId, 'rendering_complete', { render_time_ms: renderTime }),
  
  complete: (traceId, success, metrics = {}) => 
    stopUserFlowTrace(traceId, success, metrics)
};

/**
 * Monitor the monetization flow performance
 */
export const MonetizationFlowMonitor = {
  start: (attributes = {}) => startUserFlowTrace('monetization', attributes),
  
  planSelected: (traceId, planId) => 
    addFlowCheckpoint(traceId, 'plan_selected', { plan_id: planId }),
  
  checkoutStart: (traceId) => 
    addFlowCheckpoint(traceId, 'checkout_start'),
  
  paymentProcessingStart: (traceId, amount, currency) => 
    addFlowCheckpoint(traceId, 'payment_processing_start', { 
      amount: amount,
      currency: currency
    }),
  
  paymentProcessingComplete: (traceId, processingTime) => 
    addFlowCheckpoint(traceId, 'payment_processing_complete', { 
      processing_time_ms: processingTime 
    }),
  
  complete: (traceId, success, metrics = {}) => 
    stopUserFlowTrace(traceId, success, {
      transaction_complete: success.toString(),
      ...metrics
    })
};

// Create a named object for the default export
const PerformanceMonitoring = {
  initPerformanceMonitoring,
  startUserFlowTrace,
  addFlowCheckpoint,
  stopUserFlowTrace,
  measureComponentRender,
  measureNetworkRequest,
  LoginFlowMonitor,
  RegistrationFlowMonitor,
  IdeaInputFlowMonitor,
  VideoCreationFlowMonitor,
  MonetizationFlowMonitor
};

export default PerformanceMonitoring; 