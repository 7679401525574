import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Grid,
  Typography,
  Paper,
  Box,
  Stack,
  Chip,
  Zoom,
  Fade
} from '@mui/material';
import { color } from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';
import { FaCrown, FaPlus, FaArrowRight } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { CgCamera } from "react-icons/cg";
import { PiFilmReelLight } from "react-icons/pi";
import { LuCrown } from "react-icons/lu";
import { TbTie } from "react-icons/tb";
import './monetization.css';
import { SUBSCRIPTION_ICONS, LITE_USER, SUBSCRIPTION_TYPES, PLUS_USER, PRODUCER_USER, STUDIO_PRO_USER } from './subscription_setting';

// Use safer audio URLs - use only if you have these files in your public folder
// Otherwise, comment out these sound effects
// const SELECT_SOUND_URL = '/sounds/select-click.mp3'; 
// const CONTINUE_SOUND_URL = '/sounds/confirmation-tone.mp3';

// Comment out audio URLs that cause errors
// const SELECT_SOUND_URL = 'https://assets.mixkit.co/sfx/preview/mixkit-select-click-1109.mp3'; 
// const CONTINUE_SOUND_URL = 'https://assets.mixkit.co/sfx/preview/mixkit-positive-interface-beep-221.mp3';

// Directly hardcode the URLs as fallback
const FALLBACK_STRIPE_LITE = "https://buy.stripe.com/5kA28A7zxchZdFe5ko";
const FALLBACK_STRIPE_VIDEO_ESSENTIALS = "https://buy.stripe.com/cN29B27zxa9R7gQ4gl";
const FALLBACK_STRIPE_VIDEO_PRODUCER = "https://buy.stripe.com/6oEbJa2fdeq7bx6bIO";
const FALLBACK_STRIPE_STUDIO_PRO = "https://buy.stripe.com/bIY14wcTR95N0Ss8wD";

// Use environment variables with fallbacks
const STRIPE_LITE = process.env.REACT_APP_STRIPE_LITE || FALLBACK_STRIPE_LITE;
const STRIPE_VIDEO_ESSENTIALS = process.env.REACT_APP_STRIPE_VIDEO_ESSENTIALS || FALLBACK_STRIPE_VIDEO_ESSENTIALS;
const STRIPE_VIDEO_PRODUCER = process.env.REACT_APP_STRIPE_VIDEO_PRODUCER || FALLBACK_STRIPE_VIDEO_PRODUCER;
const STRIPE_STUDIO_PRO = process.env.REACT_APP_STRIPE_STUDIO_PRO || FALLBACK_STRIPE_STUDIO_PRO;

// Define colors for each plan
const PLAN_COLORS = {
  [STUDIO_PRO_USER]: "#FD9800", // Gold/Orange for Studio Pro
  [PRODUCER_USER]: "#0EA5E9",   // Blue for Producer
  [PLUS_USER]: "#22C55E",       // Green for Plus
  [LITE_USER]: "#A855F7"        // Purple for Lite
};

// Helper function to create a Firebase Performance trace
const createMonetizationTrace = (name, plan) => {
  try {
    if (window.firebase && window.firebase.performance) {
      const perf = window.firebase.performance();
      const trace = perf.trace(`monetization_${name}`);
      trace.start();
      
      // Add simple attributes that won't cause errors
      if (plan) {
        // Limit attribute values to basic strings
        trace.putAttribute('plan_type', plan.planType || 'unknown');
        trace.putAttribute('plan_price', String(plan.price || 0));
      }
      
      return trace;
    }
  } catch (err) {
    console.warn('Error creating trace:', err);
  }
  return null;
};

const MonetizationDialog = ({ open, onClose, accountInfo }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [animationsEnabled, setAnimationsEnabled] = useState(true);
  const dialogRef = useRef(null);
  const buttonRef = useRef(null);
  const selectSoundRef = useRef(null);
  const continueSoundRef = useRef(null);
  const activeTraceRef = useRef(null);
  
  // Modify audio setup to avoid errors
  useEffect(() => {
    // Sound effects are disabled until proper local files are available
    // If you want to add sounds, uncomment and provide proper local audio files
    /*
    if (typeof Audio !== 'undefined') {
      try {
        selectSoundRef.current = new Audio(SELECT_SOUND_URL);
        selectSoundRef.current.volume = 0.3;
        
        continueSoundRef.current = new Audio(CONTINUE_SOUND_URL);
        continueSoundRef.current.volume = 0.5;
        
        // Preload sounds to test if they work
        selectSoundRef.current.load();
        continueSoundRef.current.load();
      } catch (err) {
        console.warn('Could not load audio files:', err);
      }
    }
    */
  }, []);

  // Safer play sound function that won't cause errors
  const playSound = (soundRef) => {
    // Sound effects are disabled
    return;
    
    /*
    try {
      if (soundRef?.current) {
        soundRef.current.currentTime = 0;
        soundRef.current.play().catch(err => {
          // Browsers may block autoplay, this is expected in some cases
          console.log('Sound playback blocked:', err);
        });
      }
    } catch (err) {
      console.warn('Error playing sound:', err);
    }
    */
  };
  
  // Start a trace when the dialog opens
  useEffect(() => {
    if (open) {
      // Create a custom trace for the monetization dialog
      activeTraceRef.current = createMonetizationTrace('dialog_open', null);
      
      // Handle error event - this is kept but won't try to modify Firebase behavior
      window.addEventListener('error', (event) => {
        if (event.error && event.error.message && 
            event.error.message.includes('scrollTop')) {
          console.warn('Animation error detected, disabling animations');
          setAnimationsEnabled(false);
        }
        
        if (event.error && event.error.message && 
            (event.error.message.includes('Performance: Attribute value') || 
             event.error.message.includes('performance/invalid attribute value'))) {
          console.warn('Firebase Performance error detected, may be related to long CSS class names');
        }
      });
    }
    
    return () => {
      // Stop the trace when the dialog closes
      if (activeTraceRef.current) {
        activeTraceRef.current.stop();
        activeTraceRef.current = null;
      }
    };
  }, [open]);

  // Log Stripe URLs for debugging
  useEffect(() => {
    console.log("Stripe URLs loaded:", {
      lite: STRIPE_LITE,
      essentials: STRIPE_VIDEO_ESSENTIALS,
      producer: STRIPE_VIDEO_PRODUCER,
      studioPro: STRIPE_STUDIO_PRO
    });
  }, []);
  
  const plans = [
    {
      name: SUBSCRIPTION_TYPES[STUDIO_PRO_USER],
      className: "plan-studio-pro",
      icon: SUBSCRIPTION_ICONS[STUDIO_PRO_USER],
      price: 49,
      priceTag: "month",
      description: "Unlimited usage and co-creation",
      billing: "Monthly",
      features: ["Unlimited Video Production", "Access to AI Validation", "Expert Validation", "Exclusive community", "Exclusive Activities", "Priority Support"],
      url: STRIPE_STUDIO_PRO,
      color: PLAN_COLORS[STUDIO_PRO_USER],
      planType: STUDIO_PRO_USER
    },
    {
      name: SUBSCRIPTION_TYPES[PRODUCER_USER],
      className: "plan-producer",
      icon: SUBSCRIPTION_ICONS[PRODUCER_USER],
      price: 29,
      priceTag: "month",
      description: "Full feature access for a complete experience",
      billing: "Monthly",
      features: ["~10 Videos Per Month", "Access to AI Validation", "Expert Validation"],
      url: STRIPE_VIDEO_PRODUCER,
      color: PLAN_COLORS[PRODUCER_USER],
      planType: PRODUCER_USER
    },
    {
      name: SUBSCRIPTION_TYPES[PLUS_USER],
      className: "plan-plus",
      icon: SUBSCRIPTION_ICONS[PLUS_USER],
      price: 19,
      priceTag: "month",
      description: "Enhanced with cutting-edge capabilities",
      billing: "Monthly",
      features: ["~7 Videos Per Month", "Access to AI Validation", "Expert Validation"],
      url: STRIPE_VIDEO_ESSENTIALS,
      color: PLAN_COLORS[PLUS_USER],
      planType: PLUS_USER
    },
    {
      name: SUBSCRIPTION_TYPES[LITE_USER],
      className: "plan-lite",
      icon: SUBSCRIPTION_ICONS[LITE_USER],
      price: 9,
      priceTag: "month",
      description: "Unlimited usage and co-creation",
      billing: "Monthly",
      features: ["~3 Videos Per Month", "Access to AI Validation Activities"],
      url: STRIPE_LITE,
      color: PLAN_COLORS[LITE_USER],
      planType: LITE_USER
    },
  ];
  
  const handleGetPlan = (url) => {
    if (!url) {
      console.error("URL is undefined, using fallback");
    }
    
    // Ensure the URL is valid by using fallbacks if needed
    const stripeUrl = url || getFallbackUrl(selectedPlan?.planType);
    
    console.log("Opening Stripe URL:", stripeUrl);
    
    // Create a custom trace for the Stripe navigation
    const stripeTrace = createMonetizationTrace('stripe_navigation', selectedPlan);
    
    if (accountInfo.email) {
      window.open(stripeUrl + '?prefilled_email=' + accountInfo.email, '_blank').focus();
    } else {
      window.open(stripeUrl, '_blank').focus();
    }
    
    // Stop the trace after navigation
    if (stripeTrace) {
      stripeTrace.putAttribute('success', 'true');
      stripeTrace.stop();
    }
  };
  
  // Fallback function to get URL if environment variable fails
  const getFallbackUrl = (planType) => {
    switch(planType) {
      case STUDIO_PRO_USER: return FALLBACK_STRIPE_STUDIO_PRO;
      case PRODUCER_USER: return FALLBACK_STRIPE_VIDEO_PRODUCER;
      case PLUS_USER: return FALLBACK_STRIPE_VIDEO_ESSENTIALS;
      case LITE_USER: return FALLBACK_STRIPE_LITE;
      default: return FALLBACK_STRIPE_LITE;
    }
  };
  
  const handleClose = () => {
    setSelectedPlan(null);
    setShowContinueButton(false);
    if (onClose) {
      onClose();
    }
  };

  const handlePlanSelect = (plan) => {
    // Play selection sound if selecting a different plan or first selection
    if (!selectedPlan || selectedPlan.name !== plan.name) {
      // Disabled for now: playSound(selectSoundRef);
      
      // Create a custom trace for plan selection
      const selectTrace = createMonetizationTrace('plan_select', plan);
      if (selectTrace) {
        selectTrace.stop();
      }
    }
    
    setSelectedPlan(plan);
    // Show continue button with a slight delay for visual effect
    setTimeout(() => setShowContinueButton(true), 300);
  };

  const handleContinue = (e) => {
    // Disabled for now: playSound(continueSoundRef);
    
    // Prevent default button behavior
    e.preventDefault();
    e.stopPropagation();

    // Create a manual trace for continue button 
    const continueTrace = createMonetizationTrace('continue_click', selectedPlan);
    
    // Short delay to ensure trace is created before navigation
    setTimeout(() => {
      if (selectedPlan) {
        handleGetPlan(selectedPlan.url);
      }
      
      // Stop the trace after handling the click
      if (continueTrace) {
        continueTrace.stop();
      }
    }, 50); // Increased delay to ensure trace is created
  };
  
  // Helper function to determine if a feature is video count
  const isVideoCountFeature = (feature) => {
    return feature.includes("Videos Per Month") || feature === "Unlimited Video Production";
  };
  
  // Helper function to style features
  const getFeatureStyle = (feature, planColor, isSelected) => {
    // Base styles
    const styles = {
      transition: 'all 0.3s ease',
      opacity: isSelected ? 1 : 0.8,
    };
    
    // Add styles for video count features
    if (isVideoCountFeature(feature)) {
      styles.color = planColor;
      styles.fontWeight = 'bold';
      
      // Add extra emphasis if selected
      if (isSelected) {
        styles.textShadow = `0 0 8px rgba(${
          planColor.replace('#', '').match(/.{2}/g).map(hex => parseInt(hex, 16)).join(', ')
        }, 0.6)`;
        styles.fontSize = '1.05rem';
      }
    }
    
    // Additional styles for selected plan features
    if (isSelected) {
      styles.transform = 'translateX(8px)';
    }
    
    return styles;
  };
  
  // Add this CSS keyframe animation to make the continue button more attractive  
  const continueButtonStyle = useMemo(() => {
    if (!selectedPlan) return {};

    return {
      bgcolor: selectedPlan.color || '#FD9800',
      color: 'white',
      px: 4,
      py: 1.5,
      fontSize: '1.1rem',
      '&:hover': {
        bgcolor: selectedPlan.color ? `${selectedPlan.color}dd` : '#e68a00',
        transform: 'translateY(-2px)',
        boxShadow: `0 6px 20px rgba(${
          selectedPlan.color 
            ? selectedPlan.color.replace('#', '').match(/.{2}/g).map(hex => parseInt(hex, 16)).join(', ') 
            : '253, 152, 0'
        }, 0.4)`
      },
      gap: 1,
      transition: 'all 0.3s ease',
      animation: showContinueButton ? 'button-entrance 0.5s cubic-bezier(0.17, 0.67, 0.45, 1) forwards' : 'none'
    };
  }, [selectedPlan, showContinueButton]);

  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      maxWidth="lg" 
      fullWidth
      ref={dialogRef}
      PaperProps={{
        sx: {
          background: 'linear-gradient(to bottom, #1a1a1a, #2d2d2d)',
          borderRadius: '16px',
          overflow: 'visible'
        }
      }}
    >
      <DialogTitle sx={{ 
        textAlign: 'center',
        fontSize: '2rem',
        fontWeight: 'bold',
        color: '#fff',
        pt: 4
      }}>
        Choose Your Plan
      </DialogTitle>
      
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      
      <DialogContent dividers className="subscription-plans">
        <Stack 
          spacing={{ xs: 1, md: 2 }} 
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
        >
          {plans.map((plan, index) => (
            <Stack 
              className={`plan ${plan.className} ${selectedPlan?.name === plan.name ? 'selected' : ''}`}
              width={{ xs: '100%', sm: '25%' }} 
              key={`${plan.className}-${index}`}
              onClick={() => handlePlanSelect(plan)}
              sx={{
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                border: selectedPlan?.name === plan.name ? `2px solid ${plan.color || '#FD9800'}` : '1px solid rgba(255,255,255,0.1)',
                '&:hover': {
                  boxShadow: '0 0 15px rgba(255,255,255,0.1)'
                }
              }}
            >
              <span className={`plan-name ${plan.className}`}>
                <plan.icon /> 
                <span>{plan.name}</span>
              </span>
              <span>
                <a style={{ fontSize: '24px', fontWeight: 600, marginRight: '5px', verticalAlign: 'super' }}>$</a>
                <a style={{ fontSize: '36px', fontWeight: 600, marginRight: '5px' }}>{plan.price}</a>
                <a>/{plan.priceTag}</a>
              </span>
              <p>{plan.description}</p>
              <p>Billed: <a className="text-white">{plan.billing}</a></p>
              {plan.features.map((feature, index) => (
                <span 
                  className="features" 
                  key={index} 
                  style={getFeatureStyle(feature, plan.color, selectedPlan?.name === plan.name)}
                >
                  <FaCheck style={{ 
                    color: selectedPlan?.name === plan.name ? plan.color : 'inherit',
                    opacity: selectedPlan?.name === plan.name ? 1 : 0.7
                  }} />
                  {feature}
                </span>
              ))}
            </Stack>
          ))}
        </Stack>
        <Box sx={{ textAlign: 'center', marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <TbTie fontSize="40px" />
          <p>Need a custom tailored solution for your Business?</p>
          <p>
            <a 
              href="/journey#enterprise-form" 
              className="link"
              onClick={(e) => {
                e.preventDefault();
                onClose();
                window.location.href = '/journey#enterprise-form';
              }}
            >
              Glowtest Enterprise
            </a>
          </p>
        </Box>
      </DialogContent>
      
      <DialogActions sx={{ 
        justifyContent: 'center', 
        padding: 3,
        borderTop: '1px solid rgba(255,255,255,0.1)',
        position: 'relative',
        overflow: 'hidden'
      }}>
        {animationsEnabled ? (
          <Fade in={showContinueButton} timeout={400} mountOnEnter unmountOnExit>
            <Button 
              onClick={handleContinue}
              variant="contained"
              ref={buttonRef}
              className={`stripe-continue-button ${selectedPlan ? selectedPlan.className : ''}`}
              disabled={!selectedPlan}
              data-testid="continue-subscription-button"
              id="stripe-continue-button"
              data-perf-no-trace="true"
              data-fbonly="true"
              sx={{
                ...continueButtonStyle,
                '&&': { 
                  backgroundColor: selectedPlan?.color || '#FD9800',
                  color: 'white'
                }
              }}
            >
              Continue with {selectedPlan?.name} <FaArrowRight style={{ marginLeft: 8 }} />
            </Button>
          </Fade>
        ) : (
          showContinueButton && (
            <Button 
              onClick={handleContinue}
              variant="contained"
              ref={buttonRef}
              className={`stripe-continue-button ${selectedPlan ? selectedPlan.className : ''}`}
              disabled={!selectedPlan}
              data-testid="continue-subscription-button"
              id="stripe-continue-button"
              data-perf-no-trace="true"
              data-fbonly="true"
              sx={{
                ...continueButtonStyle,
                '&&': { 
                  backgroundColor: selectedPlan?.color || '#FD9800',
                  color: 'white'
                }
              }}
            >
              Continue with {selectedPlan?.name} <FaArrowRight style={{ marginLeft: 8 }} />
            </Button>
          )
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MonetizationDialog;