import { CheckCircle } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import SEO from "../../components/SEO";

export default function RegistrationResult() {
  const [isDarkTheme, setIsDarkTheme] = useState(() => {
    const savedTheme = localStorage.getItem("mui-mode");
    return savedTheme ? savedTheme === "dark" : true;
  });

const [plan, setPlan] = useState(() => {
    // Get the URL search parameters
    const queryParams = new URLSearchParams(window.location.search);
    // Get the plan parameter or default to 'Lite' if not present
    switch (queryParams.get('pl')?.toLowerCase()) {
        case 'lite':
            return 'Lite';
        case 'video_essentials':
            return 'Plus';
        case 'video_producer':
            return 'Producer';
        case 'studio_pro':
            return 'Studio Pro';
        default:
            return ''
    }
});

// SEO configuration
const seoData = {
  title: 'Registration Successful - Glowtest',
  description: `Thank you for subscribing to Glowtest${plan ? ` - ${plan} Plan` : ''}. Your account is now ready to use our AI-powered validation tools.`,
  openGraph: {
    title: 'Registration Successful - Glowtest',
    description: 'Your Glowtest account is now active. Start validating your ideas with AI-powered tools.',
    type: 'website'
  },
  meta: [
    { name: 'robots', content: 'noindex, nofollow' } // Don't index this confirmation page
  ]
};

return (
    <div
        className={`min-h-screen bg-zinc-900 text-white ${
            isDarkTheme ? "dark" : "light"
        }`}
    >
        <SEO {...seoData} />
        <main className="container mx-auto px-4 py-20">
            {/* Hero Section - Updated messaging */}
            <section className="text-center mb-16" aria-label="Hero">
                <div className="mb-6 flex justify-center">
                    <picture>
                        <source
                            media="(min-width: 768px)"
                            srcSet="/images/logo-light-desktop.png"
                        />
                        <source
                            media="(max-width: 767px)"
                            srcSet="/images/logo-light-mobile.png"
                        />
                        <img
                            src="/images/logo-light-desktop.png"
                            alt="Glowtest AI-Powered Validation Platform - Registration Successful"
                            className="h-24 w-auto"
                            style={{
                                filter: "drop-shadow(0 0 10px rgba(253, 152, 0, 0.3))",
                            }}
                        />
                    </picture>
                </div>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <CheckCircle
                        sx={{
                            color: "#28a745",
                            fontSize: "12rem",
                        }}
                    />
                    <h1 className="text-4xl md:text-6xl font-bold mb-4">
                        Subscription successful
                    </h1>
                    <Typography variant="body1" className="text-lg md:text-xl">
                        Thank you for subscribing! Your account is now active and ready to use {plan ? plan + ' Plan' : ''}.
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            mt: 4,
                            px: 4,
                            py: 1.5,
                            bgcolor: "#FD9800",
                            color: "white",
                            fontWeight: "bold",
                            "&:hover": {
                                bgcolor: "#e08700",
                            }
                        }}
                        onClick={() => window.location.href = '/journey'}
                    >
                        Continue your journey
                    </Button>
                </Box>
            </section>
        </main>
    </div>
);
}
