export const SAMPLE_GLOW_01 = [
    {
        "id": "1",
        "audience": "HR Managers",
        "key_points": [
            "Streamline employee management processes",
            "Enhance communication between employees and management",
            "Provide real-time access to employee information",
            "Facilitate performance tracking and feedback",
            "Simplify onboarding and training procedures"
        ],
        "key_points_mapping": "Problem Analysis",
        "main_message": "Transform your employee management with our intuitive mobile app.",
        "main_message_mapping": "Main Message",
        "desired_outcome": "Implement an efficient solution for managing employees effectively.",
        "desired_outcome_mapping": "Solution Implementation",
        "visual_description": "Scenes showing HR managers interacting with the app, employees engaging with features, and visual graphs of performance metrics.",
        "target_duration_in_sec": "120"
    },
    {
        "id": "2",
        "audience": "Employees",
        "key_points": [
            "Access personal employee information anytime",
            "Receive updates and notifications on important matters",
            "Engage in performance reviews and feedback processes",
            "Participate in training and development opportunities",
            "Communicate easily with HR and management"
        ],
        "key_points_mapping": "User Experience",
        "main_message": "Empower your work life with our employee management app.",
        "main_message_mapping": "Main Message",
        "desired_outcome": "Enhance employee satisfaction and engagement through accessible tools.",
        "desired_outcome_mapping": "Employee Engagement",
        "visual_description": "Scenes of employees using the app on their devices, engaging in feedback sessions, and participating in training modules.",
        "target_duration_in_sec": "120"
    }
]

export const SAMPLE_GLOW_02 = {
    1: {
        "status": true,
        "message": "Glow_02 process completed successfully",
        "status_code": 200,
        "data": {
            "glow_02_response": {
                "1": {
                    "video_id": "HR_Managers_1",
                    "audience": "HR Managers",
                    "content_reference": 0,
                    "video_base": {
                        "style": "Modern and Clean",
                        "audio_base": {
                            "timbre": "Soft electronic sounds with light percussion",
                            "tempo": 80,
                            "core_rhythm": "Four-on-the-floor kick pattern",
                            "harmonic_base": "Cmaj7 to Am7 progression"
                        },
                        "color_scheme": "Blue and White with Grey accents",
                        "target_duration_in_sec": "120"
                    },
                    "scenes": [
                        {
                            "sc_id": "1",
                            "sc_title": "Introduction to Employee Management",
                            "sc_type": "Human-focused",
                            "sc_length_seconds": "4",
                            "sc_purpose": "Introduce the topic of employee management.",
                            "sc_emotion": "subtle",
                            "sc_audio": {
                                "voiceover": {
                                    "vo_text": "Are you struggling to manage your employees effectively?",
                                    "vo_duration": "3.5"
                                },
                                "sound_design": {
                                    "style": "light and engaging",
                                    "intensity": 3
                                }
                            },
                            "sc_visuals_basics": "Natural expressions of HR managers looking concerned.",
                            "sc_visuals_extensive": "A close-up of an HR manager with a concerned expression, followed by a medium shot of them discussing with a colleague.",
                            "sc_success_metric": "Increased engagement from HR managers.",
                            "sc_count": 0
                        },
                        {
                            "sc_id": "2",
                            "sc_title": "Problem Analysis",
                            "sc_type": "Data/Information",
                            "sc_length_seconds": "6",
                            "sc_purpose": "Highlight common issues in employee management.",
                            "sc_emotion": "controlled",
                            "sc_audio": {
                                "voiceover": {
                                    "vo_text": "Many HR managers face challenges in streamlining processes and communication.",
                                    "vo_duration": "5.5"
                                },
                                "sound_design": {
                                    "style": "informative",
                                    "intensity": 4
                                }
                            },
                            "sc_visuals_basics": "Graphs showing employee management issues.",
                            "sc_visuals_extensive": "A wide shot of a presentation with graphs depicting communication gaps and performance tracking issues, followed by a close-up of a worried HR manager.",
                            "sc_success_metric": "Awareness of common issues.",
                            "sc_count": 0
                        },
                        {
                            "sc_id": "3",
                            "sc_title": "Introducing the Solution",
                            "sc_type": "Product/Service",
                            "sc_length_seconds": "5",
                            "sc_purpose": "Present the mobile app as a solution.",
                            "sc_emotion": "subtle",
                            "sc_audio": {
                                "voiceover": {
                                    "vo_text": "Transform your employee management with our intuitive mobile app.",
                                    "vo_duration": "4.5"
                                },
                                "sound_design": {
                                    "style": "uplifting",
                                    "intensity": 5
                                }
                            },
                            "sc_visuals_basics": "Display of the mobile app interface.",
                            "sc_visuals_extensive": "A close-up of a hand navigating the mobile app, transitioning to a medium shot of an HR manager smiling while using the app.",
                            "sc_success_metric": "Interest in the app.",
                            "sc_count": 0
                        },
                        {
                            "sc_id": "4",
                            "sc_title": "Features Overview",
                            "sc_type": "Abstract/Technical",
                            "sc_length_seconds": "7",
                            "sc_purpose": "Explain key features of the app.",
                            "sc_emotion": "controlled",
                            "sc_audio": {
                                "voiceover": {
                                    "vo_text": "Streamline management, enhance communication, and track performance in real-time.",
                                    "vo_duration": "6.5"
                                },
                                "sound_design": {
                                    "style": "clear and engaging",
                                    "intensity": 4
                                }
                            },
                            "sc_visuals_basics": "Visual representation of the app features.",
                            "sc_visuals_extensive": "A medium shot of the app's dashboard showcasing performance metrics, followed by a close-up of notifications being sent between employees.",
                            "sc_success_metric": "Understanding of app features.",
                            "sc_count": 0
                        },
                        {
                            "sc_id": "5",
                            "sc_title": "User Engagement",
                            "sc_type": "Human-focused",
                            "sc_length_seconds": "4",
                            "sc_purpose": "Show employees using the app.",
                            "sc_emotion": "high intensity",
                            "sc_audio": {
                                "voiceover": {
                                    "vo_text": "Experience seamless onboarding and training procedures.",
                                    "vo_duration": "3.5"
                                },
                                "sound_design": {
                                    "style": "energetic",
                                    "intensity": 6
                                }
                            },
                            "sc_visuals_basics": "Employees happily interacting with the app.",
                            "sc_visuals_extensive": "A group of employees gathered together, engaging with the app on their devices, followed by a close-up of smiles and nods of approval.",
                            "sc_success_metric": "Increased user engagement.",
                            "sc_count": 0
                        },
                        {
                            "sc_id": "6",
                            "sc_title": "Call to Action",
                            "sc_type": "Emotional",
                            "sc_length_seconds": "5",
                            "sc_purpose": "Encourage adoption of the app.",
                            "sc_emotion": "high intensity",
                            "sc_audio": {
                                "voiceover": {
                                    "vo_text": "Implement an efficient solution for managing employees effectively today!",
                                    "vo_duration": "4.5"
                                },
                                "sound_design": {
                                    "style": "inspiring",
                                    "intensity": 7
                                }
                            },
                            "sc_visuals_basics": "Group of HR managers discussing the app.",
                            "sc_visuals_extensive": "A wide shot of HR managers gathered around a table, animatedly discussing the app, ending with a close-up of a satisfied manager nodding in agreement.",
                            "sc_success_metric": "Increased app adoption interest.",
                            "sc_count": 0
                        }
                    ]
                }
            }
        }
    }, 
    2: {
        "status": true,
        "message": "Glow_02 process completed successfully",
        "status_code": 200,
        "data": {
            "glow_02_response": {
                "2": {
                    "video_id": "2_Employees",
                    "audience": "Employees",
                    "content_reference": 0,
                    "video_base": {
                        "style": "Modern and Clean",
                        "audio_base": {
                            "timbre": "Light electronic sounds",
                            "tempo": 120,
                            "core_rhythm": "Upbeat and engaging",
                            "harmonic_base": "Major key progression"
                        },
                        "color_scheme": "Blue and White with Gray accents",
                        "target_duration_in_sec": 120
                    },
                    "scenes": [
                        {
                            "sc_id": 1,
                            "sc_title": "Introduction to the App",
                            "sc_type": "Human-focused",
                            "sc_length_seconds": 5,
                            "sc_purpose": "Introduce the employee management app",
                            "sc_emotion": "high intensity",
                            "sc_audio": {
                                "voiceover": {
                                    "vo_text": "Welcome to your new employee management app!",
                                    "vo_duration": 4.5
                                },
                                "sound_design": {
                                    "style": "energetic",
                                    "intensity": 7
                                }
                            },
                            "sc_visuals_basics": "Employees smiling while using the app",
                            "sc_visuals_extensive": "A close-up of a young woman engaging with the app on her smartphone, followed by a medium shot of a diverse group of employees gathered around a tablet, excitedly discussing the app's features.",
                            "sc_success_metric": "Increased user awareness of app features",
                            "sc_count": 0
                        },
                        {
                            "sc_id": 2,
                            "sc_title": "App Features Overview",
                            "sc_type": "Abstract/Technical",
                            "sc_length_seconds": 6,
                            "sc_purpose": "Showcase key features of the app",
                            "sc_emotion": "controlled",
                            "sc_audio": {
                                "voiceover": {
                                    "vo_text": "Access personal information, receive updates, and engage in feedback effortlessly.",
                                    "vo_duration": 5.4
                                },
                                "sound_design": {
                                    "style": "informative",
                                    "intensity": 4
                                }
                            },
                            "sc_visuals_basics": "Screenshots of the app interface displaying features",
                            "sc_visuals_extensive": "Wide shots of the app's interface featuring various functionalities, transitioning to a close-up of a notification alert popping up on a user's device.",
                            "sc_success_metric": "User understanding of app functionalities",
                            "sc_count": 0
                        },
                        {
                            "sc_id": 3,
                            "sc_title": "Engaging with Performance Reviews",
                            "sc_type": "Product/Service",
                            "sc_length_seconds": 5,
                            "sc_purpose": "Highlight the performance review feature",
                            "sc_emotion": "controlled",
                            "sc_audio": {
                                "voiceover": {
                                    "vo_text": "Participate in performance reviews and feedback processes with ease.",
                                    "vo_duration": 4.5
                                },
                                "sound_design": {
                                    "style": "supportive",
                                    "intensity": 5
                                }
                            },
                            "sc_visuals_basics": "Employees discussing feedback",
                            "sc_visuals_extensive": "A medium shot of a manager and an employee engaged in a performance review discussion, showcasing the app on a laptop, followed by a close-up of the employee nodding in agreement.",
                            "sc_success_metric": "Increased participation in performance reviews",
                            "sc_count": 0
                        },
                        {
                            "sc_id": 4,
                            "sc_title": "Training and Development Opportunities",
                            "sc_type": "Data/Information",
                            "sc_length_seconds": 7,
                            "sc_purpose": "Inform about training opportunities via the app",
                            "sc_emotion": "controlled",
                            "sc_audio": {
                                "voiceover": {
                                    "vo_text": "Engage in training and development opportunities at your convenience.",
                                    "vo_duration": 6.3
                                },
                                "sound_design": {
                                    "style": "motivational",
                                    "intensity": 5
                                }
                            },
                            "sc_visuals_basics": "Employees in a training session",
                            "sc_visuals_extensive": "Wide shots of employees participating in an online training session through the app, followed by a close-up of an employee taking notes and smiling.",
                            "sc_success_metric": "Increased employee engagement in training",
                            "sc_count": 0
                        },
                        {
                            "sc_id": 5,
                            "sc_title": "Communication with HR",
                            "sc_type": "Environmental",
                            "sc_length_seconds": 5,
                            "sc_purpose": "Demonstrate ease of communication with HR",
                            "sc_emotion": "controlled",
                            "sc_audio": {
                                "voiceover": {
                                    "vo_text": "Communicate easily with HR and management through the app.",
                                    "vo_duration": 4.5
                                },
                                "sound_design": {
                                    "style": "friendly",
                                    "intensity": 4
                                }
                            },
                            "sc_visuals_basics": "Employees using the app to contact HR",
                            "sc_visuals_extensive": "Close-up of an employee typing a message to HR on the app, transitioning to a medium shot of HR staff responding with smiles in the background.",
                            "sc_success_metric": "Improved communication metrics between employees and HR",
                            "sc_count": 0
                        },
                        {
                            "sc_id": 6,
                            "sc_title": "Conclusion and Call to Action",
                            "sc_type": "Human-focused",
                            "sc_length_seconds": 5,
                            "sc_purpose": "Encourage employees to use the app",
                            "sc_emotion": "high intensity",
                            "sc_audio": {
                                "voiceover": {
                                    "vo_text": "Empower your work life with our employee management app!",
                                    "vo_duration": 4.5
                                },
                                "sound_design": {
                                    "style": "energetic",
                                    "intensity": 8
                                }
                            },
                            "sc_visuals_basics": "Employees celebrating",
                            "sc_visuals_extensive": "Wide shot of a group of employees celebrating together, using the app and sharing experiences, ending with a close-up of the app logo.",
                            "sc_success_metric": "Increased app downloads and usage",
                            "sc_count": 0
                        }
                    ]
                }
            }
        }
    }
}

export const SAMPLE_GLOW_03 = {
    1: {
        "status": true,
        "message": "Glow 03 Script completed successfully",
        "status_code": 200,
        "data": {
            "glow_03_response": {
                "1": {
                    "video_id": "HR_Managers_1",
                    "audience": "HR Managers",
                    "video_duration": "120",
                    "scenes": [
                        {
                            "sc_id": "1",
                            "sc_title": "Introduction to Employee Management",
                            "sc_duration": "4",
                            "visu_style_primary": "Modern and Clean",
                            "visu_style_secondary": "Professional",
                            "visu_color_scheme": "Blue and White with Grey accents",
                            "visu_quality": "8k, photorealistic, masterpiece quality, professional",
                            "visu_enhancements": "high dynamic range, depth of field, color grading, noise reduction",
                            "visu_neg_prompt": "worst quality, bad quality, cartoon, looking at camera, generic pose, posed group shot, vacation photo, studio backdrop",
                            "shots": [
                                {
                                    "shot_number": "001",
                                    "shot_type": "START",
                                    "visu_main_subject": "A middle-aged HR manager, wearing a smart blazer, looking concerned, with a furrowed brow, single person",
                                    "visu_environment": "in a modern office, during the day, with natural light coming through large windows",
                                    "visu_technical": "Shot with a Canon EOS R5, 50mm lens, slight zoom in, emphasizing the subject's expression",
                                    "visu_shot_type": "close-up from center",
                                    "visu_subject_treatment": "concerned expression",
                                    "duration_shot": "1.5",
                                    "transition": "fade to next shot"
                                },
                                {
                                    "shot_number": "002",
                                    "shot_type": "IMPORTANT",
                                    "visu_main_subject": "An HR manager discussing with a colleague, both wearing business attire, looking engaged, two people",
                                    "visu_environment": "in the same modern office, during the day, with a bright atmosphere",
                                    "visu_technical": "Shot with a Canon EOS R5, 35mm lens, medium shot capturing both subjects in focus",
                                    "visu_shot_type": "medium shot from left",
                                    "visu_subject_treatment": "animated conversation",
                                    "duration_shot": "2.5",
                                    "transition": "cut to next shot"
                                }
                            ]
                        },
                        {
                            "sc_id": "2",
                            "sc_title": "Problem Analysis",
                            "sc_duration": "6",
                            "visu_style_primary": "Modern and Clean",
                            "visu_style_secondary": "Professional",
                            "visu_color_scheme": "Blue and White with Grey accents",
                            "visu_quality": "8k, photorealistic, masterpiece quality, professional",
                            "visu_enhancements": "high dynamic range, depth of field, color grading, noise reduction",
                            "visu_neg_prompt": "worst quality, bad quality, cartoon, looking at camera, generic pose, posed group shot, vacation photo, studio backdrop",
                            "shots": [
                                {
                                    "shot_number": "001",
                                    "shot_type": "IMPORTANT",
                                    "visu_main_subject": "Graphs illustrating employee management issues, with clear labels and statistics",
                                    "visu_environment": "in a conference room, during a presentation, with bright overhead lighting",
                                    "visu_technical": "Shot with a Sony A7 III, 24mm lens, wide shot capturing the entire presentation screen",
                                    "visu_shot_type": "wide shot from center",
                                    "visu_subject_treatment": "data visualization",
                                    "duration_shot": "3",
                                    "transition": "dissolve to next shot"
                                },
                                {
                                    "shot_number": "002",
                                    "shot_type": "ENDING",
                                    "visu_main_subject": "A worried HR manager, wearing a formal outfit, looking at the graphs with concern, single person",
                                    "visu_environment": "in the same conference room, during the day, with focused lighting on the subject",
                                    "visu_technical": "Shot with a Sony A7 III, 50mm lens, close-up capturing the subject's worried expression",
                                    "visu_shot_type": "close-up from right",
                                    "visu_subject_treatment": "worried expression",
                                    "duration_shot": "3",
                                    "transition": "fade to next shot"
                                }
                            ]
                        },
                        {
                            "sc_id": "3",
                            "sc_title": "Introducing the Solution",
                            "sc_duration": "5",
                            "visu_style_primary": "Modern and Clean",
                            "visu_style_secondary": "Professional",
                            "visu_color_scheme": "Blue and White with Grey accents",
                            "visu_quality": "8k, photorealistic, masterpiece quality, professional",
                            "visu_enhancements": "high dynamic range, depth of field, color grading, noise reduction",
                            "visu_neg_prompt": "worst quality, bad quality, cartoon, looking at camera, generic pose, posed group shot, vacation photo, studio backdrop",
                            "shots": [
                                {
                                    "shot_number": "001",
                                    "shot_type": "START",
                                    "visu_main_subject": "A close-up of a hand interacting with a mobile app, showing the interface clearly",
                                    "visu_environment": "in a bright office, during the day, with soft natural lighting",
                                    "visu_technical": "Shot with a Canon EOS R5, 85mm lens, macro shot highlighting the app interface",
                                    "visu_shot_type": "close-up from center",
                                    "visu_subject_treatment": "focused on the app",
                                    "duration_shot": "2",
                                    "transition": "cut to next shot"
                                },
                                {
                                    "shot_number": "002",
                                    "shot_type": "ENDING",
                                    "visu_main_subject": "An HR manager smiling while using the app, wearing a casual business outfit, single person",
                                    "visu_environment": "in the same bright office, during the day, with a welcoming atmosphere",
                                    "visu_technical": "Shot with a Canon EOS R5, 50mm lens, medium shot capturing the subject's smile",
                                    "visu_shot_type": "medium shot from left",
                                    "visu_subject_treatment": "smiling expression",
                                    "duration_shot": "3",
                                    "transition": "fade to next shot"
                                }
                            ]
                        },
                        {
                            "sc_id": "4",
                            "sc_title": "Features Overview",
                            "sc_duration": "7",
                            "visu_style_primary": "Modern and Clean",
                            "visu_style_secondary": "Professional",
                            "visu_color_scheme": "Blue and White with Grey accents",
                            "visu_quality": "8k, photorealistic, masterpiece quality, professional",
                            "visu_enhancements": "high dynamic range, depth of field, color grading, noise reduction",
                            "visu_neg_prompt": "worst quality, bad quality, cartoon, looking at camera, generic pose, posed group shot, vacation photo, studio backdrop",
                            "shots": [
                                {
                                    "shot_number": "001",
                                    "shot_type": "IMPORTANT",
                                    "visu_main_subject": "The app's dashboard showcasing performance metrics, with clear visuals and graphs",
                                    "visu_environment": "in a modern office, during the day, with bright lighting highlighting the screen",
                                    "visu_technical": "Shot with a Nikon Z6, 35mm lens, medium shot capturing the dashboard in detail",
                                    "visu_shot_type": "medium shot from center",
                                    "visu_subject_treatment": "data visualization",
                                    "duration_shot": "3.5",
                                    "transition": "dissolve to next shot"
                                },
                                {
                                    "shot_number": "002",
                                    "shot_type": "ENDING",
                                    "visu_main_subject": "Close-up of notifications being sent between employees on the app, showing interaction",
                                    "visu_environment": "in the same modern office, during the day, with a collaborative atmosphere",
                                    "visu_technical": "Shot with a Nikon Z6, 50mm lens, close-up focusing on the app interface",
                                    "visu_shot_type": "close-up from right",
                                    "visu_subject_treatment": "interaction display",
                                    "duration_shot": "3.5",
                                    "transition": "fade to next shot"
                                }
                            ]
                        },
                        {
                            "sc_id": "5",
                            "sc_title": "User Engagement",
                            "sc_duration": "4",
                            "visu_style_primary": "Modern and Clean",
                            "visu_style_secondary": "Professional",
                            "visu_color_scheme": "Blue and White with Grey accents",
                            "visu_quality": "8k, photorealistic, masterpiece quality, professional",
                            "visu_enhancements": "high dynamic range, depth of field, color grading, noise reduction",
                            "visu_neg_prompt": "worst quality, bad quality, cartoon, looking at camera, generic pose, posed group shot, vacation photo, studio backdrop",
                            "shots": [
                                {
                                    "shot_number": "001",
                                    "shot_type": "START",
                                    "visu_main_subject": "A group of employees happily interacting with the app on their devices, diverse group",
                                    "visu_environment": "in a bright office space, during the day, with vibrant lighting",
                                    "visu_technical": "Shot with a Canon EOS R5, 24mm lens, wide shot capturing the group dynamics",
                                    "visu_shot_type": "wide shot from center",
                                    "visu_subject_treatment": "engaged interaction",
                                    "duration_shot": "2",
                                    "transition": "cut to next shot"
                                },
                                {
                                    "shot_number": "002",
                                    "shot_type": "ENDING",
                                    "visu_main_subject": "Close-up of employees smiling and nodding in approval, showing satisfaction",
                                    "visu_environment": "in the same bright office, during the day, with a positive atmosphere",
                                    "visu_technical": "Shot with a Canon EOS R5, 50mm lens, close-up focusing on their expressions",
                                    "visu_shot_type": "close-up from left",
                                    "visu_subject_treatment": "satisfied expressions",
                                    "duration_shot": "2",
                                    "transition": "fade to next shot"
                                }
                            ]
                        },
                        {
                            "sc_id": "6",
                            "sc_title": "Call to Action",
                            "sc_duration": "5",
                            "visu_style_primary": "Modern and Clean",
                            "visu_style_secondary": "Professional",
                            "visu_color_scheme": "Blue and White with Grey accents",
                            "visu_quality": "8k, photorealistic, masterpiece quality, professional",
                            "visu_enhancements": "high dynamic range, depth of field, color grading, noise reduction",
                            "visu_neg_prompt": "worst quality, bad quality, cartoon, looking at camera, generic pose, posed group shot, vacation photo, studio backdrop",
                            "shots": [
                                {
                                    "shot_number": "001",
                                    "shot_type": "START",
                                    "visu_main_subject": "A wide shot of HR managers animatedly discussing the app at a conference table, engaging conversation",
                                    "visu_environment": "in a bright conference room, during the day, with collaborative lighting",
                                    "visu_technical": "Shot with a Sony A7 III, 35mm lens, wide shot capturing the group interaction",
                                    "visu_shot_type": "wide shot from center",
                                    "visu_subject_treatment": "animated discussion",
                                    "duration_shot": "2.5",
                                    "transition": "cut to next shot"
                                },
                                {
                                    "shot_number": "002",
                                    "shot_type": "ENDING",
                                    "visu_main_subject": "A satisfied HR manager nodding in agreement, wearing a formal outfit, single person",
                                    "visu_environment": "in the same conference room, during the day, with focused lighting on the subject",
                                    "visu_technical": "Shot with a Sony A7 III, 50mm lens, close-up capturing the subject's satisfied expression",
                                    "visu_shot_type": "close-up from right",
                                    "visu_subject_treatment": "satisfied expression",
                                    "duration_shot": "2.5",
                                    "transition": "fade to black"
                                }
                            ]
                        }
                    ]
                }
            }
        }
    },
    2: {
        "status": true,
        "message": "Glow 03 Script completed successfully",
        "status_code": 200,
        "data": {
            "glow_03_response": {
                "2": {
                    "video_id": "2_Employees",
                    "audience": "Employees",
                    "video_duration": "120",
                    "scenes": [
                        {
                            "sc_id": "1",
                            "sc_title": "Introduction to the App",
                            "sc_duration": "5",
                            "visu_style_primary": "Modern and Clean",
                            "visu_style_secondary": "Dynamic",
                            "visu_color_scheme": "Blue and White with Gray accents",
                            "visu_quality": "8k, photorealistic, masterpiece quality, professional",
                            "visu_enhancements": "HDR, depth of field, high dynamic range, color grading",
                            "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, looking at camera, eye contact, facing camera, stock photo",
                            "shots": [
                                {
                                    "shot_number": "001",
                                    "shot_type": "START",
                                    "visu_main_subject": "Professional photograph of a young woman, engaged with the app on her smartphone, wearing a casual blue shirt, smiling with excitement, single person",
                                    "visu_environment": "in a bright office setting, morning, natural light streaming through large windows",
                                    "visu_technical": "taken with a Canon EOS R5 using a 50mm lens, slight zoom in, focused on the subject",
                                    "visu_shot_type": "close",
                                    "visu_subject_treatment": "showing engagement with the app",
                                    "duration_shot": "2",
                                    "transition": "fade"
                                },
                                {
                                    "shot_number": "002",
                                    "shot_type": "IMPORTANT",
                                    "visu_main_subject": "A diverse group of employees, gathered around a tablet, discussing the app's features, dressed in business casual attire, showing enthusiasm, three people",
                                    "visu_environment": "in a collaborative workspace, daytime, bright and inviting atmosphere",
                                    "visu_technical": "captured with a Nikon Z6, 24mm lens, pan across the group, showing interactions",
                                    "visu_shot_type": "medium",
                                    "visu_subject_treatment": "displaying excitement and teamwork",
                                    "duration_shot": "3",
                                    "transition": "cut"
                                },
                                {
                                    "shot_number": "003",
                                    "shot_type": "ENDING",
                                    "visu_main_subject": "A group of employees celebrating together, using the app and sharing experiences, wearing colorful attire, expressions of joy and satisfaction, five people",
                                    "visu_environment": "in a vibrant office space, afternoon, warm lighting creating a cheerful ambiance",
                                    "visu_technical": "shot with a Sony A7 III, 35mm lens, wide-angle shot capturing the group dynamics",
                                    "visu_shot_type": "wide",
                                    "visu_subject_treatment": "showing camaraderie and celebration",
                                    "duration_shot": "2",
                                    "transition": "fade out"
                                }
                            ]
                        },
                        {
                            "sc_id": "2",
                            "sc_title": "App Features Overview",
                            "sc_duration": "6",
                            "visu_style_primary": "Modern and Clean",
                            "visu_style_secondary": "Professional",
                            "visu_color_scheme": "Blue and White with Gray accents",
                            "visu_quality": "8k, photorealistic, masterpiece quality, professional",
                            "visu_enhancements": "HDR, sharpness enhancement, color correction, noise reduction",
                            "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, generic pose, artificial pose",
                            "shots": [
                                {
                                    "shot_number": "001",
                                    "shot_type": "START",
                                    "visu_main_subject": "Screenshots of the app interface, clearly showcasing key features, modern and sleek design, multiple screens displayed",
                                    "visu_environment": "on a digital platform, bright and clean layout, well-lit",
                                    "visu_technical": "digital screen capture, static display, focusing on the app interface",
                                    "visu_shot_type": "wide",
                                    "visu_subject_treatment": "clear and informative display of app features",
                                    "duration_shot": "3",
                                    "transition": "slide"
                                },
                                {
                                    "shot_number": "002",
                                    "shot_type": "ENDING",
                                    "visu_main_subject": "Close-up of a notification alert popping up on a user's device, highlighting the functionality, modern smartphone in hand",
                                    "visu_environment": "in an office environment, natural light, focusing on the device",
                                    "visu_technical": "taken with a Canon EOS 90D, 85mm lens, macro shot on the phone screen",
                                    "visu_shot_type": "close",
                                    "visu_subject_treatment": "emphasizing app interaction",
                                    "duration_shot": "3",
                                    "transition": "cut"
                                }
                            ]
                        },
                        {
                            "sc_id": "3",
                            "sc_title": "Engaging with Performance Reviews",
                            "sc_duration": "5",
                            "visu_style_primary": "Modern and Clean",
                            "visu_style_secondary": "Dynamic",
                            "visu_color_scheme": "Blue and White with Gray accents",
                            "visu_quality": "8k, photorealistic, masterpiece quality, professional",
                            "visu_enhancements": "high contrast, dynamic lighting, enhanced details, color grading",
                            "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, stiff posture, perfect smile",
                            "shots": [
                                {
                                    "shot_number": "001",
                                    "shot_type": "START",
                                    "visu_main_subject": "A manager and an employee engaged in a performance review discussion, focused on a laptop, wearing business attire, showing interest, two people",
                                    "visu_environment": "in a modern office setting, afternoon, warm lighting creating an inviting atmosphere",
                                    "visu_technical": "captured with a Fujifilm X-T4, 23mm lens, medium shot focusing on the interaction",
                                    "visu_shot_type": "medium",
                                    "visu_subject_treatment": "showing engagement and collaboration",
                                    "duration_shot": "2.5",
                                    "transition": "fade"
                                },
                                {
                                    "shot_number": "002",
                                    "shot_type": "ENDING",
                                    "visu_main_subject": "Close-up of the employee nodding in agreement, appearing confident and satisfied, wearing a professional shirt, single person",
                                    "visu_environment": "in the same office, soft lighting highlighting the expression",
                                    "visu_technical": "shot with a Panasonic Lumix GH5, 42.5mm lens, close-up",
                                    "visu_shot_type": "close",
                                    "visu_subject_treatment": "focusing on the positive reaction",
                                    "duration_shot": "2.5",
                                    "transition": "cut"
                                }
                            ]
                        },
                        {
                            "sc_id": "4",
                            "sc_title": "Training and Development Opportunities",
                            "sc_duration": "7",
                            "visu_style_primary": "Modern and Clean",
                            "visu_style_secondary": "Professional",
                            "visu_color_scheme": "Blue and White with Gray accents",
                            "visu_quality": "8k, photorealistic, masterpiece quality, professional",
                            "visu_enhancements": "vibrant colors, high resolution, detailed textures, smooth transitions",
                            "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, posed group shot, vacation photo",
                            "shots": [
                                {
                                    "shot_number": "001",
                                    "shot_type": "START",
                                    "visu_main_subject": "Wide shots of employees participating in an online training session through the app, wearing headsets, focused and engaged, diverse group",
                                    "visu_environment": "in a bright training room, daytime, well-lit with technology",
                                    "visu_technical": "taken with a Canon EOS M50, 18-55mm lens, wide-angle shot capturing the group",
                                    "visu_shot_type": "wide",
                                    "visu_subject_treatment": "showing active participation",
                                    "duration_shot": "3",
                                    "transition": "slide"
                                },
                                {
                                    "shot_number": "002",
                                    "shot_type": "ENDING",
                                    "visu_main_subject": "Close-up of an employee taking notes and smiling, wearing a casual shirt, showing satisfaction, single person",
                                    "visu_environment": "in the same training room, soft lighting emphasizing the expression",
                                    "visu_technical": "shot with a Nikon D7500, 35mm lens, close-up",
                                    "visu_shot_type": "close",
                                    "visu_subject_treatment": "focusing on the positive engagement",
                                    "duration_shot": "4",
                                    "transition": "cut"
                                }
                            ]
                        },
                        {
                            "sc_id": "5",
                            "sc_title": "Communication with HR",
                            "sc_duration": "5",
                            "visu_style_primary": "Modern and Clean",
                            "visu_style_secondary": "Dynamic",
                            "visu_color_scheme": "Blue and White with Gray accents",
                            "visu_quality": "8k, photorealistic, masterpiece quality, professional",
                            "visu_enhancements": "high clarity, dynamic range, enhanced color saturation, smooth transitions",
                            "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, generic pose, stiff posture",
                            "shots": [
                                {
                                    "shot_number": "001",
                                    "shot_type": "START",
                                    "visu_main_subject": "Close-up of an employee typing a message to HR on the app, wearing a casual outfit, focused expression, single person",
                                    "visu_environment": "in a cozy office corner, well-lit with natural light",
                                    "visu_technical": "captured with a Sony A6400, 50mm lens, close-up on the device",
                                    "visu_shot_type": "close",
                                    "visu_subject_treatment": "showing interaction with the app",
                                    "duration_shot": "2.5",
                                    "transition": "fade"
                                },
                                {
                                    "shot_number": "002",
                                    "shot_type": "ENDING",
                                    "visu_main_subject": "Medium shot of HR staff responding with smiles in the background, dressed in professional attire, creating a welcoming environment, two people",
                                    "visu_environment": "in an HR office, bright and inviting atmosphere",
                                    "visu_technical": "shot with a Canon EOS R6, 24-70mm lens, medium shot capturing the interaction",
                                    "visu_shot_type": "medium",
                                    "visu_subject_treatment": "showing positive communication",
                                    "duration_shot": "2.5",
                                    "transition": "cut"
                                }
                            ]
                        },
                        {
                            "sc_id": "6",
                            "sc_title": "Conclusion and Call to Action",
                            "sc_duration": "5",
                            "visu_style_primary": "Modern and Clean",
                            "visu_style_secondary": "Dynamic",
                            "visu_color_scheme": "Blue and White with Gray accents",
                            "visu_quality": "8k, photorealistic, masterpiece quality, professional",
                            "visu_enhancements": "high contrast, vibrant colors, smooth transitions, detailed textures",
                            "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, posed group shot, vacation photo",
                            "shots": [
                                {
                                    "shot_number": "001",
                                    "shot_type": "START",
                                    "visu_main_subject": "Wide shot of a group of employees celebrating together, using the app and sharing experiences, wearing colorful attire, expressions of joy, five people",
                                    "visu_environment": "in a lively office space, afternoon, bright and cheerful lighting",
                                    "visu_technical": "captured with a Panasonic Lumix S5, 24mm lens, wide-angle shot showcasing the group",
                                    "visu_shot_type": "wide",
                                    "visu_subject_treatment": "showing camaraderie and celebration",
                                    "duration_shot": "2",
                                    "transition": "fade"
                                },
                                {
                                    "shot_number": "002",
                                    "shot_type": "ENDING",
                                    "visu_main_subject": "Close-up of the app logo, modern and sleek design, vibrant colors, single element",
                                    "visu_environment": "on a simple background, focused lighting highlighting the logo",
                                    "visu_technical": "shot with a Canon EOS 90D, 50mm lens, close-up",
                                    "visu_shot_type": "close",
                                    "visu_subject_treatment": "emphasizing the app identity",
                                    "duration_shot": "3",
                                    "transition": "fade out"
                                }
                            ]
                        }
                    ]
                }
            }
        }
    }
}


export const SAMPLE_GROUP_IMAGES = {
    "1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/24610cf9-920250213021251/hr_managers.jpeg",
    "2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/24610cf9-920250213021251/employees.jpeg"
}

export const SAMPLE_GROUP_VIDEOS = {
    "1": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    "2": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
}

export const SAMPLE_SCENE_IMAGES = {
    "1_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/24610cf9-920250213021251/scene_1_introduction_to_employee_management.jpeg",
    "1_6": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/24610cf9-920250213021251/scene_6_call_to_action.jpeg",
    "1_4": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/scene_4_features_overview.jpeg",
    "1_3": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/scene_3_introducing_the_solution.jpeg",
    "1_2": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/scene_2_problem_analysis.jpeg",
    "1_5": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/scene_5_user_engagement.jpeg",
    "2_4": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/scene_4_training_and_development_opportunities.jpeg",
    "2_6": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/scene_6_conclusion_and_call_to_action.jpeg",
    "2_5": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/scene_5_communication_with_hr.jpeg",
    "2_2": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/scene_2_app_features_overview.jpeg",
    "2_3": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/scene_3_engaging_with_performance_reviews.jpeg",
    "2_1": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/scene_1_introduction_to_the_app.jpeg"
}

export const SAMPLE_SCENE_CLIPS = {
    "2_1": "https://storage.googleapis.com/gtv-videos-bucket/sample/WhatCarCanYouGetForAGrand.mp4",
    "2_2": "https://storage.googleapis.com/gtv-videos-bucket/sample/WeAreGoingOnBullrun.mp4", 
    "2_3": "https://storage.googleapis.com/gtv-videos-bucket/sample/VolkswagenGTIReview.mp4",
    "2_4": "https://storage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4",
    "2_5": "https://storage.googleapis.com/gtv-videos-bucket/sample/SubaruOutbackOnStreetAndDirt.mp4",
    "2_6": "https://storage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4",
    "1_1": "https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4",
    "1_2": "https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4",
    "1_3": "https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4",
    "1_4": "https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4",
    "1_5": "https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
    "1_6": "https://storage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
}

export const SAMPLE_FRAME_IMAGES = {
    "1_1_001": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/24610cf9-920250213021251/frame_1_1_001.jpeg",
    "1_1_002": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/24610cf9-920250213021251/frame_1_1_002.jpeg",
    "1_2_001": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/24610cf9-920250213021251/frame_1_2_001.jpeg",
    "1_2_002": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/24610cf9-920250213021251/frame_1_2_002.jpeg",
    "1_3_001": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/24610cf9-920250213021251/frame_1_3_001.jpeg",
    "1_3_002": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/24610cf9-920250213021251/frame_1_3_002.jpeg",
    "1_4_001": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/24610cf9-920250213021251/frame_1_4_001.jpeg",
    "1_4_002": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/24610cf9-920250213021251/frame_1_4_002.jpeg",
    "1_5_001": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/24610cf9-920250213021251/frame_1_5_001.jpeg",
    "1_5_002": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/24610cf9-920250213021251/frame_1_5_002.jpeg",
    "1_6_001": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/24610cf9-920250213021251/frame_1_6_001.jpeg",
    "1_6_002": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/24610cf9-920250213021251/frame_1_6_002.jpeg",
    "2_6_002": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/frame_2_6_002.jpeg",
    "2_3_001": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/frame_2_3_001.jpeg",
    "2_1_001": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/frame_2_1_001.jpeg",
    "2_2_001": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/frame_2_2_001.jpeg",
    "2_2_002": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/frame_2_2_002.jpeg",
    "2_3_002": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/frame_2_3_002.jpeg",
    "2_5_002": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/frame_2_5_002.jpeg",
    "2_1_002": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/frame_2_1_002.jpeg",
    "2_5_001": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/frame_2_5_001.jpeg",
    "2_4_002": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/frame_2_4_002.jpeg",
    "2_6_001": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/frame_2_6_001.jpeg",
    "2_1_003": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/frame_2_1_003.jpeg",
    "2_4_001": "https://chat2movie.s3.amazonaws.com/target_groups/24610cf9-920250213021251/frame_2_4_001.jpeg"
}

export const SAMPLE_FRAME_ANIMATIONS = {
    "1_1_001": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    "1_1_002": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4", 
    "1_2_001": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
    "1_2_002": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4",
    "1_3_001": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4",
    "1_3_002": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4",
    "1_4_001": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4",
    "1_4_002": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4",
    "1_5_001": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/SubaruOutbackOnStreetAndDirt.mp4",
    "1_5_002": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4",
    "1_6_001": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/VolkswagenGTIReview.mp4",
    "1_6_002": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WeAreGoingOnBullrun.mp4",
    "2_6_002": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WhatCarCanYouGetForAGrand.mp4",
    "2_3_001": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    "2_1_001": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
    "2_2_001": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
    "2_2_002": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4",
    "2_3_002": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4",
    "2_5_002": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4",
    "2_1_002": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4",
    "2_5_001": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4",
    "2_4_002": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/SubaruOutbackOnStreetAndDirt.mp4",
    "2_6_001": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4",
    "2_1_003": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/VolkswagenGTIReview.mp4",
    "2_4_001": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WeAreGoingOnBullrun.mp4"
}


export const SAMPLE_GLOW_10 = {
    glow_10_response: {
      tam: {
        value: 5000000000,
        industry: "Automated Video Creation",
        description: "The total market for automated video production services targeting various sectors.",
        cagr: 20,
        source: { name: "Market Research Future", date: "2023/05/15" }
      },
      sam: {
        value: 1500000000,
        industry: "Digital Media Production",
        description: "The segment dedicated to automated video solutions for marketing and product validation.",
        cagr: 18,
        source: { name: "Grand View Research", date: "2023/07/22" }
      },
      som: {
        value: 300000000,
        industry: "Online Marketing Tools",
        description: "The market share that can be realistically captured by the business within the digital marketing automation space.",
        cagr: 15,
        source: { name: "Statista", date: "2023/08/10" }
      }
    }
  };

export const SAMPLE_GLOW_11 = {
  glow_11_response: {
    product_cost_breakdown: [
      { component: "Software Development", cost: 15000 },
      { component: "AI Model Training", cost: 5000 },
      { component: "Video Hosting Services", cost: 2000 },
      { component: "Marketing and Advertising", cost: 3000 },
      { component: "Customer Support", cost: 2500 },
      { component: "Miscellaneous Expenses", cost: 1000 }
    ],
    market_pricing: [
      { segment: "Small Businesses", price: 99 },
      { segment: "Medium Enterprises", price: 299 },
      { segment: "Large Corporations", price: 499 }
    ],
    suggested_price_points: [
      { segment: "Small Businesses", price: 89 },
      { segment: "Medium Enterprises", price: 249 },
      { segment: "Large Corporations", price: 449 }
    ],
    customer_metrics: {
      cac: {
        "Small Businesses": 20,
        "Medium Enterprises": 50,
        "Large Corporations": 100
      },
      ltv: {
        "Small Businesses": 300,
        "Medium Enterprises": 800,
        "Large Corporations": 1500
      }
    },
    period: "monthly"
  }
};

export const SAMPLE_GLOW_21 = {
    glow_21_response: {
      markets: [
        {
          name: "Automatic AI Video Production for Product Validation",
          tam: { value: 500000000, cagr: 15 },
          sam: { value: 100000000, cagr: 18 },
          som: { value: 20000000, cagr: 20 }
        },
        {
          name: "E-commerce Video Solutions",
          tam: { value: 600000000, cagr: 12 },
          sam: { value: 120000000, cagr: 14 },
          som: { value: 24000000, cagr: 16 }
        },
        {
          name: "Online Marketing Video Production",
          tam: { value: 800000000, cagr: 10 },
          sam: { value: 160000000, cagr: 12 },
          som: { value: 32000000, cagr: 14 }
        },
        {
          name: "Social Media Video Content Creation",
          tam: { value: 700000000, cagr: 11 },
          sam: { value: 140000000, cagr: 13 },
          som: { value: 28000000, cagr: 15 }
        },
        {
          name: "Video Analytics and Performance Tracking",
          tam: { value: 400000000, cagr: 14 },
          sam: { value: 80000000, cagr: 16 },
          som: { value: 16000000, cagr: 18 }
        },
        {
          name: "Augmented Reality Video Solutions",
          tam: { value: 300000000, cagr: 20 },
          sam: { value: 60000000, cagr: 22 },
          som: { value: 12000000, cagr: 25 }
        },
        {
          name: "Corporate Training Video Production",
          tam: { value: 250000000, cagr: 8 },
          sam: { value: 50000000, cagr: 10 },
          som: { value: 10000000, cagr: 12 }
        }
      ]
    }
  };
  
export const SAMPLE_GLOW_33 = {
    "business_goals": {
        "speed": {
            "title": "Quick Market Penetration",
            "description": "Achieve rapid entry into the market with minimal delays.",
            "recommended_groups": 5,
        },
        "growth": {
            "title": "Sustainable Business Expansion",
            "description": "Focus on long-term growth strategies to ensure stability.",
            "recommended_groups": 7,
        },
        "revenue": {
            "title": "Maximizing Profit Potential",
            "description": "Implement strategies to boost revenue through effective pricing and marketing.",
            "recommended_groups": 9,
        },
        "investment": {
            "title": "Attracting Suitable Investors",
            "description": "Seek investment from entities aligned with business goals for shared success.",
            "recommended_groups": 6,
        }
    },
    "target_groups": [
        {
            "name": "Local Sports Teams",
            "score": 95,
            "key_benefits": [
                "Enhanced fan engagement through interactive overlays.",
                "Increased visibility and promotion of local athletes.",
                "Opportunities for sponsorship and advertising revenue."
            ],
            "group_size": {
                "market_size": "100-500 teams",
                "advantages": [
                    "Strong community support and loyalty.",
                    "Regular events create consistent content opportunities.",
                    "Potential for growth as new teams emerge."
                ],
                "considerations": [
                    "Limited budgets for technology investments.",
                    "Need for training on how to use the service.",
                    "Variability in tech-savvy among teams."
                ]
            }
        },
        {
            "name": "Colleges and Universities",
            "score": 90,
            "key_benefits": [
                "Opportunity to showcase athletic programs to a wider audience.",
                "Engagement with current students and alumni through live events.",
                "Potential for partnerships with local businesses for sponsorships."
            ],
            "group_size": {
                "market_size": "1,000+ institutions",
                "advantages": [
                    "Diverse range of sports to cover.",
                    "Established fan bases and alumni networks.",
                    "Access to student volunteers for production support."
                ],
                "considerations": [
                    "Budget constraints due to varying funding levels.",
                    "Need for compliance with NCAA and other regulations.",
                    "Differing levels of interest in live streaming across sports."
                ]
            }
        },
        {
            "name": "Amateur Sports Leagues",
            "score": 85,
            "key_benefits": [
                "Ability to reach family and friends who cannot attend games.",
                "Creation of a digital record of games for players and teams.",
                "Encouragement of increased participation through visibility."
            ],
            "group_size": {
                "market_size": "500-1,000 leagues",
                "advantages": [
                    "High community involvement and engagement.",
                    "Regular schedules allow for consistent streaming.",
                    "Variety of sports creates diverse content options."
                ],
                "considerations": [
                    "Limited technical knowledge among league organizers.",
                    "Potential for inconsistent production quality.",
                    "Variable interest from league members."
                ]
            }
        },
        {
            "name": "Fitness and Wellness Centers",
            "score": 80,
            "key_benefits": [
                "Attracting new clients through live classes and events.",
                "Building a community around fitness activities.",
                "Offering personalized coaching through interactive features."
            ],
            "group_size": {
                "market_size": "5,000+ centers",
                "advantages": [
                    "Growing trend of online fitness classes.",
                    "Potential for on-demand content to complement live streams.",
                    "Strong focus on customer engagement and retention."
                ],
                "considerations": [
                    "Need for high-quality production to maintain brand image.",
                    "Potential competition from established fitness streaming platforms.",
                    "Varied technical capabilities across different centers."
                ]
            }
        },
        {
            "name": "High School Sports Programs",
            "score": 75,
            "key_benefits": [
                "Providing exposure for student-athletes to scouts and recruiters.",
                "Creating a sense of school spirit and community involvement.",
                "Facilitating fundraising efforts through live events."
            ],
            "group_size": {
                "market_size": "25,000+ schools",
                "advantages": [
                    "Strong community ties and engagement.",
                    "Regular sporting events throughout the school year.",
                    "Access to a large audience of parents and students."
                ],
                "considerations": [
                    "Limited budgets for technology and equipment.",
                    "Need for training staff and students on production techniques.",
                    "Inconsistent interest across different sports."
                ]
            }
        },
        {
            "name": "Esports Organizations",
            "score": 70,
            "key_benefits": [
                "Growing audience for live-streamed gaming events.",
                "Potential for sponsorships from tech and gaming companies.",
                "Creating an engaging community around competitive gaming."
            ],
            "group_size": {
                "market_size": "1,000+ organizations",
                "advantages": [
                    "Rapidly expanding market with high engagement levels.",
                    "Diverse content opportunities across various games.",
                    "Access to tech-savvy audiences who appreciate innovation."
                ],
                "considerations": [
                    "High competition in the live-streaming space.",
                    "Need for high production quality to attract viewers.",
                    "Varied interest in different gaming genres."
                ]
            }
        },
        {
            "name": "Corporate Wellness Programs",
            "score": 65,
            "key_benefits": [
                "Enhancing employee engagement through live fitness activities.",
                "Promoting health and wellness culture within the organization.",
                "Opportunity for branding through sponsored events."
            ],
            "group_size": {
                "market_size": "10,000+ companies",
                "advantages": [
                    "Increased focus on employee well-being.",
                    "Potential for significant viewer engagement among employees.",
                    "Diverse range of activities to showcase."
                ],
                "considerations": [
                    "Need for alignment with corporate health goals.",
                    "Varying levels of interest in fitness among employees.",
                    "Budget constraints in smaller companies."
                ]
            }
        },
        {
            "name": "Community Recreation Centers",
            "score": 60,
            "key_benefits": [
                "Providing a platform for local events and activities.",
                "Fostering community connections through shared experiences.",
                "Encouraging participation in recreational programs."
            ],
            "group_size": {
                "market_size": "5,000+ centers",
                "advantages": [
                    "Strong community involvement and engagement.",
                    "Regular events provide consistent content opportunities.",
                    "Variety of activities and sports to cover."
                ],
                "considerations": [
                    "Limited resources for advanced technology.",
                    "Potential for inconsistent event scheduling.",
                    "Need for training staff on live streaming."
                ]
            }
        },
        {
            "name": "National Sports Associations",
            "score": 55,
            "key_benefits": [
                "Showcasing national talent through live events.",
                "Increasing visibility for lesser-known sports.",
                "Opportunity for sponsorship deals and partnerships."
            ],
            "group_size": {
                "market_size": "500+ associations",
                "advantages": [
                    "Access to a broad audience base.",
                    "Existing infrastructure supports live events.",
                    "Potential for high-quality productions with resources."
                ],
                "considerations": [
                    "Complexities in coordinating events across regions.",
                    "Diverse interests among different sports associations.",
                    "Variable budgets and funding sources."
                ]
            }
        },
        {
            "name": "Youth Sports Camps",
            "score": 50,
            "key_benefits": [
                "Engaging parents and families with live showcases of skills.",
                "Building a camp community through shared experiences.",
                "Attracting new participants through exposure."
            ],
            "group_size": {
                "market_size": "2,000+ camps",
                "advantages": [
                    "High engagement from parents and campers.",
                    "Regular events provide consistent content opportunities.",
                    "Potential for unique content through different sports."
                ],
                "considerations": [
                    "Limited budgets for technology investments.",
                    "Need for training on how to use the service.",
                    "Variability in tech-savvy among camp staff."
                ]
            }
        }
    ]
}