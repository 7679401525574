/**
 * Utility functions to handle page refresh and maintain consistent state
 */

/**
 * Saves the current application state that needs to persist across refreshes
 * @param {Object} state - Application state to save
 */
export const saveStateBeforeRefresh = (state) => {
  if (!state) return;
  
  try {
    // Save minimal necessary state to sessionStorage
    sessionStorage.setItem('app_refresh_state', JSON.stringify({
      lastPath: window.location.pathname,
      timestamp: Date.now(),
      // Add any other critical state needed for restart
    }));
  } catch (error) {
    console.error('Error saving state before refresh:', error);
  }
};

/**
 * Restores state after a page refresh
 * @returns {Object|null} The restored state or null if no state was saved
 */
export const restoreStateAfterRefresh = () => {
  try {
    const savedState = sessionStorage.getItem('app_refresh_state');
    if (!savedState) return null;
    
    const parsedState = JSON.parse(savedState);
    
    // Check if the stored state is recent (less than 30 seconds old)
    if (Date.now() - parsedState.timestamp < 30000) {
      return parsedState;
    }
    
    // Clear outdated state
    sessionStorage.removeItem('app_refresh_state');
    return null;
  } catch (error) {
    console.error('Error restoring state after refresh:', error);
    return null;
  }
};

/**
 * Checks if the current page load is a refresh
 * @returns {boolean} True if the page was refreshed
 */
export const isPageRefresh = () => {
  // Check performance navigation type if available
  if (window.performance) {
    if (performance.navigation && performance.navigation.type === 1) {
      return true;
    }
    
    // For newer browsers
    const navEntries = performance.getEntriesByType('navigation');
    if (navEntries.length > 0 && navEntries[0].type === 'reload') {
      return true;
    }
  }
  
  // Fallback: check if we have saved state in sessionStorage
  return sessionStorage.getItem('app_refresh_state') !== null;
};
