import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Stack,
  styled,
  createTheme,
  ThemeProvider,
  Fade,
  Grow,
  Zoom
} from '@mui/material';
import processConfig from '../../config/processIndex.json';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import {
  LightbulbOutlined,
  ArrowForward,
  People,
  CheckCircleOutline,
  Search,
  Code,
  Build,
  TrendingUp
} from '@mui/icons-material';

// Create theme with your corporate orange
const theme = createTheme({
  palette: {
    mode: 'light', // Default mode
    primary: {
      main: '#FD9800',
      light: '#FDB544',
      dark: '#D68100',
      contrastText: '#FFFFFF',
    },
    grey: {
      200: '#EFEFEF'
    },
    background: {
      default: '#FFFFFF', 
      paper: '#FFFFFF'
    },
    text: {
      primary: '#333333',
      secondary: '#666666'
    },
    divider: '#E0E0E0'
  }
});

// Function to update theme based on current app theme
const getThemeWithMode = (isDarkMode) => {
  return createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      mode: isDarkMode ? 'dark' : 'light',
      background: {
        default: isDarkMode ? '#121212' : '#FFFFFF',
        paper: isDarkMode ? '#1E1E1E' : '#FFFFFF'
      },
      text: {
        primary: isDarkMode ? '#F0F0F0' : '#333333',
        secondary: isDarkMode ? '#CCCCCC' : '#666666'
      },
      divider: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : '#E0E0E0'
    }
  });
};

const ProgressBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '4px',
  marginBottom: theme.spacing(2),
  '& .bar': {
    flex: 1,
    height: '4px',
    backgroundColor: theme.palette.grey[200],
    borderRadius: '2px',
    transition: 'background-color 0.3s ease-in-out, transform 0.3s ease-in-out',
  },
  '& .bar.active': {
    backgroundColor: theme.palette.primary.main,
    transform: 'scaleY(1.5)',
  }
}));

const OptionButton = styled(Button)(({ theme, active }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
  padding: '16px 8px',
  borderColor: active ? theme.palette.primary.main : theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
  backgroundColor: active ? `${theme.palette.primary.main}10` : 'transparent',
  color: active ? theme.palette.primary.main : theme.palette.mode === 'dark' ? '#F0F0F0' : 'var(--color-text)',
  transition: 'all 0.3s ease-in-out',
  position: 'relative',
  overflow: 'hidden',
  
  '& .MuiSvgIcon-root': {
    marginBottom: '8px',
    fontSize: '2rem',
    transition: 'transform 0.3s ease-in-out, color 0.3s ease-in-out',
    color: active ? theme.palette.primary.main : theme.palette.mode === 'dark' ? '#F0F0F0' : 'var(--color-text)',
  },
  '& .MuiTypography-root': {
    color: active ? theme.palette.primary.main : theme.palette.mode === 'dark' ? '#F0F0F0' : 'inherit',
    transition: 'color 0.3s ease-in-out',
  },
  '& .sublabel': {
    fontSize: '0.75rem',
    color: active ? theme.palette.primary.main : theme.palette.mode === 'dark' ? '#CCCCCC' : 'rgba(0, 0, 0, 0.6)',
    transition: 'color 0.3s ease-in-out',
  },
  '&:hover': {
    backgroundColor: active ? `${theme.palette.primary.main}20` : theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
    borderColor: theme.palette.primary.main,
    transform: 'translateY(-2px)',
    boxShadow: active ? `0 4px 8px ${theme.palette.primary.main}30` : '0 4px 8px rgba(0, 0, 0, 0.05)',
  },
  '&:hover .MuiSvgIcon-root': {
    transform: 'scale(1.1)',
    color: '#FD9800',
  },
  '&:hover .MuiTypography-root': {
    color: '#FD9800',
  },
  '&:hover .sublabel': {
    color: theme.palette.primary.light,
  }
}));

const Glow08Processor = ({ data, onComplete, sessionId }) => {
  const process = processConfig.processes.find(p => p.id === 'glow_08');
  const [isDarkMode, setIsDarkMode] = useState(false);
  
  // Use a ref to store custom styles that will be applied to question headers
  const headerStyles = {
    light: {
      color: '#333333',
      backgroundColor: '#FFFFFF',
      padding: '16px',
      fontWeight: 'bold',
      borderRadius: '4px 4px 0 0',
      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
      border: '1px solid #EFEFEF',
    },
    dark: {
      color: '#FFFFFF',
      backgroundColor: '#000000',
      padding: '16px',
      fontWeight: 'bold',
      borderRadius: '4px 4px 0 0', 
      boxShadow: '0 1px 3px rgba(255,255,255,0.2)',
      border: '1px solid rgba(255,255,255,0.2)',
    }
  };
  
  // Detect dark mode by checking CSS variables
  useEffect(() => {
    const detectDarkMode = () => {
      // Check for dark mode in multiple ways
      const bodyStyles = window.getComputedStyle(document.body);
      const htmlClasses = document.documentElement.classList;
      
      // More comprehensive dark mode detection
      const isDark = (
        // Check if dark mode class exists
        htmlClasses.contains('dark-mode') || 
        htmlClasses.contains('darkMode') || 
        htmlClasses.contains('dark') ||
        // Check color-scheme
        bodyStyles.colorScheme === 'dark' ||
        // Fallback to our previous checks
        bodyStyles.getPropertyValue('--color-background').includes('18, 18, 18')
      );
      
      setIsDarkMode(isDark);
      
      // Apply direct styles to all question headers 
      const headers = document.querySelectorAll('.question-header');
      headers.forEach(header => {
        const styles = isDark ? headerStyles.dark : headerStyles.light;
        Object.assign(header.style, styles);
      });
    };
    
    // Run initial detection
    detectDarkMode();
    
    // Set up observer for theme changes
    const observer = new MutationObserver(detectDarkMode);
    observer.observe(document.body, { attributes: true, attributeFilter: ['class', 'style'] });
    observer.observe(document.documentElement, { attributes: true, attributeFilter: ['class'] });
    
    // Also run on window load to catch any late styling
    window.addEventListener('load', detectDarkMode);
    
    return () => {
      observer.disconnect();
      window.removeEventListener('load', detectDarkMode);
    };
  }, []);
  
  // Create refs for each question section
  const questionRefs = useRef([]);
  
  // Initialize the refs array
  useEffect(() => {
    questionRefs.current = questionRefs.current.slice(0, questions.length);
  }, []);
  
  const questions = [
    {
      id: 'stage',
      question: "What best describes your current stage?",
      options: [
        { value: 'idea_only', label: "Just an idea", icon: LightbulbOutlined },
        { value: 'early_prototype', label: "Prototype/MVP", icon: Code },
        { value: 'working_product', label: "Working product", icon: Build },
        { value: 'revenue_generating', label: "Paying customers", icon: TrendingUp }
      ]
    },
    {
      id: 'validation',
      question: "Have you validated this with potential users?",
      options: [
        { value: 'no_validation', label: "Not yet", icon: Search },
        { value: 'informal_talks', label: "Informal talks", icon: People },
        { value: 'structured_research', label: "Structured research", icon: CheckCircleOutline },
        { value: 'paying_customers', label: "Paying customers", icon: TrendingUp }
      ]
    },
    {
      id: 'team_expertise',
      question: "What's your team's expertise in this specific field?",
      options: [
        { value: 'learning', label: "Learning the space", subLabel: "New to industry", icon: Search },
        { value: 'some_experience', label: "Some experience", subLabel: "1-3 years relevant", icon: Build },
        { value: 'deep_knowledge', label: "Deep knowledge", subLabel: "3-7 years relevant", icon: CheckCircleOutline },
        { value: 'industry_expert', label: "Industry expert", subLabel: "7+ years relevant", icon: TrendingUp }
      ]
    },
    {
      id: 'team',
      question: "What's your current team situation?",
      options: [
        { value: 'solo_founder', label: "Just me", icon: People },
        { value: 'multiple_cofounders', label: "Co-founders", icon: People },
        { value: 'small_team_2_5', label: "Small team", icon: People },
        { value: 'established_team_6_plus', label: "Established", icon: Build }
      ]
    },
    {
      id: 'budget',
      question: "What is your budget for this project?",
      options: [
        { value: 'budget_under_10k', label: "Under $10K", subLabel: "MVP Budget", icon: LightbulbOutlined },
        { value: 'budget_10k_50k', label: "$10K-$50K", subLabel: "Basic Product", icon: Code },
        { value: 'budget_50k_100k', label: "$50K-$100K", subLabel: "Full Product", icon: Build },
        { value: 'budget_over_100k', label: "Over $100K", subLabel: "Enterprise Scale", icon: TrendingUp }
      ]
    },
    {
      id: 'goal',
      question: "What's your primary goal right now?",
      options: [
        { value: 'goal_validate_idea', label: "Validate idea", icon: Search },
        { value: 'goal_build_product', label: "Build product", icon: Build },
        { value: 'goal_acquire_users', label: "Get users", icon: People },
        { value: 'goal_scale_business', label: "Scale business", icon: TrendingUp }
      ]
    }
  ];
  
  const getAnswers = () => {
    if (typeof data === 'object') {
      return questions.reduce((acc, question) => {
        if (data[question.id]) {
          acc[question.id] = data[question.id];
        } else {
          acc[question.id] = null;
        }
        return acc;
      }, {});
    }
    return {};
  }

  const [answers, setAnswers] = useState(getAnswers());
  const [allAnswers, setAllAnswers] = useState(false);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);

  const handleSelect = (questionId, value) => {
    // Update the answer
    setAnswers(prev => ({
      ...prev,
      [questionId]: value
    }));
    
    // Find the index of the current question
    const currentIndex = questions.findIndex(q => q.id === questionId);
    
    // If there's a next question, scroll to it after a short delay
    if (currentIndex < questions.length - 1) {
      const nextIndex = currentIndex + 1;
      setActiveQuestionIndex(nextIndex);
      
      setTimeout(() => {
        // Scroll to the next question with smooth animation
        questionRefs.current[nextIndex]?.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }, 300);
    }
  };

  useEffect(() => {
    setAllAnswers(Object.values(answers).every(answer => answer != null));    
  }, [answers]);

  const handleComplete = () => {
    if (allAnswers) {
      onComplete({
        processId: 'glow_08',
        data: {
          status: true,
          glow_08_response: answers
        }
      });
    }
  };

  return (
    <ThemeProvider theme={getThemeWithMode(isDarkMode)}>
      <ViewerHeader 
        title={process.info.title}
        subtitle={process.info.description}
        processId="glow_08"
        showRerun={!!process.endpoints.run}
      />
      <Box sx={{ p: 3, backgroundColor: 'var(--color-surface)' }}>
        <Stack spacing={3} sx={{ maxWidth: 900, mx: 'auto'}}>
          {questions.map((q, qIndex) => (
            <Zoom 
              in={true} 
              key={q.id}
              style={{ 
                transformOrigin: '0 0 0',
                transitionDelay: `${qIndex * 100}ms`
              }}
              timeout={500}
            >
              <Card 
                elevation={0} 
                sx={{ 
                  borderRadius: 2,
                  backgroundColor: 'var(--card-bg)',
                  transition: 'all 0.3s ease',
                  opacity: activeQuestionIndex >= qIndex ? 1 : 0.7,
                  transform: activeQuestionIndex === qIndex ? 'scale(1.02)' : 'scale(1)',
                  boxShadow: activeQuestionIndex === qIndex ? 
                    '0 4px 12px rgba(253, 152, 0, 0.15)' : 
                    '0 1px 3px rgba(0,0,0,0.05)',
                  border: activeQuestionIndex === qIndex ? 
                    '1px solid rgba(253, 152, 0, 0.3)' : 
                    '1px solid transparent',
                  '&:hover': {
                    boxShadow: '0 4px 12px rgba(0,0,0,0.08)'
                  }
                }}
                ref={el => questionRefs.current[qIndex] = el}
              >
                {/* Direct HTML element with inline style */}
                <div 
                  className="question-header"
                  style={{
                    fontSize: '1.1rem',
                    margin: 0,
                    transition: 'color 0.3s ease, background-color 0.3s ease',
                    zIndex: 5
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.color = '#FD9800';
                  }}
                  onMouseOut={(e) => {
                    const styles = isDarkMode ? headerStyles.dark : headerStyles.light;
                    e.currentTarget.style.color = styles.color;
                  }}
                >
                  {q.question}
                </div>
                <CardContent>
                  <ProgressBar>
                    {q.options.map((_, index) => (
                      <Box
                        key={index}
                        className={`bar ${index <= (answers[q.id] ? q.options.findIndex(opt => opt.value === answers[q.id]) : -1) ? 'active' : ''}`}
                      />
                    ))}
                  </ProgressBar>
                  <Stack
                    direction="row"
                    spacing={1}
                  >
                    {q.options.map((option, oIndex) => {
                      const Icon = option.icon;
                      const isSelected = answers[q.id] === option.value;
                      return (
                        <Fade in={true} key={option.value} timeout={(oIndex + 1) * 150 + (qIndex * 50)}>
                          <OptionButton
                            active={isSelected ? 'active' : ''}
                            onClick={() => handleSelect(q.id, option.value)}
                            variant="outlined"
                            sx={{
                              transform: isSelected ? 'scale(1.03)' : 'scale(1)',
                            }}
                          >
                            <Icon />
                            <Typography variant="button">{option.label}</Typography>
                            {option.subLabel && (
                              <Typography className="sublabel">
                                {option.subLabel}
                              </Typography>
                            )}
                          </OptionButton>
                        </Fade>
                      );
                    })}
                  </Stack>
                </CardContent>
              </Card>
            </Zoom>
          ))}
        </Stack>
        <ViewFooter
          isComplete={() => allAnswers}
          onComplete={handleComplete}
          completeButtonText={process.footer?.continueText || "Continue"}
          processId="glow_08"
        />
      </Box>
    </ThemeProvider>
  );
};

export default Glow08Processor;
