import React, { useState, useEffect } from 'react';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import processConfig from '../../config/processIndex.json';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Button,
} from '@mui/material';
import { 
  Rocket, TrendingUp, DollarSign, Gem, 
  CheckCircle, ArrowRight, Users 
} from 'lucide-react';
import { 
  BarChart, Bar, XAxis, YAxis, CartesianGrid, 
  Tooltip, Legend, ReferenceLine 
} from 'recharts';

import { useDispatch } from 'react-redux';
import { showNotification } from '../notification/notificationSlide';
import { SAMPLE_GLOW_33 } from '../../data/sampleGlowData';

const minNumberOfGroup = 10;


  // Validate data structure matches SAMPLE_DATA schema and collect any missing fields
  const validateDataStructure = (data) => {
    if (!data?.target_groups || !Array.isArray(data.target_groups)) {
      return { isValid: false, message: 'Missing target groups data' };
    }

    if (!data?.business_goals || typeof data.business_goals !== 'object') {
      return { isValid: false, message: 'Missing or invalid business goals data' };
    }

    const missingFields = [];
    const isValid = data.target_groups.every(group => {
      const missing = [];
      if (!group.name) missing.push('name');
      if (typeof group.score !== 'number') missing.push('score');
      if (!Array.isArray(group.key_benefits)) missing.push('key_benefits');
      if (!group.group_size) missing.push('group_size');
      if (group.group_size && !group.group_size.market_size) missing.push('group_size.market_size');
      if (!Array.isArray(group.group_size?.advantages)) missing.push('group_size.advantages');
      if (!Array.isArray(group.group_size?.considerations)) missing.push('group_size.considerations');
      
      if (missing.length > 0) {
        missingFields.push(`Group "${group.name || 'unnamed'}": ${missing.join(', ')}`);
      }
      return missing.length === 0;
    });

    return {
      isValid: missingFields.length === 0,
      message: missingFields.length > 0 ? 
        `Invalid data structure. Missing fields: ${missingFields.join('; ')}` : 
        'Data structure is valid'
    };
  };

const GoalBasedAnalysis = ({ data, onComplete, sessionId }) => {
  const process = processConfig.processes.find(p => p.id === 'glow_33');
  const dispatch = useDispatch();
  const mountedRef = React.useRef(false);

  const [displayData, setDisplayData] = useState(data?.target_groups && data?.business_goals ? data : SAMPLE_GLOW_33);
  const isDemo = !data?.target_groups;
  const nbTargetGroup = displayData.target_groups.length
  
  useEffect(() => {
    if (!mountedRef.current) {
      const validation = validateDataStructure(displayData);
      if (!validation.isValid) {
        dispatch(showNotification({message: validation.message, severity:'warning'}));
      }
      mountedRef.current = true;
    }
  }, [displayData, dispatch]);

  const businessIcons = {
    'speed': Rocket,
    'growth': TrendingUp,
    'revenue': DollarSign,
    'investment': Gem
  }

  // Business goals configuration
  const businessGoals = Object.entries(displayData.business_goals)
    .map(([key, value]) => {
      const recommendedGroups = nbTargetGroup >= value.recommended_groups ? value.recommended_groups : nbTargetGroup - 1;
      return {
        key,
        ...value,
        recommended_groups: recommendedGroups,
        icon: businessIcons[key] ? businessIcons[key] : Rocket // Check if key exists in businessIcons
      };
    })
    .sort((a, b) => a.recommended_groups - b.recommended_groups);

  const [selectedGoal, setSelectedGoal] = useState(() => {    
    return displayData?.selectedGoal || null;
  });
  const [selectedAmount, setSelectedAmount] = useState(() => {        
    return displayData?.selectedAmount || 7;
  });
  const [selectedGroupData, setSelectedGroupData] = useState(() => {    
    return displayData?.selectedAmount ? displayData.target_groups[displayData?.selectedAmount]:  displayData.target_groups[7];
  });

  const handleChangeGroup = (index) => {
    if (index >= 0 && index < nbTargetGroup) {      
      setSelectedAmount(index - 1);
      setSelectedGroupData(displayData.target_groups[index - 1]);
    }
  }

  // Get groups based on selected amount
  const getGroupsForSize = (size) => displayData.target_groups.slice(0, size + 1);

  // Calculate average score
  const calculateAverage = (groups) => {
    if (!groups || groups.length === 0) return 0;
    const sum = groups.reduce((acc, group) => acc + group.score, 0);
    return (sum / groups.length).toFixed(1);
  };

  // Color utilities
  const getFeasibilityColor = (score) => {
    if (score >= 80) return 'bg-green-500';
    if (score >= 70) return 'bg-yellow-500';
    return 'bg-red-500';
  };

  const getAverageScoreColor = (score) => {
    if (score >= 80) return 'text-green-600';
    if (score >= 70) return 'text-yellow-600';
    return 'text-red-600';
  };

  const handleContinue = () => {
    if (selectedGoal) {
      onComplete({
        processId: 'glow_33',
        data: {
          status: true,
          glow_33_response: {
            selectedGoal: selectedGoal,
            selectedAmount: selectedAmount, 
            ...data
          }
        }
      });
    }
  };

  return (
    <>
    {isDemo && (
      <Box sx={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(-45deg)',
        color: 'rgba(255, 140, 0, 0.45)',
        fontSize: '48px',
        fontWeight: 'bold',
        pointerEvents: 'none',
        zIndex: 9999,
        width: '100%',
        textAlign: 'center',
        userSelect: 'none',
        textTransform: 'uppercase'
      }}>
        Sample (Glowtest)
      </Box>
    )}
    <ViewerHeader 
      title={process.info.title}
      subtitle={process.info.description}
      processId="glow_33"
      sessionId={sessionId}
      showRerun={!!process.endpoints.run}
    />
    <Card className="w-full mx-auto">
      <CardContent>
        <div className="space-y-8">
          {/* Goal Selection */}
          <div className="space-y-4">
            <div className="grid grid-cols-4 gap-4">
              {Object.entries(businessGoals).map(([key, goal]) => {
                const Icon = goal.icon;
                return (
                  <Button
                    key={key}
                    variant={selectedGoal === key ? "contained" : "outlined"}
                    onClick={() => {
                      setSelectedGoal(key);
                      handleChangeGroup(goal.recommended_groups);
                    }}
                    className="h-auto py-4 flex flex-col gap-2"
                  >
                    <Icon className="h-6 w-6" />
                    <div className="text-sm font-medium">{goal.title}</div>
                    <div className="text-xs opacity-75">{goal.description}</div>
                  </Button>
                );
              })}
            </div>
          </div>

          {selectedGoal && displayData?.target_groups?.[businessGoals[selectedGoal]?.recommended_groups] && (
            <>
              {/* Goal Summary */}
              <div className="border border-green-200 rounded-lg p-6">
                <div className="flex items-start gap-4">
                  <div className="bg-green-100 p-3 rounded-full">
                    <Users className="h-6 w-6 text-green-600" />
                  </div>
                  <div>
                    <h4 className="text-lg font-medium">
                      Recommended: {businessGoals[selectedGoal]?.recommended_groups} Target Groups
                    </h4>
                    <div className="mt-4">
                      <div className="font-medium mb-2">Key Benefits:</div>
                      <ul className="space-y-1">
                        {displayData.target_groups[selectedAmount]?.key_benefits?.map((benefit, idx) => (
                          <li key={idx} className="text-sm flex items-center gap-2">
                            <CheckCircle className="h-4 w-4 text-green-500" />
                            {benefit}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* Target Groups Visualization */}
              <div className="space-y-6 border border-green-200 rounded-lg p-6">
                <div className="flex justify-around gap-2 items-center">
                  {displayData.target_groups.map((group, index) => (
                    <div key={index} className="flex flex-col items-center gap-1">
                      <Button
                        variant={selectedAmount === index ? "contained" : "outlined"}
                        onClick={() => handleChangeGroup(index)}
                        className="min-w-[60px]"
                        title='Feasibility Score reflects how suitable this target group is based on market size, ease of implementation, readiness to adopt, and ROI potential. Higher scores indicate better overall fit.'
                      >
                        {index + 1}
                        {index === selectedAmount && (
                          <CheckCircle className="ml-1 text-green-500" size={14} />
                        )}
                      </Button>
                      <span className={`text-sm font-medium ${getAverageScoreColor(calculateAverage(getGroupsForSize(index)))}`}>
                        Avg: {calculateAverage(getGroupsForSize(index))}%
                      </span>
                    </div>
                  ))}
                </div>

                <div className="space-y-2">
                  {getGroupsForSize(selectedAmount).map((group) => (
                    <div key={group.name} className="space-y-1">
                      <div className="flex justify-between text-sm">
                        <span className="font-medium flex items-center gap-2">
                          {group.name}
                          <span className="text-gray-500">
                            {group?.group_size?.market_size ? (typeof group?.group_size?.market_size === 'number' ? group.group_size.market_size.toLocaleString() : group.group_size.market_size ): ''}
                          </span>
                        </span>
                        <span>{group.score}%</span>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2.5">
                        <div
                          className={`h-2.5 rounded-full ${getFeasibilityColor(group.score)}`}
                          style={{ width: `${group.score}%` }}
                        ></div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Group Size Considerations */}
                <div className="grid grid-cols-2 gap-4">
                  <div className="p-4 rounded-lg" style={{backgroundColor:'var(--card-bg)'}}>
                    <h4 className="font-medium mb-3">Advantages of {selectedAmount + 1} Groups</h4>
                    <ul className="space-y-2">
                      {selectedGroupData?.group_size?.advantages?.map((advantage, idx) => (
                        <li key={idx} className="text-sm flex items-center gap-2">
                          <CheckCircle className="h-4 w-4 text-green-500" />
                          {advantage}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="p-4 rounded-lg" style={{backgroundColor:'var(--card-bg)'}}>
                    <h4 className="font-medium mb-3">Considerations</h4>
                    <ul className="space-y-2">
                      {selectedGroupData?.group_size?.considerations?.map((consideration, idx) => (
                        <li key={idx} className="text-sm flex items-center gap-2">
                          <ArrowRight className="h-4 w-4 text-gray-500" />
                          {consideration}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              {/* Score Legend */}
              <div className="text-sm text-gray-500 border border-green-200 rounded-lg p-6">
                <div>Feasibility Score Legend:</div>
                <div className="flex flex-wrap justify-around gap-4 mt-2">
                  <span className="flex items-center">
                    <span className="w-3 h-3 rounded-full bg-green-500 mr-2"></span>
                    High (80-100%)
                  </span>
                  <span className="flex items-center">
                    <span className="w-3 h-3 rounded-full bg-yellow-500 mr-2"></span>
                    Medium (70-79%)
                  </span>
                  <span className="flex items-center">
                    <span className="w-3 h-3 rounded-full bg-red-500 mr-2"></span>
                    Low (60-69%)
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </CardContent>
    </Card>

    <ViewFooter
      isDisabled={!sessionId} 
      showButton={!!selectedGoal}
      onComplete={handleContinue}
      buttonText={process.footer.continueText}
      processId="glow_33"
    />
    </>
  );
};

export default GoalBasedAnalysis;