import React from 'react';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { loadingGlowStatus, selectGlowStatus, selectSessionStatus } from '../session/sessionSlide';

/**
 * Standardized footer component for all process viewers
 * Ensures consistent handling of states and transitions
 */
const ViewFooter = ({ 
  processId, 
  onComplete, 
  onBack,
  isCompleteDisabled = false,
  isComplete = () => true,
  completeButtonText = "Continue",
  backButtonText = "Back",
  showBackButton = true,
  showCompleteButton = true,
  loadingStates = {},
  useCustomFooter = false,
  children = null
}) => {
  // Get the global session status
  const sessionStatus = useSelector(selectSessionStatus);
  const processStatus = useSelector(state => selectGlowStatus(state, processId));
  
  
  // Use loadingProcesses as the primary name
  const loadingProcesses = useSelector((state) => loadingGlowStatus(state)[processId] || false);
  
  // Check if this process is in loading state
  const isProcessLoading = loadingProcesses;
  
  // Combine disabled conditions
  const isDisabled = isProcessLoading || isCompleteDisabled || !isComplete();
  
  // Standardized button behavior to ensure consistent UX
  const handleComplete = () => {
    if (isDisabled) return;
    
    // This is the standard pattern for completing a process
    // The onComplete will typically:
    // 1. Call updateProcessState(processId, 'completed', { nextProcess: nextProcessId })
    // 2. Handle nextStep navigation
    if (onComplete) onComplete();
  };

  return useCustomFooter ? (
    children
  ) : (
    <div
      style={{ 
        padding: '16px',
        borderTop: '1px solid #e0e0e0',
        marginTop: 'auto',
        backgroundColor: 'var(--color-surface)',
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
        alignItems: 'center',
      }}
    >
      {/* Left slot - Back button */}
      <div style={{ justifySelf: 'start' }}>
        {showBackButton && onBack && (
          <Button 
            variant="outlined" 
            color="inherit" 
            onClick={onBack}
            disabled={isProcessLoading}
          >
            {backButtonText}
          </Button>
        )}
      </div>
      
      {/* Center slot - Continue button */}
      <div style={{ justifySelf: 'center' }}>
        {showCompleteButton && (
          <Button 
            variant="contained"
            color="primary" 
            onClick={handleComplete}
            disabled={isDisabled}
            style={{ 
              backgroundColor: '#FD9800',
              color: 'white',
              minWidth: '120px'
            }}
          >
            {loadingProcesses[processId] && isProcessLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              completeButtonText
            )}
          </Button>
        )}
      </div>
      
      {/* Right slot - Empty to balance the layout */}
      <div style={{ justifySelf: 'end' }}>
        {/* This space intentionally left empty for layout balance */}
      </div>
    </div>
  );
};

export default ViewFooter;