import React, { useState, useEffect } from 'react';
import { CheckCircle } from 'lucide-react';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const EnterpriseForm = ({ activeSection }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    role: '',
    message: '',
    videoType: activeSection === 'business' ? 'business' : 'content',
    monthlyContent: ''
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState('');
  const [isDarkTheme, setIsDarkTheme] = useState(() => {
    const savedTheme = localStorage.getItem('mui-mode');
    return savedTheme ? savedTheme === 'dark' : true; // Default to dark if not set
  });

  // Listen for theme changes
  useEffect(() => {
    const handleThemeChange = () => {
      const currentTheme = localStorage.getItem('mui-mode');
      setIsDarkTheme(currentTheme === 'dark');
    };

    window.addEventListener('storage', handleThemeChange);
    document.addEventListener('themeChange', handleThemeChange);

    return () => {
      window.removeEventListener('storage', handleThemeChange);
      document.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setFormError('');
    
    // Format data to match the API schema
    const requestData = {
      name: formData.name,
      business_email: formData.email,
      company: formData.company || "",
      role: formData.role || "",
      video_content_type: formData.videoType || (activeSection === 'business' ? "business" : "content"),
      tell_us_about_your_video_testing_needs: formData.message,
      monthly_content_volume: formData.monthlyContent || ""
    };

    console.log('Submitting form to:', `${API_BASE_URL}/api/v1/user/request_video_testing`);
    console.log('Form data:', requestData);

    try {
      // 1. Send data to the backend API
      const apiResponse = await fetch(`${API_BASE_URL}/api/v1/user/request_video_testing`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      console.log('API Response status:', apiResponse.status);
      
      if (!apiResponse.ok) {
        console.error('API response not OK:', await apiResponse.text());
        throw new Error('Network response was not ok');
      }

      const data = await apiResponse.json();
      console.log('API response data:', data);
      
      // 2. Try to send email via Firebase function (don't block form submission if it fails)
      try {
        // Skip email sending in development environment to avoid CORS issues
        const isDevelopment = window.location.hostname === 'localhost' || 
                             window.location.hostname === '127.0.0.1';
        
        if (!isDevelopment) {
          const emailContent = `
            New Enterprise Form Submission:
            
            Name: ${formData.name}
            Email: ${formData.email}
            Company: ${formData.company || "Not provided"}
            Role: ${formData.role || "Not provided"}
            Type: ${formData.videoType || (activeSection === 'business' ? "Business" : "Content")}
            Monthly Volume: ${formData.monthlyContent || "Not specified"}
            
            Message:
            ${formData.message}
          `;

          // Firebase function endpoint
          const firebaseEndpoint = "https://us-central1-glowtest-fe.cloudfunctions.net/sendEmail";
          
          const emailResponse = await fetch(firebaseEndpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: formData.email,
              message: emailContent
            }),
          });

          console.log('Email sending response:', emailResponse.status);
        } else {
          console.log('Skipping email sending in development environment');
        }
      } catch (emailError) {
        // Log but don't block form submission
        console.warn('Email sending failed, but form was submitted:', emailError);
      }
      
      if (data.status_code === 200) {
        setFormSubmitted(true);
        // Reset form data
        setFormData({
          name: '',
          email: '',
          company: '',
          role: '',
          message: '',
          videoType: activeSection === 'business' ? 'business' : 'content',
          monthlyContent: ''
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setFormError('There was an error submitting your request. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Enhanced select styles for better compatibility
  const selectStyles = {
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
    backgroundImage: 'url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FD9800%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 0.7rem top 50%',
    backgroundSize: '0.65rem auto',
    paddingRight: '1.5rem',
    backgroundColor: isDarkTheme ? '#111827' : '#1f2937', // Force dark background
    color: '#ffffff !important',
    borderColor: isDarkTheme ? '#4b5563' : '#374151'
  };

  // Override for each option
  const optionStyles = {
    backgroundColor: isDarkTheme ? '#111827' : '#1f2937',
    color: '#ffffff'
  };

  // Use inline CSS to force option styles
  const injectSelectFix = () => {
    // This adds a <style> tag to force select option styles
    return (
      <style jsx="true">{`
        select option {
          background-color: #111827 !important;
          color: white !important;
        }
        select {
          color: white !important;
        }
        /* Force the dropdown background in all browsers */
        @-moz-document url-prefix() {
          select {
            background-color: #111827 !important;
            color: #ffffff !important;
          }
          select option {
            background-color: #111827 !important;
            color: #ffffff !important;
          }
        }
      `}</style>
    );
  };

  return (
    <section id="enterprise-form" className="py-6 sm:py-10 md:py-16 mb-8 sm:mb-12 md:mb-20 bg-gradient-to-r from-orange-500/20 to-amber-500/20 rounded-lg sm:rounded-xl md:rounded-2xl px-3 sm:px-4" aria-labelledby="enterprise-form-title">
      {injectSelectFix()}
      <div className="max-w-4xl mx-auto">
        <h2 id="enterprise-form-title" className="text-xl sm:text-2xl md:text-4xl font-bold mb-3 sm:mb-4 md:mb-6 text-center">
          {activeSection === 'business' 
            ? "Ready to Validate Your Business Ideas?" 
            : "Ready to Transform Your Content Strategy?"}
        </h2>
        <p className="text-sm sm:text-base md:text-xl text-zinc-300 mb-4 sm:mb-6 md:mb-8 text-center">
          Get in touch with us to discuss your specific needs
        </p>

        {formSubmitted ? (
          <div className="text-center bg-zinc-900/30 p-4 sm:p-6 md:p-8 rounded-lg sm:rounded-xl max-w-2xl mx-auto">
            <CheckCircle size={40} className="mx-auto mb-3 sm:mb-4 md:mb-6 text-orange-500" />
            <h3 className="text-lg sm:text-xl md:text-2xl font-bold mb-2 text-white">Thank You!</h3>
            <p className="text-zinc-300 text-sm sm:text-base md:text-xl">Your request has been successfully sent. We'll be in touch with you shortly.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="max-w-2xl mx-auto bg-zinc-900/30 p-4 sm:p-6 md:p-8 rounded-lg sm:rounded-xl" aria-label="Contact form">
            {formError && (
              <div className="mb-4 sm:mb-6 p-2 sm:p-3 bg-red-500/20 border border-red-500 rounded-lg text-red-200 text-xs sm:text-sm">
                {formError}
              </div>
            )}
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 sm:gap-4 md:gap-6 mb-4 sm:mb-6">
              <div>
                <label htmlFor="name" className="block text-xs sm:text-sm font-medium text-zinc-400 mb-1 sm:mb-2">
                  Name<span className="text-orange-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={(e) => setFormData({...formData, name: e.target.value})}
                  className="w-full px-3 sm:px-4 py-2 sm:py-3 border border-zinc-700 rounded-lg focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500 text-white text-sm"
                  style={{ backgroundColor: isDarkTheme ? '#111827' : '#1f2937' }}
                  required
                  aria-required="true"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-xs sm:text-sm font-medium text-zinc-400 mb-1 sm:mb-2">
                  Email<span className="text-orange-500 ml-1">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={(e) => setFormData({...formData, email: e.target.value})}
                  className="w-full px-3 sm:px-4 py-2 sm:py-3 border border-zinc-700 rounded-lg focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500 text-white text-sm"
                  style={{ backgroundColor: isDarkTheme ? '#111827' : '#1f2937' }}
                  required
                  aria-required="true"
                />
              </div>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 sm:gap-4 md:gap-6 mb-4 sm:mb-6">
              <div>
                <label htmlFor="company" className="block text-xs sm:text-sm font-medium text-zinc-400 mb-1 sm:mb-2">
                  Company
                </label>
                <input
                  type="text"
                  id="company"
                  value={formData.company}
                  onChange={(e) => setFormData({...formData, company: e.target.value})}
                  className="w-full px-3 sm:px-4 py-2 sm:py-3 border border-zinc-700 rounded-lg focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500 text-white text-sm"
                  style={{ backgroundColor: isDarkTheme ? '#111827' : '#1f2937' }}
                />
              </div>
              <div>
                <label htmlFor="role" className="block text-xs sm:text-sm font-medium text-zinc-400 mb-1 sm:mb-2">
                  Role
                </label>
                <input
                  type="text"
                  id="role"
                  value={formData.role}
                  onChange={(e) => setFormData({...formData, role: e.target.value})}
                  className="w-full px-3 sm:px-4 py-2 sm:py-3 border border-zinc-700 rounded-lg focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500 text-white text-sm"
                  style={{ backgroundColor: isDarkTheme ? '#111827' : '#1f2937' }}
                />
              </div>
            </div>
            
            <div className="mb-4 sm:mb-6">
              <label htmlFor="videoType" className="block text-xs sm:text-sm font-medium text-zinc-400 mb-1 sm:mb-2">
                Video Content Type<span className="text-orange-500 ml-1">*</span>
              </label>
              <select
                id="videoType"
                value={formData.videoType}
                onChange={(e) => setFormData({...formData, videoType: e.target.value})}
                className="w-full px-3 sm:px-4 py-2 sm:py-3 border border-zinc-700 rounded-lg focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500 text-white text-sm"
                style={selectStyles}
                required
                aria-required="true"
              >
                {activeSection === 'business' ? (
                  <>
                    <option value="business">Business Validation Video</option>
                    <option value="investor">Investor Pitch Video</option>
                    <option value="product">Product Demo Video</option>
                  </>
                ) : activeSection === 'creators' ? (
                  <>
                    <option value="content">Social Media Content</option>
                    <option value="educational">Educational Content</option>
                    <option value="marketing">Marketing Video</option>
                  </>
                ) : (
                  <>
                    <option value="training">Training Materials</option>
                    <option value="presentations">Presentation Videos</option>
                    <option value="updates">Executive Updates</option>
                  </>
                )}
              </select>
            </div>
            
            <div className="mb-4 sm:mb-6">
              <label htmlFor="monthlyContent" className="block text-xs sm:text-sm font-medium text-zinc-400 mb-1 sm:mb-2">
                Approximate Monthly Video Content Volume
              </label>
              <select
                id="monthlyContent"
                value={formData.monthlyContent}
                onChange={(e) => setFormData({...formData, monthlyContent: e.target.value})}
                className="w-full px-3 sm:px-4 py-2 sm:py-3 border border-zinc-700 rounded-lg focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500 text-white text-sm"
                style={selectStyles}
              >
                <option value="">Please select...</option>
                <option value="1-5">1-5 videos per month</option>
                <option value="6-15">6-15 videos per month</option>
                <option value="16-30">16-30 videos per month</option>
                <option value="31+">31+ videos per month</option>
              </select>
            </div>
            
            <div className="mb-4 sm:mb-6">
              <label htmlFor="message" className="block text-xs sm:text-sm font-medium text-zinc-400 mb-1 sm:mb-2">
                Tell us about your video testing needs<span className="text-orange-500 ml-1">*</span>
              </label>
              <textarea
                id="message"
                value={formData.message}
                onChange={(e) => setFormData({...formData, message: e.target.value})}
                rows="4"
                className="w-full px-3 sm:px-4 py-2 sm:py-3 border border-zinc-700 rounded-lg focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500 text-white text-sm"
                style={{ backgroundColor: isDarkTheme ? '#111827' : '#1f2937' }}
                required
                aria-required="true"
              ></textarea>
            </div>
            
            <button
              type="submit"
              className="w-full bg-orange-500 hover:bg-orange-600 text-white font-medium py-2 sm:py-3 px-4 rounded-lg transition-colors duration-200 flex items-center justify-center text-sm"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit Request'}
            </button>
          </form>
        )}
      </div>
    </section>
  );
};

export default EnterpriseForm;