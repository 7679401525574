import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import Cookies from 'js-cookie';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const initialState = {
  selected: null,
  companies: [],
  message: '',
  status: 'idle',
}

function validateCompanyData(companyData) {
  if (!companyData || typeof companyData !== 'object') {
    throw new Error('Invalid companyData: must be an object');
  }

  const { company_id, company_name, company_description } = companyData;

  if (company_id && (typeof company_id !== 'string' || company_id.trim() === '')) {
    throw new Error('Invalid company_id: must be a non-empty string');
  }

  if (typeof company_name !== 'string' || company_name.trim() === '') {
    throw new Error('Invalid company_name: must be a non-empty string');
  }

  if (company_description && (typeof company_description !== 'string' || company_description.trim() === '')) {
    throw new Error('Invalid company_description: must be a string');
  }
}

export const fetchCompanyList = createAsyncThunk(
  'company/fetchList',
  async (_, { getState, rejectWithValue }) => {
    const state = getState();
    if (state.companies.status === 'loading') {
      return rejectWithValue('Request already in progress');
    }
    try {
      const token = Cookies.get('token');
      if (!token) {
        return rejectWithValue("Token is required");
      }
      const response = await axios.get(`${API_BASE_URL}/api/v1/company_project/list`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status_code === 200 && response.data.data.company_list) {
        return response.data.data.company_list;
      } else {
        return rejectWithValue('Company list not found or status code is not 200');
      }
    } catch (error) {
      console.log('error', error);
      // Something else went wrong
      return rejectWithValue(error.message);
    }
  });

export const createCompany = createAsyncThunk(
  'company/create',
  async (companyData, { rejectWithValue }) => {
    try {
      validateCompanyData(companyData)      
      const token = Cookies.get('token');
      if (!token) {
        return rejectWithValue("Token is required");
      }
      const response = await axios.post(`${API_BASE_URL}/api/v1/company_project/create`, companyData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data.status && response.data.data && response.data.data.company_id) {
        companyData.company_id = response.data.data.company_id;
        return companyData;
      } else {
        return rejectWithValue('Failed to create company: ' + (response.data.message || 'Unknown error'));
      }
    } catch (error) {
      console.log('error', error);
      // Something else went wrong
      return rejectWithValue(error.message);
    }
  });
  
// Update a company by ID
export const updateCompany = createAsyncThunk(
  'company/update',
  async (updatedData, { rejectWithValue }) => {
    try {
      validateCompanyData(updatedData)
      const token = Cookies.get('token');
      if (!token) {
        return rejectWithValue("Token is required");
      }
      const response = await axios.post(`${API_BASE_URL}/api/v1/company_project/edit`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status && response.data.data && response.data.data.company_id) {        
        return updatedData;
      } else {
        return rejectWithValue('Failed to update company: ' + (response.data.message || 'Unknown error'));
      }
    } catch (error) {
      console.log('error', error);
      // Something else went wrong
      return rejectWithValue(error.message);
    }
  });

const companiesSlide = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    companyAdded(state, action) {
      state.companies.push(action.payload);
    },
    companySelected(state, action) {
      const companyExists = state.companies.some(company => company.company_id === action.payload);
      if (companyExists) {
        state.selected = action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyList.pending, (state, action) => {
      state.status = 'pending'
    })
    builder.addCase(fetchCompanyList.fulfilled, (state, action) => {
      state.status = 'success';
      state.companies = action.payload
    })
    builder.addCase(fetchCompanyList.rejected, (state, action) => {
      state.status = 'error';
      state.message = action.payload; // Handle the error message
    })
    builder.addCase(createCompany.pending, (state, action) => {
      state.status = 'pending'
    })
    builder.addCase(createCompany.fulfilled, (state, action) => {
      state.status = 'success';
      state.companies.push(action.payload)
      console.log('success', action.payload);
    })
    builder.addCase(createCompany.rejected, (state, action) => {
      state.status = 'error';
      state.message = action.payload; // Handle the error message
    })
    builder.addCase(updateCompany.pending, (state, action) => {
      state.status = 'pending'
    })
    builder.addCase(updateCompany.fulfilled, (state, action) => {
      state.status = 'success';
      const index = state.companies.findIndex(company => company.company_id === action.payload.company_id);
      if (index !== -1) {
        state.companies[index] = action.payload; // Update the existing company with the new data
      }
      console.log('success', action.payload);
    })
    builder.addCase(updateCompany.rejected, (state, action) => {
      state.status = 'error';
      state.message = action.payload; // Handle the error message
      console.log('error', action.payload);
    })
  }
})

export const { companyAdded, companySelected, companyToggled, companiesLoading } = companiesSlide.actions

export const selectAllCompanies = (state) => state.companies.companies
export const selectSelectedCompany = (state) => state.companies.selected
export const selectSelectedCompanyName = (state) => {
  const selectedCompanyId = state.companies.selected;
  const selectedCompany = state.companies.companies.find(company => company.company_id === selectedCompanyId);
  return selectedCompany ? selectedCompany.company_name : null;
};
export const selectCompaniesStatus = (state) => state.companies.status
export const selectCompaniesMessage = (state) => state.companies.message

export default companiesSlide.reducer