import React, { useState, useEffect, useMemo } from 'react';
import { 
  Paper, 
  Typography, 
  Box, 
  CircularProgress, 
  Divider, 
  Chip, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  Alert,
  Tooltip
} from '@mui/material';
import { 
  ChevronDown, 
  Clock, 
  DollarSign, 
  Image, 
  FileVideo, 
  Headphones, 
  Info,
  AlertTriangle,
  Layers
} from 'lucide-react';

// Import the calculator utility
import { 
  calculateVideoMetrics, 
  formatCost, 
  formatProcessingTime,
  formatCredits
} from '../../utils/videoMetricsCalculator';

/**
 * Component to display estimated cost and time metrics for video generation
 */
const VideoMetricsDisplay = ({ 
  providerConfig,
  requestParams,
  isLoading = false,
  onMetricsUpdate
}) => {
  const [error, setError] = useState(null);

  // Memoize the metrics calculation to prevent unnecessary recalculations
  const metrics = useMemo(() => {
    if (!providerConfig || !requestParams) {
      return null;
    }

    try {
      // Split the provider config into separate config objects
      const configs = {
        image: providerConfig,
        animation: providerConfig,
        voiceover: providerConfig
      };
      
      // Calculate metrics
      const calculatedMetrics = calculateVideoMetrics(configs, requestParams);
      setError(null);
      return calculatedMetrics;
    } catch (err) {
      console.error('Error calculating video metrics:', err);
      setError('Failed to calculate video metrics');
      return null;
    }
  }, [providerConfig, requestParams]);

  // Report metrics back to parent
  useEffect(() => {
    if (metrics && typeof onMetricsUpdate === 'function') {
      onMetricsUpdate(metrics);
    }
  }, [metrics, onMetricsUpdate]);

  if (isLoading) {
    return (
      <Paper elevation={1} sx={{ p: 3, mb: 3, textAlign: 'center' }}>
        <CircularProgress size={40} sx={{ mb: 2 }} />
        <Typography>Loading cost estimates...</Typography>
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
        <Typography variant="body2" color="text.secondary">
          Please check your configuration and try again.
        </Typography>
      </Paper>
    );
  }

  if (!metrics) {
    return null;
  }

  const imageMetadata = metrics.breakdown.textToImage.metadata || {};
  const animationMetadata = metrics.breakdown.imageToAnimation.metadata || {};
  const voiceoverMetadata = metrics.breakdown.textToVoiceover.metadata || {};

  // Use any available metadata (they should all have the same basic info)
  const metadata = imageMetadata || animationMetadata || voiceoverMetadata || {};
  
  return (
    <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <DollarSign size={20} />
        Cost & Processing Estimates
      </Typography>
      
      {/* Add Shot Configuration Information Section */}
      {metadata && metadata.totalShots > 0 && (
        <Box sx={{ mb: 3 }}>
          <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
              <Layers size={16} />
              Automatic Shot Configuration
              <Tooltip title="These parameters are automatically determined based on video duration">
                <Info size={16} style={{ marginLeft: 4, cursor: 'help' }} />
              </Tooltip>
            </Typography>
            
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
              <Chip 
                label={`${metadata.secondsPerShot}s per shot`} 
                size="small"
                variant="outlined"
              />
              <Chip 
                label={`${metadata.shotsPerScene} shots per scene`} 
                size="small"
                variant="outlined"
              />
              <Chip 
                label={`${metadata.scenesPerVoiceover} scenes per voiceover`} 
                size="small"
                variant="outlined"
              />
            </Box>
            
            <Box sx={{ p: 1.5, bgcolor: 'action.hover', borderRadius: 1 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                For a {metadata.totalDuration}s video with {metadata.secondsPerShot}s per shot:
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                <Chip 
                  label={`${metadata.totalShots} shots total`} 
                  size="small"
                  color="primary"
                  variant="outlined" 
                />
                <Chip 
                  label={`${metadata.totalScenes} scenes`} 
                  size="small"
                  variant="outlined" 
                />
                <Chip 
                  label={`${metadata.totalVoiceoverSegments} voice segments`} 
                  size="small"
                  variant="outlined" 
                />
              </Box>
            </Box>
          </Paper>
        </Box>
      )}
      
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mb: 3 }}>
        <Paper variant="outlined" sx={{ flex: 1, p: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">Estimated Cost</Typography>
          <Typography variant="h4" sx={{ color: '#FD9800', fontWeight: 'bold' }}>
            {formatCost(metrics.yourCostUSD)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {formatCredits(metrics.userCostCredits)}
          </Typography>
        </Paper>
        
        <Paper variant="outlined" sx={{ flex: 1, p: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">Processing Time</Typography>
          <Typography variant="h5" sx={{ fontWeight: 'medium' }}>
            {formatProcessingTime(metrics.totalProcessingTimeSec)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Estimated generation time
          </Typography>
        </Paper>
      </Box>
      
      {metadata && metadata.totalShots > 0 && (
        <Box sx={{ mb: 3 }}>
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
              <Layers size={16} />
              Video Structure
            </Typography>
            
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
              <Tooltip title="Total video duration in seconds">
                <Chip 
                  label={`${metadata.totalDuration}s total duration`} 
                  size="small"
                  variant="outlined"
                />
              </Tooltip>
              
              <Tooltip title="Each shot is a distinct image that gets animated">
                <Chip 
                  label={`${metadata.totalShots} shots (${metadata.secondsPerShot}s each)`} 
                  size="small"
                  variant="outlined" 
                  color="primary"
                />
              </Tooltip>
              
              <Tooltip title="Scenes group multiple shots together, often with the same voiceover">
                <Chip 
                  label={`${metadata.totalScenes} scenes`} 
                  size="small"
                  variant="outlined"
                />
              </Tooltip>
              
              <Tooltip title="Voiceover segments may span multiple scenes">
                <Chip 
                  label={`${metadata.totalVoiceoverSegments} voice segments`} 
                  size="small"
                  variant="outlined"
                />
              </Tooltip>
            </Box>
          </Paper>
        </Box>
      )}
      
      <Divider sx={{ my: 2 }} />
      
      <Typography variant="subtitle1" sx={{ mb: 2 }}>Cost Breakdown</Typography>
      
      {/* Text to Image */}
      <Accordion sx={{ mb: 1 }}>
        <AccordionSummary expandIcon={<ChevronDown size={20} />}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', pr: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Image size={16} />
              <Typography>
                Text to Image
                {imageMetadata && imageMetadata.totalShots > 0 && 
                  ` (${imageMetadata.totalShots} shots)`}
              </Typography>
            </Box>
            <Typography>{formatCost(metrics.breakdown.textToImage.yourCostUSD)}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>          
          {imageMetadata && imageMetadata.totalShots > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2" color="text.secondary">Cost Calculation</Typography>
              <Typography variant="body2">
                {imageMetadata.totalShots} shots × cost per image
              </Typography>
            </Box>
          )}
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body2" color="text.secondary">Processing Time</Typography>
            <Typography variant="body2">{formatProcessingTime(metrics.breakdown.textToImage.processingTimeSec)}</Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      
      {/* Image to Animation */}
      <Accordion sx={{ mb: 1 }}>
        <AccordionSummary expandIcon={<ChevronDown size={20} />}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', pr: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <FileVideo size={16} />
              <Typography>
                Image to Animation
                {animationMetadata && 
                  ` (${animationMetadata.totalDuration}s)`}
              </Typography>
            </Box>
            <Typography>{formatCost(metrics.breakdown.imageToAnimation.yourCostUSD)}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>          
          {animationMetadata && animationMetadata.totalDuration > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2" color="text.secondary">Cost Calculation</Typography>
              <Typography variant="body2">
                {animationMetadata.totalDuration} seconds × cost per second
              </Typography>
            </Box>
          )}
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body2" color="text.secondary">Processing Time</Typography>
            <Typography variant="body2">{formatProcessingTime(metrics.breakdown.imageToAnimation.processingTimeSec)}</Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      
      {/* Text to Voiceover */}
      <Accordion sx={{ mb: 1 }}>
        <AccordionSummary expandIcon={<ChevronDown size={20} />}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', pr: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Headphones size={16} />
              <Typography>
                Text to Voiceover
                {voiceoverMetadata && voiceoverMetadata.totalVoiceoverSegments > 0 && 
                  ` (${voiceoverMetadata.totalVoiceoverSegments} segments)`}
              </Typography>
            </Box>
            <Typography>{formatCost(metrics.breakdown.textToVoiceover.yourCostUSD)}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>          
          {voiceoverMetadata && voiceoverMetadata.totalVoiceoverSegments > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2" color="text.secondary">Cost Calculation</Typography>
              <Typography variant="body2">
                {voiceoverMetadata.totalDuration} seconds audio × cost per second
                {voiceoverMetadata.totalVoiceoverSegments > 1 && 
                  ` (${voiceoverMetadata.totalVoiceoverSegments} segments)`}
              </Typography>
            </Box>
          )}
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body2" color="text.secondary">Processing Time</Typography>
            <Typography variant="body2">{formatProcessingTime(metrics.breakdown.textToVoiceover.processingTimeSec)}</Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      
      <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 2, fontStyle: 'italic' }}>
        Estimates based on current provider pricing and configurations. 
      </Typography>
    </Paper>
  );
};

export default VideoMetricsDisplay;