import { useEffect, useState, useCallback, useRef } from 'react';
import Cookies from 'js-cookie';
import { TextField, Box, Button, useTheme, Card, CardContent, Typography, FormControl, InputLabel, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Divider, List, ListItem, ListItemText, Chip, CircularProgress, IconButton } from '@mui/material';
import { FaPlay, FaChartLine, FaExclamationTriangle, FaBuilding, FaPlus, FaSync, FaChevronDown, FaChevronUp, FaCalendarAlt, FaVideo, FaEdit } from 'react-icons/fa';
import { Play, VideoIcon, ArrowRight } from 'lucide-react';
import ViewerHeader from './common/ViewerHeader';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCompanyList, companySelected, createCompany, selectAllCompanies, selectCompaniesStatus, selectSelectedCompany} from './company/companySlide';
import { runProcess } from './session/sessionSlide';
import axios from 'axios';

const IdeaInputCard = ({ 
  inputText, 
  setInputText, 
  hasUnsavedChanges,
  setHasUnsavedChanges,
  onRun,
  config,
  isDarkTheme,
  sessionData = {},
  onFetchSessionList = () => {},
  glowData = {}
}) => {
    
  const theme = useTheme();
  const [isNewCompanyDialogOpen, setIsNewCompanyDialogOpen] = useState(false);
  const [newCompanyName, setNewCompanyName] = useState('');
  const dispatch = useDispatch()  
  const selectedCompany = useSelector(selectSelectedCompany) || ''
  const companyStatus = useSelector(selectCompaniesStatus)
  const companies = useSelector(selectAllCompanies)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showButtonPulse, setShowButtonPulse] = useState(false);
  const [highlightedSection, setHighlightedSection] = useState(null);
  const [sessionsExpanded, setSessionsExpanded] = useState(true);
  const [isLoadingSessions, setIsLoadingSessions] = useState(false);
  const [localSessionData, setLocalSessionData] = useState(null);
  const [sessionDetails, setSessionDetails] = useState({});
  const [loadingSessionDetails, setLoadingSessionDetails] = useState({});
  const [promptDialogOpen, setPromptDialogOpen] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState('');
  
  // Use a ref to track which session details we've already fetched
  const fetchedSessionsRef = useRef({});

  // Fetch session details for a specific session
  const fetchSessionDetails = useCallback(async (sessionId) => {
    // Check if we've already fetched this session or are currently fetching it
    if (fetchedSessionsRef.current[sessionId]) return;
    
    // Mark as fetched to prevent duplicate requests
    fetchedSessionsRef.current[sessionId] = true;
    setLoadingSessionDetails(prev => ({ ...prev, [sessionId]: true }));
    
    try {
      const token = Cookies.get('token');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL || ''}/api/v1/session/get_session_details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ session_id: sessionId })
      });
      
      const data = await response.json();
      
      if (data.status) {
        console.log(`Session details for ${sessionId}:`, data);
        setSessionDetails(prev => ({ 
          ...prev, 
          [sessionId]: data.data 
        }));
      } else {
        console.error('Failed to fetch session details:', data.message);
      }
    } catch (error) {
      console.error('Error fetching session details:', error);
    } finally {
      setLoadingSessionDetails(prev => ({ ...prev, [sessionId]: false }));
    }
  }, []); // Remove sessionDetails dependency

  // Add a pulsing effect to the button periodically when no input
  useEffect(() => {
    if (inputText.trim() === '') {
      // Pulse every 5 seconds for 2 seconds
      const intervalId = setInterval(() => {
        setShowButtonPulse(true);
        setTimeout(() => setShowButtonPulse(false), 2000);
      }, 7000);
      
      // Initial pulse after 2 seconds
      const initialTimeoutId = setTimeout(() => {
        setShowButtonPulse(true);
        setTimeout(() => setShowButtonPulse(false), 2000);
      }, 2000);
      
      return () => {
        clearInterval(intervalId);
        clearTimeout(initialTimeoutId);
      };
    }
  }, [inputText]);

  // Add event listener for highlighting sections
  useEffect(() => {
    const handleHighlightSection = (event) => {
      const { section } = event.detail;
      if (section) {
        // Set state to highlight the section
        setHighlightedSection(section);
      }
    };

    const handleResetHighlight = () => {
      setHighlightedSection(null);
    };

    window.addEventListener('highlight-stepper-section', handleHighlightSection);
    window.addEventListener('reset-stepper-highlight', handleResetHighlight);
    
    return () => {
      window.removeEventListener('highlight-stepper-section', handleHighlightSection);
      window.removeEventListener('reset-stepper-highlight', handleResetHighlight);
    };
  }, []);

  // Auto-expand sessions list if there are only a few sessions
  useEffect(() => {
    const sessions = sessionData?.data?.sessions || [];
    if (sessions.length > 0 && sessions.length <= 5) {
      setSessionsExpanded(true);
    }
  }, [sessionData]);

  // Fetch session details when sessions are loaded
  useEffect(() => {
    const sessions = localSessionData?.data?.sessions || sessionData?.data?.sessions || [];
    if (sessions.length > 0) {
      // Only fetch details for sessions we haven't fetched yet
      sessions.forEach(session => {
        if (!fetchedSessionsRef.current[session.session_id]) {
          fetchSessionDetails(session.session_id);
        }
      });
    }
  }, [localSessionData, sessionData, fetchSessionDetails]);

  // Use a ref to track API calls
  const glow61ApiCalledRef = useRef(false);

  // Add effect to preload glow_61 when user starts typing
  useEffect(() => {
    const preloadGlow61 = async () => {
      // Only preload if:
      // 1. We have input text
      // 2. We haven't called the API yet
      // 3. We don't have the data in Redux
      if (
        inputText.trim().length > 0 && 
        !glow61ApiCalledRef.current && 
        (!glowData?.['glow_61'] || !glowData?.['glow_61']?.config)
      ) {
        try {
          // Mark that we're making the API call
          glow61ApiCalledRef.current = true;

          // Preload glow_61 configuration by directly calling the text-to-image config API
          const token = Cookies.get('token');
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/config/get_text_to_image_config`,
            {
              headers: { 
                Authorization: `Bearer ${token}`,
                accept: 'application/json'
              }
            }
          );

          // Store in Redux for immediate access
          if (response?.data?.data) {
            dispatch({
              type: 'session/updateGlowStep',
              payload: {
                stepId: 'glow_61',
                data: {
                  glow_61_response: response.data.data,
                  config: response.data.data,
                  cached: true
                }
              }
            });
            console.log('Cached glow_61 configuration from text-to-image config API');
          }
        } catch (error) {
          // Reset the ref on error so we can try again
          glow61ApiCalledRef.current = false;
          console.error('Error in glow_61 preload:', error);
        }
      }
    };

    // Use a debounced version to avoid too many calls
    const timeoutId = setTimeout(preloadGlow61, 1000);
    return () => clearTimeout(timeoutId);
  }, [inputText, dispatch]); // Remove glowData from dependencies

  const handleCompanyChange = (event) => {
    if (event.target.value === 'add_new') {
      setIsNewCompanyDialogOpen(true);
      setIsSubmitting(false);
    } else {
      dispatch(companySelected(event.target.value))
      setHasUnsavedChanges(true);
    }
  };

  const handleAddNewCompany = async () => {
    if (newCompanyName.trim() && !isSubmitting) {
      const companyNameToCreate = newCompanyName.trim();
      // Close the dialog immediately for better UX
      setIsNewCompanyDialogOpen(false);
      setIsSubmitting(true);
      
      // Create a temporary ID for optimistic updates
      const tempId = `temp-${Date.now()}`;
      
      // Optimistically add the company to the UI
      // We'll create a temporary company object
      const tempCompany = {
        company_id: tempId,
        company_name: companyNameToCreate
      };
      
      // Add to Redux store immediately for instant UI update
      dispatch({ 
        type: 'company/companyAdded', 
        payload: tempCompany 
      });
      
      // Select the new company immediately
      dispatch(companySelected(tempId));
      
      // Clear the input field
      setNewCompanyName('');
      
      // Mark that we have unsaved changes
      setHasUnsavedChanges(true);
      
      try {
        // Do the API call in the background
        const result = await dispatch(createCompany({company_name: companyNameToCreate}));
        
        // Once we get the real ID back, update the selection to the real ID
        if (result.payload && result.payload.company_id) {
          dispatch(companySelected(result.payload.company_id));
        }
      } catch (error) {
        console.error('Error creating company:', error);
        // Optionally show an error toast here
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const isFormValid = () => {
    return inputText.trim().length > 0;
  };

  const handleValidateIdea = () => {
    onRun('glow_08'); // Normal flow
  };

  const handleSkipToVideo = () => {
    onRun('glow_61'); // Skip to video flow
  };

  const handleFetchSessionList = () => {
    setIsLoadingSessions(true);
    // Reset fetched sessions tracking when deliberately fetching new sessions
    fetchedSessionsRef.current = {};
    
    const result = onFetchSessionList();
    
    // Handle both promise and non-promise returns
    if (result && typeof result.then === 'function') {
      result
        .then(response => {
          // Store the API response in local state
          if (response && response.status && response.data && response.data.sessions) {
            setLocalSessionData(response);
            console.log("Sessions data received:", response);
          }
        })
        .catch(error => {
          console.error("Error fetching sessions:", error);
        })
        .finally(() => {
          setIsLoadingSessions(false);
        });
    } else {
      // If not a promise, just set loading to false
      setIsLoadingSessions(false);
    }
  };

  const highlightBusinessPath = () => {
    // Dispatch an event to highlight relevant sections
    const event = new CustomEvent('highlight-stepper-section', {
      detail: { section: 'ideaChain' }
    });
    window.dispatchEvent(event);
  };

  const highlightVideoPath = () => {
    // Dispatch an event to highlight relevant sections
    const event = new CustomEvent('highlight-stepper-section', {
      detail: { section: 'videoChain' }
    });
    window.dispatchEvent(event);
  };

  const toggleCategoryExpansion = (chainId) => {
    setHighlightedSection(highlightedSection === chainId ? null : chainId);
  };

  // Format date to readable format
  const formatDate = (dateString) => {
    if (!dateString) return 'Unknown date';
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    } catch (e) {
      return 'Invalid date';
    }
  };

  // Extract date from session ID if created_at is not available
  const getSessionDate = (session) => {
    if (session.created_at) return formatDate(session.created_at);
    
    // Try to extract date from session ID format like SES20250323092721_9b97
    if (session.session_id && session.session_id.startsWith('SES')) {
      try {
        // Extract date parts - format is SES20250323092721_9b97
        // where 20250323 is the date (YYYYMMDD) and 092721 is the time (HHMMSS)
        const dateStr = session.session_id.substring(3, 11); // Extract 20250323
        const timeStr = session.session_id.substring(11, 17); // Extract 092721
        
        if (dateStr.length === 8 && timeStr.length === 6) {
          const year = dateStr.substring(0, 4);
          const month = dateStr.substring(4, 6);
          const day = dateStr.substring(6, 8);
          
          const hours = timeStr.substring(0, 2);
          const minutes = timeStr.substring(2, 4);
          const seconds = timeStr.substring(4, 6);
          
          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }
      } catch (e) {
        console.log("Error parsing date from session ID:", e);
      }
    }
    
    return 'Unknown date';
  };

  // Navigate to a specific session
  const openSession = (sessionId) => {
    // Don't fully reload the page, just change the URL and let the useEffect in Journey handle it
    window.history.pushState({}, '', `/journey?session=${sessionId}`);
    
    // Dispatch a custom event to notify the Journey component that the URL has changed
    // This is needed because pushState doesn't trigger the useEffect with location dependency
    window.dispatchEvent(new Event('popstate'));
    
    // Show a loading indicator if needed
    setIsLoadingSessions(true);
  };

  // Select and switch to a session
  const selectSession = (sessionId) => {
    // Use the same approach as openSession
    window.history.pushState({}, '', `/journey?session=${sessionId}`);
    window.dispatchEvent(new Event('popstate'));
    setIsLoadingSessions(true);
  };

  // Open prompt dialog with full text
  const openPromptDialog = (prompt) => {
    setSelectedPrompt(prompt);
    setPromptDialogOpen(true);
  };

  return (
    <Box className="process-view-container" sx={{ maxWidth: '800px', margin: '0 auto' }}>
      <ViewerHeader 
        title={config?.info?.title}
        subtitle={config?.info?.description}
        processId="glow_01"
        showRerun={false}
      />

      <TextField
        fullWidth
        multiline
        rows={3}
        variant="outlined"
        placeholder={config.info.placeholderText}
        value={inputText}
        onChange={(e) => {
          setInputText(e.target.value);
          setHasUnsavedChanges(true);
        }}
        sx={{
          mb: inputText.trim() === '' ? 1 : 2,
          '& .MuiOutlinedInput-root': {
            borderRadius: 2,
            ...(inputText.trim() === '' && {
              animation: 'pulse 2s infinite',
              boxShadow: '0 0 5px rgba(253, 152, 0, 0.4)',
              border: '1px solid rgba(253, 152, 0, 0.6)',
            }),
            // Always use dark background for input field
            backgroundColor: 'rgba(30, 30, 30, 0.9)'
          },
          '& .MuiInputBase-input': {
            // Always use white text for contrast against dark background
            color: '#FFFFFF',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255, 255, 255, 0.23)',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255, 255, 255, 0.4)',
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FD9800', // Always use the primary orange
          },
          // Style placeholder text to be visible on dark background
          '& .MuiInputBase-input::placeholder': {
            color: 'rgba(255, 255, 255, 0.7)',
            opacity: 1
          }
        }}
        InputProps={{
          style: {
            color: '#FFFFFF', // Always white text
          }
        }}
      />
      
      {inputText.trim() === '' && (
        <Typography 
          variant="body2" 
          sx={{ 
            color: 'primary.main', 
            mb: 2, 
            fontSize: '0.9rem',
            fontStyle: 'italic',
            animation: 'fadeIn 1s infinite alternate',
            '@keyframes fadeIn': {
              '0%': { opacity: 0.7 },
              '100%': { opacity: 1 }
            }
          }}
        >
          Start by describing your idea here...
        </Typography>
      )}
      
      <FormControl 
        fullWidth 
        variant="outlined" 
        sx={{ mb: 2 }}
      >
        <InputLabel style={{ color: '#FFFFFF' }}>Allocate Idea under Company/Project </InputLabel>
        <Select
          value={selectedCompany}
          onChange={handleCompanyChange}
          label="Allocate Idea under Company/Project"
          sx={{
            '& .MuiSelect-select': {
              color: '#FFFFFF',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(255, 255, 255, 0.23)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(255, 255, 255, 0.4)',
            },
            '& .MuiSvgIcon-root': {
              color: '#FFFFFF',
            },
            backgroundColor: 'rgba(30, 30, 30, 0.9)'
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                bgcolor: 'rgba(30, 30, 30, 0.95)',
                color: '#FFFFFF',
                '& .MuiMenuItem-root': {
                  color: '#FFFFFF'
                },
                '& .MuiMenuItem-root:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.1)'
                }
              }
            }
          }}
        >
          <MenuItem value="">
            <em style={{ color: '#FFFFFF' }}>None</em>
          </MenuItem>
          {companies.map(company => (
            <MenuItem key={company.company_id} value={company.company_id}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '#FFFFFF' }}>
                <FaBuilding />
                <Typography sx={{ color: '#FFFFFF' }}>{company.company_name}</Typography>
              </Box>
            </MenuItem>
          ))}
          <MenuItem value="add_new">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '#FD9800' }}>
              <FaPlus />
              <Typography>Add New Company/Project</Typography>
            </Box>
          </MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ 
        position: 'relative',
        width: '100%',
        mt: 3,
        mb: 4, 
        display: 'flex',
        justifyContent: 'center',
        gap: 3
      }}>
        {/* Path 1 */}
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          borderRadius: 2,
          p: 2,
          border: '1px solid rgba(253, 152, 0, 0.3)',
          bgcolor: 'rgba(253, 152, 0, 0.05)',
          flex: 1,
          maxWidth: 300
        }}>
          <Button
            id="start-analysis-button"
            variant="contained"
            size="large"
            startIcon={<Play size={20} />}
            onClick={handleValidateIdea}
            disabled={!isFormValid()}
            onMouseEnter={highlightBusinessPath}
            onMouseLeave={() => window.dispatchEvent(new CustomEvent('reset-stepper-highlight'))}
            sx={{
              minWidth: '200px',
              py: 1.5,
              fontSize: '1.1rem',
              fontWeight: 600,
              bgcolor: '#FD9800',
              boxShadow: '0 4px 12px rgba(253, 152, 0, 0.4)',
              '&:hover': {
                bgcolor: '#E68A00',
                transform: 'translateY(-2px)',
                boxShadow: '0 6px 16px rgba(253, 152, 0, 0.6)',
              },
              transition: 'all 0.3s ease',
              '&.Mui-disabled': {
                bgcolor: 'action.disabledBackground',
              },
              '&.highlight': {
                animation: 'pulse 1.5s infinite'
              }
            }}
          >
            Validate Business Idea
          </Button>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
            <ArrowRight size={16} />
            <Typography variant="body2">Analysis → Validation → Video</Typography>
          </Box>
        </Box>
        
        {/* Path 2 */}
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          borderRadius: 2,
          p: 2,
          border: '1px solid rgba(75, 112, 226, 0.3)',
          bgcolor: 'rgba(75, 112, 226, 0.05)',
          flex: 1,
          maxWidth: 300
        }}>
          <Button
            id="skip-to-video-button"
            variant="contained"
            size="large"
            startIcon={<VideoIcon size={20} />}
            onClick={handleSkipToVideo}
            disabled={!isFormValid()}
            onMouseEnter={highlightVideoPath}
            onMouseLeave={() => window.dispatchEvent(new CustomEvent('reset-stepper-highlight'))}
            sx={{
              minWidth: '200px',
              py: 1.5,
              fontSize: '1.1rem',
              fontWeight: 600,
              bgcolor: '#4B70E2',
              boxShadow: '0 4px 12px rgba(75, 112, 226, 0.4)',
              '&:hover': {
                bgcolor: '#3A5DC8',
                transform: 'translateY(-2px)',
                boxShadow: '0 6px 16px rgba(75, 112, 226, 0.6)',
              },
              transition: 'all 0.3s ease',
              '&.Mui-disabled': {
                bgcolor: 'action.disabledBackground',
              }
            }}
          >
            Create a Video
          </Button>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
            <ArrowRight size={16} />
            <Typography variant="body2">Skip to Video Creation</Typography>
          </Box>
        </Box>
      </Box>

      <Typography variant="body2" sx={{ 
        color: 'success.main', 
        mt: 1,
        fontWeight: 500
      }}>
        Join the 87% who succeeded with proper validation
      </Typography>

      {/* Previous Sessions Overview - Always visible */}
      <Box sx={{ mt: 6, mb: 4 }}>
        <Divider sx={{ mb: 2 }} />
        
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              cursor: 'pointer',
              '&:hover': {
                color: 'primary.main'
              }
            }}
            onClick={() => setSessionsExpanded(!sessionsExpanded)}
          >
            <FaCalendarAlt size={18} />
            <Typography variant="h6">
              Your Previous Sessions
            </Typography>
            <IconButton 
              size="small"
              sx={{ ml: 1 }}
              aria-label={sessionsExpanded ? "Collapse sessions list" : "Expand sessions list"}
            >
              {sessionsExpanded ? <FaChevronUp size={14} /> : <FaChevronDown size={14} />}
            </IconButton>
          </Box>
          
          <Button
            variant="outlined"
            size="small"
            startIcon={isLoadingSessions ? <CircularProgress size={16} /> : <FaSync />}
            onClick={handleFetchSessionList}
            disabled={isLoadingSessions}
            sx={{
              color: theme.palette.grey[600],
              borderColor: theme.palette.grey[400],
              fontSize: '0.8rem',
              height: '32px',
              '&:hover': {
                borderColor: theme.palette.grey[600],
                bgcolor: 'rgba(0, 0, 0, 0.04)',
              }
            }}
          >
            {isLoadingSessions ? 'Fetching...' : 'Fetch Session List'}
          </Button>
        </Box>
        
        {sessionsExpanded && (
          <Box sx={{ width: '100%', overflow: 'hidden', border: '1px solid rgba(0,0,0,0.12)', borderRadius: 1, bgcolor: 'background.paper', maxHeight: '400px', overflowY: 'auto' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                <tr style={{ 
                  backgroundColor: theme => theme.palette.mode === 'dark' ? '#333' : '#f5f5f5',
                  height: '50px'
                }}>
                  <th style={{ padding: '12px 16px', textAlign: 'left', fontWeight: 600 }}>Company/Project</th>
                  <th style={{ padding: '12px 16px', textAlign: 'left', fontWeight: 600 }}>Date Created</th>
                  <th style={{ padding: '12px 16px', textAlign: 'left', fontWeight: 600, width: '40%' }}>Input Prompt</th>
                  <th style={{ padding: '12px 16px', textAlign: 'right', fontWeight: 600 }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {(localSessionData?.data?.sessions?.length > 0 || sessionData?.data?.sessions?.length > 0) ? (
                  (localSessionData?.data?.sessions || sessionData?.data?.sessions).map((session, index) => (
                    <tr key={session.session_id} style={{ 
                      borderTop: '1px solid rgba(0,0,0,0.12)',
                      height: '60px',
                      transition: 'background-color 0.2s'
                    }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.02)'}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                    >
                      <td style={{ padding: '12px 16px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <FaBuilding style={{ fontSize: '14px', opacity: 0.7 }} />
                          <Typography variant="body2">
                            {session.company_name || 'No Company'}
                          </Typography>
                        </Box>
                      </td>
                      <td style={{ padding: '12px 16px' }}>
                        <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <FaCalendarAlt size={12} />
                          {getSessionDate(session)}
                        </Typography>
                      </td>
                      <td style={{ padding: '12px 16px' }}>
                        {loadingSessionDetails[session.session_id] ? (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <CircularProgress size={14} />
                            <Typography variant="body2" color="text.secondary">Loading prompt...</Typography>
                          </Box>
                        ) : sessionDetails[session.session_id]?.session_details?.input_prompt ? (
                          <Box>
                            <Typography 
                              variant="body2" 
                              sx={{ 
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                              }}
                            >
                              {sessionDetails[session.session_id].session_details.input_prompt}
                            </Typography>
                            {sessionDetails[session.session_id].session_details.input_prompt.length > 100 && (
                              <Button 
                                size="small" 
                                onClick={() => openPromptDialog(sessionDetails[session.session_id].session_details.input_prompt)} 
                                sx={{ 
                                  p: 0, 
                                  minWidth: 'auto', 
                                  fontSize: '0.7rem',
                                  textTransform: 'none',
                                  mt: 0.5
                                }}
                              >
                                Read More
                              </Button>
                            )}
                          </Box>
                        ) : (
                          <Typography variant="body2" color="text.secondary" fontStyle="italic">
                            No prompt available
                          </Typography>
                        )}
                      </td>
                      <td style={{ padding: '12px 16px', textAlign: 'right' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                          {/* Session type chip - default to Business if not specified */}
                          <Chip 
                            size="small" 
                            label={session.session_type === 'video' ? 'Video' : 'Business'} 
                            sx={{ 
                              height: '24px',
                              fontSize: '0.7rem',
                              bgcolor: session.session_type === 'video' ? 'rgba(75, 112, 226, 0.1)' : 'rgba(253, 152, 0, 0.1)',
                              color: session.session_type === 'video' ? '#4B70E2' : '#FD9800',
                              borderRadius: '4px',
                              '& .MuiChip-label': {
                                px: 1
                              }
                            }}
                            icon={session.session_type === 'video' ? 
                              <FaVideo style={{ fontSize: '10px', marginLeft: '4px' }} /> : 
                              <FaPlay style={{ fontSize: '10px', marginLeft: '4px' }} />
                            }
                          />
                          
                          <Button 
                            variant="outlined" 
                            size="small"
                            onClick={() => selectSession(session.session_id)}
                            sx={{ 
                              minWidth: '80px',
                              fontSize: '0.75rem',
                              backgroundColor: theme.palette.background.paper,
                              '&:hover': {
                                backgroundColor: theme.palette.action.hover,
                              }
                            }}
                          >
                            Select
                          </Button>
                        </Box>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} style={{ padding: '30px', textAlign: 'center' }}>
                      <Typography variant="body1" color="text.secondary">
                        No sessions found. Use the "Fetch Session List" button above to retrieve your sessions.
                      </Typography>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Box>
        )}
      </Box>

      <Dialog 
        open={isNewCompanyDialogOpen} 
        onClose={() => {
          setIsNewCompanyDialogOpen(false);
          setIsSubmitting(false);
          setNewCompanyName('');
        }}
        PaperProps={{
          sx: {
            borderRadius: 2,
            width: '100%',
            maxWidth: '500px',
            backgroundColor: 'rgb(30, 30, 30)',
            color: '#FFFFFF'
          }
        }}
      >
        <DialogTitle sx={{ color: '#FFFFFF' }}>Add New Company/Project</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Company/Project Name"
            fullWidth
            value={newCompanyName}
            onChange={(e) => setNewCompanyName(e.target.value)}
            sx={{ 
              mt: 2,
              '& .MuiInputBase-input': {
                color: '#FFFFFF',
              },
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'rgba(30, 30, 30, 0.9)'
              },
              '& .MuiInputLabel-root': {
                color: '#FFFFFF',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(255, 255, 255, 0.23)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(255, 255, 255, 0.4)',
              }
            }}
            InputProps={{
              style: {
                color: '#FFFFFF',
              }
            }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button 
            onClick={() => setIsNewCompanyDialogOpen(false)}
            color="inherit"
            sx={{ color: '#FFFFFF' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleAddNewCompany}
            variant="contained"
            disabled={!newCompanyName.trim() || isSubmitting}
          >
            {isSubmitting ? 'Adding...' : 'Add Company'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Prompt Dialog */}
      <Dialog
        open={promptDialogOpen}
        onClose={() => setPromptDialogOpen(false)}
        maxWidth="md"
        PaperProps={{
          sx: { 
            width: '100%', 
            maxWidth: '600px', 
            borderRadius: 2,
            backgroundColor: 'rgb(30, 30, 30)',
            color: '#FFFFFF'
          }
        }}
      >
        <DialogTitle sx={{ color: '#FFFFFF' }}>Session Prompt</DialogTitle>
        <DialogContent dividers sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }}>
          <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
            {selectedPrompt}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPromptDialogOpen(false)} sx={{ color: '#FD9800' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default IdeaInputCard;