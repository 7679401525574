import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
  TextField,
  IconButton,
  Grid,
  Chip,
  styled,
  ThemeProvider
} from '@mui/material';
import {
  Search as SearchIcon,
  ExpandMore as ChevronDownIcon,
  ChevronRight as ChevronRightIcon,
  Check as CheckIcon,
  AccessTime as ClockIcon,
  Lock as LockIcon,
  MovieCreation as EntertainmentIcon,
  School as EducationIcon,
  Campaign as MarketingIcon,
  Person as PersonalIcon,
  Business as ProfessionalIcon,
  Article as JournalismIcon,
  AutoAwesome as AutoAwesomeIcon
} from '@mui/icons-material';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import processConfig from '../../config/processIndex.json';
import { useDispatch } from 'react-redux';
import { updateGlowStep } from '../../components/session/sessionSlide';
import { VIDEO_INDEX, getCategoryById, getTypeById, getAvailableTypes } from '../../config/videoIndex';

// Styled components to replace Tailwind classes
const VideoTypeButton = styled(Button)(({ theme, active, status, isDynamic }) => ({
  width: '100%',
  height: '100%',
  justifyContent: 'flex-start',
  padding: theme.spacing(2),
  backgroundColor: active ? '#FD9800' : 'var(--color-background)',
  color: active 
    ? theme.palette.primary.contrastText 
    : status === 1 
      ? theme.palette.text.primary
      : theme.palette.text.primary,
  opacity: status === 1 ? 0.95 : 1,
  '&:hover': {
    backgroundColor: active ? '#E68A00' : theme.palette.action.hover,
  },
  '& .type-name': {
    color: active 
      ? theme.palette.primary.contrastText 
      : status === 1 
        ? theme.palette.text.primary
        : theme.palette.text.primary,
    opacity: status === 1 ? 0.95 : 1,
  },
  '& .duration': {
    fontSize: '0.75rem',
    color: active 
      ? theme.palette.primary.contrastText 
      : status === 1 
        ? theme.palette.text.primary
        : theme.palette.text.secondary,
    fontStyle: isDynamic ? 'italic' : 'normal'
  },
  ...(isDynamic && {
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    '&:hover': {
      borderLeft: `3px solid ${theme.palette.primary.dark}`,
    }
  })
}));

const CategoryRow = styled(Box)(({ theme, isDynamic }) => ({
  cursor: 'pointer',
  backgroundColor: theme.palette.background,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  borderBottom: `1px solid ${theme.palette.divider}`,
  ...(isDynamic && {
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(253, 152, 0, 0.1)' 
      : 'rgba(253, 152, 0, 0.05)'
  })
}));

const CATEGORY_ICONS = {
  dyn: AutoAwesomeIcon,
  ent: EntertainmentIcon,
  edu: EducationIcon,
  mkt: MarketingIcon,
  per: PersonalIcon,
  pro: ProfessionalIcon,
  jou: JournalismIcon
};

const isCategoryComingSoon = (category) => {
  return Object.values(category.types).every(type => type.status === 1);
};

// Add special handling for dynamic duration display
const formatDuration = (duration) => {
  return duration === 'custom' ? 'Auto-adjusted' : duration;
};

const Glow62Processor = React.memo(({ data, onComplete, sessionId }) => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const previousSelectedType = useRef(null);
  
  const process = useMemo(() => processConfig.processes.find(p => p.id === 'glow_62'), []);
  const [searchTerm, setSearchTerm] = useState('');
  
  const DEFAULT_TYPE = useMemo(() => ({
    id: 'pv',
    categoryId: 'mkt',
    name: "Product Validation",
    dur: "30s",
    status: 2
  }), []);

  // Initialize with default or saved selection
  const [selectedType, setSelectedType] = useState(() => {
    const initialType = data?.selectedType || DEFAULT_TYPE;
    previousSelectedType.current = initialType;
    return initialType;
  });

  // Initialize expanded categories
  const [expandedCategories, setExpandedCategories] = useState(() => {
    const categoryId = data?.selectedType?.categoryId || DEFAULT_TYPE.categoryId;
    return {
      [categoryId]: true
    };
  });

  // Memoize the update function
  const updateGlowStepData = useCallback((type, status = false) => {
    if (!type || typeof type !== 'object') return;
    
    dispatch(updateGlowStep({
      stepId: 'glow_62',
      data: { selectedType: type },
      status,
      ready: true
    }));
  }, [dispatch]);

  // Effect to handle initial mount and type changes
  useEffect(() => {
    if (isFirstRender.current) {
      updateGlowStepData(selectedType);
      isFirstRender.current = false;
      return;
    }

    // Only update if the type has actually changed
    if (JSON.stringify(previousSelectedType.current) !== JSON.stringify(selectedType)) {
      previousSelectedType.current = selectedType;
      updateGlowStepData(selectedType);
    }
  }, [selectedType, updateGlowStepData]);

  // Get filtered types with memoization
  const filteredTypes = useMemo(() => {
    const searchLower = searchTerm.toLowerCase();
    return Object.entries(VIDEO_INDEX.categories).map(([catId, category]) => {
      const types = Object.entries(category.types)
        .map(([typeId, type]) => ({
          id: typeId,
          categoryId: catId,
          ...type
        }))
        .filter(type => type.name.toLowerCase().includes(searchLower));

      return {
        id: catId,
        name: category.name,
        types,
        isAvailable: types.some(type => type.status === 2)
      };
    }).filter(cat => cat.types.length > 0);
  }, [searchTerm]);

  const toggleCategory = useCallback((categoryId) => {
    setExpandedCategories(prev => ({
      ...prev,
      [categoryId]: !prev[categoryId]
    }));
  }, []);

  const toggleAll = useCallback((expand) => {
    setExpandedCategories(() => {
      const newState = {};
      Object.keys(VIDEO_INDEX.categories).forEach(category => {
        newState[category] = expand;
      });
      return newState;
    });
  }, []);

  const handleContinue = useCallback(() => {
    if (!selectedType) return;
    
    onComplete({
      processId: 'glow_62',
      data: {
        status: true,
        glow_62_response: {
          selectedType
        }
      }
    });
  }, [selectedType, onComplete]);

  const handleTypeSelection = useCallback((type) => {
    if (!type || type.status !== 2) return;
    setSelectedType(type);
  }, []);

  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value || '');
  }, []);

  return (
    <>
      <ViewerHeader 
        title="Select Content Type"
        subtitle={process.info.description}
        processId="glow_62"
        showRerun={!!process.endpoints.run}
      />
      <Box sx={{ p: 3}}>
        <Card elevation={0} sx={{ borderRadius: 2}}>
          <CardContent>
            <Stack spacing={3}>
              {/* Search and Controls */}
              <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ position: 'relative', flexGrow: 1, maxWidth: 'md' }}>
                  <TextField
                    fullWidth
                    placeholder="Search for specific types..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                      startAdornment: (
                        <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />
                      ),
                      sx: { pl: 1 }
                    }}
                  />
                </Box>
                <Stack direction="row" spacing={1}>
                  <Button 
                    variant="outlined"
                    size="small"
                    onClick={() => toggleAll(true)}
                  >
                    Expand All
                  </Button>
                  <Button 
                    variant="outlined"
                    size="small"
                    onClick={() => toggleAll(false)}
                  >
                    Collapse All
                  </Button>
                </Stack>
              </Box>

              {/* Status Legend */}
              <Stack direction="row" spacing={2} justifyContent="center">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: 'success.main' }} />
                  <Typography variant="body2">Available</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <ClockIcon sx={{ fontSize: 16, color: 'warning.main' }} />
                  <Typography variant="body2">Coming Soon</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <LockIcon sx={{ fontSize: 16, color: 'error.main' }} />
                  <Typography variant="body2">Not Available</Typography>
                </Box>
              </Stack>

              {/* Categories and Types */}
              <Card sx={{backgroundColor: 'var(--color-background)'}} variant="outlined">
                {filteredTypes.map((category) => (
                  <React.Fragment key={category.id}>
                    <CategoryRow 
                      onClick={() => toggleCategory(category.id)}
                      sx={{ 
                        p: 2, 
                        display: 'flex', 
                        alignItems: 'center',
                        opacity: isCategoryComingSoon(category) ? 0.7 : 1,
                      }}
                      isDynamic={category.id === 'dyn'}
                    >
                      <IconButton size="small" sx={{ mr: 1 }}>
                        {expandedCategories[category.id] ? 
                          <ChevronDownIcon /> : 
                          <ChevronRightIcon />}
                      </IconButton>
                      {React.createElement(CATEGORY_ICONS[category.id], {
                        sx: { 
                          mr: 2,
                          color: category.id === 'dyn' ? '#FD9800' : 
                            isCategoryComingSoon(category) ? 'text.secondary' : '#FD9800',
                          fontSize: 24
                        }
                      })}
                      <Typography 
                        variant="subtitle1" 
                        sx={{ 
                          flexGrow: 1,
                          color: isCategoryComingSoon(category) ? 'text.secondary' : 'text.primary',
                          fontWeight: category.id === 'dyn' ? 600 : 400
                        }}
                      >
                        {category.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {category.types.filter(t => t.status === 2).length} available / {category.types.length} types
                      </Typography>
                    </CategoryRow>

                    {expandedCategories[category.id] && (
                      <Box sx={{ p: 2, bgcolor: 'action.hover' }}>
                        <Grid container spacing={1}>
                          {category.types.map((type) => (
                            <Grid item xs={12} sm={6} md={4} key={type.id}>
                              <VideoTypeButton
                                onClick={() => handleTypeSelection(type)}
                                active={(selectedType?.id === type.id) ? 'active' : ''}
                                disabled={type.status !== 2}
                                status={type.status}
                                isDynamic={type.dur === 'custom'}
                              >
                                {selectedType?.id === type.id ? (
                                  <CheckIcon sx={{ mr: 1 }} />
                                ) : type.status === 2 ? (
                                  <Box 
                                    sx={{ 
                                      width: 8, 
                                      height: 8, 
                                      borderRadius: '50%', 
                                      bgcolor: 'success.main',
                                      mr: 1.5,
                                      ml: 0.5
                                    }} 
                                  />
                                ) : null}
                                <Box sx={{ flexGrow: 1 }}>
                                  <Typography variant="body1" className="type-name">{type.name}</Typography>
                                  <Typography className="duration" variant="caption">
                                    {formatDuration(type.dur)}
                                  </Typography>
                                </Box>
                                {type.status !== 2 && (
                                  type.status === 1 ? 
                                    <ClockIcon sx={{ color: 'warning.main' }} /> :
                                    <LockIcon sx={{ color: 'error.main' }} />
                                )}
                              </VideoTypeButton>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    )}
                  </React.Fragment>
                ))}
              </Card>
            </Stack>
          </CardContent>
        </Card>

        <ViewFooter 
          isDisabled={!selectedType}
          showButton={true}
          onComplete={handleContinue}
          buttonText={process.footer.continueText}
        />
      </Box>
    </>
  );
});

export default React.memo(Glow62Processor, (prevProps, nextProps) => {
  return (
    prevProps.sessionId === nextProps.sessionId &&
    JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data)
  );
});