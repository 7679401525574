import React from 'react';
import { ArrowRight, Brain, Target, ChartBar, PlayCircle, Video } from 'lucide-react';

const HowToGetStarted = ({ section, handleShowPricing }) => {
  if (!section || (section !== 'business' && section !== 'creators')) {
    // Simple version with generic steps
    const steps = [
      {
        icon: Brain,
        title: 'Market Discovery',
        description: 'AI-powered market analysis and segmentation to identify your target audience',
        duration: '1-2 days'
      },
      {
        icon: Target,
        title: 'Rapid Testing',
        description: 'Multi-variant audience testing to validate your assumptions',
        duration: '1-2 days'
      },
      {
        icon: ChartBar,
        title: 'Data Analysis',
        description: 'ML-driven insight generation and performance metrics',
        duration: '1 day'
      },
      {
        icon: PlayCircle,
        title: 'Validated Build',
        description: 'Market-verified development with confidence in your direction',
        duration: '2-4 weeks'
      }
    ];

    return (
      <section className="py-12 md:py-16 mb-12 md:mb-20" aria-labelledby="how-to-start-title">
        <div className="text-center mb-8 md:mb-12 px-4">
          <h2 id="how-to-start-title" className="text-2xl md:text-3xl font-bold mb-3 md:mb-4">
            How to Get Started
          </h2>
          <p className="text-zinc-300 max-w-2xl mx-auto text-sm md:text-base">
            Our proven process for market validation
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6 px-4">
          {steps.map((step, index) => (
            <div 
              key={index}
              className="bg-zinc-800/30 rounded-lg p-5 md:p-6 flex flex-col"
            >
              <div className="mb-3 md:mb-4">
                <step.icon className="w-7 h-7 md:w-8 md:h-8 text-orange-500" />
              </div>
              <h3 className="text-lg md:text-xl font-bold mb-2">{step.title}</h3>
              <p className="text-sm text-zinc-400 mb-4 flex-grow">{step.description}</p>
              <div className="mt-auto pt-3 md:pt-4 border-t border-zinc-700">
                <div className="flex justify-between items-center">
                  <span className="text-xs md:text-sm text-zinc-400">Duration: {step.duration}</span>
                  {index === steps.length - 1 && (
                    <button
                      onClick={() => handleShowPricing()}
                      className="text-orange-500 hover:text-orange-400 text-sm font-semibold flex items-center gap-1 touch-manipulation p-2 -mr-2"
                      aria-label="Get Started with validation"
                    >
                      Get Started
                      <ArrowRight size={16} />
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }
  
  // Detailed version based on user type
  return (
    <section className="mb-12 md:mb-20 px-4" aria-labelledby="how-to-start-detailed-title">
      <div className="container mx-auto">
        <h2 id="how-to-start-detailed-title" className="text-2xl md:text-3xl font-bold mb-5 md:mb-8 text-center">
          How to Get Started in 3 Simple Steps
        </h2>
        <p className="text-zinc-300 max-w-3xl mx-auto text-center mb-4 text-sm md:text-base">
          No expertise required - we've made validation simple for everyone
        </p>
        
        {/* Text-to-Video Highlight Banner */}
        <div className="max-w-3xl mx-auto bg-gradient-to-r from-orange-500/20 to-amber-500/20 p-3 md:p-4 rounded-lg mb-8 md:mb-10 flex flex-col md:flex-row items-center justify-center gap-2 md:gap-3">
          <Video className="text-orange-400 h-5 w-5 md:h-6 md:w-6" />
          <p className="text-orange-300 font-medium text-center md:text-left text-sm md:text-base">
            {section === 'business' 
              ? 'Create professional business videos from just text descriptions' 
              : 'Create complete 30+ second videos from just text descriptions'}
          </p>
          <button 
            onClick={() => handleShowPricing()}
            className="ml-0 md:ml-4 mt-2 md:mt-0 bg-orange-500/30 hover:bg-orange-500/50 text-orange-200 px-3 py-1 rounded-lg text-sm transition-colors flex items-center gap-1 touch-manipulation"
          >
            See Examples <ArrowRight className="w-3 h-3" />
          </button>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-8">
          {section === 'business' ? (
            <>
              <div className="bg-zinc-800/30 p-5 md:p-6 rounded-xl transform transition-all hover:scale-105">
                <div className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-orange-500/20 flex items-center justify-center text-xl md:text-2xl font-bold text-orange-400 mb-4">1</div>
                <h3 className="text-lg md:text-xl font-bold mb-2 md:mb-3">Describe Your Business Idea</h3>
                <p className="text-sm md:text-base text-zinc-400 mb-3 md:mb-4">
                  Use our guided template to outline your business concept, value proposition, and target market.
                </p>
                <p className="text-xs md:text-sm text-orange-300">No expertise required</p>
              </div>
              
              <div className="bg-zinc-800/30 p-5 md:p-6 rounded-xl transform transition-all hover:scale-105">
                <div className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-orange-500/20 flex items-center justify-center text-xl md:text-2xl font-bold text-orange-400 mb-4">2</div>
                <h3 className="text-lg md:text-xl font-bold mb-2 md:mb-3">Generate Validation Materials</h3>
                <p className="text-sm md:text-base text-zinc-400 mb-3 md:mb-4">
                  Our AI transforms your description into professional pitch videos, landing pages, and marketing materials.
                </p>
                <div className="flex items-center gap-2 text-xs md:text-sm text-orange-300">
                  <Video className="h-3 w-3" /> Complete validation package in minutes
                </div>
              </div>
              
              <div className="bg-zinc-800/30 p-5 md:p-6 rounded-xl transform transition-all hover:scale-105">
                <div className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-orange-500/20 flex items-center justify-center text-xl md:text-2xl font-bold text-orange-400 mb-4">3</div>
                <h3 className="text-lg md:text-xl font-bold mb-2 md:mb-3">Get Market Feedback in 24 Hours</h3>
                <p className="text-sm md:text-base text-zinc-400 mb-3 md:mb-4">
                  Receive comprehensive market analysis, including target audience interest, pricing feedback, and competitive positioning.
                </p>
                <p className="text-xs md:text-sm text-orange-300">With actionable recommendations</p>
              </div>
            </>
          ) : (
            <>
              <div className="bg-zinc-800/30 p-5 md:p-6 rounded-xl transform transition-all hover:scale-105">
                <div className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-orange-500/20 flex items-center justify-center text-xl md:text-2xl font-bold text-orange-400 mb-4">1</div>
                <h3 className="text-lg md:text-xl font-bold mb-2 md:mb-3">Describe Your Video Concept</h3>
                <p className="text-sm md:text-base text-zinc-400 mb-3 md:mb-4">
                  Enter your script, content outline, or just describe what you want your video to communicate.
                </p>
                <p className="text-xs md:text-sm text-orange-300">No production experience needed</p>
              </div>
              
              <div className="bg-zinc-800/30 p-5 md:p-6 rounded-xl transform transition-all hover:scale-105">
                <div className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-orange-500/20 flex items-center justify-center text-xl md:text-2xl font-bold text-orange-400 mb-4">2</div>
                <h3 className="text-lg md:text-xl font-bold mb-2 md:mb-3">Generate Professional Videos</h3>
                <p className="text-sm md:text-base text-zinc-400 mb-3 md:mb-4">
                  Our AI transforms your text into complete 30+ second videos with voiceover, music, and visuals.
                </p>
                <div className="flex items-center gap-2 text-xs md:text-sm text-orange-300">
                  <Video className="h-3 w-3" /> From text to complete videos in minutes
                </div>
              </div>
              
              <div className="bg-zinc-800/30 p-5 md:p-6 rounded-xl transform transition-all hover:scale-105">
                <div className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-orange-500/20 flex items-center justify-center text-xl md:text-2xl font-bold text-orange-400 mb-4">3</div>
                <h3 className="text-lg md:text-xl font-bold mb-2 md:mb-3">Optimize Based on Feedback</h3>
                <p className="text-sm md:text-base text-zinc-400 mb-3 md:mb-4">
                  Test your videos with real audiences and get detailed feedback on engagement, clarity, and conversion potential.
                </p>
                <p className="text-xs md:text-sm text-orange-300">Refine and improve your content</p>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default HowToGetStarted; 