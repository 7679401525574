import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import Cookies from 'js-cookie';
import { signInWithPopup, getAdditionalUserInfo, GoogleAuthProvider, OAuthProvider, signOut } from "firebase/auth";

const appleProvider = new OAuthProvider("apple.com");

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const ANONYMOUS_USER = 'user01_unreg';
const REGISTER_USER = 'user02_reg';
const PAYING_USER = 'user03_paying';


const initialState = {
  accountInfo: {
    initials: '',
    nickname: '',
    userToken: '',
    userType: ANONYMOUS_USER,
    subscription_type: '',
    credit: undefined,
    firstName: '',
    lastName: '',
    email: '',
    emailVerified: false,
    lastLoginAt: '',
    guestToken: Cookies.get('guestToken') || '', // Get guestToken from cache
    guestCredits: Cookies.get('guestCredits') || undefined,
    mySubscription: {},
  },
  token: '',
  message: '',
  status: 'idle',
}

/**
 * Asynchronously fetches the remaining credit balance for the user.
 * 
 * This function uses the createAsyncThunk utility from Redux Toolkit to handle 
 * the asynchronous operation of retrieving the user's credit balance from the server.
 * It checks for a valid token in cookies, makes a POST request to the API, 
 * and returns the current credits if successful. If the token is missing or 
 * the request fails, it rejects with an appropriate error message.
 * 
 * @function getRemainingCredit
 * @returns {Promise<number>} The current credit balance of the user.
 * @throws {string} An error message if the token is missing or the request fails.
 */
export const getRemainingCredit = createAsyncThunk(
  'user/getRemainingCredit',
  async (data, { rejectWithValue }) => { 
    try {
      const token = data?.token ?? Cookies.get('token');
      if (!token) {
        return rejectWithValue("Token is required");
      }
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/credits/get_credit_balance`,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200 && response.data && response.data.status_code === 200) {
        return response.data.data.current_credits; // Return the current credits
      } else {
        return rejectWithValue( response.data.message || "Failed to fetch remaining credit");
      }
    } catch (error) {
      console.log('error', error);
      // Something else went wrong
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Asynchronously signs in a user anonymously.
 * 
 * This function uses the createAsyncThunk utility from Redux Toolkit to handle 
 * the asynchronous operation of signing in a user without requiring any credentials. 
 * It makes a GET request to the API endpoint for anonymous sign-in and returns 
 * the user data if successful. If the request fails or the response is not as expected, 
 * it rejects with an appropriate error message.
 * 
 * @function signInAnonymous
 * @returns {Promise<Object>} The user data if sign-in is successful.
 * @throws {string} An error message if the sign-in fails.
 */
export const signInAnonymous = createAsyncThunk(
  'user/signInAnonymous',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/user/sign_in_anonymous`
      );
      if (response.status === 200 && response.data?.status_code === 200 && response.data.data) {
        return response.data.data; // Return the user data
      } else {
        return rejectWithValue("Failed to sign in anonymously");
      }
    } catch (error) {
      console.log('error', error);
      // Something else went wrong
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Handle check and process valid authenticate data after user has been successful signed-in or logged-in
 * 
 * @param {*} data 
 * @returns 
 */
async function handleValidCredentials(data) {
    const updateInfo = {
      picture: data.picture ?? '',
      initials: data.initials ?? '',
      nickname: data.nickname ?? '',
      email: data.email ?? '',
      userToken: data.user_token ?? '',
      userType: data.user_type ?? '',
      guestToken: Cookies.get('guestToken') || '', // Get guestToken from cache
      guestCredits: Cookies.get('guestCredits') || 0,
      subscription_type: data.subscription_type ?? '',
      lastLoginAt: data.last_login_at ?? '',
      firstName: data.first_name ?? '',
      lastName: data.last_name ?? ''
    };
    
    // Get system user token by firebase token
    if (data.accessToken && data.methodOfRegistration && data.methodOfRegistration === 'GMAIL') {
      // Ensure all required fields are present
      updateInfo.methodOfRegistration = data.methodOfRegistration;
      const payload = { firebase_token: data.accessToken };

      try {
        const response = await axios.post(
          `${API_BASE_URL}/api/v1/user/get_user_token_from_firebase_token`,
          payload
        );
        if (!response.status) {
          throw new Error(response.message ?? 'Failed to request get user token API');
        }
        if (response.data && response.data.status_code === 200 && response.data.data.user_token && response.data.data.user_type) {
          updateInfo.userToken = response.data.data.user_token;
          updateInfo.userType = response.data.data.user_type;
          updateInfo.subscription_type = response.data.data.subscription_type ?? '';
        } else {
          throw new Error(response.data.message ?? `Failed to request get user token API`);
        }
      } catch (error) {
        throw new Error(`Error running: ${error.message}`);
      }
    } else {
      if (!data.user_token || !data.user_type) {
        throw new Error(`Invalid user data`);
      }
    }
    return updateInfo; 
};

export const handleLogout = createAsyncThunk(
  'user/handleLogout',
  async (data, { rejectWithValue }) => {
    if (!data.auth) {
      return rejectWithValue("Authentication service is missing");
    }
    try {
      await signOut(data.auth);
      return initialState.accountInfo; // Return an empty object or any relevant data if needed
    } catch (error) {
      console.error("Error during logout:", error);
      return rejectWithValue(error.message);
    }
  }
);

function validateEmail (email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

/**
 * Handles the email login process by validating the email format,
 * sending a login request to the server, and processing the response.
 *
 * @function handleEmailLogin
 * @param {Object} data - The login data containing email and password.
 * @param {string} data.email - The user's email address.
 * @param {string} data.password - The user's password.
 * @param {Object} thunkAPI - The thunk API object containing methods for dispatching actions.
 * @returns {Promise<Object|Error>} - Returns a promise that resolves to the user data if successful,
 * or rejects with an error message if the login fails.
 */
export const handleEmailLogin = createAsyncThunk(
  'user/handleEmailLogin',
  async (data, { rejectWithValue }) => {
    const { email, password } = data;

    if (!validateEmail(email)) {
      return rejectWithValue("Invalid email format");
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/api/v1/user/user_login`, {
        email,
        password
      });

      if (response.data?.status_code === 200) {
        return await handleValidCredentials(response.data.data);
      } else {
        return rejectWithValue(response.data.message || "Login failed");
      }
    } catch (error) {
      console.log('error', error);
      // Something else went wrong
      return rejectWithValue(error.message);
    }
  }
);

export const handleEmailRegister = createAsyncThunk(
  'user/handleEmailRegister',
  async (data, { rejectWithValue }) => {
    const guest_token = Cookies.get('guestToken');
    if (!validateEmail(data.email)) {
      return rejectWithValue("Invalid email format");
    }
    if (data.password.length < 8) {
      return rejectWithValue("Password must be at least 8 characters");
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/api/v1/user/user_signup`, {
        email: data.email,
        password: data.password,
        first_name: data.firstName,
        last_name: data.lastName,
        nickname: data.nickname || "",
        current_credits: 0,
        guest_token: guest_token
      });

      if (response.data.status_code === 200) {
        console.log("Registration successful:", response.data);

        const userData = {
          "email": data.email,
          "initials": data.initials,
          "first_name": data.firstName ?? '',
          "last_name": data.lastName ?? '',
          "user_token": response.data.data.user_token,
          "nickname": data.nickname ?? '',
          "picture": '',
          "email_verified": false,
        }
        await handleValidCredentials(userData);
        return userData; // Return userData for further processing if needed
      } else {
        return rejectWithValue(response.data.message || "Registration failed");
      }
    } catch (error) {
      console.log('error', error);
      // Something else went wrong
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Register new user in BE if new User is detected after 
 * sign-in by Google or Apple provider 
 * 
 * @param {*} data 
 */
async function registerNewUser(data) {
  try {
    const payload = {
      "email": data.email,
      "first_name": data.firstName,
      "last_name": data.lastName,
      "method_of_registration": data.methodOfRegistration,
      "email_verified": true,
      "current_credits": 0,
      "guest_token": Cookies.get('guestToken') || '', // Get guestToken from cache
    }
    const response = await axios.post(`${API_BASE_URL}/api/v1/user/user_signup_gmail_or_apple`, 
      payload,        
      { headers: { Authorization: `Bearer ${data.userToken}` } }
    );

    if (response.status !== 200) {
      console.log(response.status + ' ' + response.statusText);
    }
    
    const responseData = response.data;

    if (responseData.status === true && responseData.status_code === 200) {
      return data;
      // showNotice('You received free Credits because you registered.')
    } else if (responseData.message) {
      return false;
      // showNotice(responseData.message, 'error')
    } else {
      return false;
      // showNotice('"Unfortunately, we have encountered an error', 'error')
    }
  }
  catch (error) {
    return false;
  } finally {
    return false;
  }
}

export const handleGoogleSignIn = createAsyncThunk(
  'user/handleGoogleSignIn',
  async (data, { rejectWithValue }) => {
    if (!data.auth) {
      return rejectWithValue("Authentication service is missing");
    }
    try {
      const googleProvider = new GoogleAuthProvider();
      const result = await signInWithPopup(data.auth, googleProvider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;

      // The signed-in user info.
      const user = result.user;
      const additionalUserInfo = getAdditionalUserInfo(result);
      const initialData = {
        "email": user.email,
        "firstName": additionalUserInfo.profile.given_name ?? '',
        "lastName": additionalUserInfo.profile.family_name ?? '',
        "picture": additionalUserInfo.profile.picture,
        "accessToken": user.accessToken,
        "isNewUser": additionalUserInfo.isNewUser ?? false,
        "nickname": user.displayName ?? '',
        "methodOfRegistration": "GMAIL",
        "emailVerified": additionalUserInfo.profile.verified_email,
        "currentCredits": 0
      };

      const updateInfo =  await handleValidCredentials(initialData);
      if(updateInfo.userToken && additionalUserInfo.isNewUser) {
        return await registerNewUser(updateInfo);
      }
      return updateInfo;
    } catch (error) {
      console.log('error', error);
      // Something else went wrong
      return rejectWithValue(error.message);
    }
  }
);


export const handleAppleSignIn = createAsyncThunk(
  'user/handleAppleSignIn',
  async (data, { rejectWithValue }) => {
    if (!data.auth) {
      return rejectWithValue("Authentication service is missing");
    }
    try {
      // const googleProvider = new GoogleAuthProvider();
      // const result = await signInWithPopup(data.auth, googleProvider);
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;

      // // The signed-in user info.
      // const user = result.user;
      // const additionalUserInfo = getAdditionalUserInfo(result);
      // const initialData = {
      //   "email": user.email,
      //   "firstName": additionalUserInfo.profile.given_name ?? '',
      //   "lastName": additionalUserInfo.profile.family_name ?? '',
      //   "picture": additionalUserInfo.profile.picture,
      //   "accessToken": user.accessToken,
      //   "isNewUser": additionalUserInfo.isNewUser ?? false,
      //   "nickname": user.displayName ?? '',
      //   "methodOfRegistration": "GMAIL",
      //   "emailVerified": additionalUserInfo.profile.verified_email,
      //   "currentCredits": 0
      // };

      // const updateInfo =  await handleValidCredentials(initialData);
      // if(updateInfo.userToken && additionalUserInfo.isNewUser) {
      //   return await registerNewUser(updateInfo);
      // }
      // return updateInfo;
    } catch (error) {
      console.log('error', error);
      // Something else went wrong
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Asynchronously fetches the user's profile information.
 * 
 * This function uses createAsyncThunk to handle the asynchronous operation of 
 * retrieving the user's profile information from the server. It requires a valid 
 * authentication token and makes a request to the user profile API endpoint.
 * 
 * @function getUserProfile
 * @param {Object} data - Optional data containing token
 * @param {string} data.token - Optional token (will use cookie if not provided)
 * @returns {Promise<Object>} The user profile data if successful
 * @throws {string} An error message if the fetch fails
 */
export const getUserProfile = createAsyncThunk(
  'user/getUserProfile',
  async (data, { rejectWithValue }) => {
    try {
      const token = data?.userToken ?? Cookies.get('token');
      if (!token) {
        return rejectWithValue("Token is required");
      }
      
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/user/user_profile`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      if (response.status === 200 && response.data?.status_code === 200) {
        // Return the response data directly
        return {
          ...data,  // Keep other fields from the original data
          first_name: response?.data?.data?.first_name,
          last_name: response?.data?.data?.last_name,
          initials: response?.data?.data?.initials,
          nickname: response?.data?.data?.nickname,
          current_credits: response?.data?.data?.current_credits,
          email_verified: response?.data?.data?.email_verified,
          last_login_at: response?.data?.data?.last_login_at,
          // Map other fields as needed
        };
      } else {
        return rejectWithValue(response.data.message || "Failed to fetch user profile");
      }
    } catch (error) {
      console.log('error', error);
      // Something else went wrong
      return rejectWithValue(error.message);
    }
  }
);


/**
 * Asynchronously updates the user's profile information.
 *
 * This function uses createAsyncThunk to handle the asynchronous operation of updating
 * a user's profile information in the backend. It sends the updated profile data to the server
 * and returns the updated user data if successful.
 *
 * @function updateUserProfile
 * @param {Object} data - The profile data to update
 * @param {string} data.first_name - User's first name
 * @param {string} data.last_name - User's last name
 * @param {string} data.nickname - User's nickname
 * @param {string} data.token - Optional token (will use cookie if not provided)
 * @returns {Promise<Object>} The updated user data if successful
 * @throws {string} An error message if the update fails
 */
export const updateUserProfile = createAsyncThunk(
  'user/updateUserProfile',
  async (data, { rejectWithValue }) => {
    try {
      const token = data?.userToken ?? Cookies.get('token');
      if (!token) {
        return rejectWithValue("Token is required");
      }

      const response = await axios.post(
        `${API_BASE_URL}/api/v1/user/update_personal_profile`,
        {
          first_name: data.first_name,
          last_name: data.last_name,
          nickname: data.nickname,
        },
        { headers: { Authorization: `Bearer ${data.userToken}` } }
      );

      if (response.status === 200 && response.data?.status_code === 200) {
        // Return the response data directly
        return {
          ...data,  // Keep other fields from the original data
          first_name: response?.data?.data?.first_name,
          last_name: response?.data?.data?.last_name,
          initials: response?.data?.data?.initials,
          nickname: response?.data?.data?.nickname,
          // Preserve existing userToken and other required fields
          userToken: data.userToken,
          // Map other fields as needed
        };
      } else {
        return rejectWithValue(response.data.message || "Failed to update profile");
      }
    } catch (error) {
      console.log('error', error);
      if (error.response) {
        return rejectWithValue(error.response.data.message || 'Server error');
      }
      if (error.request) {
        return rejectWithValue('No response from server');
      }
      return rejectWithValue(error.message || 'Unknown error');
    }
  }
);

/**
 * Asynchronously fetches the user's subscription information.
 * 
 * This function uses createAsyncThunk to handle the asynchronous operation of retrieving 
 * the user's subscription information from the server. It requires a valid authentication token 
 * and makes a request to the subscription API endpoint.
 * 
 * @function mySubscription
 * @param {Object} data - Optional data containing token
 * @param {string} data.userToken - Optional token (will use cookie if not provided)
 * @returns {Promise<Object>} The user subscription data if successful
 * @throws {string} An error message if the fetch fails
 */
export const mySubscription = createAsyncThunk(
  'user/mySubscription',
  async (data, { rejectWithValue }) => {
    try {
      const token = data?.userToken ?? Cookies.get('token');
      if (!token) {
        return rejectWithValue("Token is required");
      }
      
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/subscription/my_subscription`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      if (response.status === 200 && response.data?.status_code === 200) {
        // Return the user type data
        return response?.data?.data;
      } else {
        return rejectWithValue(response.data.message || "Failed to fetch subscription information");
      }
    } catch (error) {
      console.log('error', error);
      if (error.response) {
        return rejectWithValue(error.response.data.message || 'Server error');
      }
      if (error.request) {
        return rejectWithValue('No response from server');
      }
      return rejectWithValue(error.message || 'Unknown error');
    }
  }
);

const authenticateSlide = createSlice({
  name: 'authenticate',
  initialState,
  reducers: {
    loadCachedAccountInfo(state, action) {
      if (action.payload && typeof action.payload === 'object' && 'userToken' in action.payload) {
        state.accountInfo = action.payload;
        state.token = state.accountInfo.userToken || state.accountInfo.guestToken;
        state.status = 'succeeded';
      } else {
        console.error("Invalid payload structure:", action.payload);
      }
    }
  },
  extraReducers: (builder) => {
  builder
    .addCase(signInAnonymous.fulfilled, (state, action) => {
      const anonymousInfo = {...initialState.accountInfo};
      anonymousInfo.guestToken = action.payload.user_token;
      state.accountInfo = anonymousInfo;
      state.token = state.accountInfo.guestToken;
      state.status = 'succeeded';
      Cookies.set('token', state.accountInfo.guestToken, { expires: 30 }); // Set cookie to expire in 30 day
      Cookies.set('guestToken', state.accountInfo.guestToken, { expires: 30 });  // Set cookie to expire in 30 day
      Cookies.set('accountInfo', JSON.stringify(state.accountInfo), { expires: 30 }); // Set cookie to expire in 30 day
    })
    .addCase(signInAnonymous.rejected, (state, action) => {
      state.message = action.payload;
      state.status = 'error';
    })
    .addCase(handleEmailRegister.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(handleEmailRegister.fulfilled, (state, action) => {
      if (action.payload && typeof action.payload === 'object' && 'userToken' in action.payload) {
        state.accountInfo = action.payload;
        state.token = state.accountInfo.userToken;
        state.status = 'succeeded';
        Cookies.set('token', state.accountInfo.userToken, { expires: 30 }); // Set cookie to expire in 30 days
        Cookies.set('accountInfo', JSON.stringify(state.accountInfo), { expires: 30 }); // Set cookie to expire in 30 days
      } else {
        console.error("Invalid payload structure:", action.payload);
        state.status = 'error';
        state.message = "Invalid account information received";
      }
    })
    .addCase(handleEmailRegister.rejected, (state, action) => {
      state.status = 'error';
      state.message = action.payload;
    })
    .addCase(handleEmailLogin.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(handleEmailLogin.fulfilled, (state, action) => {
      if (action.payload && typeof action.payload === 'object' && 'userToken' in action.payload) {
        state.accountInfo = action.payload;
        state.token = state.accountInfo.userToken;
        state.status = 'succeeded';
        Cookies.set('token', state.accountInfo.userToken, { expires: 30 }); // Set cookie to expire in 30 days
        Cookies.set('accountInfo', JSON.stringify(state.accountInfo), { expires: 30 }); // Set cookie to expire in 30 days
      } else {
        console.error("Invalid payload structure:", action.payload);
        state.status = 'error';
        state.message = "Invalid account information received";
      }
    })
    .addCase(handleEmailLogin.rejected, (state, action) => {
      state.status = 'error';
      state.message = action.payload;
    })
    .addCase(handleGoogleSignIn.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(handleGoogleSignIn.fulfilled, (state, action) => {
      if (action.payload && typeof action.payload === 'object' && 'userToken' in action.payload) {
        state.accountInfo = action.payload;
        state.token = state.accountInfo.userToken;
        state.status = 'succeeded';
        Cookies.set('token', state.accountInfo.userToken, { expires: 30 }); // Set cookie to expire in 30 days
        Cookies.set('accountInfo', JSON.stringify(state.accountInfo), { expires: 30 }); // Set cookie to expire in 30 days
      } else {
        console.error("Invalid payload structure:", action.payload);
        state.status = 'error';
        state.message = "Invalid account information received";
      }
    })
    .addCase(handleGoogleSignIn.rejected, (state, action) => {
      state.status = 'error';
      state.message = action.payload;
    })
    .addCase(handleLogout.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(handleLogout.fulfilled, (state, action) => {
        const guestToken = state.accountInfo.guestToken || Cookies.get('guestToken');
        const guestCredits = state.accountInfo.guestCredits || 0;
        if (guestToken) {
          const anonymousInfo = {...initialState.accountInfo, guestToken: guestToken, guestCredits: guestCredits};
          state.accountInfo = anonymousInfo;
          state.token = guestToken;
          state.status = 'succeeded';
          Cookies.set('token', guestToken, { expires: 30 }); // Set cookie to expire in 30 days
          Cookies.set('accountInfo', JSON.stringify(state.accountInfo), { expires: 30 }); // Set cookie to expire in 30 days
        } else {
          state.status = 'idle';
          Cookies.remove('token');
          Cookies.remove('accountInfo');
        }
    })
    .addCase(handleLogout.rejected, (state, action) => {
      state.status = 'error';
      state.message = action.payload;
    })
    .addCase(getRemainingCredit.fulfilled, (state, action) => {
      // Ensure we're storing the actual number from the API response
      const creditValue = action.payload;
      console.log('Credit value from API in reducer:', creditValue);
      
      // Store the raw numeric value, not a string
      state.accountInfo.credit = creditValue; 
      
      if(state.token === state.accountInfo.guestToken) {
        state.accountInfo.guestCredits = creditValue;
        Cookies.set('guestCredits', state.accountInfo.guestCredits, { expires: 30 }); // Set cookie to expire in 30 days
      }
      
      // Store the updated accountInfo
      Cookies.set('accountInfo', JSON.stringify(state.accountInfo), { expires: 30 }); // Set cookie to expire in 30 days
      state.status = 'succeeded';
    })
    .addCase(getRemainingCredit.rejected, (state, action) => {
      state.status = 'error';
      state.message = action.payload;
    })
    .addCase(updateUserProfile.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(updateUserProfile.fulfilled, (state, action) => {      
      if (action.payload && typeof action.payload === 'object' && 'userToken' in action.payload) {
        state.accountInfo = { ...state.accountInfo, ...action.payload };
        state.token = state.accountInfo.userToken;
        state.status = 'succeeded';
        Cookies.set('token', state.accountInfo.userToken, { expires: 30 }); // Update cookie
        Cookies.set('accountInfo', JSON.stringify(state.accountInfo), { expires: 30 }); // Update account info in cookie
      } else {
        console.error("Invalid payload structure:", action.payload);
        state.status = 'error';
        state.message = "Invalid account information received";
      }
    })
    .addCase(updateUserProfile.rejected, (state, action) => {
      state.status = 'error';
      state.message = action.payload;
    })
    .addCase(getUserProfile.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(getUserProfile.fulfilled, (state, action) => {      
      if (action.payload && typeof action.payload === 'object') {
        state.accountInfo = { ...state.accountInfo, ...action.payload };
        state.status = 'succeeded';
        Cookies.set('token', state.accountInfo.userToken, { expires: 30 }); // Update cookie
        Cookies.set('accountInfo', JSON.stringify(state.accountInfo), { expires: 30 }); // Update account info in cookie
      } else {
        console.error("Invalid payload structure:", action.payload);
        state.status = 'error';
        state.message = "Invalid account information received";
      }
    })
    .addCase(getUserProfile.rejected, (state, action) => {
      state.status = 'error';
      state.message = action.payload;
    })
    .addCase(mySubscription.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(mySubscription.fulfilled, (state, action) => {
      if (action.payload && typeof action.payload === 'object') {        
      state.accountInfo = { ...state.accountInfo, mySubscription: action.payload };
      state.status = 'succeeded';
      Cookies.set('accountInfo', JSON.stringify(state.accountInfo), { expires: 30 }); // Update account info in cookie
      } else {
      console.error("Invalid payload structure:", action.payload);
      state.status = 'error';
      state.message = "Invalid subscription information received";
      }
    })
    .addCase(mySubscription.rejected, (state, action) => {
      state.status = 'error';
      state.message = action.payload;
    })
  }
})

export const { loadCachedAccountInfo } = authenticateSlide.actions

export const selectAccountInfo = (state) => state.authenticate.accountInfo
export const selectRemainingCredits = (state) => state.authenticate.accountInfo.credit
export const selectToken = (state) => state.authenticate.token
export const selectAuthenticateStatus = (state) => state.authenticate.status
export const selectAuthenticateMessage = (state) => state.authenticate.message

export default authenticateSlide.reducer