// API and environment constants
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

// News API pricing constants
export const NEWS_API_PRICE_PER_REQUEST = 0.00167; // Based on GNews Essential tier (€49.99/30,000 requests)
export const VIDEO_PRODUCTION_BASE_PRICE = 0.99; // Base price per video
export const VIDEO_PRODUCTION_LENGTH_MULTIPLIER = 0.49; // Additional cost per minute

// GNews API configuration
export const GNEWS_API_SEARCH_URL = 'https://gnews.io/api/v4/search';
export const GNEWS_API_HEADLINES_URL = 'https://gnews.io/api/v4/top-headlines';
// Use environment variable for API key with fallback
export const GNEWS_API_KEY = process.env.REACT_APP_GNEWS_API_KEY || '660b78eb5cd3722016773cbfa9a98cf8';

// Request limits
export const DEFAULT_FREE_TIER_REQUESTS = 100; // Free tier daily limit for GNews API 