import React from 'react';
import TamSamSomViewer from './glow_10_viewer_tamsamsom';
import ViewerHeader from '../common/ViewerHeader';
import processConfig from '../../config/processIndex.json';
import ViewFooter from '../common/ViewFooter';
import { Box, Button } from '@mui/material';
import { SAMPLE_GLOW_10 } from '../../data/sampleGlowData';

const Glow10Processor = ({ data, onComplete, sessionId, isLandingPage = false, hideHeader = false }) => {
  const process = processConfig.processes.find(p => p.id === 'glow_10');
  
  // console.log('Glow10Processor received data:', data);

  // Handle both data structures (direct and wrapped in market_analysis)
  const marketData = data?.glow_10_response || data?.market_analysis;
  const hasRealData = marketData?.tam?.value;
  
  // Create the correct data structure for the viewer
  const displayData = hasRealData ? { glow_10_response: marketData } : SAMPLE_GLOW_10;
  const isDemo = !hasRealData;

  if (!process) {
    return <div>Process configuration not found</div>;
  }

  return (
    <Box>
      {/* Show watermark only when it's demo data AND not on landing page */}
      {isDemo && !isLandingPage && (
        <Box sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) rotate(-45deg)',
          color: 'rgba(255, 140, 0, 0.45)',
          fontSize: '48px',
          fontWeight: 'bold',
          pointerEvents: 'none',
          zIndex: 9999,
          width: '100%',
          textAlign: 'center',
          userSelect: 'none',
          textTransform: 'uppercase'
        }}>
          Sample (Glowtest)
        </Box>
      )}

      {/* Show header unless explicitly hidden for landing page */}
      {!isLandingPage && !hideHeader && (
        <ViewerHeader 
          title={process.info.title}
          subtitle={process.info.description}
          processId="glow_09"
          sessionId={sessionId}
          showRerun={!!process.endpoints.run}
        />
      )}

      {/* Rest of your component */}
      <TamSamSomViewer data={displayData.glow_10_response} isLandingPage={isLandingPage} hideHeader={hideHeader} />
      
      {/* Only show ViewFooter when not on landing page */}
      {!isLandingPage && (
        <ViewFooter
          isDisabled={!sessionId} 
          onComplete={onComplete}
          buttonText={process?.footer?.continueText || "Continue"}
          processId="glow_10"
        />
      )}
    </Box>
  );
};

export default Glow10Processor;