import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import Cookies from 'js-cookie';
import processConfig from '../../config/processIndex.json';
import { ProcessChainManager } from '../../utils/processChain';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
  // Update process order to use all chains

const processOrder = [
  ...processConfig.processChains.ideaChain,
  ...processConfig.processChains.videoChain,
  ...processConfig.processChains.advertisementChain
].filter((value, index, self) => self.indexOf(value) === index)

// Create a structure for the Glow steps data that's consistent with session slice
const emptyGlowStepsState = {
  glow_61: null,
  glow_62: null,
  glow_63: null,
  glow_08: {
    answers: {
      stage: null,
      validation: null,
      team_expertise: null,
      team: null,
      budget: null,
      goal: null
    },
    status: 'idle',
    error: null
  },
  glow_33: {
    answers: {
      amount: null,
      groupNames: [],
      goal: null
    },
    status: 'idle',
    error: null
  },
  glow_91: null,
  glow_92: null
};

const initialState = {
  data: {
    sessionId: '',
    sessionName: '',
    companyId: '',
    companyName: '',
    companyStatus: '',
    inputPrompt: '',
    glowData: {
      glow_01: { status: false, response: {} },
      glow_02: { status: false, response: {} },
      glow_03: { status: false, response: {} },
      glow_04: { status: false, response: {} },
      glow_08: { status: false, response: {} },
      glow_09: { status: false, response: {} },
      glow_10: { status: false, response: {} },
      glow_11: { status: false, response: {} },
      glow_12: { status: false, response: {} },
      glow_21: { status: false, response: {} },
      glow_33: { status: false, response: {} },
      glow_61: { status: false, response: {} },
      glow_62: { status: false, response: {} },
      glow_63: { status: false, response: {} },
      glow_91: { status: false, response: {} },
    },
    // Add glowSteps directly under the session data
    glowSteps: { ...emptyGlowStepsState },
    glowIdsMap: null,
    imageData: null,
  },
  process: '',
  status: 'idle',
  message: '',
};



const handleProcessPayload = async (processId, sessionId, inputPrompt, glowData) => {
  const currentProcessIndex = processOrder.indexOf(processId);
  const prevProcessId = currentProcessIndex > 0 ? processOrder[currentProcessIndex - 1] : null;
  const prevProcessResponse = glowData[prevProcessId] || {};
  
  // Create a default payload for all steps
  const payload = {
    session_id: sessionId,
    input_prompt: inputPrompt,
    process_id: processId,
    previous_process: prevProcessId,
    previous_response: prevProcessResponse,
    options: {
      max_length: processConfig.processes.find(p => p.id === processId)?.validations?.maxLength?.description || 2000
    }
  };

  // Move glow_01's video type handling directly to glow_04
  if (processId === 'glow_04') {
    const glow62Response = glowData['glow_62']['response']['glow_62_response'] || null;
    if (glow62Response && glow62Response.selectedType) {
      payload.process_id = 'glow_01'
      payload.video_type = glow62Response.selectedType.id;
      payload.target_video_duration = parseInt(glow62Response.selectedType.dur) * 60; // Convert minutes to seconds
    } else {
      return 'Please fill in the video format details first in the previous step (Video Type Selection)';
    }
  }
  
  return payload;
}

export const runProcess = createAsyncThunk(
  'session/runProcess',
  async ({ processId, inputData = {}, option = {} }, { rejectWithValue, getState  }) => {
    // Check if status is loading and process equals processId then return
    const state = getState();
    // if (state.session.status === 'loading' && state.session.process === processId)
    //   return rejectWithValue('Process is already running');
    const process = processConfig.processes.find(p => p.id === processId) || processConfig.initialProcess;
    if (!process) {
      return rejectWithValue(`Process ${processId} not found in configuration`);
    }
    
    const endpoint = `${process.endpoints.run}`;
    if (!endpoint || endpoint === 'undefined') {
      // Return empty data with the option values
      return { 
        glowId: processId, 
        data: {}, 
        status: false, 
        ready: true
      };
    }
    const payload = option?.payload || await handleProcessPayload(processId, state.session.data.sessionId, state.session.data.inputPrompt, state.session.data.glowData);
    if (typeof payload === 'string') {
      return rejectWithValue(payload);
    }
    // Merge inputData with payload
    Object.assign(payload, inputData);

    console.log(`Running ${processId} with payload:`, payload);

    try {
      const token = Cookies.get('token');
      if (!token) {
        return rejectWithValue("Token is required");
      }
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/${endpoint}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      if (!response.data.status) {
        return rejectWithValue(`Error running ${processId}: ${response.data.message}`);
      }
      return { 
        glowId: processId, 
        data: response.data.data, 
        status: option.status !== undefined ? option.status : true,
        ready: true
      };
    } catch (error) {
      console.log('error', error);
      // Something else went wrong
      return rejectWithValue(error.message);
    }
  }
);

export const updateGlowTextResponse = createAsyncThunk(
  'session/updateGlowTextResponse',
  async ({ processId, updatedResponse }, { rejectWithValue, getState  }) => {
    // Check if status is loading and process equals processId then return
    const state = getState();
    try {
      const token = Cookies.get('token');
      if (!token) {
        return rejectWithValue("Token is required");
      }
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/glow_dash/save_preference`,
        {
          session_id: state.session.data.sessionId,
          module_name: processId,
          module_data: updatedResponse
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      if (!response.data.status) {
        return rejectWithValue(`Error updating glow text response: ${response.data.message}`);
      }
      return { status: true, processId: processId, data: updatedResponse };
    } catch (error) {
      console.log('error', error);
      if (error.response) {
        return rejectWithValue(error.response.data.message || 'Server error');
      }
      if (error.request) {
        return rejectWithValue('No response from server');
      }
      return rejectWithValue(error.message || 'Unknown error');
    }
  }
);

export const fetchGlowIdsMap = createAsyncThunk(
  'session/fetchGlowIdsMap',
  async (sessionId, { rejectWithValue }) => {
    try {
      const token = Cookies.get('token');
      if (!token) {
        return rejectWithValue("Token is required");
      }
      
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/glow_text/get_glow_ids_map`,
        { session_id: sessionId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (!response.data.status) {
        return rejectWithValue(`Error fetching glow IDs map: ${response.data.message}`);
      }
      
      return response.data.data;
    } catch (error) {
      console.log('error', error);
      if (error.response) {
        return rejectWithValue(error.response.data.message || 'Server error');
      }
      if (error.request) {
        return rejectWithValue('No response from server');
      }
      return rejectWithValue(error.message || 'Unknown error');
    }
  }
);

// Add a new async thunk for fetching image data
export const fetchImageData = createAsyncThunk(
  'session/fetchImageData',
  async (sessionId, { rejectWithValue, getState }) => {
    try {
      const token = Cookies.get('token');
      if (!token) {
        return rejectWithValue("Token is required");
      }
      
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/workflow/get_image_data`,
        { session_id: sessionId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (!response.data.status) {
        return rejectWithValue(`Error fetching image data: ${response.data.message}`);
      }
      
      return response.data.data;
    } catch (error) {
      console.log('error', error);
      // More specific error handling
      if (error.response) {
        return rejectWithValue(error.response.data.message || 'Server error');
      }
      if (error.request) {
        return rejectWithValue('No response from server');
      }
      return rejectWithValue(error.message || 'Unknown error');
    }
  }
);

const processSessionData = async (sessionData) => {
  const mappedData = {
    sessionId: sessionData.session_id || '',
    sessionName: sessionData.session_name || '',
    companyId: sessionData.company_id || '',
    companyName: sessionData.company_name || '',
    companyStatus: sessionData.company_status || '',
    inputPrompt: sessionData.input_prompt || '',
    glowData: {
      glow_01: { 
        status: sessionData.glow_01_status || false, 
        ready: !!sessionData.glow_01_data && Object.keys(sessionData.glow_01_data || {}).length > 0, 
        response: sessionData.glow_01_data || {} 
      },
      glow_02: { 
        status: sessionData.glow_02_status || false, 
        ready: !!sessionData.glow_02_data && Object.keys(sessionData.glow_02_data || {}).length > 0, 
        response: sessionData.glow_02_data || {} 
      },
      glow_03: { 
        status: sessionData.glow_03_status || false, 
        ready: !!sessionData.glow_03_data && Object.keys(sessionData.glow_03_data || {}).length > 0, 
        response: sessionData.glow_03_data || {} 
      },
      glow_04: { 
        status: sessionData.glow_04_status || false, 
        ready: !!sessionData.glow_04_data && Object.keys(sessionData.glow_04_data || {}).length > 0, 
        response: sessionData.glow_04_data || {} 
      },
      glow_08: { 
        status: sessionData.glow_08_status || false, 
        ready: !!sessionData.glow_08_data && Object.keys(sessionData.glow_08_data || {}).length > 0, 
        response: sessionData.glow_08_data || {} 
      },
      glow_09: { 
        status: sessionData.glow_09_status || false, 
        ready: !!sessionData.glow_09_data && Object.keys(sessionData.glow_09_data || {}).length > 0, 
        response: sessionData.glow_09_data || {} 
      },
      glow_10: { 
        status: sessionData.glow_10_status || false, 
        ready: !!sessionData.glow_10_data && Object.keys(sessionData.glow_10_data || {}).length > 0, 
        response: sessionData.glow_10_data || {} 
      },
      glow_11: { 
        status: sessionData.glow_11_status || false, 
        ready: !!sessionData.glow_11_data && Object.keys(sessionData.glow_11_data || {}).length > 0, 
        response: sessionData.glow_11_data || {} 
      },
      glow_12: { 
        status: sessionData.glow_12_status || false, 
        ready: !!sessionData.glow_12_data && Object.keys(sessionData.glow_12_data || {}).length > 0, 
        response: sessionData.glow_12_data || {} 
      },
      glow_21: { 
        status: sessionData.glow_21_status || false, 
        ready: !!sessionData.glow_21_data && Object.keys(sessionData.glow_21_data || {}).length > 0, 
        response: sessionData.glow_21_data || {} 
      },
      glow_33: { 
        status: sessionData.glow_33_status || false, 
        ready: !!sessionData.glow_33_data && Object.keys(sessionData.glow_33_data || {}).length > 0, 
        response: sessionData.glow_33_data || {} 
      },
      glow_61: { 
        status: sessionData.glow_61_status || false, 
        ready: !!sessionData.glow_61_data && Object.keys(sessionData.glow_61_data || {}).length > 0, 
        response: sessionData.glow_61_data || {} 
      },
      glow_62: { 
        status: sessionData.glow_62_status || false, 
        ready: !!sessionData.glow_62_data && Object.keys(sessionData.glow_62_data || {}).length > 0, 
        response: sessionData.glow_62_data || {} 
      },
      glow_63: { 
        status: sessionData.glow_63_status || false, 
        ready: !!sessionData.glow_63_data && Object.keys(sessionData.glow_63_data || {}).length > 0, 
        response: sessionData.glow_63_data || {} 
      },
      glow_91: { 
        status: sessionData.glow_91_status || false, 
        ready: !!sessionData.glow_91_data && Object.keys(sessionData.glow_91_data || {}).length > 0, 
        response: sessionData.glow_91_data || {} 
      },
    }
  };

  return mappedData;
}

export const getSessionData = createAsyncThunk(
  'session/getSessionData', 
  async (sessionId, { rejectWithValue }) => {
    const cachedData = localStorage.getItem(sessionId);
    if (cachedData) {
      const sessionData = JSON.parse(cachedData);
      return sessionData;
    } else {
      try {
        const token = Cookies.get('token');
        if (!token) {
          return rejectWithValue("Token is required");
        }
        const response = await axios.post(
          `${API_BASE_URL}/api/v1/session/get_session_details`,
          { session_id: sessionId },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data.status) {
          const sessionData = await processSessionData(response.data.data.session_details);
          localStorage.setItem(sessionData.sessionId, JSON.stringify(sessionData));
          // Cache the response
          return sessionData;
        }
        return rejectWithValue('Session data not found');
      } catch (error) {
        console.log('error', error);
        // More specific error handling
        if (error.response) {
          // Server responded with error
          return rejectWithValue(error.response.data.message || 'Server error');
        }
        if (error.request) {
          // No response received
          return rejectWithValue('No response from server');
        }
        // Something else went wrong
        return rejectWithValue(error.message || 'Unknown error');
      }
    }
});

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    updateGlowData(state, action) {
      const { glowId, data } = action.payload;
      if (state.data && state.data.glowData && data) {
        // Create the property if it doesn't exist
        if (!state.data.glowData[glowId]) {
          state.data.glowData[glowId] = { status: false, response: {}, ready: false };
        }
        
        // Update the data
        state.data.glowData[glowId].status = true;
        state.data.glowData[glowId].response = data;
        
        // Save to localStorage
        if (state.data.sessionId) {
          localStorage.setItem(state.data.sessionId, JSON.stringify(state.data));
        }
        
        state.status = 'succeeded';
      } else {
        state.status = 'error';
        state.message = 'Invalid glowId or payload data is not valid';
      }
    },
    setSessionId(state, action) {
      state.data.sessionId = action.payload;
    },
    setInputPrompt(state, action) {
      state.data.inputPrompt = action.payload;
    },
    clearSessionData(state) {
      state.data = {...initialState.data}; // Reset to initial state
    },
    updateGlowReady(state, action) {
      const {glowId, ready} = action.payload
      if (state.data && state.data.glowData) {
        // Create the property if it doesn't exist
        if (!state.data.glowData[glowId]) {
          state.data.glowData[glowId] = { status: false, response: {}, ready: false };
        }
        
        // Now we can safely update the ready property
        state.data.glowData[glowId].ready = ready;
        
        // Save to localStorage
        if (state.data.sessionId) {
          localStorage.setItem(state.data.sessionId, JSON.stringify(state.data));
        }
      }
    },
    updateGlowStatus(state, action) {
      const {glowId, status} = action.payload;
      console.log(`Updating status for ${glowId} to ${status}`);
      
      if (state.data && state.data.glowData) {
        // Create the property if it doesn't exist
        if (!state.data.glowData[glowId]) {
          state.data.glowData[glowId] = { status: false, response: {}, ready: false };
        }
        
        // Update the status immediately ONLY for the specified glowId
        state.data.glowData[glowId].status = status;
        
        // Don't automatically mark as ready - let the updateGlowReady action handle that separately
        // This separation allows better control over when a process is considered "ready"
        
        // Save to localStorage immediately
        if (state.data.sessionId) {
          localStorage.setItem(state.data.sessionId, JSON.stringify(state.data));
        }
      }
    },
    // Add setCurrentSession reducer
    setCurrentSession: (state, action) => {
      const sessionId = action.payload;
      // console.log(`Setting current session to: ${sessionId}`);
      
      // Create the glowSteps for this session if they don't exist
      if (!state.data.glowSteps) {
        state.data.glowSteps = emptyGlowStepsState;
      }
      
      // Set the session ID in the session data
      state.data.sessionId = sessionId;
      
      if (sessionId) {
        localStorage.setItem('currentSessionId', sessionId);
      }
    },
    updateGlowStep(state, action) {
      const { stepId, data } = action.payload;
      console.log(`Updating ${stepId} with:`, data);
      
      // Make sure glowSteps exists
      if (!state.data.glowSteps) {
        state.data.glowSteps = { ...emptyGlowStepsState };
      }
      
      state.data.glowSteps[stepId] = data;
      
      // Save to localStorage if we have a sessionId
      if (state.data.sessionId) {
        localStorage.setItem(state.data.sessionId, JSON.stringify(state.data));
      }
    },
    
    clearGlowSteps(state) {
      // Reset glowSteps to initial state
      state.data.glowSteps = { ...emptyGlowStepsState };
      
      // Save to localStorage if we have a sessionId
      if (state.data.sessionId) {
        localStorage.setItem(state.data.sessionId, JSON.stringify(state.data));
      }
    },
    
    updateGlow08Answer(state, action) {
      // Ensure glowSteps and glow_08 exist
      if (!state.data.glowSteps) {
        state.data.glowSteps = { ...emptyGlowStepsState };
      }
      
      if (!state.data.glowSteps.glow_08) {
        state.data.glowSteps.glow_08 = { ...emptyGlowStepsState.glow_08 };
      }
      
      state.data.glowSteps.glow_08.answers = {
        ...state.data.glowSteps.glow_08.answers,
        ...action.payload
      };
      
      // Save to localStorage if we have a sessionId
      if (state.data.sessionId) {
        localStorage.setItem(state.data.sessionId, JSON.stringify(state.data));
      }
    },
    
    setGlow08Status(state, action) {
      // Ensure glowSteps and glow_08 exist
      if (!state.data.glowSteps) {
        state.data.glowSteps = { ...emptyGlowStepsState };
      }
      
      if (!state.data.glowSteps.glow_08) {
        state.data.glowSteps.glow_08 = { ...emptyGlowStepsState.glow_08 };
      }
      
      state.data.glowSteps.glow_08.status = action.payload;
      
      // Save to localStorage if we have a sessionId
      if (state.data.sessionId) {
        localStorage.setItem(state.data.sessionId, JSON.stringify(state.data));
      }
    },
    
    clearGlow08(state) {
      // Ensure glowSteps exists
      if (!state.data.glowSteps) {
        state.data.glowSteps = { ...emptyGlowStepsState };
      }
      
      state.data.glowSteps.glow_08 = { ...emptyGlowStepsState.glow_08 };
      
      // Save to localStorage if we have a sessionId
      if (state.data.sessionId) {
        localStorage.setItem(state.data.sessionId, JSON.stringify(state.data));
      }
    },
    
    updateGlow33Answer(state, action) {
      console.log('Updating glow_33 answer:', action.payload);
      
      // Ensure glowSteps and glow_33 exist
      if (!state.data.glowSteps) {
        state.data.glowSteps = { ...emptyGlowStepsState };
      }
      
      if (!state.data.glowSteps.glow_33) {
        state.data.glowSteps.glow_33 = { ...emptyGlowStepsState.glow_33 };
      }
      
      state.data.glowSteps.glow_33.answers = {
        ...state.data.glowSteps.glow_33.answers,
        ...action.payload
      };
      
      // Save to localStorage if we have a sessionId
      if (state.data.sessionId) {
        localStorage.setItem(state.data.sessionId, JSON.stringify(state.data));
      }
    },
    
    setGlow33Status(state, action) {
      // Ensure glowSteps and glow_33 exist
      if (!state.data.glowSteps) {
        state.data.glowSteps = { ...emptyGlowStepsState };
      }
      
      if (!state.data.glowSteps.glow_33) {
        state.data.glowSteps.glow_33 = { ...emptyGlowStepsState.glow_33 };
      }
      
      state.data.glowSteps.glow_33.status = action.payload;
      
      // Save to localStorage if we have a sessionId
      if (state.data.sessionId) {
        localStorage.setItem(state.data.sessionId, JSON.stringify(state.data));
      }
    },
    
    clearGlow33(state) {
      // Ensure glowSteps exists
      if (!state.data.glowSteps) {
        state.data.glowSteps = { ...emptyGlowStepsState };
      }
      
      state.data.glowSteps.glow_33 = { ...emptyGlowStepsState.glow_33 };
      
      // Save to localStorage if we have a sessionId
      if (state.data.sessionId) {
        localStorage.setItem(state.data.sessionId, JSON.stringify(state.data));
      }
    },
    setGlowIdsMap(state, action) {
      state.data.glowIdsMap = action.payload;
      // Save to localStorage if we have a sessionId
      if (state.data.sessionId) {
        localStorage.setItem(state.data.sessionId, JSON.stringify(state.data));
      }
    },
    setImageData(state, action) {
      state.data.imageData = action.payload;
      // Save to localStorage if we have a sessionId
      if (state.data.sessionId) {
        localStorage.setItem(state.data.sessionId, JSON.stringify(state.data));
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSessionData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSessionData.fulfilled, (state, action) => {
        // Preserve loading status for in-progress processes
        const loadingStates = {};
        if (state.data && state.data.glowData) {
          Object.keys(state.data.glowData).forEach(processId => {
            if (state.data.glowData[processId]?.loading) {
              loadingStates[processId] = true;
            }
          });
        }
        
        // Merge action.payload with initialState as before
        state.data = { 
          ...initialState.data, 
          ...action.payload,
          glowData: {
            ...initialState.data.glowData,
            ...action.payload.glowData,
          }
        };
        
        // Restore loading states for processes that were in progress
        if (state.data && state.data.glowData) {
          Object.keys(loadingStates).forEach(processId => {
            if (state.data.glowData[processId]) {
              state.data.glowData[processId].loading = true;
            }
          });
        }
        
        console.log('Session data:', state.data);
        state.status = 'succeeded';
      })
      .addCase(getSessionData.rejected, (state, action) => {
        state.status = 'error';
        state.message = action.payload;
      })
      .addCase(runProcess.pending, (state, action) => {
        state.status = 'loading';
        state.data.glowData[action.meta.arg.processId].loading = true; // Set loading to true for the process
        state.process = action.meta.arg.processId; // Set state.process to processId
      })
      .addCase(runProcess.fulfilled, (state, action) => {      
        const { glowId, data, status } = action.payload;
        const options = action.meta.arg.option || {}; // Get options from the action
        
        // Define excluded processes that should never be automatically marked as ready
        const excludedProcesses = ['glow_82', 'glow_102'];
        state.data.glowData[glowId].loading = false; // Set loading to false for the process
        if (state.data && state.data.glowData && data) {
          // Create the property if it doesn't exist
          if (!state.data.glowData[glowId]) {
            state.data.glowData[glowId] = { status: false, response: {}, ready: true };
          }
          
          // Update the data
          // Use the status from options if provided, otherwise use the payload status
          state.data.glowData[glowId].status = false;
          
          // Only use ready flag from options - don't default to true if not specified
          // This prevents auto-setting all processes to ready
          state.data.glowData[glowId].ready = true;
          if (!excludedProcesses.includes(glowId)) {
            // Only set ready based on data existence for non-excluded processes
            // The presence of actual API data means this process should be 'ready'
            const hasData = !!data && Object.keys(data).length > 0;
            state.data.glowData[glowId].ready = hasData;
          }
          state.data.glowData[glowId].response = data;
          
          // // Debug log the state update
          // console.log(`Updated process state for ${glowId}:`, {
          //   status: state.data.glowData[glowId].status,
          //   ready: state.data.glowData[glowId].ready,
          //   loading: state.data.glowData[glowId].loading,
          //   hasData: !!data && Object.keys(data).length > 0,
          //   fromOptions: options.ready !== undefined
          // });
          
          // Save to localStorage
          if (state.data.sessionId) {
            localStorage.setItem(state.data.sessionId, JSON.stringify(state.data));
          }
          
          state.process = ''; // Reset process
          state.status = 'completed';
        } else {
          state.status = 'error';
          state.message = `Invalid glowId: ${glowId} or payload data is not valid`;
        }
      })
      .addCase(runProcess.rejected, (state, action) => {
        state.status = 'error';
        state.message = action.payload;
        state.data.glowData[action.meta.arg.processId].loading = false; // Set loading to false for the process
        console.log('Error:', action);
        
      })
      .addCase(updateGlowTextResponse.pending, (state) => {
        state.status = 'loading'; // Set status to loading when the request is pending
      })
      .addCase(updateGlowTextResponse.fulfilled, (state, action) => {
        const { processId, data } = action.payload;
        if (state.data && state.data.glowData && data) {
          // Create the property if it doesn't exist
          if (!state.data.glowData[processId]) {
            state.data.glowData[processId] = { status: false, response: {}, ready: false };
          }
          
          // Update the data
          state.data.glowData[processId].response = data; // Update the response
          state.data.glowData[processId].status = true; // Set status to true
          
          // Save to localStorage
          if (state.data.sessionId) {
            localStorage.setItem(state.data.sessionId, JSON.stringify(state.data));
          }
          
          state.status = 'succeeded'; // Update status
        } else {
          state.status = 'error';
          state.message = `Failed to update glow text response for process: ${processId}`;
        }
      })
      .addCase(updateGlowTextResponse.rejected, (state, action) => {
        state.status = 'error'; // Set status to error when the request is rejected
        state.message = action.payload; // Set the error message from the action payload
      })
      .addCase(fetchGlowIdsMap.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGlowIdsMap.fulfilled, (state, action) => {
        state.data.glowIdsMap = action.payload;
        state.status = 'succeeded';
        
        // Save to localStorage
        if (state.data.sessionId) {
          localStorage.setItem(state.data.sessionId, JSON.stringify(state.data));
        }
      })
      .addCase(fetchGlowIdsMap.rejected, (state, action) => {
        state.status = 'error';
        state.message = action.payload;
      })
      .addCase(fetchImageData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchImageData.fulfilled, (state, action) => {
        state.data.imageData = action.payload;
        state.status = 'succeeded';
        
        // Save to localStorage
        if (state.data.sessionId) {
          localStorage.setItem(state.data.sessionId, JSON.stringify(state.data));
        }
      })
      .addCase(fetchImageData.rejected, (state, action) => {
        state.status = 'error';
        state.message = action.payload;
      })
  },
});

// Export actions
export const {
  updateGlowData,
  clearSessionData,
  setSessionId,
  setInputPrompt,
  updateGlowReady,
  setCurrentSession,
  updateGlowStep,
  clearGlowSteps,
  updateGlow08Answer,
  setGlow08Status,
  clearGlow08,
  updateGlow33Answer,
  setGlow33Status,
  clearGlow33,
  updateGlowStatus,
  setGlowIdsMap,
  setImageData,
} = sessionSlice.actions;

export default sessionSlice.reducer;

export const selectSessionId = (state) => state.session.data.sessionId
export const selectInputPrompt = (state) => state.session.data.inputPrompt
export const selectSessionProcess = (state) => {
  // Geting process name from processConfig by state.session.process
  const process = processConfig.processes.find(p => p.id === state.session.process) || processConfig.initialProcess;
  return process.displayName;
}
export const selectSessionStatus = (state) => state.session.status
export const selectSessionMessage = (state) => state.session.message
export const selectSessionData = (state) => state.session.data
export const selectGlowData = (state, glowId) => {
  if(!state.session.data.glowData) return {}; // Return empty object if glowData is not available
  if(glowId === 'idea_description')
    return {};
  return state.session.data.glowData[glowId] ? state.session.data.glowData[glowId].response : {};
}
export const selectGlowStatus = (state, glowId) => {
  if(!state.session.data.glowData) return false;
  if(glowId === 'idea_description')
    return state.session.data.inputPrompt !== '';
  return state.session.data.glowData[glowId] ? state.session.data.glowData[glowId].status : false; // Return false if glowId does not exist
}

export const readyGlowStatus = (state, glowId) => {
  if(!state.session.data.glowData) return false;
  if(glowId === 'idea_description')
    return state.session.data.inputPrompt !== '';
  return state.session.data.glowData[glowId] ? state.session.data.glowData[glowId].ready || false : false; // Use ready property
}

export const loadingGlowStatus = (state) => {
  if (!state.session.data.glowData) return {};
  // Create an object of all glow loading statuses
  return ProcessChainManager.processOrder.reduce((acc, glowId) => {
    acc[glowId] = glowId === 'idea_description' ? 
      false : 
      state.session.data.glowData[glowId]?.loading || false;
    return acc;
  }, {});
}

// Add new selectors for glowSteps
export const selectGlowStep = (state, stepId) => {
  return state.session.data.glowSteps?.[stepId] || null;
};

export const selectAllGlowSteps = (state) => {
  return state.session.data.glowSteps || emptyGlowStepsState;
};

export const selectGlow08Answers = (state) => {
  return state.session.data.glowSteps?.glow_08?.answers || emptyGlowStepsState.glow_08.answers;
};

export const selectGlow08Status = (state) => {
  return state.session.data.glowSteps?.glow_08?.status || 'idle';
};

export const selectGlow33Answers = (state) => {
  return state.session.data.glowSteps?.glow_33?.answers || emptyGlowStepsState.glow_33.answers;
};

export const selectGlow33Status = (state) => {
  return state.session.data.glowSteps?.glow_33?.status || 'idle';
};

// Add new selector for glowIdsMap
export const selectGlowIdsMap = (state) => state.session.data.glowIdsMap;

// Add a selector to get the image data
export const selectImageData = (state) => state.session.data.imageData;


