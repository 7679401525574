import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Tabs,
  Tab,
  Avatar,
  Paper,
  Chip,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { FaUser, FaCrown } from "react-icons/fa";
import MonetizationDialog from "./monetization";
import { Clear, Save, InfoOutlined, Edit } from "@mui/icons-material";
// Remove incorrect import
// import { dispatch } from "d3";
// Add the correct useDispatch hook
import { useDispatch } from "react-redux";
import { updateUserProfile } from "../../auth/authenticateSlide";

const ANONYMOUS_USER = "user01_unreg";
const REGISTER_USER = "user02_reg";
const PAYING_USER = "user03_paying";

const PLUS_USER = "user03_01_plus";
const PRO_USER = "user03_02_pro";
const PREMIUM_USER = "user03_03_premium";
const LIFETIME_USER = "user03_04_lifetime";

const USER_TYPES = {
  [ANONYMOUS_USER]: "guest",
  [REGISTER_USER]: "register",
  [PAYING_USER]: "paying",
};

const SUBCRIPTION_TYPES = {
  [PLUS_USER]: "plus",
  [PRO_USER]: "pro",
  [PREMIUM_USER]: "premium",
  [LIFETIME_USER]: "lifetime",
};

const StyledTab = styled(Tab)(({ theme }) => ({
  alignItems: "flex-start",
  minWidth: "200px",
  padding: "20px",
}));

const InfoRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "8px",
  gap: "16px",
  justifyContent: "space-between",
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  minWidth: "120px",
  fontWeight: "bold",
  color: "#666",
}));

const ProfilePage = ({ open, onClose, accountInfo, userProfileLoading, openedProfileTab, userTypeLoading }) => {
  // console.log("accountInfo", accountInfo);
  
  // Add this line to get the dispatch function from Redux
  const dispatch = useDispatch();
  // Set the initial active tab based on the openedProfileTab prop
  const [activeTab, setActiveTab] = useState(openedProfileTab || 0);
  const [monetizationOpen, setMonetizationOpen] = useState(false);
  // Add success notification dialog state
  const [successDialog, setSuccessDialog] = useState(false);
  const [editMode, setEditMode] = useState({
    initials: false,
    nickname: false,
    first_name: false,
    last_name: false,
    email: false,
    last_login_at: false,
  });
  const userData = accountInfo || {
    initials: "JD",
    nickname: "Johnny",
    first_name: "John",
    last_name: "Doe",
    email: "john.doe@example.com",
    last_login_at: "2024-01-20 14:30",
    picture: "https://images.unsplash.com/photo-1633332755192-727a05c4013d",
    subscription_type: "Premium",
    userType: "user02_reg",
    subscription: {
      plan: "Premium",
      status: "Active",
      validUntil: "2024-12-31",
    },
  };

  const [formData, setFormData] = useState(userData);
  
  // Create refs for editable fields
  const inputRefs = {
    nickname: useRef(null),
    first_name: useRef(null),
    last_name: useRef(null),
    email: useRef(null),
    initials: useRef(null),
    last_login_at: useRef(null),
  };

  // Create a stable ref for the currently edited field
  const activeEditFieldRef = useRef(null);

  // Focus input field when entering edit mode
  useEffect(() => {
    Object.keys(editMode).forEach(field => {
      if (editMode[field] && inputRefs[field]?.current) {
        inputRefs[field].current.focus();
      }
    });
  }, [editMode]);
  
  // Update formData whenever userData changes
  useEffect(() => {
    setFormData(userData);
  }, [userData]);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const toggleMonetizationDialog = () => {
    setMonetizationOpen(!monetizationOpen);
  };

  // Remember which field is being edited
  const handleEditClick = (field) => {
    if (editMode[field]) {
      setFormData((prev) => ({ ...prev, [field]: userData[field] }));
      activeEditFieldRef.current = null;
    } else {
      activeEditFieldRef.current = field;
    }
    setEditMode((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  // Modified handleChangeField to prevent focus loss
  const handleChangeField = (field, value) => {
    // Store current selection position before state update
    const selectionStart = inputRefs[field]?.current?.selectionStart;
    const selectionEnd = inputRefs[field]?.current?.selectionEnd;
    
    setFormData((prev) => ({ ...prev, [field]: value }));
    
    // Use setTimeout to restore cursor position after React's re-render
    setTimeout(() => {
      if (inputRefs[field]?.current) {
        inputRefs[field].current.focus();
        // Restore cursor position if available
        if (selectionStart !== undefined && selectionEnd !== undefined) {
          inputRefs[field].current.selectionStart = selectionStart;
          inputRefs[field].current.selectionEnd = selectionEnd;
        }
      }
    }, 0);
  };

  const handleSave = (field) => {
    // Now using the proper Redux dispatch function
    dispatch(updateUserProfile(formData))
    .then((response) => {
      // Handle successful update
      console.log("Profile updated successfully", response);
      // Show success notification dialog
      setSuccessDialog(true);
    });

    // Exit edit mode for this field
    setEditMode((prev) => ({ ...prev, [field]: false }));

    // Clear the active edit field reference
    activeEditFieldRef.current = null;
  };

  // Add function to close success dialog
  const handleCloseSuccessDialog = () => {
    setSuccessDialog(false);
  };

  // Enhanced focus handling
  useEffect(() => {
    const activeField = activeEditFieldRef.current;
    if (activeField && inputRefs[activeField]?.current) {
      const input = inputRefs[activeField].current;
      // Focus and move cursor to the end of the text
      input.focus();
      const length = input.value.length;
      input.setSelectionRange(length, length);
    }
  }, [editMode]);

  const renderInfoRow = (label, value, field, isEditable) => (
    <InfoRow>
      <InfoLabel>{label}:</InfoLabel>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {editMode[field] ? (
          <>
            <TextField
              inputRef={inputRefs[field]}
              variant="outlined"
              size="small"
              value={formData[field]}
              onChange={(e) => handleChangeField(field, e.target.value)}
              // Add key to ensure React treats this as the same component
              key={`edit-field-${field}`}
              // Prevent default behavior that might cause focus issues
              onBlur={(e) => e.preventDefault()}
            />
            <IconButton onClick={() => handleSave(field)}>
              <Save />
            </IconButton>
          </>
        ) : (
          <Typography>{formData[field]}</Typography>
        )}
        {isEditable && (
          <IconButton onClick={() => handleEditClick(field)}>
            {editMode[field] ? <Clear /> : <Edit />}
          </IconButton>
        )}
      </Box>
    </InfoRow>
  );

  const TabPanel = ({ children, index }) => (
    <Box
      role="tabpanel"
      hidden={activeTab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {activeTab === index && children}
    </Box>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Profile Information</DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <Grid
            item
            xs={12}
            md={3}
            sx={{ borderRight: "1px solid var(--card-bg)" }}
          >
            <Box sx={{ p: 3, textAlign: "center" }}>
              <Avatar
                src={userData.picture}
                alt={userData.firstName}
                sx={{ width: 120, height: 120, margin: "0 auto 16px" }}
              />
              <Typography variant="h6" gutterBottom>
                {`${userData.first_name} ${userData.last_name}`}
              </Typography>
              <Chip
                icon={<FaCrown />}
                label={
                  USER_TYPES[userData.userType]
                    ? USER_TYPES[userData.userType]
                    : "N/A"
                }
                color="primary"
                sx={{ mt: 1, textTransform: "capitalize" }}
              />
            </Box>
            <Divider />
            <Tabs
              orientation="vertical"
              value={activeTab}
              onChange={handleChange}
              aria-label="Profile tabs"
              sx={{ borderColor: "divider" }}
            >
              <StyledTab
                icon={<FaUser />}
                iconPosition="start"
                label="General Information"
                id="tab-0"
                aria-controls="tabpanel-0"
              />
              <StyledTab
                icon={<FaCrown />}
                iconPosition="start"
                label="My Subscription"
                id="tab-1"
                aria-controls="tabpanel-1"
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box sx={{ p: 4 }}>
              <TabPanel index={0}>
                <Typography variant="h5" gutterBottom>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    General Information
                    {userProfileLoading && (
                      <CircularProgress 
                        size={20} 
                        sx={{ ml: 2 }} 
                      />
                    )}
                  </Box>
                </Typography>
                <Box sx={{ mt: 3 }}>
                  {renderInfoRow("Initials", userData.initials, "initials")}
                  <Divider />
                  {renderInfoRow("Nickname", userData.nickname, "nickname", !userProfileLoading)}
                  <Divider />
                  {renderInfoRow(
                    "First Name",
                    userData.first_name,
                    "first_name", !userProfileLoading
                  )}
                  <Divider />
                  {renderInfoRow("Last Name", userData.last_name, "last_name", !userProfileLoading)}
                  <Divider />
                  {renderInfoRow("Email", userData.email, "email")}
                  <Divider />
                  {renderInfoRow("Last Login", userData.last_login_at, "last_login_at")}
                  <Divider />
                </Box>
              </TabPanel>
              <TabPanel index={1}>
                <Typography variant="h5" gutterBottom>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                  My Subscription
                    {userTypeLoading && (
                      <CircularProgress 
                        size={20} 
                        sx={{ ml: 2 }} 
                      />
                    )}
                  </Box>
                </Typography>
                <Box sx={{ mt: 3 }}>
                  <InfoRow>
                    <InfoLabel>Current Plan:</InfoLabel>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                      <Typography sx={{ 
                         background: '#292930',
                         borderRadius: '4px',
                         padding: '4px',
                         marginLeft: '5px',
                       }}>
                        {userData.mySubscription?.your_plan?.current_plan || "N/A"}
                      </Typography>
                      {userData.mySubscription?.your_plan?.plan_amount || "$0"}
                      </Box>
                  </InfoRow>
                  <Divider />
                  {/* <InfoRow>
                    <InfoLabel>Status:</InfoLabel>
                    <Chip
                      label={userData.subscription?.status || "N/A"}
                      color="success"
                      size="small"
                    />
                  </InfoRow>
                  <Divider /> */}
                  <InfoRow>
                    <InfoLabel>Valid Until:</InfoLabel>
                    <Typography>
                      {userData.mySubscription?.your_plan?.next_payment || "N/A"}
                    </Typography>
                  </InfoRow>
                  <Divider />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      toggleMonetizationDialog();
                    }}
                  >
                    Upgrade Plan
                  </Button>
                </Box>
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
      <MonetizationDialog
        accountInfo={accountInfo}
        open={monetizationOpen}
        onClose={toggleMonetizationDialog}
      />
      
      {/* Success Notification Dialog */}
      <Dialog 
        open={successDialog} 
        onClose={handleCloseSuccessDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Success
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ py: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1, flexDirection: "column" }}>
              <InfoOutlined sx={{
                fontSize: 64,
                color: "var(--success)",
              }} />
              <Typography variant="body1">
                Profile updated successfully!
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuccessDialog} color="primary" variant="contained">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default ProfilePage;
