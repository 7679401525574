import { CgCamera } from 'react-icons/cg';
import { LuCrown } from 'react-icons/lu';
import { PiFilmReelLight } from 'react-icons/pi';
import { TbTie } from 'react-icons/tb';

export const ANONYMOUS_USER = 'user01_unreg';
export const REGISTER_USER = 'user02_reg';
export const PAYING_USER = 'user03_paying';

export const LITE_USER = 'user03_01_lite';
export const PLUS_USER = 'user03_02_plus';
export const PRODUCER_USER = 'user03_03_producer';
export const STUDIO_PRO_USER = 'user03_04_studio_pro';

export const USER_TYPES = {
  [ANONYMOUS_USER]: 'guest',
  [REGISTER_USER]: 'register',
  [PAYING_USER]: 'paying',
}

export const SUBSCRIPTION_TYPES = {
  [LITE_USER]: 'lite',
  [PLUS_USER]: 'plus',
  [PRODUCER_USER]: 'producer',
  [STUDIO_PRO_USER]: 'studio pro',
}

export const SUBSCRIPTION_ICONS = {
  [LITE_USER]: TbTie,
  [PLUS_USER]: CgCamera,
  [PRODUCER_USER]: PiFilmReelLight,
  [STUDIO_PRO_USER]: LuCrown,
}