import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Play, ChevronLeft, ChevronRight } from 'lucide-react';

const AudienceShowcase = ({ filteredAudiences }) => {
  const navigate = useNavigate();
  const [activeVideo, setActiveVideo] = useState(null);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const scrollContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState({});
  const [hasError, setHasError] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Complete video variants data with all audience types
  const videoVariants = [
    {
      id: 1,
      audience: "Investors",
      title: "Market Validation for Investors with Glowtest",
      thumbnail: "https://i.ytimg.com/vi/EvtEd1cUf2I/mqdefault.jpg",
      videoId: "EvtEd1cUf2I",
      description: "Investment-grade market validation with predictive analytics and ROI forecasting",
      keyPoints: [
        "AI-powered market sizing",
        "Risk assessment metrics",
        "Revenue projection models"
      ],
      metrics: {
        engagement: "48%",
        conversion: "42%",
        retention: "93%"
      }
    },
    {
      id: 2,
      audience: "Small Business Owners",
      title: "Cost-Effective Market Testing",
      thumbnail: "https://i.ytimg.com/vi/m7XJ0mzwcl8/mqdefault.jpg",
      videoId: "m7XJ0mzwcl8",
      description: "Affordable market testing solution that saves time and reduces risk",
      keyPoints: [
        "No expertise required",
        "Budget-friendly pricing",
        "Guided validation process"
      ],
      metrics: {
        engagement: "52%",
        conversion: "45%",
        retention: "88%"
      }
    },
    {
      id: 3,
      audience: "Product Managers",
      title: "Rapid Idea Validation for Product Teams",
      thumbnail: "https://i.ytimg.com/vi/yZjwmQzhdcs/mqdefault.jpg",
      videoId: "yZjwmQzhdcs",
      description: "Validate multiple product concepts in hours without technical expertise",
      keyPoints: [
        "Test 4-7 ideas in parallel",
        "24-hour validation cycle",
        "Feature priority insights"
      ],
      metrics: {
        engagement: "65%",
        conversion: "38%",
        retention: "91%"
      }
    },
    {
      id: 7,
      audience: "Content Creators",
      title: "Video & Social Media Content Testing",
      thumbnail: "https://i.ytimg.com/vi/mZ45T_vvzK0/mqdefault.jpg",
      videoId: "mZ45T_vvzK0",
      description: "Test your content ideas before investing time in production",
      keyPoints: [
        "Social media performance prediction",
        "Audience resonance metrics",
        "Content format optimization"
      ],
      metrics: {
        engagement: "73%",
        conversion: "52%",
        retention: "87%"
      }
    },
    {
      id: 4,
      audience: "Marketing Teams",
      title: "Data-Driven Market Insights",
      thumbnail: "https://i.ytimg.com/vi/clqe_oPX9v8/mqdefault.jpg",
      videoId: "clqe_oPX9v8",
      description: "Target audience insights and campaign optimization platform",
      keyPoints: [
        "Audience segmentation",
        "Message testing tools",
        "ROI optimization"
      ],
      metrics: {
        engagement: "54%",
        conversion: "47%",
        retention: "89%"
      }
    },
    {
      id: 5,
      audience: "Communities",
      title: "Product Validation Suite",
      thumbnail: "https://i.ytimg.com/vi/MaLWAJsCQo0/mqdefault.jpg",
      videoId: "MaLWAJsCQo0",
      description: "Community-driven product validation and feedback management",
      keyPoints: [
        "Community engagement tools",
        "Feedback aggregation",
        "Sentiment analysis"
      ],
      metrics: {
        engagement: "58%",
        conversion: "41%",
        retention: "94%"
      }
    },
    {
      id: 6,
      audience: "Marketers",
      title: "Rapid MVP Testing Platform",
      thumbnail: "https://i.ytimg.com/vi/dNIjARqt4S0/mqdefault.jpg",
      videoId: "dNIjARqt4S0",
      description: "Rapid market validation for campaign and content optimization",
      keyPoints: [
        "A/B testing automation",
        "Audience insights",
        "Campaign analytics"
      ],
      metrics: {
        engagement: "51%",
        conversion: "46%",
        retention: "90%"
      }
    }
  ];

  // Initialize loading state for all videos on mount
  useEffect(() => {
    const initialLoadingState = {};
    videoVariants.forEach(video => {
      initialLoadingState[video.id] = true;
    });
    setIsLoading(initialLoadingState);
    
    // Add window resize listener for mobile detection
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Filter videos based on prop
  const filteredVideos = filteredAudiences 
    ? videoVariants.filter(video => filteredAudiences.includes(video.audience))
    : videoVariants;

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      // Use smaller scroll amount on mobile
      const scrollAmount = direction === 'left' 
        ? (isMobile ? -260 : -350) 
        : (isMobile ? 260 : 350);
        
      scrollContainerRef.current.scrollBy({ 
        left: scrollAmount, 
        behavior: 'smooth' 
      });
    }
  };

  const handleImageLoad = (id) => {
    setIsLoading(prev => ({ ...prev, [id]: false }));
  };

  const handleImageError = (id) => {
    setHasError(prev => ({ ...prev, [id]: true }));
    setIsLoading(prev => ({ ...prev, [id]: false }));
  };

  const handleCloseModal = () => {
    setVideoModalOpen(false);
    setActiveVideo(null);
  };

  return (
    <section className="py-12 md:py-16 mb-12 md:mb-20" aria-label="Audience-specific video examples">
      <div className="text-center mb-8 md:mb-12 px-4">
        <h2 className="text-2xl md:text-3xl font-bold mb-3 md:mb-4">
          Successful {filteredAudiences?.includes('Investors') ? 'Validation' : 'Video'} Examples
        </h2>
        <p className="text-zinc-300 max-w-2xl mx-auto text-sm md:text-base">
          {filteredAudiences?.includes('Investors') 
            ? "See how different businesses and teams have successfully validated their ideas" 
            : "See how content creators and marketing teams have created impactful videos"}
        </p>
      </div>

      <div className="relative px-2 md:px-4">
        {/* Scroll Controls - Larger touch targets on mobile */}
        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10">
          <button 
            className="w-10 h-10 md:w-12 md:h-12 bg-black/50 backdrop-blur-sm rounded-full flex items-center justify-center hover:bg-black/70 transition-colors touch-manipulation"
            onClick={() => handleScroll('left')}
            aria-label="Scroll left"
          >
            <ChevronLeft className="text-white" size={isMobile ? 28 : 24} />
          </button>
        </div>
        
        <div className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10">
          <button 
            className="w-10 h-10 md:w-12 md:h-12 bg-black/50 backdrop-blur-sm rounded-full flex items-center justify-center hover:bg-black/70 transition-colors touch-manipulation"
            onClick={() => handleScroll('right')}
            aria-label="Scroll right"
          >
            <ChevronRight className="text-white" size={isMobile ? 28 : 24} />
          </button>
        </div>
        
        {/* Scrollable Video Gallery with touch optimizations */}
        <div 
          ref={scrollContainerRef}
          className="flex overflow-x-auto pb-6 hide-scrollbar snap-x snap-mandatory"
          style={{ 
            scrollbarWidth: 'none', 
            msOverflowStyle: 'none',
            WebkitOverflowScrolling: 'touch' 
          }}
          aria-label="Video gallery"
        >
          <div className="flex gap-4 md:gap-6 px-2 md:px-4">
            {filteredVideos.map((video) => (
              <div 
                key={video.id} 
                className="flex-shrink-0 w-[260px] md:w-80 bg-zinc-800/40 rounded-xl overflow-hidden snap-center"
              >
                <button 
                  className="relative w-full h-36 md:h-48 overflow-hidden focus:outline-none focus:ring-2 focus:ring-orange-500"
                  onClick={() => {
                    setActiveVideo(video);
                    setVideoModalOpen(true);
                  }}
                  aria-label={`Play ${video.title} video`}
                >
                  {/* Thumbnail with fallback mechanism */}
                  {isLoading[video.id] && (
                    <div className="absolute inset-0 flex items-center justify-center bg-zinc-900">
                      <div className="w-8 h-8 border-4 border-orange-500 border-t-transparent rounded-full animate-spin"></div>
                    </div>
                  )}
                  
                  {hasError[video.id] ? (
                    <div className="w-full h-full bg-zinc-800 flex items-center justify-center">
                      <div className="text-center p-4">
                        <div className="text-5xl mb-2">🎬</div>
                        <p className="text-zinc-400 text-sm">{video.audience} Video</p>
                      </div>
                    </div>
                  ) : (
                    <img 
                      src={video.thumbnail}
                      alt={video.title}
                      className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                      onLoad={() => handleImageLoad(video.id)}
                      onError={() => handleImageError(video.id)}
                      style={{ display: isLoading[video.id] ? 'none' : 'block' }}
                      loading="lazy"
                    />
                  )}
                  
                  {/* Play button overlay - larger on mobile */}
                  <div className="absolute inset-0 bg-black/40 flex items-center justify-center hover:bg-black/60 active:bg-black/70 transition-all">
                    <div className="bg-orange-500 rounded-full p-3 md:p-4">
                      <Play className="w-6 h-6 md:w-7 md:h-7 text-white" />
                    </div>
                  </div>
                </button>
                
                <div className="p-4 md:p-5">
                  <div className="mb-3">
                    <span className="text-xs font-medium bg-orange-500/20 text-orange-400 px-2 py-1 rounded">
                      {video.audience}
                    </span>
                  </div>
                  
                  <h3 className="text-base md:text-lg font-bold mb-2 line-clamp-2">{video.title}</h3>
                  <p className="text-xs md:text-sm text-zinc-400 mb-3 md:mb-4 line-clamp-2">{video.description}</p>
                  
                  <div className="grid grid-cols-3 gap-2 text-center border-t border-zinc-700/30 pt-3 md:pt-4 mt-3 md:mt-4">
                    <div>
                      <p className="text-xs text-zinc-500">Engagement</p>
                      <p className="text-xs md:text-sm font-medium text-green-400">{video.metrics.engagement}</p>
                    </div>
                    <div>
                      <p className="text-xs text-zinc-500">Conversion</p>
                      <p className="text-xs md:text-sm font-medium text-green-400">{video.metrics.conversion}</p>
                    </div>
                    <div>
                      <p className="text-xs text-zinc-500">Retention</p>
                      <p className="text-xs md:text-sm font-medium text-green-400">{video.metrics.retention}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      {/* Video Modal - Mobile optimized */}
      <Dialog 
        open={videoModalOpen} 
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
        aria-labelledby="video-modal-title"
      >
        <DialogContent 
          sx={{ 
            padding: 0, 
            bgcolor: '#121212', 
            position: 'relative',
            '&:first-of-type': { paddingTop: 0 }
          }}
        >
          <IconButton
            onClick={handleCloseModal}
            aria-label="Close"
            sx={{ 
              position: 'absolute', 
              top: 8, 
              right: 8, 
              color: 'white', 
              bgcolor: 'rgba(0,0,0,0.5)',
              zIndex: 10,
              padding: '12px',
              '&:hover': {
                bgcolor: 'rgba(0,0,0,0.7)',
              }
            }}
          >
            <Close />
          </IconButton>
          
          {activeVideo && (
            <>
              <div className="aspect-video bg-black relative">
                <iframe
                  src={`https://www.youtube.com/embed/${activeVideo.videoId}?autoplay=1&rel=0`}
                  title={activeVideo.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full h-full absolute top-0 left-0"
                ></iframe>
              </div>
              
              <div className="p-4 md:p-6">
                <h3 id="video-modal-title" className="text-lg md:text-xl font-bold mb-2">{activeVideo.title}</h3>
                <p className="text-sm md:text-base text-zinc-400 mb-4">{activeVideo.description}</p>
                
                <div className="mb-4">
                  <h4 className="text-sm font-medium mb-2">Key Points:</h4>
                  <ul className="grid grid-cols-1 sm:grid-cols-3 gap-2">
                    {activeVideo.keyPoints.map((point, idx) => (
                      <li key={idx} className="flex items-center text-xs md:text-sm bg-zinc-800/50 p-2 rounded">
                        <span className="w-2 h-2 bg-orange-500 rounded-full mr-2 flex-shrink-0"></span>
                        {point}
                      </li>
                    ))}
                  </ul>
                </div>
                
                <div className="flex flex-col sm:flex-row gap-3 md:gap-4">
                  <button
                    onClick={() => {
                      handleCloseModal();
                      navigate('/journey');
                    }}
                    className="mt-2 md:mt-4 bg-orange-500 text-white px-4 md:px-6 py-2 rounded-lg font-medium hover:bg-orange-600 transition-colors touch-manipulation"
                  >
                    Try It Now
                  </button>
                  
                  <button
                    onClick={handleCloseModal}
                    className="mt-2 md:mt-4 border border-zinc-600 text-white px-4 md:px-6 py-2 rounded-lg font-medium hover:bg-zinc-800 transition-colors touch-manipulation"
                  >
                    Close
                  </button>
                </div>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </section>
  );
};

export default AudienceShowcase; 