/**
 * Google Tag Manager utility for pushing events to the data layer
 * @module gtmUtils
 */

/**
 * Push an event to the GTM data layer
 * @param {Object} dataLayerObject - The data object to push to the data layer
 */
export const pushToDataLayer = (dataLayerObject) => {
  try {
    if (window.dataLayer) {
      window.dataLayer.push(dataLayerObject);
    } else {
      // Initialize dataLayer if it doesn't exist
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(dataLayerObject);
    }
  } catch (error) {
    console.error('Error pushing to data layer:', error);
  }
};

/**
 * Track a page view event in GTM
 * @param {string} pageName - Name of the page
 * @param {string} pageUrl - URL of the page
 * @param {Object} [additionalParams={}] - Additional parameters to include
 */
export const trackGTMPageView = (pageName, pageUrl, additionalParams = {}) => {
  pushToDataLayer({
    event: 'pageView',
    pageName,
    pageUrl,
    timestamp: new Date().toISOString(),
    ...additionalParams
  });
};

/**
 * Track a button click event in GTM
 * @param {string} buttonId - Identifier for the button
 * @param {string} buttonName - Human-readable name of the button
 * @param {Object} [additionalParams={}] - Additional parameters to include
 */
export const trackGTMButtonClick = (buttonId, buttonName, additionalParams = {}) => {
  pushToDataLayer({
    event: 'buttonClick',
    buttonId,
    buttonName,
    timestamp: new Date().toISOString(),
    ...additionalParams
  });
};

/**
 * Track a user interaction event in GTM
 * @param {string} category - Event category
 * @param {string} action - Event action
 * @param {string} label - Event label
 * @param {Object} [additionalParams={}] - Additional parameters to include
 */
export const trackGTMEvent = (category, action, label, additionalParams = {}) => {
  pushToDataLayer({
    event: 'userInteraction',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    timestamp: new Date().toISOString(),
    ...additionalParams
  });
};

/**
 * Track ecommerce events in GTM
 * @param {string} actionType - Type of ecommerce action (e.g., 'purchase', 'add_to_cart')
 * @param {Object} productData - Data about the product(s) involved
 * @param {Object} [additionalParams={}] - Additional parameters to include
 */
export const trackGTMEcommerce = (actionType, productData, additionalParams = {}) => {
  pushToDataLayer({
    event: 'ecommerce',
    ecommerce: {
      [actionType]: productData
    },
    timestamp: new Date().toISOString(),
    ...additionalParams
  });
};

/**
 * Set user-related data in GTM
 * @param {Object} userData - User data to set
 */
export const setGTMUserData = (userData) => {
  pushToDataLayer({
    event: 'userData',
    user: userData,
    timestamp: new Date().toISOString()
  });
}; 