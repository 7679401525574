/**
 * Utility functions for constructing image paths
 */

// Construct S3_BASE_URL using environment variables with fallbacks
const S3_BASE_URL = `https://${process.env.REACT_APP_DEV_AWS_BUCKET_NAME || 'chat2movie'}.s3.${process.env.REACT_APP_DEV_AWS_REGION || 'us-east-1'}.amazonaws.com`;

// Map style names to their simplified versions
const STYLE_NAME_MAP = {
  'realistic_image': 'realistic',
  'digital_illustration': 'digital',
  'vector_illustration': 'vector',
  'icon': 'icon',
  'logo_raster': 'logo'
};

// Default fallback image paths for guaranteed working images
export const DEFAULT_THUMBNAIL = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/thumbnails/recraft_recraftv3_realistic_null.webp`;
export const DEFAULT_FULL_IMAGE = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/full/recraft_recraftv3_realistic_null.webp`;

/**
 * Generates a thumbnail or full image path based on the new folder structure.
 * @param {Object} options - Configuration options
 * @param {string} options.provider - Provider name (default: 'recraft')
 * @param {string} options.model - Model name (default: 'recraftv3')
 * @param {string} options.style - Style name (default: 'realistic')
 * @param {string} options.substyle - Substyle name (default: 'null')
 * @param {boolean} options.isThumbnail - Whether to generate a thumbnail path (default: false)
 * @returns {string} - The complete S3 URL
 */
export const generateImagePath = ({
  provider = 'recraft',
  model = 'recraftv3',
  style = 'realistic',
  substyle = 'null',
  isThumbnail = false
} = {}) => {
  try {
    // Map style names if needed
    const mappedStyle = STYLE_NAME_MAP[style] || style;
    const folderType = isThumbnail ? 'thumbnails' : 'full';
    
    // Construct the filename with the naming convention
    const filename = `${provider}_${model}_${mappedStyle}_${substyle}.webp`;
    
    // Construct the URL with the simplified path structure
    return `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/${folderType}/${filename}`;
  } catch (err) {
    console.error('Error generating image path:', err);
    return isThumbnail ? DEFAULT_THUMBNAIL : DEFAULT_FULL_IMAGE;
  }
};

/**
 * Transforms a legacy path into the new path format
 * @param {string} path - The legacy path string (e.g., 'providers/recraft/models/recraftv3/styles/realistic_image.webp')
 * @returns {string} - The complete S3 URL in the new format
 */
export const convertLegacyPath = (path) => {
  try {
    if (!path || typeof path !== 'string') {
      console.warn('Invalid path provided to convertLegacyPath:', path);
      return DEFAULT_FULL_IMAGE;
    }
    
    // Extract path type (thumbnails or full) and components
    const isThumb = path.includes('thumbnails/');
    const folderType = isThumb ? 'thumbnails' : 'full';
    
    // Parse the path to extract components
    let provider = 'recraft'; // Default to recraft
    let model = 'recraftv3'; // Default to recraftv3
    let style = 'realistic'; // Default to realistic
    let substyle = 'null';
    
    // Extract provider
    if (path.includes('providers/')) {
      const match = path.match(/providers\/([^\/]+)/);
      if (match && match[1]) provider = match[1];
    }
    
    // Extract model
    if (path.includes('models/')) {
      const match = path.match(/models\/([^\/]+)/);
      if (match && match[1]) model = match[1];
    }
    
    // Extract style
    if (path.includes('styles/')) {
      const match = path.match(/styles\/([^\/\.]+)/);
      if (match && match[1]) {
        style = match[1];
        // Map style names if needed
        style = STYLE_NAME_MAP[style] || style;
      }
    }
    
    // Extract substyle
    if (path.includes('substyles/')) {
      const match = path.match(/substyles\/([^\/\.]+)/);
      if (match && match[1]) substyle = match[1];
    }
    
    return generateImagePath({
      provider,
      model,
      style,
      substyle,
      isThumbnail: isThumb
    });
  } catch (err) {
    console.error('Error converting legacy path:', err, path);
    return DEFAULT_FULL_IMAGE;
  }
};

export default {
  generateImagePath,
  convertLegacyPath,
  DEFAULT_THUMBNAIL,
  DEFAULT_FULL_IMAGE,
  S3_BASE_URL
}; 