import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Viewers.css";
import "./glow_03_processor_script.css";
import processConfig from "../../config/processIndex.json";
import {
  Box,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  TextField,
  Switch,
  Typography,
} from "@mui/material";
import {
  Close,
  Refresh,
  Edit,
  SwapHoriz,
  AddCircle,
  SlideshowOutlined,
  ImageOutlined,
} from "@mui/icons-material";
import ViewerHeader from "../common/ViewerHeader";
import ViewFooter from "../common/ViewFooter";
import { ProcessChainManager } from "../../utils/processChain";
import {
  generateAndSaveTargetGroupImage,
  generateAnimationToClip,
  generateClipToVideo,
  generateImageToAnimation,
  generateTargetGroupImage,
  imageToAnimationConfig,
} from "../../utils/processUtils";
import ActionBar from "./glow_04_action_bar";
import {
  updateGlowTextResponse,
  selectGlowData,
  selectGlowIdsMap,
  fetchGlowIdsMap,
  setGlowIdsMap,
  fetchImageData,
  selectImageData,
  setImageData,
  selectSessionData,
} from "../../components/session/sessionSlide";
import {
  SAMPLE_GLOW_01,
  SAMPLE_GROUP_IMAGES,
  SAMPLE_SCENE_IMAGES,
  SAMPLE_SCENE_CLIPS,
  SAMPLE_FRAME_IMAGES,
  SAMPLE_FRAME_ANIMATIONS,
  SAMPLE_GROUP_VIDEOS,
} from "../../data/sampleGlowData";
import {
  getRemainingCredit,
  selectAccountInfo,
  selectToken,
} from "../../auth/authenticateSlide";
import { showNotification } from "../notification/notificationSlide";
import MonetizationDialog from "../ui/monetization";
import PremiumFeatureOverlay from "../ui/PremiumFeatureOverlay";
import { set } from "date-fns";

const Glow04ProcessorIcicle = ({ data, onComplete, sessionId }) => {
  const dispatch = useDispatch();
  const process = processConfig.processes.find((p) => p.id === "glow_04");

  // Data comes in directly, not wrapped
  const hasRealData = data && Object.entries(data).length ? true : false;
  const [displayData, setDisplayData] = useState(() => {
    const initialData = hasRealData ? data : SAMPLE_GLOW_01;

    return Array.isArray(initialData) ? initialData : [initialData];
  });

  const [isDemo, setIsDemo] = useState(!hasRealData);
  const accountInfo = useSelector(selectAccountInfo);
  const activeToken = useSelector(selectToken);

  const showNotice = (message, severity = "success") => {
    dispatch(showNotification({ message: message, severity: severity }));
  };

  const [isLoading, setIsLoading] = useState(false);
  const [processedData, setProcessedData] = useState(data);

  const [currentFocusNode, setCurrentFocusNode] = useState();
  const [hoverScene, setHoverScene] = useState();
  const [hoverFrame, setHoverFrame] = useState();
  const [hoverRelatedNode, setHoverRelatedNode] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [selectedScene, setSelectedScene] = useState();
  const [selectedFrame, setSelectedFrame] = useState();

  const [groupImages, setGroupImages] = useState({});
  const [groupVideos, setGroupVideos] = useState({});
  const [loadingGroupImages, setLoadingGroupImages] = useState({});
  const [frameImages, setFrameImages] = useState({});
  const [frameAnimationEstimates, setFrameAnimationEstimates] = useState(null);
  const [frameAnimations, setFrameAnimations] = useState({});
  const [loadingFrameImages, setLoadingFrameImages] = useState({});
  const [sceneImages, setSceneImages] = useState({});
  const [sceneClips, setSceneClips] = useState({});
  const [loadingSceneImages, setLoadingSceneImages] = useState({});
  const [sceneCount, setSceneCount] = useState({});
  const [frameCount, setFrameCount] = useState({});
  const selectedWidth = 70;

  const [dialogData, setDialogData] = useState({
    title: "",
    subtitle: "",
    img: "",
  });
  const [sceneEditModal, setSceneEditModal] = useState({
    open: false,
    data: null,
  });
  const [editFormData, setEditFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});

  // Add new state for frame edit modal
  const [frameEditModal, setFrameEditModal] = useState({
    open: false,
    data: null,
  });

  // Add new state for group edit modal
  const [groupEditModal, setGroupEditModal] = useState({
    open: false,
    data: null,
  });
  const [groupEditFormData, setGroupEditFormData] = useState({});
  const [groupFormErrors, setGroupFormErrors] = useState({});
  const [imageToAnimationConfigData, setImageToAnimationConfigData] =
    useState(null);

  const glow61Response = useSelector((state) =>
    selectGlowData(state, "glow_61")
  );

  const glowIdsMap = useSelector((state) => selectGlowIdsMap(state))

  const imageData = useSelector((state) => selectImageData(state))

  const glow03Response = useSelector((state) => 
    selectGlowData(state, "glow_03")
  );

  React.useEffect(() => {
    if (data && Object.entries(data).length) {
      setDisplayData(data);
      setIsDemo(false);
    }
  }, [data]);

  React.useEffect(() => {
    onGetImageToAnimationConfig();
  }, [imageToAnimationConfigData]);

  const onGetImageToAnimationConfig = async () => {
    if (!imageToAnimationConfigData) {
      if (!isDemo) {
        const result = await imageToAnimationConfig();
        if (result) {
          setImageToAnimationConfigData(result);
        }
      }
      return;
    }
  };

  React.useEffect(() => {
    // Generate key for scene and frame combine with group number
    displayData.forEach((group) => {
      setSceneCount((prevCount) => ({
        ...prevCount,
        [group.id]: group.scenes?.length || 0,
      }));
      let nbFrame = 0;
      group.scenes?.forEach((scene) => {
        nbFrame += scene.shots?.length || 0;
      });
      setFrameCount((prevCount) => ({ ...prevCount, [group.id]: nbFrame }));
    });

    if (displayData && !isDemo) {
      displayData.forEach((group) => {
        if (group.audience && !groupImages[group.id]) {
          // generateImageForGroup(group, group.id, false, true);
        }

        group.scenes?.forEach((scene) => {
          const sceneKey = `${group.id}_${scene.sc_id}`;
          if (scene.sc_visuals_extensive && !sceneImages[sceneKey]) {
            // generateImageForScene(scene, sceneKey, false, true);
          }
          scene.shots?.forEach((frame) => {
            const frameKey = `${group.id}_${scene.sc_id}_${frame.shot_number}`;
            if (frame.visu_main_subject && !frameImages[frameKey]) {
              // generateImageForFrame(scene, frame, frameKey, false, true);
            }
          });
        });
      });
    }
  }, [displayData]);

  React.useEffect(() => {
    if (isDemo) {
      setGroupImages({});
      setSceneImages({});
      setFrameImages({});
      setFrameAnimations({});
    } else {
      // Reset all images when switching from demo to non-demo
      setGroupImages({});
      setSceneImages({});
      setFrameImages({});
      setFrameAnimations({});
      setFrameAnimationEstimates(
        JSON.parse(localStorage.getItem("frameAnimationEstimates"))
      );
      console.log("Frame Animation Estimates:", frameAnimationEstimates);
    }
  }, [isDemo]);

  // Fetch GlowIdsMap when component loads and when necessary conditions are met
  React.useEffect(() => {    
    const shouldFetchData = sessionId && !isDemo;
    const hasGlow03Response = glow03Response && glow03Response['glow_03_response'];
    
    if (shouldFetchData && hasGlow03Response) {
      dispatch(fetchGlowIdsMap(sessionId));
    }
  }, [sessionId, isDemo, glow03Response, dispatch]);

  // Fetch image data when glow_03 has value and not in demo mode
  React.useEffect(() => {    
    const shouldFetchData = sessionId && !isDemo;
    const hasGlow03Response = glow03Response && glow03Response['glow_03_response'];
    
    if (shouldFetchData && hasGlow03Response) {
      dispatch(fetchImageData(sessionId));
    }
  }, [sessionId, isDemo, glow03Response, dispatch]);

  const updateDisplayData = async (groupId, data, glow_id) => {
    const updatedData = displayData.map((group) => {
      if (group.id == groupId && typeof data === "object" && data !== null) {
        const updatedGroup = { ...group };
        Object.entries(data).forEach(([key, value]) => {
          if (value.scenes) {
            if (!updatedGroup.video_id && value.video_id)
              updatedGroup.video_id = value.video_id;
            if (glow_id === "glow_02") {
              updatedGroup.scenes = value.scenes.map((scene) => {
                return {
                  ...scene,
                  key: `${group.id}_${scene.sc_id}`,
                  shots: [...(scene.shots || [])],
                };
              });
            } else if (glow_id === "glow_03") {
              updatedGroup.scenes = group.scenes.map((existingScene) => {
                const scene = value.scenes.find(
                  (s) => s.sc_id == existingScene.sc_id
                );
                if (scene) {
                  return {
                    ...existingScene,
                    ...scene, // Copy all new attributes from scene
                    shots: scene.shots.map((shot) => ({
                      ...shot,
                      key: `${group.id}_${existingScene.sc_id}_${shot.shot_number}`,
                    })),
                  };
                }
                return existingScene;
              });
            }
          }
        });
        return updatedGroup;
      }
      return group;
    });

    setDisplayData(updatedData);
    // Dispatch updateGlowData action after updating displayData
    // if(!isDemo) dispatch(updateGlowTextResponse({ glowId: 'glow_04', data: { 'glow_04_response' : updatedData } }));
    if (!isDemo)
      dispatch(
        updateGlowTextResponse({
          processId: "glow_04",
          updatedResponse: { glow_04_response: updatedData },
        })
      );
  };

  const preparePayload = (visualPrompt, negativePrompt, frameKey) => {
    if (!visualPrompt) {
      console.log(
        "Warning: Missing visual prompt. Please ensure visual prompt is provided before proceeding."
      );
      return;
    }

    const glow61Data = glow61Response.glow_61_response || {};
    if (
      !glow61Data.provider ||
      !glow61Data.model ||
      !glow61Data.style ||
      !glow61Data.substyle
    ) {
      console.log(glow61Data);
      console.log(
        "Warning: Missing provider, model or style. Please ensure all attributes are configurated in Video Format before proceeding."
      );
      return;
    }

    const animationGroup = glowIdsMap
      ? glowIdsMap[frameKey ? frameKey?.split("_")[0] : ""]
      : "";
    let animationScene = "";
    let animationFrame = "";
    if (animationGroup) {
      animationScene = animationGroup[frameKey?.split("_")[1]];
      if (animationScene) {
        animationFrame = animationScene[frameKey?.split("_")[2]];
      }
    }
    const thisVideoImageData = imageData ? imageData['SHOTS']?.find(item => item['video_id'] == animationFrame["video_id"]) : null;
    const thisImageData = thisVideoImageData ? thisVideoImageData['image_data']?.find(item => item['image_id'] == animationFrame["image_id"]) : null;
    return {
      session_id: sessionId,
      video_id: animationFrame ? animationFrame["video_id"] : null,
      image_id: animationFrame ? animationFrame["image_id"] : null,
      version: 0,
      filename: thisImageData?.file_name,
      file_type: thisImageData?.file_type,
      visual_prompt: visualPrompt,
      model_provider: glow61Data.provider,
      image_extension: ".png",
      recraft_config: {
        model: glow61Data.model,
        quality: "standard",
        size: glow61Data.size ? glow61Data.size : "1024x1024",
        style: glow61Data.style,
        extra_body: {
          substyle: glow61Data.substyle,
          negative_prompt: negativePrompt || "null",
          artistic_level: null,
          no_text: true
        }
      }
    };
  };

  const prepareAnimationPayload = (frameKey) => {
    const animationGroup = glowIdsMap[frameKey?.split("_")[0]];
    let animationScene = "";
    let animationFrame = "";
    if (animationGroup) {
      animationScene = animationGroup[frameKey?.split("_")[1]];
      if (animationScene) {
        animationFrame = animationScene[frameKey?.split("_")[2]];
      }
    }

    return {
      session_id: sessionId,
      animation_id: animationFrame ? animationFrame["animation_id"] : "string",
      hosting: "inhouse",
      provider: "stability",
      model: "stable-video-diffusion-img2vid-xt-1-1",
      video_extensions: ".mp4",
      screen_formats: "16:9",
      video_resolutions: "1280x720",
      fps: 12,
      duration: 3,
      quality_options: {
        motion_bucket_id: "auto",
        augmentation_level: 0.02,
        cfg_scale: 1.8,
        steps: 30,
        sampler_name: "euler_ancestral",
        scheduler: "karras",
        guidance_scale: 1.8,
        prompt_extension: false,
      },
    };
  };

  const generateImageForFrame = async (
    scene,
    frame,
    frameKey,
    force = false,
    isOnlyCache = false
  ) => {
    // Move all hook-dependent code to the top, before any conditionals
    const hasToken = !!activeToken;
    const hasGlowIds = !isOnlyCache && (!glowIdsMap || !imageData);
    const hasRequiredAttributes = !frame.visu_main_subject || !frame.visu_environment || (!force && frameImages[frameKey]);
    const hasSufficientCredits = accountInfo?.credit < 0;
    
    // Now use these variables in your conditionals
    if (hasGlowIds) {
      getGlowIdsMapsAgain(() => {
        setTimeout(() => {
          generateImageForFrame(scene, frame, frameKey, force, isOnlyCache)
        }, 1000);
      });
      return;
    }
    
    if (hasRequiredAttributes) {
      console.log(
        "Warning: Missing visu_main_subject or visu_environment. Please ensure both are provided before proceeding."
      );
      return;
    }
    
    if (hasSufficientCredits) {
      showNotice(
        "Insufficient credits. Please add more credits to continue.",
        "error"
      );
      setMonetizationOpen(true);
      return;
    }

    setLoadingFrameImages((prev) => ({ ...prev, [frameKey]: true }));
    try {
      const fullPrompt = [
        frame.visu_main_subject,
        frame.visu_environment,
        frame.visu_technical,
        scene.visu_style_primary,
        scene.visu_style_secondary,
        scene.visu_color_scheme,
        frame.visu_shot_type,
        frame.visu_subject_treatment,
        scene.visu_quality,
        scene.visu_enhancements,
      ]
        .filter(Boolean)
        .join(", ");

      const payload = preparePayload(
        fullPrompt,
        scene.visu_neg_prompt || "",
        frameKey
      );
      console.log("imageData", imageData);
      console.log("payload", payload);
      const imagePaths = await generateTargetGroupImage(
        payload,
        `${sessionId}/frame_${frameKey}`,
        force,
        isOnlyCache
      );

      if (!imagePaths?.s3Path) {
        throw new Error("Image path not found in response.");
      }
      if (!isDemo) {
        setFrameImages((prev) => ({
          ...prev,
          [frameKey]: imagePaths?.s3Path,
        }));
        if (force && imagePaths?.s3Path) {
          dispatch(getRemainingCredit({ token: activeToken }));
        }
      }
    } catch (error) {
      console.error("Failed to generate frame image:", error);
    } finally {
      setLoadingFrameImages((prev) => ({ ...prev, [frameKey]: false }));
    }
  };

  const getGlowIdsMapsAgain = async (callBack) => {
    if (!isDemo) {
      if (!glowIdsMap) {
        await dispatch(fetchGlowIdsMap(sessionId));        
      }
      if (!imageData) {
        await dispatch(fetchImageData(sessionId))
      }
      setTimeout(() => {
        if (callBack) {
          callBack()
        }
      }, 0);
    }
  }

  const [frameViewModes, setFrameViewModes] = useState({})

  const onGenerateImageToAnimation = async (
    groupId,
    scene,
    frame,
    frameKey,
    force = false,
    isOnlyCache = false
  ) => {
    if (isDemo) {
      setFrameAnimations((prev) => ({
        ...prev, 
        [frameKey]: SAMPLE_FRAME_ANIMATIONS[frameKey] 
      }));
      setFrameViewModes((prev) => ({ ...prev, [frameKey]: 'animation' }));
    }
    if (frameAnimations[frameKey] && frameImages[frameKey]) {
      if (frameViewModes[frameKey] === 'image') {
        setFrameViewModes((prev) => ({ ...prev, [frameKey]: 'animation' }));
      } else {
        setFrameViewModes((prev) => ({ ...prev, [frameKey]: 'image' }));
      }
      return;
    }
    if (!glowIdsMap) {
      if (!isDemo) {
        getGlowIdsMapsAgain(() => {
          onGenerateImageToAnimation(groupId, scene, frame, frameKey, force, isOnlyCache)
        });
      }
      return;
    }
    if (
      !frame.visu_main_subject ||
      !frame.visu_environment ||
      (!force && frameImages[frameKey])
    ) {
      console.log(
        "Warning: Missing visu_main_subject or visu_environment. Please ensure both are provided before proceeding."
      );
      return;
    }
    // Check credit balance before proceeding
    if (accountInfo?.credit < 0) {
      showNotice(
        "Insufficient credits. Please add more credits to continue.",
        "error"
      );
      setMonetizationOpen(true);
      return;
    }

    setLoadingFrameImages((prev) => ({ ...prev, [frameKey]: true }));
    try {
      const fullPrompt = [
        frame.visu_main_subject,
        frame.visu_environment,
        frame.visu_technical,
        scene.visu_style_primary,
        scene.visu_style_secondary,
        scene.visu_color_scheme,
        frame.visu_shot_type,
        frame.visu_subject_treatment,
        scene.visu_quality,
        scene.visu_enhancements,
      ]
        .filter(Boolean)
        .join(", ");

      const payload = prepareAnimationPayload(frameKey);
      console.log("payload", payload);
      const animationPaths = await generateImageToAnimation(
        payload,
        `${sessionId}/frame_${frameKey}`,
        force,
        isOnlyCache
      );

      if (!animationPaths?.s3_url) {
        if (animationPaths?.estimated_time) {
          setFrameAnimationEstimates((prev) => ({
            ...prev,
            [frameKey]: animationPaths?.estimated_time,
          }));
          showNotice(
            `${animationPaths?.message} ${animationPaths.estimated_time}`,
            "info"
          );
          return;
        }
        throw new Error("Animation path not found in response.");
      }
      if (!isDemo) {
        setFrameAnimations((prev) => ({
          ...prev,
          [frameKey]: animationPaths?.s3_url,
        }));
        if (force && animationPaths?.s3_url) {
          dispatch(getRemainingCredit({ token: activeToken }));
        }
      }
    } catch (error) {
      console.error("Failed to generate frame animation:", error);
    } finally {
      setLoadingFrameImages((prev) => ({ ...prev, [frameKey]: false }));
    }
  };

  const generateImageForScene = async (
    scene,
    sceneKey,
    force = false,
    isOnlyCache = false
  ) => {
    if (!scene.sc_visuals_extensive || (!force && sceneImages[sceneKey])) {
      console.log(
        "Warning: Missing sc_visuals_extensive. Please ensure attribute are provided before proceeding."
      );
      return;
    }
    // Check credit balance before proceeding
    if (accountInfo?.credit < 0) {
      showNotice(
        "Insufficient credits. Please add more credits to continue.",
        "error"
      );
      setMonetizationOpen(true);
      return;
    }

    setLoadingSceneImages((prev) => ({ ...prev, [sceneKey]: true }));
    try {
      // const fullPrompt = [
      //   scene.sc_visuals_extensive,
      //   scene.visu_style_primary,
      //   scene.visu_style_secondary,
      //   scene.visu_color_scheme,
      //   scene.visu_quality,
      //   scene.visu_enhancements
      // ].filter(Boolean).join(', ');
      const payload = preparePayload(
        scene.sc_visuals_extensive,
        scene.visu_neg_prompt || "",
        "scene"
      );
      const imagePaths = await generateTargetGroupImage(
        payload,
        `${sessionId}/scene_${sceneKey}`,
        force,
        isOnlyCache
      );

      if (!imagePaths?.s3Path) {
        throw new Error("Image path not found in response.");
      }

      if (force && imagePaths?.s3Path) {
        dispatch(getRemainingCredit({ token: activeToken }));
      }

      setSceneImages((prev) => ({
        ...prev,
        [sceneKey]: imagePaths?.s3Path,
      }));
    } catch (error) {
      console.error("Failed to generate scene image:", error);
    } finally {
      setLoadingSceneImages((prev) => ({ ...prev, [sceneKey]: false }));
    }
  };

  const toggleGroup = (groupId) => {
    if (currentFocusNode?.type === "group" && selectedGroup === groupId)
      setSelectedGroup();
    else setSelectedGroup(groupId);
  };

  const toggleScene = (sceneKey) => {
    setSelectedGroup();
    if (currentFocusNode?.type === "scene" && selectedScene === sceneKey)
      setSelectedScene();
    else setSelectedScene(sceneKey);
    setSelectedFrame();
  };

  const toggleFrame = (frameKey) => {
    setSelectedGroup();
    if (
      currentFocusNode?.type === "frame" &&
      selectedScene &&
      selectedFrame === frameKey
    )
      setSelectedFrame();
    else setSelectedFrame(frameKey);
  };

  const renderFrame = (frame, groupId, scene, selectedSceneData) => {
    const frameKey = frame.key;

    const nbFrame = scene?.shots?.length || 0;
    // const isExpanded = expandedFrames[frameKey];
    const otherWidth =
      (100 - selectedWidth) / Math.max(1, sceneCount[groupId] - 1);

    const sceneWidth = selectedScene
      ? scene.key === selectedScene
        ? selectedWidth
        : otherWidth
      : (nbFrame / Math.max(1, frameCount[groupId])) * 100;

    const selectedFrameData =
      selectedFrame && !!scene
        ? scene.shots?.find((frame) => frame.key === selectedFrame)
        : {};
    const isSelected = frameKey === selectedFrame && selectedFrameData;
    const isHover =
      (!selectedScene && hoverScene === scene.key) ||
      (!selectedScene && hoverFrame === groupId);

    // This block calculates the width of the frame based on the selected scene and frame states.
    // It initializes the frameWidth to 100% and adjusts it based on whether a scene is selected
    // and whether a specific frame is selected within that scene.

    // Plz dont change it until know it...
    let frameWidth = "100%";
    if (selectedSceneData && selectedScene) {
      const isCurrentSceneSelected = selectedScene === scene?.key;
      const shotsLength = scene?.shots?.length || 1;

      if (selectedFrame) {
        if (isCurrentSceneSelected) {
          const neighboursWidth =
            (100 - selectedWidth) / (frameCount[groupId] - shotsLength);
          frameWidth = isSelected
            ? `${selectedWidth - neighboursWidth * (shotsLength - 1)}%`
            : `${neighboursWidth}%`;
        } else {
          frameWidth = `${sceneWidth / shotsLength}%`;
        }
      } else {
        frameWidth = `${
          (1 / shotsLength) *
          (isCurrentSceneSelected ? selectedWidth : sceneWidth)
        }%`;
      }
    }

    return (
      <div
        key={frameKey}
        className={`frame-wrapper${
          selectedFrame
            ? selectedFrame === frame.key
              ? isSelected
                ? " selected"
                : ""
              : " unselected"
            : ""
        }`}
        style={{
          width: frameWidth,
        }}
        onMouseEnter={() => {
          setHoverRelatedNode({ frame: groupId, scene: scene.key });
        }}
        onMouseLeave={() => {
          setHoverRelatedNode();
        }}
        onClick={(e) => {
          if (isSelected) {
            // setDialogData({title: frame.visu_main_subject, img: frameImages[frameKey]});
          } else {
            if (selectedScene !== scene.key) {
              toggleScene(scene.key);
            }
            setCurrentFocusNode({ type: "frame", key: frameKey });
            toggleFrame(frameKey);
          }
        }}
      >
        {loadingFrameImages[frameKey] ? (
          <CircularProgress size={24} />
        ) : (
          frameImages[frameKey] && (
            <div
              className={`frame-card ${isSelected ? " selected" : ""} ${
                isHover ? " hover" : ""
              }`}
              onMouseEnter={() => {
                setHoverRelatedNode({ frame: groupId, scene: scene.key });
              }}
              onMouseLeave={() => {
                setHoverRelatedNode();
              }}
              onClick={(e) => {
                if (isSelected) {
                  setDialogData({
                    title: frame.visu_main_subject,
                    img: frameImages[frameKey],
                  });
                } else {
                  if (selectedScene !== scene.key) {
                    toggleScene(scene.key);
                  }
                  setCurrentFocusNode({ type: "frame", key: frameKey });
                  toggleFrame(frameKey);
                }
              }}
            >
              <div
                className="frame-image"
              >
                {isSelected && (
                  <div className="node-action-buttons">
                        <IconButton
                          className="refresh-button"
                          title="Generate a new image for this shot"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            generateImageForFrame(scene, frame, frameKey, true);
                          }}
                        >
                          <Refresh fontSize="small" />
                        </IconButton>
                        <IconButton
                          className="refresh-button"
                          title={!frameAnimations[frameKey] ? 
                            "Generate Image to Animation" : 
                            frameAnimations[frameKey] && frameImages[frameKey] ?
                            (frameViewModes[frameKey] === 'image' ?
                              "Show Animation" : "Show Image") : ""
                          }
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            onGenerateImageToAnimation(
                              groupId,
                              scene,
                              frame,
                              frameKey,
                              true
                            );
                          }}
                        >
                          {frameViewModes[frameKey] === 'image' ? 
                          <SlideshowOutlined fontSize="small"/> : 
                          <ImageOutlined fontSize="small"/>}
                        </IconButton>
                  </div>
                )}
                {frameViewModes[frameKey] === 'image' && (
                  <img
                    src={frameImages[frameKey]}
                    alt={`Visual for Frame ${frameKey}`}
                  />
                )}
                {frameViewModes[frameKey] === 'animation' && frameAnimations[frameKey] && (
                  <video 
                    onMouseEnter={() => {
                      if (isSelected && frameAnimations[frameKey]) {
                        const video = document.querySelector(`video[data-frame="${frameKey}"]`);
                        if (video && video.readyState >= 3) {
                          if (video) video.play();
                        }
                      }
                    }}
                    onMouseLeave={() => {
                      if (frameAnimations && frameAnimations[frameKey]) {
                        const video = document.querySelector(`video[data-frame="${frameKey}"]`);
                        if (video) video.pause();
                      }
                    }}
                    data-frame={frameKey}
                    src={frameAnimations && frameAnimations[frameKey]} 
                    controls 
                    style={{ 
                      width: '100%', 
                      height: '100%', 
                      objectFit: isSelected ? 'contain' : 'cover'
                    }}
                  />
                )}
                {selectedFrame == frameKey &&
                  frameAnimationEstimates && frameAnimationEstimates[sessionId + "/frame_" + frameKey] && (
                    <div className="animation-estimate">
                      Animation in progress{" "}
                      {
                        frameAnimationEstimates[
                          sessionId + "/frame_" + frameKey
                        ]
                      }
                    </div>
                  )}
              </div>
            </div>
          )
        )}
        {(!frameImages[frameKey] ||
          (frameImages[frameKey] && selectedFrame == frameKey)) &&
          renderFrameDetail(scene, frame)}
        {selectedScene &&
          selectedScene === scene.key &&
          selectedFrame === frameKey &&
          selectedFrameData && (
            <div
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                zIndex: 10,
              }}
              onClick={(e) => {
                if (selectedScene !== scene.key) {
                  toggleScene(scene.key);
                }
                setCurrentFocusNode();
                toggleFrame(frameKey);
              }}
            >
              <IconButton size="small">
                <Close />
              </IconButton>
            </div>
          )}
      </div>
    );
  };

  const handleSaveFrameDetails = (updatedData) => {
    // Validate form data
    const errors = validateFrameFormData(updatedData);

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Update the frame data in displayData
    const updatedDisplayData = displayData.map((group) => ({
      ...group,
      scenes: group.scenes?.map((scene) => ({
        ...scene,
        shots: scene.shots?.map((shot) =>
          shot.key === updatedData.key ? { ...shot, ...updatedData } : shot
        ),
      })),
    }));

    setDisplayData(updatedDisplayData);
    setFrameEditModal({ open: false, data: null });
    setEditFormData({});
    setFormErrors({});

    // Update Redux store if not in demo mode
    if (!isDemo) {
      dispatch(
        updateGlowTextResponse({
          processId: "glow_04",
          updatedResponse: { glow_04_response: updatedDisplayData },
        })
      );
    }
  };

  const onGeneratePicture = (scene, frame, frameKey, force, isOnlyCache) => {
    setFrameViewModes((prev) => ({ ...prev, [frameKey]: 'image' }));
    if (isDemo) {
      setFrameImages((prev) => ({
        ...prev,
        [frameKey]: SAMPLE_FRAME_IMAGES[frameKey],
      }));
    } else {
      generateImageForFrame(scene, frame, frameKey, force, isOnlyCache);
    }
  };

  const renderFrameDetail = (scene, frameData) => {
    return (
      <div className="frame-details">
        {frameData.primary_person && (
          <div className="frame-item">
            <strong>Primary Person:</strong>
            <p>Description: {frameData.primary_person.description}</p>
            <p>Action: {frameData.primary_person.action}</p>
            <p>Expression: {frameData.primary_person.expression}</p>
          </div>
        )}

        {frameData.environment && (
          <div className="frame-item">
            <strong>Environment:</strong>
            <p>Setting: {frameData.environment.setting}</p>
            <p>Background: {frameData.environment.background}</p>
            <p>Props: {frameData.environment.props.join(", ")}</p>
          </div>
        )}

        {frameData.technical && (
          <div className="frame-item">
            <strong>Technical:</strong>
            <p>Camera: {frameData.technical.camera}</p>
            <p>Lighting: {frameData.technical.lighting}</p>
            {frameData.technical.color_grade && (
              <p>Color Grade: {frameData.technical.color_grade}</p>
            )}
          </div>
        )}

        {frameData.audio && (
          <div className="frame-item">
            <strong>Audio:</strong>
            <p>Narration: {frameData.audio.narration || "None"}</p>
            <p>Music: {frameData.audio.music}</p>
          </div>
        )}

        <p>
          <strong>Shot number:</strong> {frameData.shot_number}
        </p>
        <div className="frame-item">
          <strong>Subject:</strong>
          <p>{frameData.visu_main_subject}</p>
        </div>
        <div className="frame-item">
          <strong>Environment:</strong>
          <p>{frameData.visu_environment}</p>
        </div>
        <div className="frame-item">
          <strong>Technical:</strong>
          <p>{frameData.visu_technical}</p>
        </div>
        <p>
          <strong>Type:</strong> {frameData.visu_shot_type}
        </p>
        <p>
          <strong>Subject treatment:</strong> {frameData.visu_subject_treatment}
        </p>
        <p>
          <strong>Duration:</strong> {frameData.duration_shot} seconds
        </p>
        <p>
          <strong>Transition:</strong> {frameData.transition}
        </p>

        <div
          style={{
            display: "flex",
            marginTop: "16px",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          {selectedFrame && (
            <>
              {!frameImages[frameData.key] && (
                <Button
                  variant="contained"
                  onClick={() =>
                    onGeneratePicture(
                      scene,
                      frameData,
                      frameData?.key,
                      true,
                      false
                    )
                  }
                >
                  Generate Picture
                </Button>
              )}
              <Button
                variant="contained"
                onClick={() =>
                  setFrameEditModal({ open: true, data: frameData })
                }
              >
                Edit Frame Details
              </Button>
            </>
          )}
        </div>
      </div>
    );
  };
  const [monetizationOpen, setMonetizationOpen] = useState(false);
  const toggleMonetizationDialog = () => {
    setMonetizationOpen(!monetizationOpen);
  };

  const renderScene = (sceneData, groupId, selectedSceneData) => {
    const sceneKey = sceneData.key;
    const nbFrame = sceneData?.shots?.length || 0;
    // const isExpanded = expandedScenes[sceneKey];
    const isSelected = selectedScene && sceneData.key === selectedScene;
    const isHover =
      (!selectedScene && hoverFrame === groupId) ||
      (!selectedScene && hoverRelatedNode?.scene === sceneKey);
    const otherWidth =
      (100 - selectedWidth) / Math.max(1, sceneCount[groupId] - 1);
    const sceneWidth =
      selectedScene && selectedSceneData
        ? isSelected
          ? selectedWidth
          : otherWidth
        : nbFrame
        ? (nbFrame / Math.max(1, frameCount[groupId])) * 100
        : 100 / sceneCount[groupId];

    return (
      <div
        key={sceneKey}
        className={`scene-wrapper ${
          selectedScene ? (isSelected ? "selected" : "unselected") : ""
        }`}
        style={{ width: `${sceneWidth}%` }}
        onMouseEnter={() => {
          setHoverScene(sceneKey);
          setHoverRelatedNode({ frame: groupId });
        }}
        onMouseLeave={() => {
          setHoverScene();
          setHoverRelatedNode();
        }}
        onClick={() => {
          const isSceneMinimize =
            selectedGroup ||
            (selectedScene &&
              selectedSceneData &&
              selectedSceneData.key &&
              selectedFrame);
          setCurrentFocusNode({ type: "scene", key: sceneKey });
          if (isSceneMinimize || !isSelected) {
            toggleScene(sceneKey);
          }
        }}
      >
        {loadingSceneImages[sceneKey] ? (
          <CircularProgress size={24} />
        ) : (
          sceneClips[sceneKey] && (
            <div
              className={`scene-card ${isSelected ? "selected" : ""} ${
                isHover ? "hover" : ""
              }`}
            >
              <div
                className="scene-image"
              >
                <>
                  {isSelected && (
                    <IconButton
                      className="refresh-button"
                      title="Generate a new image for this scene"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        generateClip(sceneData, sceneData?.key);
                      }}
                    >
                      <Refresh fontSize="small" />
                    </IconButton>
                  )}
                  {sceneClips[sceneKey] && (
                    <video
                      onMouseEnter={() => {
                        if (isSelected && sceneClips[sceneKey]) {
                          const video = document.querySelector(`video[data-scene="${sceneKey}"]`);
                          if (video && video.readyState >= 3) {
                            if (video) video.play();
                          }
                        }
                      }}
                      onMouseLeave={() => {
                        if (sceneClips[sceneKey]) {
                          const video = document.querySelector(`video[data-scene="${sceneKey}"]`);
                          if (video) video.pause();
                        }
                      }}
                      data-scene={sceneKey}
                      src={sceneClips[sceneKey]}
                      controls
                      style={{ 
                        width: "100%", 
                        height: "100%", 
                        objectFit: isSelected ? 'contain' : 'cover' 
                      }} />
                  )}
                  {/* <img
                    src={sceneImages[sceneKey]}
                    alt={`Visual for Scene ${sceneKey}`}
                    onClick={(e) => {
                      if (isSelected) {
                        setDialogData({
                          title: sceneData.sc_visuals_basics,
                          img: sceneImages[sceneKey],
                        });
                      } else {
                        setSelectedFrame();
                      }
                    }}
                  /> */}
                </>
              </div>
            </div>
          )
        )}
        {(!sceneImages[sceneKey] ||
          (sceneImages[sceneKey] && selectedScene === sceneKey)) &&
          renderSceneDetail(sceneData)}
        {selectedScene && selectedScene === sceneData.key && (
          <div
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              zIndex: 10,
            }}
            onClick={(e) => {
              e.stopPropagation();
              setCurrentFocusNode();
              toggleScene();
              setSelectedFrame();
            }}
          >
            <IconButton size="small">
              <Close />
            </IconButton>
          </div>
        )}
      </div>
    );
  };

  const animationToClip = async (
    scene,
    sceneKey,
    force = false,
    isOnlyCache = false
  ) => {
    if (!scene.sc_visuals_extensive || (!force && sceneImages[sceneKey])) {
      console.log(
        "Warning: Missing sc_visuals_extensive. Please ensure attribute are provided before proceeding."
      );
      return;
    }
    // Check credit balance before proceeding
    if (accountInfo?.credit < 0) {
      showNotice(
        "Insufficient credits. Please add more credits to continue.",
        "error"
      );
      setMonetizationOpen(true);
      return;
    }

    setLoadingSceneImages((prev) => ({ ...prev, [sceneKey]: true }));
    try {
      // const fullPrompt = [
      //   scene.sc_visuals_extensive,
      //   scene.visu_style_primary,
      //   scene.visu_style_secondary,
      //   scene.visu_color_scheme,
      //   scene.visu_quality,
      //   scene.visu_enhancements
      // ].filter(Boolean).join(', ');
      const payload = preparePayload(
        scene.sc_visuals_extensive,
        scene.visu_neg_prompt || "",
        "scene"
      );
      const imagePaths = await generateAnimationToClip(
        payload,
        `${sessionId}/scene_${sceneKey}`,
        force,
        isOnlyCache
      );

      if (!imagePaths?.s3Path) {
        throw new Error("Image path not found in response.");
      }

      if (force && imagePaths?.s3Path) {
        dispatch(getRemainingCredit({ token: activeToken }));
      }

      setSceneImages((prev) => ({
        ...prev,
        [sceneKey]: imagePaths?.s3Path,
      }));
    } catch (error) {
      console.error("Failed to generate scene image:", error);
    } finally {
      setLoadingSceneImages((prev) => ({ ...prev, [sceneKey]: false }));
    }
  };

  const generateClip = (sceneData, sceneKey) => {
    if (isDemo) {
      setSceneClips((prev) => ({
        ...prev,
        [sceneKey]: SAMPLE_SCENE_CLIPS[sceneKey],
      }))
    } else {
      animationToClip(sceneData, sceneKey, true, false);
    }
  };

  const renderSceneDetail = (sceneData) => {
    return (
      <div className="scene-details">
        <p>
          <strong>Title:</strong> {sceneData.sc_title}s
        </p>
        <p>
          <strong>Length:</strong> {sceneData.sc_length_seconds}s
        </p>
        <p>
          <strong>Purpose:</strong> {sceneData.sc_purpose}
        </p>
        <p>
          <strong>Emotion:</strong> {sceneData.sc_emotion}
        </p>
        <div className="scene-item">
          <strong>Visual Basics:</strong>
          <p>{sceneData.sc_visuals_basics}</p>
        </div>
        <div className="scene-item">
          <strong>Visual Details:</strong>
          <p>{sceneData.sc_visuals_extensive}</p>
        </div>
        <div className="scene-item">
          <strong>Success Metric:</strong>
          <p>{sceneData.sc_success_metric}</p>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "16px",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          {selectedScene && (
            <>
              {!sceneClips[sceneData.key] && (
                <Button
                  variant="contained"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    generateClip(sceneData, sceneData?.key);
                  }}
                >
                  Generate Clip
                </Button>
              )}
              <Button
                variant="contained"
                onClick={() =>
                  setSceneEditModal({ open: true, data: sceneData })
                }
              >
                Edit Scene Details
              </Button>
            </>
          )}
        </div>
      </div>
    );
  };

  const clipToVideo = async (
    group,
    groupId,
    force = false,
    isOnlyCache = false
  ) => {
    if (
      !group.audience ||
      !group.visual_description ||
      (!force && groupImages[groupId])
    )
      return;
    // Check credit balance before proceeding
    if (accountInfo?.credit < 0) {
      showNotice(
        "Insufficient credits. Please add more credits to continue.",
        "error"
      );
      setMonetizationOpen(true);
      return;
    }
    setLoadingGroupImages((prev) => ({ ...prev, [groupId]: true }));
    const targetGroupName = group.audience.toLowerCase().replace(/\s+/g, "_");
    try {
      const payload = preparePayload(group.visual_description, "", "audience");
      const imagePaths = await generateClipToVideo(
        payload,
        `${sessionId}/group_${groupId}`,
        force,
        isOnlyCache
      );

      if (!imagePaths?.s3Path) {
        throw new Error("Image path not found in response.");
      }

      setGroupImages((prev) => ({
        ...prev,
        [groupId]: imagePaths?.s3Path,
      }));

      if (force && imagePaths?.s3Path) {
        dispatch(getRemainingCredit({ token: activeToken }));
      }
    } catch (error) {
      console.error("Failed to generate group image:", error);
    } finally {
      setLoadingGroupImages((prev) => ({ ...prev, [groupId]: false }));
    }
  };

  const generateVideo = (group, groupId, force = false) => {
    if (isDemo) {
      setGroupVideos((prev) => ({
        ...prev,
        [groupId]: SAMPLE_GROUP_VIDEOS[groupId],
      }));
    } else {
      clipToVideo(group, groupId, force);
    }
  };
  const renderTargetGroup = (groupData, groupId) => {
    const selectedSceneData = selectedScene
      ? groupData.scenes?.find((scene) => scene.key === selectedScene)
      : {};
    const isHover =
      (!selectedScene && hoverFrame === groupId) ||
      (!selectedScene && hoverRelatedNode?.frame === groupId);
    const isGroupMinimize =
      !selectedGroup &&
      selectedScene &&
      selectedSceneData &&
      selectedSceneData.key;
    const isSceneMinimize =
      selectedGroup ||
      (selectedScene &&
        selectedSceneData &&
        selectedSceneData.key &&
        selectedFrame);
    const isFrameMinimize =
      selectedGroup ||
      (selectedScene &&
        selectedSceneData &&
        selectedSceneData.key &&
        !selectedFrame);
    return (
      <div key={`group-${groupId}`} className="target-group glow_03">
        <div
          className={`group-content ${
            selectedGroup === groupId ? "selected" : ""
          } ${isGroupMinimize ? "minimize" : ""} ${isHover ? "hover" : ""}`}
          onMouseEnter={() => {
            setHoverFrame(groupId);
          }}
          onMouseLeave={() => {
            setHoverFrame();
          }}
          onClick={() => {
            setCurrentFocusNode({ type: "group", key: groupId });
            if (selectedGroup !== groupId) {
              toggleGroup(groupId);
            }
          }}
        >
          {loadingGroupImages[groupId] ? (
            <CircularProgress size={24} />
          ) : (
            groupVideos[groupId] && (
              <div className="group-card">
                <div
                  className="group-image"
                  onClick={() => {
                    setCurrentFocusNode({ type: "group", key: groupId });
                    if (selectedGroup !== groupId) {
                      toggleGroup(groupId); 
                    }
                  }}
                >
                  <>
                    <IconButton
                      className="refresh-button"
                      title="Generate a new image for this group"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        generateVideo(groupData, groupId, true);
                      }}
                    >
                      <Refresh fontSize="small" />
                    </IconButton>
                    {groupVideos[groupId] && (
                      <video 
                        onMouseEnter={() => {
                          if (groupVideos[groupId]) {
                            const video = document.querySelector(`video[data-group="${groupId}"]`);
                            if (video && video.readyState >= 3) {
                              if (video) video.play();
                            }
                          }
                        }}
                        onMouseLeave={() => {
                          if (groupVideos[groupId]) {
                            const video = document.querySelector(`video[data-group="${groupId}"]`);
                            if (video) video.pause();
                          }
                        }}
                        data-group={groupId}
                        src={groupVideos[groupId]} 
                        controls 
                        style={{ 
                          width: '100%', 
                          height: '100%', 
                          objectFit: selectedGroup === groupId ? 'contain' : 'cover' 
                        }}
                      />
                    )}
                    {/* <img
                      src={groupImages[groupId]}
                      alt={`Visual for ${groupData.audience}`}
                      onClick={(e) => {
                        // Open the dialog with the full image
                        setDialogData({
                          title: groupData.audience,
                          img: groupImages[groupId],
                        });
                      }}
                    /> */}
                  </>
                </div>
              </div>
            )
          )}
          <div className="group-details">
            <div
              className="group-detail"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <div>
                <h4>Video Base Information</h4>
                <p>
                  <strong>Description:</strong> {groupData.visual_description}
                </p>
                <p>
                  <strong>Duration:</strong> {groupData.target_duration_in_sec}{" "}
                  seconds
                </p>
                <p>
                  <strong>Message:</strong> {groupData.main_message}
                </p>
              </div>
              <div
                sx={{ display: "flex", justifyContent: "center", gap: "8px" }}
              >
                {!groupVideos[groupId] && (
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      generateVideo(groupData, groupId, true);
                    }}
                  >
                    Generate Video
                  </Button>
                )}
              </div>
            </div>

            <div className="group-detail">
              <h4>Key points</h4>
              <ul>
                {groupData.key_points.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>
            <IconButton
              className="edit-button"
              title="Edit Group Details"
              size="small"
              onClick={() => {
                setGroupEditModal({ open: true, data: groupData });
                setGroupEditFormData({ ...groupData });
              }}
            >
              <Edit />
            </IconButton>
          </div>
        </div>
        {sceneCount[groupId] === 0 && (
          <ActionBar
            currentLevel={0}
            updateData={updateDisplayData}
            groupId={groupId}
            isDemo={isDemo}
          />
        )}

        <div className={`scene-content ${isSceneMinimize ? "minimize" : ""}`}>
          {groupData.scenes?.map((scene) =>
            renderScene(scene, groupId, selectedSceneData)
          )}
          <div className={`scene-content-actions`}>
            <IconButton title="Add a new scene" onClick={() => {}}>
              <AddCircle />
            </IconButton>
          </div>
        </div>
        {sceneCount[groupId] > 0 && frameCount[groupId] === 0 && (
          <ActionBar
            currentLevel={1}
            updateData={updateDisplayData}
            groupId={groupId}
            isDemo={isDemo}
          />
        )}

        <div className={`frame-content ${isFrameMinimize ? "minimize" : ""}`}>
          {groupData.scenes?.map((scene) =>
            scene.shots?.map((frame) =>
              renderFrame(frame, groupId, scene, selectedSceneData)
            )
          )}
          <div className={`frame-content-actions`}>
            <IconButton title="Add a new frame" onClick={() => {}}>
              <AddCircle />
            </IconButton>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="process-viewer">
        <Box>
          <ViewerHeader
            title={process.info.title}
            subtitle={process.info.description}
            processId="glow_04"
            sessionId={sessionId}
            showRerun={!!process.endpoints.run}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      </div>
    );
  }

  if (!displayData) {
    return <div>No data available</div>;
  }

  // Function to validate text for special characters
  const containsSpecialChars = (str) => {
    // Regex to match special characters except spaces, periods, commas, and basic punctuation
    const specialCharsRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|<>?~`]/;
    return specialCharsRegex.test(str);
  };

  // Validate all fields in the form data
  const validateSceneFormData = (data) => {
    const errors = {};
    const fieldsToValidate = [];

    fieldsToValidate.forEach((field) => {
      if (data[field] && containsSpecialChars(data[field])) {
        errors[field] = "Special characters are not allowed";
      }
    });

    // Special validation for length (should be a number)
    if (data.sc_length_seconds) {
      if (isNaN(data.sc_length_seconds)) {
        errors.sc_length_seconds = "Must be a number";
      } else if (data.sc_length_seconds < 0) {
        errors.sc_length_seconds = "Must be a positive number";
      }
    }

    return errors;
  };

  // Validate all fields in the form data
  const validateFrameFormData = (data) => {
    const errors = {};
    const fieldsToValidate = [];

    fieldsToValidate.forEach((field) => {
      if (data[field] && containsSpecialChars(data[field])) {
        errors[field] = "Special characters are not allowed";
      }
    });

    // Special validation for length (should be a number)
    if (data.duration_shot) {
      if (isNaN(data.duration_shot)) {
        errors.duration_shot = "Must be a number";
      } else if (data.duration_shot < 0) {
        errors.duration_shot = "Must be a positive number";
      }
    }

    return errors;
  };

  const handleInputChange = (field, value) => {
    setEditFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    // Clear error for this field when user starts typing
    setFormErrors((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  const handleSaveSceneDetails = (updatedData) => {
    // Validate form data
    const errors = validateSceneFormData(updatedData);

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return; // Don't save if there are validation errors
    }

    // Update the scene data in displayData
    const updatedDisplayData = displayData.map((group) => ({
      ...group,
      scenes: group.scenes?.map((scene) =>
        scene.key === updatedData.key ? { ...scene, ...updatedData } : scene
      ),
    }));

    setDisplayData(updatedDisplayData);
    setSceneEditModal({ open: false, data: null });
    setEditFormData({});
    setFormErrors({});

    // Update Redux store if not in demo mode
    if (!isDemo) {
      dispatch(
        updateGlowTextResponse({
          processId: "glow_04",
          updatedResponse: { glow_04_response: updatedDisplayData },
        })
      );
    }
  };

  const handleSaveGroupDetails = (updatedData) => {
    // Validate form data
    const errors = validateGroupFormData(updatedData);

    if (Object.keys(errors).length > 0) {
      setGroupFormErrors(errors);
      return; // Don't save if there are validation errors
    }

    // Update the group data in displayData
    const updatedDisplayData = displayData.map((group) =>
      group.id === updatedData.id ? { ...group, ...updatedData } : group
    );

    setDisplayData(updatedDisplayData);
    setGroupEditModal({ open: false, data: null });
    setGroupEditFormData({});
    setGroupFormErrors({});

    // Update Redux store if not in demo mode
    if (!isDemo) {
      dispatch(
        updateGlowTextResponse({
          processId: "glow_04",
          updatedResponse: { glow_04_response: updatedDisplayData },
        })
      );
    }
  };

  const validateGroupFormData = (data) => {
    const errors = {};
    const fieldsToValidate = [];

    fieldsToValidate.forEach((field) => {
      if (data[field] && containsSpecialChars(data[field])) {
        errors[field] = "Special characters are not allowed";
      }
    });

    // Special validation for length (should be a number)
    if (data.target_duration_in_sec) {
      if (isNaN(data.target_duration_in_sec)) {
        errors.target_duration_in_sec = "Must be a number";
      } else if (data.target_duration_in_sec < 0) {
        errors.target_duration_in_sec = "Must be a positive number";
      }
    }

    return errors;
  };

  return (
    <div className="process-viewer">
      <Box>
        {isDemo && (
          <Box
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%) rotate(-45deg)",
              color: "rgba(255, 140, 0, 0.45)",
              fontSize: "48px",
              fontWeight: "bold",
              pointerEvents: "none",
              zIndex: 9999,
              width: "100%",
              textAlign: "center",
              userSelect: "none",
              textTransform: "uppercase",
            }}
          >
            Sample (Glowtest)
          </Box>
        )}
        <ViewerHeader
          title={process.info.title}
          subtitle={process.info.description}
          processId="glow_04"
          sessionId={sessionId}
          showRerun={!!process.endpoints.run}
        />
        {displayData?.map((group, index) => {
          if (accountInfo.subscription_type === "") {
            if (index > 0) {
              return (
                <div
                  key={`group-${group.id}`}
                  className="target-group glow_03 premium-locked"
                >
                  {renderTargetGroup(SAMPLE_GLOW_01[1], SAMPLE_GLOW_01[1].id)}
                  <PremiumFeatureOverlay/>
                </div>
              );
            }
          }
          return renderTargetGroup(group, group.id);
        })}
        <ViewFooter
          isDisabled={!sessionId}
          onComplete={onComplete}
          buttonText={process.footer.continueText}
          processId="glow_04"
        />
        <Dialog
          open={dialogData.img != ""}
          onClose={() => setDialogData({ title: "", subtitle: "", img: "" })}
          maxWidth="sm"
        >
          <DialogTitle>
            {dialogData.title}
            <IconButton
              aria-label="close"
              onClick={() =>
                setDialogData({ title: "", subtitle: "", img: "" })
              }
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent
            dividers
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden", // Prevent scrolling
            }}
          >
            <img
              src={dialogData.img}
              alt="Full view"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={sceneEditModal.open}
          onClose={() => {
            setSceneEditModal({ open: false, data: null });
            setEditFormData({});
            setFormErrors({});
          }}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            Edit Scene Details
            <IconButton
              aria-label="close"
              onClick={() => {
                setSceneEditModal({ open: false, data: null });
                setEditFormData({});
                setFormErrors({});
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            {sceneEditModal.data && (
              <Box
                component="form"
                sx={{ "& .MuiTextField-root": { m: 1, width: "100%" } }}
              >
                <TextField
                  label="Title"
                  defaultValue={sceneEditModal.data.sc_title}
                  onChange={(e) =>
                    handleInputChange("sc_title", e.target.value)
                  }
                  error={!!formErrors.sc_title}
                  helperText={formErrors.sc_title}
                />
                <TextField
                  label="Length (seconds)"
                  defaultValue={sceneEditModal.data.sc_length_seconds}
                  onChange={(e) =>
                    handleInputChange("sc_length_seconds", e.target.value)
                  }
                  error={!!formErrors.sc_length_seconds}
                  helperText={formErrors.sc_length_seconds}
                />
                <TextField
                  label="Purpose"
                  defaultValue={sceneEditModal.data.sc_purpose}
                  onChange={(e) =>
                    handleInputChange("sc_purpose", e.target.value)
                  }
                  error={!!formErrors.sc_purpose}
                  helperText={formErrors.sc_purpose}
                />
                <TextField
                  label="Emotion"
                  defaultValue={sceneEditModal.data.sc_emotion}
                  onChange={(e) =>
                    handleInputChange("sc_emotion", e.target.value)
                  }
                  error={!!formErrors.sc_emotion}
                  helperText={formErrors.sc_emotion}
                />
                <TextField
                  label="Visual Basics"
                  multiline
                  rows={3}
                  defaultValue={sceneEditModal.data.sc_visuals_basics}
                  onChange={(e) =>
                    handleInputChange("sc_visuals_basics", e.target.value)
                  }
                  error={!!formErrors.sc_visuals_basics}
                  helperText={formErrors.sc_visuals_basics}
                />
                <TextField
                  label="Visual Details"
                  multiline
                  rows={3}
                  defaultValue={sceneEditModal.data.sc_visuals_extensive}
                  onChange={(e) =>
                    handleInputChange("sc_visuals_extensive", e.target.value)
                  }
                  error={!!formErrors.sc_visuals_extensive}
                  helperText={formErrors.sc_visuals_extensive}
                />
                <TextField
                  label="Success Metric"
                  defaultValue={sceneEditModal.data.sc_success_metric}
                  onChange={(e) =>
                    handleInputChange("sc_success_metric", e.target.value)
                  }
                  error={!!formErrors.sc_success_metric}
                  helperText={formErrors.sc_success_metric}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setSceneEditModal({ open: false, data: null });
                setEditFormData({});
                setFormErrors({});
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (Object.keys(editFormData).length > 0) {
                  handleSaveSceneDetails({
                    ...sceneEditModal.data,
                    ...editFormData,
                  });
                } else setSceneEditModal({ open: false, data: null });
              }}
              variant="contained"
            >
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={frameEditModal.open}
          onClose={() => {
            setFrameEditModal({ open: false, data: null });
            setEditFormData({});
            setFormErrors({});
          }}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            Edit Frame Details
            <IconButton
              aria-label="close"
              onClick={() => {
                setFrameEditModal({ open: false, data: null });
                setEditFormData({});
                setFormErrors({});
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            {frameEditModal.data && (
              <Box
                component="form"
                sx={{ "& .MuiTextField-root": { m: 1, width: "100%" } }}
              >
                <TextField
                  label="Main Subject"
                  defaultValue={frameEditModal.data.visu_main_subject}
                  onChange={(e) =>
                    handleInputChange("visu_main_subject", e.target.value)
                  }
                  error={!!formErrors.visu_main_subject}
                  helperText={formErrors.visu_main_subject}
                  multiline
                  rows={2}
                />
                <TextField
                  label="Environment"
                  defaultValue={frameEditModal.data.visu_environment}
                  onChange={(e) =>
                    handleInputChange("visu_environment", e.target.value)
                  }
                  error={!!formErrors.visu_environment}
                  helperText={formErrors.visu_environment}
                  multiline
                  rows={2}
                />
                <TextField
                  label="Technical"
                  defaultValue={frameEditModal.data.visu_technical}
                  onChange={(e) =>
                    handleInputChange("visu_technical", e.target.value)
                  }
                  error={!!formErrors.visu_technical}
                  helperText={formErrors.visu_technical}
                  multiline
                  rows={2}
                />
                <TextField
                  label="Shot Type"
                  defaultValue={frameEditModal.data.visu_shot_type}
                  onChange={(e) =>
                    handleInputChange("visu_shot_type", e.target.value)
                  }
                  error={!!formErrors.visu_shot_type}
                  helperText={formErrors.visu_shot_type}
                />
                <TextField
                  label="Subject Treatment"
                  defaultValue={frameEditModal.data.visu_subject_treatment}
                  onChange={(e) =>
                    handleInputChange("visu_subject_treatment", e.target.value)
                  }
                  error={!!formErrors.visu_subject_treatment}
                  helperText={formErrors.visu_subject_treatment}
                />
                <TextField
                  label="Duration (seconds)"
                  defaultValue={frameEditModal.data.duration_shot}
                  onChange={(e) =>
                    handleInputChange("duration_shot", e.target.value)
                  }
                  error={!!formErrors.duration_shot}
                  helperText={formErrors.duration_shot}
                />
                <TextField
                  label="Transition"
                  defaultValue={frameEditModal.data.transition}
                  onChange={(e) =>
                    handleInputChange("transition", e.target.value)
                  }
                  error={!!formErrors.transition}
                  helperText={formErrors.transition}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setFrameEditModal({ open: false, data: null });
                setEditFormData({});
                setFormErrors({});
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (Object.keys(editFormData).length > 0) {
                  handleSaveFrameDetails({
                    ...frameEditModal.data,
                    ...editFormData,
                  });
                } else setFrameEditModal({ open: false, data: null });
              }}
              variant="contained"
            >
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={groupEditModal.open}
          onClose={() => {
            setGroupEditModal({ open: false, data: null });
            setGroupEditFormData({});
            setGroupFormErrors({});
          }}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            Edit Group Details
            <IconButton
              aria-label="close"
              onClick={() => {
                setGroupEditModal({ open: false, data: null });
                setGroupEditFormData({});
                setGroupFormErrors({});
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            {groupEditModal.data && (
              <Box
                component="form"
                sx={{ "& .MuiTextField-root": { m: 1, width: "100%" } }}
              >
                <TextField
                  label="Description"
                  defaultValue={groupEditModal.data.visual_description}
                  onChange={(e) =>
                    handleInputChange("visual_description", e.target.value)
                  }
                  error={!!groupFormErrors.visual_description}
                  helperText={groupFormErrors.visual_description}
                />
                <TextField
                  label="Duration (seconds)"
                  defaultValue={groupEditModal.data.target_duration_in_sec}
                  onChange={(e) =>
                    handleInputChange("target_duration_in_sec", e.target.value)
                  }
                  error={!!groupFormErrors.target_duration_in_sec}
                  helperText={groupFormErrors.target_duration_in_sec}
                />
                <TextField
                  label="Message"
                  defaultValue={groupEditModal.data.main_message}
                  onChange={(e) =>
                    handleInputChange("main_message", e.target.value)
                  }
                  error={!!groupFormErrors.main_message}
                  helperText={groupFormErrors.main_message}
                />
                <TextField
                  label="Key Points"
                  defaultValue={groupEditModal.data.key_points.join("\n")}
                  onChange={(e) =>
                    handleInputChange(
                      "key_points",
                      e.target.value.split("\n").map((point) => point.trim())
                    )
                  }
                  error={!!groupFormErrors.key_points}
                  helperText="Enter each key point on a new line"
                  multiline
                  rows={groupEditModal.data.key_points.length}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setGroupEditModal({ open: false, data: null });
                setGroupEditFormData({});
                setGroupFormErrors({});
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (Object.keys(editFormData).length > 0) {
                  handleSaveGroupDetails({
                    ...groupEditModal.data,
                    ...editFormData,
                  });
                } else setGroupEditModal({ open: false, data: null });
              }}
              variant="contained"
            >
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
        <MonetizationDialog
          accountInfo={accountInfo}
          open={monetizationOpen}
          onClose={toggleMonetizationDialog}
        />
      </Box>
    </div>
  );
};

export default Glow04ProcessorIcicle;
