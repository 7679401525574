import React from 'react';
import { Typography, Box, Button, Tooltip } from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { ProcessChainManager } from '../../utils/processChain';

import { useDispatch, useSelector } from 'react-redux';
import { selectSessionStatus, selectSessionProcess, runProcess, loadingGlowStatus } from '../../components/session/sessionSlide';


const ViewerHeader = ({ title, subtitle, processId, sessionId, showRerun = true }) => {
  const sessionStatus = useSelector(selectSessionStatus);
  const sessionProcess = useSelector(selectSessionProcess);
  const dispatch = useDispatch();

  const handleRerun = async () => {
    try {
      // Clear the cache for this process
      dispatch(runProcess({ processId: processId}))
    } catch (error) {
      console.error('Error rerunning process:', error);
    }
  };

  // Use loadingProcesses as the primary name
  const loadingProcesses = useSelector((state) => loadingGlowStatus(state)[processId] || false);

  return (
    <Box sx={{ 
      textAlign: 'center', 
      my: 3,
      borderBottom: '1px solid var(--border-color)',
      pb: 2,
      position: 'relative'
    }}>
      {showRerun && (
        <Box sx={{ 
          position: 'absolute', 
          left: 0, 
          top: 0 
        }}>
          <Tooltip title="Rerun this analysis">
            {loadingProcesses && sessionStatus === 'loading' ? (
              <Typography
                variant="body2"
                sx={{
                  color: 'primary.main',
                  fontSize: '0.75rem',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  '&::after': {
                    content: '""',
                    display: 'inline-block',
                    width: '1em',
                    height: '1em',
                    border: '2px solid currentColor',
                    borderRadius: '50%',
                    borderTopColor: 'transparent',
                    animation: 'spin 1s linear infinite'
                  }
                }}
              >
                Loading {sessionProcess} data...
              </Typography>
            ) : (
              <Button 
                onClick={handleRerun}              
                size="small"
                sx={{
                  color: 'var(--button-text)',
                  backgroundColor: 'var(--button-bg)',
                  '&:hover': {
                    backgroundColor: 'var(--button-hover)'
                  },
                }}
              >
                <RefreshIcon sx={{paddingRight: '5px'}} />
                Request data
              </Button>
            )}
          </Tooltip>
        </Box>
      )}
      <Typography variant="h4" sx={{ 
        color: 'var(--color-text-primary)',
        fontWeight: 600,
        fontSize: '28px'
      }}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="subtitle1" sx={{ 
          color: 'var(--color-text-secondary)',
          mt: 1
        }}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default ViewerHeader;