/**
 * Utility for calculating video generation costs and processing times
 * Based on provider configurations and request parameters
 */

/**
 * Convert price string (e.g. '$0.005') to float value
 * @param {string|number} priceStr - The price as a string or number
 * @returns {number} - The price as a float
 */
export const convertPriceStringToFloat = (priceStr) => {
  // If already a number, just return it as float
  if (typeof priceStr === 'number') {
    return parseFloat(priceStr);
  }
  
  // If empty or undefined, return 0
  if (!priceStr) {
    return 0.0;
  }
  
  // Extract numerical value from string (remove $ and other non-numeric chars except . and -)
  const match = priceStr.match(/[-+]?\d*\.?\d+/);
  if (match) {
    return parseFloat(match[0]);
  }
  
  return 0.0;
};

/**
 * Calculate costs and processing times for any service type
 * @param {Object} config - Complete provider configuration with pricing information
 * @param {Object} requestParams - User's request parameters
 * @param {string} serviceType - Type of service: "image", "animation", "voiceover", or "video"
 * @param {boolean} applyMarkup - Whether to apply markup to provider costs
 * @param {number} markupPercentage - Markup percentage to apply
 * @param {boolean} convertToCredits - Whether to convert USD prices to application credits
 * @param {number} usdToCreditRate - Conversion rate from USD to application credits
 * @returns {Object} - Contains costs, processing time, and other metrics
 */
export const calculateServiceMetrics = (
  config,
  requestParams,
  serviceType,
  applyMarkup = true,
  markupPercentage = null,
  convertToCredits = true,
  usdToCreditRate = null
) => {
  // Get global pricing settings from config
  const pricingSettings = config.pricing_settings || {};
  
  // Use provided markup percentage or default from config
  if (markupPercentage === null) {
    markupPercentage = pricingSettings.markup_percentage || 20;
  }
  
  const markupMultiplier = 1 + (markupPercentage / 100);
  
  // Use provided credit rate or default from config
  if (usdToCreditRate === null) {
    usdToCreditRate = pricingSettings.usd_to_credit_rate || 1000;
  }
  
  // Base result structure
  const result = {
    providerCostUSD: 0.0,
    yourCostUSD: 0.0,
    userCostCredits: 0.0,
    processingTimeSec: 0,
    markupApplied: applyMarkup,
    markupPercentage: applyMarkup ? markupPercentage : 0,
    usdToCreditRate: usdToCreditRate
  };
  
  // Extract request parameters
  const provider = requestParams.provider;
  const model = requestParams.model;
  const hosting = requestParams.hosting || 'inhouse';
  const totalDuration = requestParams.duration_seconds || requestParams.target_video_duration || 30;
  
  // Calculate shot counts based on video duration
  const secondsPerShot = requestParams.seconds_per_shot || 2;
  const shotsPerScene = requestParams.shots_per_scene || 3;
  const totalScenes = requestParams.total_scenes || Math.ceil(totalDuration / (secondsPerShot * shotsPerScene));
  
  // Calculate shot counts
  const totalShots = Math.ceil(totalDuration / secondsPerShot);
  const totalVoiceoverSegments = Math.ceil(totalScenes / (requestParams.scenes_per_voiceover || 1));
  
  // Check if provider and model are active
  if (provider && config.providers && config.providers[provider]) {
    const providerConfig = config.providers[provider];
    
    if (providerConfig.status === 'active') {
      // Identify the right price path and metrics based on service type
      let serviceKey, units, costKey, timeKey, fpsMultiplier;
      
      if (serviceType === 'image') {
        // Handle text-to-image or image-to-image
        serviceKey = requestParams.generation_mode || 'text_to_image';
        // Use the number of shots/images needed for the video
        units = requestParams.number_of_images || totalShots;
        costKey = 'provider_cost_per_image_usd';
        timeKey = 'est_proc_time_in_sec';
        
      } else if (serviceType === 'animation' || serviceType === 'video') {
        // Handle image-to-video
        serviceKey = 'image_to_video';
        // Each shot needs animation processing
        units = totalDuration;
        costKey = 'provider_cost_per_second_usd';
        timeKey = 'est_proc_time_per_sec_in_sec';
        
        // Calculate FPS multiplier if applicable
        fpsMultiplier = 1.0;
        if (requestParams.quality_options && requestParams.quality_options.fps) {
          const selectedFps = requestParams.quality_options.fps;
          
          // Get default FPS from config
          const formats = providerConfig.supported_formats || {};
          const fpsConfig = formats.fps && formats.fps[model] ? formats.fps[model] : {};
          const defaultFps = fpsConfig.default || 6;
          
          if (defaultFps > 0) {
            fpsMultiplier = Math.max(1.0, selectedFps / defaultFps);
          }
        }
        
      } else if (serviceType === 'voiceover') {
        // Handle text-to-speech
        serviceKey = 'text_to_speech';
        const text = requestParams.text || '';
        
        // Estimate audio length from text (using 2.5 words per second)
        let audioSeconds;
        if (text) {
          const wordCount = text.split(/\s+/).length;
          audioSeconds = wordCount / 2.5;
        } else {
          audioSeconds = requestParams.audio_seconds || totalDuration;
        }
        
        // Apply speed adjustment and consider voiceover segmentation
        const speed = requestParams.speed || 1.0;
        const speedAdjustment = speed > 0 ? 1.0 / speed : 1.0;
        units = audioSeconds * speedAdjustment;
        
        // If we're doing scene-based voiceovers, adjust the units
        if (requestParams.voiceover_per_scene) {
          // Multiply by the number of voiceover segments we'll need
          units = units * totalVoiceoverSegments;
        }
        
        costKey = 'provider_cost_per_second_usd';
        timeKey = 'est_proc_time_per_sec_in_sec';
      }
      
      // Check hosting option is available
      let hostingConfig = providerConfig.pricing && 
                        providerConfig.pricing.hosting_options && 
                        providerConfig.pricing.hosting_options[hosting];
                        
      if (!hostingConfig) {
        // For recraft-style configs, get pricing directly
        hostingConfig = providerConfig.pricing || {};
      }
      
      if (hostingConfig && hostingConfig.status !== 'unavailable') {
        // Get the services
        const services = hostingConfig.services || {};
        
        // Find the right cost
        let providerCostPerUnit = 0.0;
        
        if (services[serviceKey]) {
          if (model && services[serviceKey][model]) {
            const costStr = services[serviceKey][model][costKey] || '0';
            providerCostPerUnit = convertPriceStringToFloat(costStr);
          } else {
            // Try without model
            const costStr = services[serviceKey][costKey] || '0';
            providerCostPerUnit = convertPriceStringToFloat(costStr);
          }
        }
        
        // Calculate total provider cost based on service type
        let providerTotalCost = 0;
        
        if (serviceType === 'image') {
          // For images, we need to generate one per shot
          providerTotalCost = providerCostPerUnit * units;
        } else if (serviceType === 'animation') {
          // For animation, we process each second of the video
          providerTotalCost = providerCostPerUnit * units;
          if (fpsMultiplier) {
            providerTotalCost *= fpsMultiplier;
          }
        } else if (serviceType === 'voiceover') {
          // For voiceover, we process the audio segments
          providerTotalCost = providerCostPerUnit * units;
        }
        
        // Apply markup if requested
        let yourTotalCost = providerTotalCost;
        if (applyMarkup) {
          yourTotalCost = providerTotalCost * markupMultiplier;
        }
        
        // Convert to credits if requested
        const userTotalCredits = convertToCredits ? yourTotalCost * usdToCreditRate : 0.0;
        
        // Calculate processing time based on service type
        let totalProcTime = 0;
        
        if (serviceType === 'image') {
          // Processing time for images
          const procTimePerUnit = hostingConfig[timeKey] || 1;
          totalProcTime = Math.ceil(procTimePerUnit * units);
        } else if (serviceType === 'animation') {
          // Processing time for animation
          const procTimePerUnit = hostingConfig[timeKey] || 40;
          totalProcTime = Math.ceil(procTimePerUnit * units);
        } else if (serviceType === 'voiceover') {
          // Processing time for voiceover
          const procTimePerUnit = hostingConfig[timeKey] || 0.025;
          totalProcTime = Math.ceil(procTimePerUnit * units);
        }
        
        // Add shot calculation metadata to the result
        result.metadata = {
          totalDuration,
          secondsPerShot,
          totalShots,
          shotsPerScene,
          totalScenes,
          totalVoiceoverSegments
        };
        
        // Update result
        result.providerCostUSD = parseFloat(providerTotalCost.toFixed(4));
        result.yourCostUSD = parseFloat(yourTotalCost.toFixed(4));
        result.userCostCredits = parseFloat(userTotalCredits.toFixed(2));
        result.processingTimeSec = totalProcTime;
      }
    }
  }
  
  return result;
};

/**
 * Calculate costs and processing times for a complete text-to-video pipeline
 * @param {Object} configs - Dictionary mapping config types to their contents
 * @param {Object} requestParams - User's request parameters
 * @param {boolean} applyMarkup - Whether to apply markup to provider costs
 * @param {number} markupPercentage - Markup percentage to apply
 * @param {boolean} convertToCredits - Whether to convert USD prices to application credits
 * @param {number} usdToCreditRate - Conversion rate from USD to application credits
 * @returns {Object} - Contains costs, processing time, breakdown by step, and standardized metrics
 */
export const calculateVideoMetrics = (
  configs,
  requestParams,
  applyMarkup = true,
  markupPercentage = null,
  convertToCredits = true,
  usdToCreditRate = null
) => {
  // If we only have one config, create a simple dictionary for consistent handling
  if (!configs.image && !configs.animation && !configs.voiceover) {
    configs = {
      image: configs,
      animation: configs,
      voiceover: configs
    };
  }
  
  // Get global pricing settings from first available config
  let pricingSettings = { markup_percentage: 20, usd_to_credit_rate: 1000 };
  for (const configType of Object.keys(configs)) {
    if (configs[configType] && configs[configType].pricing_settings) {
      pricingSettings = configs[configType].pricing_settings;
      break;
    }
  }
  
  // Use provided markup percentage or default from config
  if (markupPercentage === null) {
    markupPercentage = pricingSettings.markup_percentage || 20;
  }
  
  // Use provided credit rate or default from config
  if (usdToCreditRate === null) {
    usdToCreditRate = pricingSettings.usd_to_credit_rate || 1000;
  }
  
  // Extract common parameters for shot calculation
  const totalDuration = requestParams.duration_seconds || 
                       (requestParams.text_to_image && requestParams.text_to_image.duration_seconds) || 
                       (requestParams.image_to_video && requestParams.image_to_video.duration_seconds) || 
                       30;
                       
  const secondsPerShot = requestParams.seconds_per_shot || 2;
  const shotsPerScene = requestParams.shots_per_scene || 3;
  const totalScenes = requestParams.total_scenes || Math.ceil(totalDuration / (secondsPerShot * shotsPerScene));
  const totalShots = Math.ceil(totalDuration / secondsPerShot);
  const totalVoiceoverSegments = Math.ceil(totalScenes / (requestParams.scenes_per_voiceover || 1));
  
  // Add these parameters to each request
  const enrichedRequestParams = {
    ...requestParams,
    duration_seconds: totalDuration,
    seconds_per_shot: secondsPerShot,
    shots_per_scene: shotsPerScene,
    total_scenes: totalScenes
  };
  
  if (requestParams.text_to_image) {
    enrichedRequestParams.text_to_image = {
      ...requestParams.text_to_image,
      duration_seconds: totalDuration,
      seconds_per_shot: secondsPerShot,
      shots_per_scene: shotsPerScene,
      total_scenes: totalScenes
    };
  }
  
  if (requestParams.image_to_video) {
    enrichedRequestParams.image_to_video = {
      ...requestParams.image_to_video,
      duration_seconds: totalDuration,
      seconds_per_shot: secondsPerShot,
      shots_per_scene: shotsPerScene,
      total_scenes: totalScenes
    };
  }
  
  if (requestParams.text_to_speech) {
    enrichedRequestParams.text_to_speech = {
      ...requestParams.text_to_speech,
      duration_seconds: totalDuration,
      seconds_per_shot: secondsPerShot,
      shots_per_scene: shotsPerScene,
      total_scenes: totalScenes,
      scenes_per_voiceover: requestParams.scenes_per_voiceover || 1
    };
  }
  
  const result = {
    providerCostUSD: 0.0,
    yourCostUSD: 0.0,
    userCostCredits: 0.0,
    totalProcessingTimeSec: 0,
    markupApplied: applyMarkup,
    markupPercentage: applyMarkup ? markupPercentage : 0,
    usdToCreditRate: usdToCreditRate,
    metadata: {
      totalDuration,
      secondsPerShot,
      shotsPerScene,
      totalScenes,
      totalShots,
      totalVoiceoverSegments
    },
    breakdown: {
      textToImage: {
        providerCostUSD: 0.0, 
        yourCostUSD: 0.0,
        userCostCredits: 0.0, 
        processingTimeSec: 0,
        metadata: {
          totalDuration,
          secondsPerShot,
          shotsPerScene,
          totalScenes,
          totalShots,
          totalVoiceoverSegments
        }
      },
      imageToAnimation: {
        providerCostUSD: 0.0, 
        yourCostUSD: 0.0,
        userCostCredits: 0.0, 
        processingTimeSec: 0,
        metadata: {
          totalDuration,
          secondsPerShot,
          shotsPerScene,
          totalScenes,
          totalShots,
          totalVoiceoverSegments
        }
      },
      textToVoiceover: {
        providerCostUSD: 0.0, 
        yourCostUSD: 0.0,
        userCostCredits: 0.0, 
        processingTimeSec: 0,
        metadata: {
          totalDuration,
          secondsPerShot,
          shotsPerScene,
          totalScenes,
          totalShots,
          totalVoiceoverSegments
        }
      }
    },
    processingTimeMetrics: {
      textToImage: { timePerUnit: 0, unitType: "image" },
      imageToAnimation: { timePerUnit: 0, unitType: "second of video" },
      textToVoiceover: { timePerUnit: 0, unitType: "second of audio" }
    }
  };
  
  // Text-to-Image calculation
  if (enrichedRequestParams.text_to_image && configs.image) {
    const metrics = calculateServiceMetrics(
      configs.image,
      enrichedRequestParams.text_to_image,
      'image',
      applyMarkup,
      markupPercentage,
      convertToCredits,
      usdToCreditRate
    );
    
    result.breakdown.textToImage.providerCostUSD = metrics.providerCostUSD;
    result.breakdown.textToImage.yourCostUSD = metrics.yourCostUSD;
    result.breakdown.textToImage.userCostCredits = metrics.userCostCredits;
    result.breakdown.textToImage.processingTimeSec = metrics.processingTimeSec;
    
    // Copy metadata if available
    if (metrics.metadata) {
      result.breakdown.textToImage.metadata = { ...metrics.metadata };
    }
    
    result.providerCostUSD += metrics.providerCostUSD;
    result.yourCostUSD += metrics.yourCostUSD;
    result.userCostCredits += metrics.userCostCredits;
    result.totalProcessingTimeSec += metrics.processingTimeSec;
    
    // Get processing time per unit
    const provider = enrichedRequestParams.text_to_image.provider;
    const hosting = enrichedRequestParams.text_to_image.hosting || 'inhouse';
    
    if (provider && configs.image.providers && configs.image.providers[provider]) {
      const providerConfig = configs.image.providers[provider];
      const hostingConfig = providerConfig.pricing && 
                            providerConfig.pricing.hosting_options && 
                            providerConfig.pricing.hosting_options[hosting];
      
      if (!hostingConfig) {
        // For recraft-style configs
        const hostingConfig = providerConfig.pricing || {};
        result.processingTimeMetrics.textToImage.timePerUnit = hostingConfig.est_proc_time_in_sec || 3;
      } else if (hostingConfig) {
        result.processingTimeMetrics.textToImage.timePerUnit = hostingConfig.est_proc_time_in_sec || 3;
      }
    }
  }
  
  // Image-to-Animation calculation
  const i2vKeys = ['image_to_video', 'image_to_animation'];
  const i2vKey = i2vKeys.find(key => enrichedRequestParams[key]);
  
  if (i2vKey && configs.animation) {
    const metrics = calculateServiceMetrics(
      configs.animation,
      enrichedRequestParams[i2vKey],
      'animation',
      applyMarkup,
      markupPercentage,
      convertToCredits,
      usdToCreditRate
    );
    
    result.breakdown.imageToAnimation.providerCostUSD = metrics.providerCostUSD;
    result.breakdown.imageToAnimation.yourCostUSD = metrics.yourCostUSD;
    result.breakdown.imageToAnimation.userCostCredits = metrics.userCostCredits;
    result.breakdown.imageToAnimation.processingTimeSec = metrics.processingTimeSec;
    
    // Copy metadata if available
    if (metrics.metadata) {
      result.breakdown.imageToAnimation.metadata = { ...metrics.metadata };
    }
    
    result.providerCostUSD += metrics.providerCostUSD;
    result.yourCostUSD += metrics.yourCostUSD;
    result.userCostCredits += metrics.userCostCredits;
    result.totalProcessingTimeSec += metrics.processingTimeSec;
    
    // Get processing time per unit
    const provider = enrichedRequestParams[i2vKey].provider;
    const hosting = enrichedRequestParams[i2vKey].hosting || 'inhouse';
    
    if (provider && configs.animation.providers && configs.animation.providers[provider]) {
      const providerConfig = configs.animation.providers[provider];
      const hostingConfig = providerConfig.pricing && 
                            providerConfig.pricing.hosting_options && 
                            providerConfig.pricing.hosting_options[hosting];
      
      if (hostingConfig) {
        result.processingTimeMetrics.imageToAnimation.timePerUnit = hostingConfig.est_proc_time_per_sec_in_sec || 40;
      }
    }
  }
  
  // Text-to-Voiceover calculation
  if (enrichedRequestParams.text_to_speech && configs.voiceover) {
    const metrics = calculateServiceMetrics(
      configs.voiceover,
      enrichedRequestParams.text_to_speech,
      'voiceover',
      applyMarkup,
      markupPercentage,
      convertToCredits,
      usdToCreditRate
    );
    
    result.breakdown.textToVoiceover.providerCostUSD = metrics.providerCostUSD;
    result.breakdown.textToVoiceover.yourCostUSD = metrics.yourCostUSD;
    result.breakdown.textToVoiceover.userCostCredits = metrics.userCostCredits;
    result.breakdown.textToVoiceover.processingTimeSec = metrics.processingTimeSec;
    
    // Copy metadata if available
    if (metrics.metadata) {
      result.breakdown.textToVoiceover.metadata = { ...metrics.metadata };
    }
    
    result.providerCostUSD += metrics.providerCostUSD;
    result.yourCostUSD += metrics.yourCostUSD;
    result.userCostCredits += metrics.userCostCredits;
    result.totalProcessingTimeSec += metrics.processingTimeSec;
    
    // Get processing time per unit
    const provider = enrichedRequestParams.text_to_speech.provider;
    const hosting = enrichedRequestParams.text_to_speech.hosting || 'inhouse';
    
    if (provider && configs.voiceover.providers && configs.voiceover.providers[provider]) {
      const providerConfig = configs.voiceover.providers[provider];
      const hostingConfig = providerConfig.pricing && 
                            providerConfig.pricing.hosting_options && 
                            providerConfig.pricing.hosting_options[hosting];
      
      if (hostingConfig) {
        result.processingTimeMetrics.textToVoiceover.timePerUnit = hostingConfig.est_proc_time_per_sec_in_sec || 0.025;
      }
    }
  }
  
  // Round final costs
  result.providerCostUSD = parseFloat(result.providerCostUSD.toFixed(4));
  result.yourCostUSD = parseFloat(result.yourCostUSD.toFixed(4));
  result.userCostCredits = parseFloat(result.userCostCredits.toFixed(2));
  
  return result;
};

/**
 * Formats the processing time into a human-readable string
 * @param {number} seconds - Processing time in seconds
 * @returns {string} - Formatted time string
 */
export const formatProcessingTime = (seconds) => {
  if (seconds < 60) {
    return `${seconds} seconds`;
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ${remainingSeconds > 0 ? `${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}` : ''}`;
  } else {
    const hours = Math.floor(seconds / 3600);
    const remainingMinutes = Math.floor((seconds % 3600) / 60);
    return `${hours} hour${hours !== 1 ? 's' : ''} ${remainingMinutes > 0 ? `${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}` : ''}`;
  }
};

/**
 * Formats the cost into a human-readable string with appropriate currency symbol
 * @param {number} cost - Cost in USD
 * @returns {string} - Formatted cost string
 */
export const formatCost = (cost) => {
  return `$${parseFloat(cost).toFixed(2)}`;
};

/**
 * Formats the credits amount in a human-readable way
 * @param {number} credits - Number of credits
 * @returns {string} - Formatted credits string
 */
export const formatCredits = (credits) => {
  return `${parseFloat(credits).toLocaleString()} credits`;
};

export default {
  convertPriceStringToFloat,
  calculateServiceMetrics,
  calculateVideoMetrics,
  formatProcessingTime,
  formatCost,
  formatCredits
}; 