import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Slider,
  CircularProgress,
  Alert,
  styled,
  Chip,
  IconButton,
  useTheme
} from '@mui/material';
import {
  Globe
} from 'lucide-react';
import {
  Man2 as Man2Icon,
  Woman as WomanIcon,
  VolumeUp as VolumeUpIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Stop as StopIcon
} from '@mui/icons-material';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import processConfig from '../../config/processIndex.json';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import { updateGlowStep, selectGlowStep, updateGlowReady } from '../../components/session/sessionSlide';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Speed marks for the slider
const speedMarks = [
  { value: 0.5, label: '0.5x' },
  { value: 0.75, label: '0.75x' },
  { value: 1, label: '1x' },
  { value: 1.25, label: '1.25x' },
  { value: 1.5, label: '1.5x' },
];

// Language prefixes mapping
const LANGUAGE_MAP = {
  'a': 'American English',
  'b': 'British English',
  'e': 'Spanish',
  'f': 'French',
  'h': 'Hindi',
  'i': 'Italian',
  'j': 'Japanese',
  'p': 'Brazilian Portuguese',
  'z': 'Mandarin Chinese',
};

// Gender mapping
const GENDER_MAP = {
  'f': 'Female',
  'm': 'Male',
};

// Map of language codes to display names for commonly used languages
const languageDisplayNames = {
  'en': 'English',
  'en-US': 'English (US)',
  'en-GB': 'English (UK)',
  'es': 'Spanish',
  'fr': 'French',
  'de': 'German',
  'it': 'Italian',
  'ja': 'Japanese',
  'ko': 'Korean',
  'pt': 'Portuguese',
  'ru': 'Russian',
  'zh': 'Chinese',
};

// Get user-friendly provider display name
const getProviderDisplayName = (provider) => {
  return "Advanced AI Model";
};

// Get friendly display name for a voice
const getVoiceDisplayName = (voiceName) => {
  if (!voiceName || voiceName.length < 3) return voiceName;
  
  // For special voice names in f5_tts provider
  if (voiceName === 'female_ref') return 'Female Reference';
  if (voiceName === 'morgan_freeman') return 'Morgan Freeman';
  
  // Parse the voice name format (e.g., 'am_eric' => 'American Male - Eric')
  const genderCode = voiceName.charAt(1);
  const nameWithoutPrefix = voiceName.substring(3); // Skip the first 3 chars (2 for language/gender + underscore)
  
  const gender = GENDER_MAP[genderCode] || '';
  
  // Capitalize the voice name
  const formattedName = nameWithoutPrefix
    .charAt(0).toUpperCase() + nameWithoutPrefix.slice(1);
    
  return gender 
    ? `${gender} - ${formattedName}` 
    : formattedName;
};

// Get language display name from config if available
// NOTE: This function is not used as we use getLanguageDisplayNameFromConfig inside the component
// which has access to the component state
// const getLanguageDisplayName = (langCode) => {
//   if (!langCode) return '';
//   
//   return LANGUAGE_MAP[langCode] || `Language ${langCode.toUpperCase()}`;
// };

const VoiceOption = styled(Card)(({ theme, selected }) => ({
  cursor: 'pointer',
  height: '100%',
  transition: 'all 0.2s',
  border: selected ? `2px solid #FD9800` : '1px solid',
  borderColor: selected ? '#FD9800' : theme.palette.divider,
  '&:hover': {
    borderColor: '#FD9800',
    boxShadow: theme.shadows[3]
  },
  // Add orange background for selected cards
  backgroundColor: selected ? 'rgba(253, 152, 0, 0.05)' : 'transparent',
  transform: selected ? 'scale(1.02)' : 'scale(1)',
  boxShadow: selected ? '0 4px 8px rgba(253, 152, 0, 0.15)' : 'none',
}));

// Define the pulse animation
const pulseKeyframes = `
@keyframes pulse {
  0% {
    opacity: 0.6;
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 0.6;
    transform: scale(0.9);
  }
}
`;

// Cache config in localStorage to ensure persistence across browser sessions
const saveConfigToLocalStorage = (config) => {
  try {
    localStorage.setItem('glow_102_audio_config', JSON.stringify(config));
    console.log('Config saved to localStorage');
  } catch (error) {
    console.warn('Failed to save config to localStorage:', error);
  }
};

// Get config from localStorage if available
const getConfigFromLocalStorage = () => {
  try {
    const savedConfig = localStorage.getItem('glow_102_audio_config');
    if (savedConfig) {
      console.log('Config retrieved from localStorage');
      return JSON.parse(savedConfig);
    }
  } catch (error) {
    console.warn('Failed to retrieve config from localStorage:', error);
  }
  return null;
};

// Simple audio player to manage one audio instance
const audioPlayer = {
  currentAudio: null,
  currentVoiceId: null,
  isPlaying: false,

  stop() {
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio.currentTime = 0;
      this.currentAudio = null;
      this.currentVoiceId = null;
      this.isPlaying = false;
    }
  },

  async play(audio, voiceId) {
    // First stop any currently playing audio
    this.stop();
    
    // Set up the new audio
    this.currentAudio = audio;
    this.currentVoiceId = voiceId;
    
    try {
      await audio.play();
      this.isPlaying = true;
    } catch (err) {
      console.error('Failed to play audio:', err);
      this.stop();
      throw err;
    }
  }
};

const Glow102ProcessorVoiceNarration = ({ data, onComplete, showNotice, trackProcessApiStatus, updateProcessState }) => {
  const process = processConfig.processes.find(p => p.id === 'glow_102');
  const dispatch = useDispatch();
  const savedState = useSelector(state => selectGlowStep(state, 'glow_102'));
  const theme = useTheme();
  
  // Add refs for scroll animations
  const voiceSelectionRef = useRef(null);
  const speedSelectionRef = useRef(null);
  
  // Reference to track if API has been called already - persists across renders
  const apiCalledRef = useRef(false);
  const configSavedToReduxRef = useRef(false);
  
  // Store the initializeConfig function reference
  const initializeConfigRef = useRef(null);

  // Audio caching system - Enhance with prefetching for better performance
  const audioCache = useRef(new Map());
  const audioPreviewTimeoutRef = useRef(null);
  const audioPreloadQueue = useRef([]);
  const isPreloadingRef = useRef(false);

  // Add loading state for audio
  const [isLoadingAudio, setIsLoadingAudio] = useState(false);

  // State for managing audio configuration and selected options
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPlayingVoice, setCurrentPlayingVoice] = useState(null);
  const [audio] = useState(new Audio());
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [genderFilter, setGenderFilter] = useState('all'); // 'all', 'male', or 'female'
  
  // New state to track UI steps
  const [currentStep, setCurrentStep] = useState(1); // 1 for language selection, 2 for voice selection

  const [answers, setAnswers] = useState(() => {
    // Initialize from Redux state if available
    const initialState = savedState || data || {};
    return {
      provider: initialState.provider || '',
      voice: initialState.voice || initialState.voice_by || '', // Support both new and old formats
      voice_name: initialState.voice_name || '',
      speed: initialState.speed || 1,
      language: initialState.language || '',
    };
  });

  // Reference to track completion state and prevent multiple calls
  const completingRef = useRef(false);

  // Reference for tracking render count to detect loops
  const renderCountRef = useRef(0);

  // Add the keyframes to the document on component mount
  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.type = 'text/css';
    styleElement.appendChild(document.createTextNode(pulseKeyframes));
    document.head.appendChild(styleElement);
    
    // Clean up on unmount
    return () => {
      if (styleElement && document.head.contains(styleElement)) {
        document.head.removeChild(styleElement);
      }
    };
  }, []); // Empty dependency array to ensure this only runs once

  // Define isComplete function
  const isComplete = () => {
    const complete = Boolean(answers.voice && answers.provider);
    console.log('Is glow_102 complete?', complete, 'voice:', answers.voice, 'provider:', answers.provider);
    return complete;
  };
  
  // Save answers to Redux whenever they change
  useEffect(() => {
    // Skip initial render or if no config exists yet
    if (!config) return;
    
    // Use a ref to track if we've already dispatched to prevent loops
    const shouldUpdate = !configSavedToReduxRef.current;
    
    if (shouldUpdate) {
      console.log('Saving voice settings to Redux');
      dispatch(updateGlowStep({
        stepId: 'glow_102',
        data: answers
      }));
    }
  }, [answers, dispatch, config]);
  
  // Save config to Redux when it changes
  useEffect(() => {
    if (config && !configSavedToReduxRef.current) {
      console.log('Saving config to Redux for persistent caching');
      
      // Update Redux with the complete config data
      dispatch(updateGlowStep({
        stepId: 'glow_102',
        data: {
          ...answers,
          config: config
        }
      }));
      
      // Also save to localStorage for extra persistence
      saveConfigToLocalStorage(config);
      
      // Mark as saved so we don't do it again
      configSavedToReduxRef.current = true;
    }
  }, [config, dispatch, answers]);
  
  // Define a more robust version of getLanguageDisplayName that uses the config state
  const getLanguageDisplayNameFromConfig = (langCode) => {
    if (!langCode) return '';
    
    // Log for debugging
    console.log(`Getting language display name for code: "${langCode}"`);
    
    // First check if we have the language in the API-provided options
    if (config && config.data && config.data.providers && config.data.providers.kokoro && 
        config.data.providers.kokoro.lana_code && config.data.providers.kokoro.lana_code.options) {
      
      // Look for an exact match in the kokoro lana_code options
      const langOption = config.data.providers.kokoro.lana_code.options.find(
        option => option.code === langCode
      );
      
      if (langOption && langOption.name) {
        console.log(`Found language in API config: ${langOption.name}`);
        return langOption.name;
      }
    }
    
    // If not found in API, check our predefined map
    if (LANGUAGE_MAP[langCode]) {
      console.log(`Found language in predefined map: ${LANGUAGE_MAP[langCode]}`);
      return LANGUAGE_MAP[langCode];
    }
    
    console.log(`No match found for language code: "${langCode}"`);
    
    // Don't show "Language M" for 'm' which is likely a gender marker
    if (langCode === 'm') {
      return 'Unknown Language';
    }
    
    // Last resort - if it looks like a language code, format it nicely
    return typeof langCode === 'string' ? 
      `Language ${langCode.toUpperCase()}` : 
      `Unknown (${langCode})`;
  };
  
  // Extract languages from config
  const extractLanguagesFromConfig = (config) => {
    if (!config || !config.data || !config.data.providers) {
      return [];
    }
    
    const languageCodes = new Set();
    const languageDebug = {};
    
    // Go through all providers and extract language codes
    Object.entries(config.data.providers).forEach(([providerName, provider]) => {
      // For kokoro provider, check lana_code options for languages
      if (providerName === 'kokoro' && provider.lana_code && provider.lana_code.type === 'list') {
        provider.lana_code.options.forEach(option => {
          if (option.code) {
            languageCodes.add(option.code);
            if (!languageDebug[option.code]) {
              languageDebug[option.code] = [];
            }
            languageDebug[option.code].push(`From kokoro lana_code: ${option.name}`);
          }
        });
      }
      
      // Also check voice_by options for language prefixes
      if (provider && provider.voice_by && provider.voice_by.type === 'list' && provider.voice_by.options) {
        provider.voice_by.options.forEach(voice => {
          if (voice.name && voice.name.length >= 2) {
            const langCode = voice.name.charAt(0);
            
            // Skip gender markers that might be misinterpreted as language codes
            if (langCode === 'm' || langCode === 'f') {
              // Only treat these as languages if they're in our predefined map
              if (!LANGUAGE_MAP[langCode]) {
                return;
              }
            }
            
            languageCodes.add(langCode);
            if (!languageDebug[langCode]) {
              languageDebug[langCode] = [];
            }
            languageDebug[langCode].push(`From voice name: ${voice.name}`);
          }
        });
      }
    });
    
    console.log('Extracted language codes:', Array.from(languageCodes));
    console.log('Language debug info:', languageDebug);
    
    return Array.from(languageCodes).sort();
  };
  
  // Initialize config - runs once on mount
  useEffect(() => {
    const initializeConfig = async () => {
      // Skip if already initialized
      if (apiCalledRef.current) {
        return;
      }
      
      try {
        setLoading(true);
        apiCalledRef.current = true;
        
        // CACHING HIERARCHY:
        // 1. First check Redux state (fastest, in-memory)
        // 2. Then check localStorage (persists across sessions/refreshes)
        // 3. Finally fetch from API if needed
        
        // Check Redux first (memory cache)
        if (savedState?.config) {
          console.log('CACHE HIT: Using config from Redux state');
          setConfig(savedState.config);
          configSavedToReduxRef.current = true;
          
          // We still want to start at step 1, even with saved data
          setCurrentStep(1);
          
          // Always start with no language selected regardless of saved data
          setSelectedLanguage('');
          
          setLoading(false);
          
          // Notify parent component that this process is ready
          dispatch(updateGlowStep({
            stepId: 'glow_102',
            data: { ...answers },
            status: false, // not completed yet
            ready: true // but ready for interaction
          }));
          
          // Use trackProcessApiStatus if available to mark the process as ready
          if (trackProcessApiStatus) {
            trackProcessApiStatus('glow_102', 'configLoaded', true, true);
            console.log('Called trackProcessApiStatus for glow_102');
            
            // Force the ready state update
            if (updateProcessState) {
              console.log('Calling updateProcessState with forceReady: true');
              updateProcessState('glow_102', 'ready', { forceReady: true });
              
              // Log the current state after the update
              console.log('Ready state should be forced to true now');
            } else {
              console.error('updateProcessState function not available to glow_102');
              
              // Direct fallback - dispatch updateGlowReady directly
              console.log('Dispatching updateGlowReady directly');
              dispatch(updateGlowReady({ 
                glowId: 'glow_102', 
                ready: true 
              }));
              console.log('Direct dispatch of updateGlowReady successful');
            }
          } else {
            console.error('trackProcessApiStatus function not available to glow_102');
            
            // Direct fallback if trackProcessApiStatus is not available
            console.log('Dispatching updateGlowReady directly');
            dispatch(updateGlowReady({ 
              glowId: 'glow_102', 
              ready: true 
            }));
            console.log('Direct dispatch of updateGlowReady successful');
          }
          
          return;
        }
        
        // Check localStorage next (persistent cache)
        const localStorageConfig = getConfigFromLocalStorage();
        if (localStorageConfig) {
          console.log('CACHE HIT: Using config from localStorage');
          setConfig(localStorageConfig);
          
          // We still want to start at step 1, even with saved data
          setCurrentStep(1);
          
          // Always start with no language selected regardless of saved data
          setSelectedLanguage('');
          
          setLoading(false);
          
          // Notify parent component that this process is ready
          dispatch(updateGlowStep({
            stepId: 'glow_102',
            data: { ...answers },
            status: false, // not completed yet
            ready: true // but ready for interaction
          }));
          
          // Use trackProcessApiStatus if available to mark the process as ready
          if (trackProcessApiStatus) {
            trackProcessApiStatus('glow_102', 'configLoaded', true, true);
            console.log('Called trackProcessApiStatus for glow_102');
            
            // Force the ready state update
            if (updateProcessState) {
              console.log('Calling updateProcessState with forceReady: true');
              updateProcessState('glow_102', 'ready', { forceReady: true });
              
              // Log the current state after the update
              console.log('Ready state should be forced to true now');
            } else {
              console.error('updateProcessState function not available to glow_102');
              
              // Direct fallback - dispatch updateGlowReady directly
              console.log('Dispatching updateGlowReady directly');
              dispatch(updateGlowReady({ 
                glowId: 'glow_102', 
                ready: true 
              }));
              console.log('Direct dispatch of updateGlowReady successful');
            }
          } else {
            console.error('trackProcessApiStatus function not available to glow_102');
            
            // Direct fallback if trackProcessApiStatus is not available
            console.log('Dispatching updateGlowReady directly');
            dispatch(updateGlowReady({ 
              glowId: 'glow_102', 
              ready: true 
            }));
            console.log('Direct dispatch of updateGlowReady successful');
          }
          
          return;
        }
        
        // Only proceed to API call if not already completed 
        // This is critical to prevent infinite re-renders when revisiting a completed step
        if (savedState && savedState.status === true) {
          console.log('Step already completed, skipping API call');
          setLoading(false);
          return;
        }
        
        // Fetch from API as last resort (CACHE MISS)
        console.log('CACHE MISS: Fetching audio config from API');
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/v1/config/get_text_to_voiceover_config`,
            {
              headers: { 
                Authorization: `Bearer ${Cookies.get('token')}`,
                accept: 'application/json'
              }
            }
          );
          
          console.log('API Response:', response);
          
          // Process the response according to the new structure
          if (response && response.data) {
            // New API response format has status, message, status_code, and data fields
            // We need to check if the response is successful
            if (response.data.status && response.data.data) {
              // Extract the main configuration data
              const responseData = response.data.data;
              
              // Create a properly formatted config object
              const configData = {
                success: true,
                data: responseData
              };
              
              console.log('Processed API Response:', configData);
              
              // Validate the config has the minimum required structure
              if (configData.data && configData.data.providers) {
                setConfig(configData);
                
                // Also save to localStorage for future use
                saveConfigToLocalStorage(configData);
                
                // Set language selection from the new config
                const languageCodes = extractLanguagesFromConfig(configData);
                if (languageCodes.length > 0) {
                  const defaultLanguage = answers.language || languageCodes[0];
                  setSelectedLanguage(defaultLanguage);
                  if (!answers.language) {
                    setAnswers(prev => ({ ...prev, language: defaultLanguage }));
                  }
                } else {
                  setError('No languages found in the response. Please refresh and try again.');
                }
                
                // Notify parent component that this process is ready after API load
                dispatch(updateGlowStep({
                  stepId: 'glow_102',
                  data: { ...answers },
                  status: false, // not completed yet
                  ready: true // but ready for interaction
                }));
                
                // Use trackProcessApiStatus if available to mark the process as ready
                if (trackProcessApiStatus) {
                  trackProcessApiStatus('glow_102', 'configLoaded', true, true);
                  console.log('Called trackProcessApiStatus for glow_102');
                  
                  // Force the ready state update
                  if (updateProcessState) {
                    console.log('Calling updateProcessState with forceReady: true');
                    updateProcessState('glow_102', 'ready', { forceReady: true });
                    
                    // Log the current state after the update
                    console.log('Ready state should be forced to true now');
                  } else {
                    console.error('updateProcessState function not available to glow_102');
                    
                    // Direct fallback - dispatch updateGlowReady directly
                    console.log('Dispatching updateGlowReady directly');
                    dispatch(updateGlowReady({ 
                      glowId: 'glow_102', 
                      ready: true 
                    }));
                    console.log('Direct dispatch of updateGlowReady successful');
                  }
                } else {
                  console.error('trackProcessApiStatus function not available to glow_102');
                  
                  // Direct fallback if trackProcessApiStatus is not available
                  console.log('Dispatching updateGlowReady directly');
                  dispatch(updateGlowReady({ 
                    glowId: 'glow_102', 
                    ready: true 
                  }));
                  console.log('Direct dispatch of updateGlowReady successful');
                }
              } else {
                throw new Error('Invalid response format: Missing providers in the data structure');
              }
            } else {
              throw new Error(`API returned error: ${response.data.message || 'Unknown error'}`);
            }
          } else {
            throw new Error('Invalid response: No data returned from API');
          }
        } catch (apiError) {
          console.error('API Error:', apiError);
          setError(`API Error: ${apiError.message || 'Unknown API error'}`);
          throw apiError; // Re-throw to be caught by the outer try-catch
        }
      } catch (err) {
        console.error('Error initializing config:', err);
        setError(err.message || 'Failed to load voice options');
        apiCalledRef.current = false;
      } finally {
        setLoading(false);
      }
    };
    
    // Store the function reference so it can be called from the retry button
    initializeConfigRef.current = initializeConfig;
    
    // Only run initialization if this is the first render or when specific dependencies change
    if (!apiCalledRef.current) {
      initializeConfig();
    }
    
    // Don't include answers in dependency array to prevent repeated calls
    // when answers change, but still use the current answers within the function
  }, [savedState, dispatch, trackProcessApiStatus, updateProcessState, answers]);
  
  // Check if step is already completed when revisiting
  useEffect(() => {
    // Skip if we don't have saved state or if loading
    if (!savedState || loading) {
      return;
    }
    
    console.log('Checking saved state for completion', savedState);
    
    // If the step was already completed, we should avoid re-initializing things
    // that might cause infinite loops
    if (savedState.status === true) {
      console.log('This step was previously completed');
      
      // Set answers from saved state to populate UI
      if (savedState.data) {
        setAnswers(prevAnswers => {
          // Only update if different to avoid unnecessary renders
          if (JSON.stringify(prevAnswers) !== JSON.stringify(savedState.data)) {
            return savedState.data;
          }
          return prevAnswers;
        });
      }
      
      // Don't automatically set selectedLanguage or currentStep
      // Let the user select the language explicitly
      
      // Mark as already saved to prevent redundant Redux dispatches
      configSavedToReduxRef.current = true;
    }
  }, [savedState, loading]);
  
  // Log config structure when it changes to help debug
  useEffect(() => {
    if (config && config.data && config.data.providers) {
      console.log('Config structure loaded. Provider keys:', Object.keys(config.data.providers));
      
      // Log the structure of the first provider to understand its format
      const firstProviderKey = Object.keys(config.data.providers)[0];
      if (firstProviderKey) {
        const firstProvider = config.data.providers[firstProviderKey];
        console.log(`First provider (${firstProviderKey}) structure:`, {
          isArray: Array.isArray(firstProvider),
          type: typeof firstProvider,
          hasVoiceBy: firstProvider && typeof firstProvider === 'object' && 'voice_by' in firstProvider,
          keys: firstProvider && typeof firstProvider === 'object' ? Object.keys(firstProvider) : []
        });
      }
    }
  }, [config]);
  
  // Clean up resources on unmount
  useEffect(() => {
    return () => {
      if (audio) {
        // Make sure to stop any playing audio
        audio.pause();
        // Clear the source to prevent any further attempts to load or play
        audio.src = '';
        // Remove all event listeners
        audio.onended = null;
        audio.onerror = null;
        audio.onplay = null;
        audio.onpause = null;
      }
      
      // Clear the audio cache to free memory
      audioCache.current.clear();
    };
  }, [audio]);
  
  // Get all available language codes
  const getAvailableLanguages = useMemo(() => {
    return extractLanguagesFromConfig(config);
  }, [config]);
  
  // Get voice options from all providers, filtered by selected language and gender
  const getVoiceOptions = useMemo(() => {
    if (!config || !config.data || !config.data.providers || !selectedLanguage) {
      return [];
    }
    
    const allVoices = [];
    
    try {
      // Collect voices from all providers
      Object.entries(config.data.providers).forEach(([provider, providerData]) => {
        // Handle different provider data structures
        let voiceOptions = [];
        
        // Case 1: Provider is an array of voices directly
        if (Array.isArray(providerData)) {
          voiceOptions = providerData;
          
          // Add provider info if not already there
          voiceOptions = voiceOptions.map(voice => ({
            ...voice,
            id: voice.id || voice.name, // Use name as ID if id doesn't exist
            provider, // Add provider key to each voice
            providerName: getProviderDisplayName(provider)
          }));
        }
        // Case 2: Provider has voice_by.options structure
        else if (providerData && providerData.voice_by && 
                 providerData.voice_by.type === 'list' && 
                 Array.isArray(providerData.voice_by.options)) {
          voiceOptions = providerData.voice_by.options.map(voice => ({
            ...voice,
            id: voice.id || voice.name, // Use name as ID if id doesn't exist
            provider,
            providerName: getProviderDisplayName(provider)
          }));
        }
        // Log structure if we couldn't parse it
        else {
          console.warn('Unsupported provider data structure:', provider, providerData);
          return; // Skip this provider
        }
        
        // Filter voices by language and gender
        voiceOptions.forEach(voice => {
          // Ensure voice has an id property
          if (!voice.id && voice.name) {
            console.log('📋 Adding fallback ID for voice:', voice.name);
            voice.id = voice.name; // Use name as fallback ID
          }
          
          // Check if this voice matches the selected language
          let matchesLanguage = false;
          
          // Different matching strategies based on provider and voice structure
          if (voice.language) {
            // Direct language property match (new API response format)
            // Get the language display name for the selected language code
            const selectedLangDisplayName = config.data.providers.kokoro?.lana_code?.options?.find(
              lang => lang.code === selectedLanguage
            )?.name || LANGUAGE_MAP[selectedLanguage] || '';
            
            // Check if the voice language matches the selected language display name
            matchesLanguage = voice.language === selectedLangDisplayName;
          } else if (voice.name && voice.name.length >= 2) {
            // Infer from voice name prefix (legacy format)
            const langPrefix = voice.name.charAt(0);
            matchesLanguage = langPrefix === selectedLanguage;
          }
          
          // Apply gender filter if not set to 'all'
          const matchesGender = genderFilter === 'all' || 
            (voice.gender && voice.gender.toLowerCase() === genderFilter);
          
          if (matchesLanguage && matchesGender) {
            allVoices.push(voice);
          }
        });
      });
      
      // Log all voice IDs for debugging
      console.log('📋 Available voice options:', allVoices.map(v => ({ id: v.id, name: v.name, provider: v.provider })));
      
      return allVoices;
    } catch (error) {
      console.error('Error processing voice options:', error);
      return [];
    }
  }, [selectedLanguage, config, genderFilter]);
  
  // Helper function to get voice preview URL
  const getVoicePreviewUrl = useCallback((voiceId) => {
    console.log('🔍 Getting preview URL for voice ID:', voiceId);
    
    // No config, can't find URL
    if (!config || !config.data || !config.data.providers) {
      console.error('❌ No config data available for finding voice URL');
      return null;
    }
    
    // Look through all providers to find the voice
    for (const providerKey in config.data.providers) {
      const provider = config.data.providers[providerKey];
      console.log(`🔍 Checking provider ${providerKey} for voice ${voiceId}`);
      
      // Handle case where provider might be an object with voice_by.options property
      if (provider.voice_by && Array.isArray(provider.voice_by.options)) {
        console.log(`🔍 Provider ${providerKey} has voice_by.options structure`);
        
        // First try to match by id
        let voice = provider.voice_by.options.find(v => v.id === voiceId);
        
        // If not found by id, try to match by name
        if (!voice) {
          voice = provider.voice_by.options.find(v => v.name === voiceId);
          console.log(`🔍 Looking for voice by name ${voiceId}:`, voice ? 'Found' : 'Not found');
        }
        
        if (voice) {
          console.log(`🔍 Found voice in ${providerKey} voice_by.options:`, voice.name);
          if (voice.file && voice.file.url) {
            console.log(`🔍 Voice has file URL:`, voice.file.url);
            return voice.file.url;
          } else {
            console.log(`❌ Voice found but no file.url property:`, voice);
          }
        }
      }
      // Handle case where provider might be an array of voices directly
      else if (Array.isArray(provider)) {
        console.log(`🔍 Provider ${providerKey} is an array with ${provider.length} voices`);
        const voice = provider.find(v => v.id === voiceId || v.name === voiceId);
        if (voice) {
          console.log(`🔍 Found voice in ${providerKey} array:`, voice.name || voice.id);
          if (voice.file && voice.file.url) {
            console.log(`🔍 Voice has file URL:`, voice.file.url);
            return voice.file.url;
          } else {
            console.log(`❌ Voice found but no file.url property:`, voice);
          }
        }
      } else {
        console.log(`❌ Provider ${providerKey} has unrecognized structure:`, typeof provider);
      }
    }
    
    console.error('❌ Could not find voice with ID/name:', voiceId);
    return null;
  }, [config]);

  // Preload voice audio files in background
  const preloadVoiceAudio = useCallback((voiceId) => {
    if (!voiceId || audioCache.current.has(voiceId)) return;
    
    // Add to preload queue if not already in queue
    if (!audioPreloadQueue.current.includes(voiceId)) {
      audioPreloadQueue.current.push(voiceId);
      
      // Start preloading if not already doing so
      if (!isPreloadingRef.current) {
        isPreloadingRef.current = true;
        processPreloadQueue();
      }
    }
  }, []);
  
  // Process the preload queue one by one
  const processPreloadQueue = useCallback(() => {
    if (audioPreloadQueue.current.length === 0) {
      isPreloadingRef.current = false;
      return;
    }
    
    const voiceId = audioPreloadQueue.current.shift();
    
    // Skip if already cached
    if (audioCache.current.has(voiceId)) {
      processPreloadQueue();
      return;
    }
    
    const previewUrl = getVoicePreviewUrl(voiceId);
    if (!previewUrl) {
      processPreloadQueue();
      return;
    }
    
    console.log('🔊 Preloading audio for:', voiceId);
    const newAudio = new Audio();
    
    newAudio.oncanplaythrough = () => {
      console.log('🔊 Preloaded audio for:', voiceId);
      audioCache.current.set(voiceId, newAudio);
      processPreloadQueue();
    };
    
    newAudio.onerror = () => {
      console.error('❌ Error preloading audio for:', voiceId);
      processPreloadQueue();
    };
    
    newAudio.src = previewUrl;
    newAudio.load();
  }, [getVoicePreviewUrl]);

  // Simplified stop function
  const handleStopVoice = useCallback(() => {
    audioPlayer.stop();
    setCurrentPlayingVoice(null);
    setIsLoadingAudio(false);
  }, []);

  // Simplified play function
  const handleVoicePreview = useCallback(async (voiceId) => {
    console.log('🔊 Attempting to preview voice:', voiceId);
    
    try {
      setIsLoadingAudio(true);
      
      // Get the preview URL
      const previewUrl = getVoicePreviewUrl(voiceId);
      if (!previewUrl) {
        throw new Error('No preview URL available');
      }

      // Use cached audio if available
      let audioToPlay;
      if (audioCache.current.has(voiceId)) {
        audioToPlay = audioCache.current.get(voiceId);
        audioToPlay.currentTime = 0;
      } else {
        // Create new audio if not cached
        audioToPlay = new Audio(previewUrl);
        audioCache.current.set(voiceId, audioToPlay);
      }

      // Set up event listeners
      audioToPlay.onended = () => {
        setCurrentPlayingVoice(null);
        audioPlayer.stop();
      };

      // Play the audio
      await audioPlayer.play(audioToPlay, voiceId);
      setCurrentPlayingVoice(voiceId);
      
    } catch (error) {
      console.error('Error playing audio:', error);
      handleStopVoice();
      if (showNotice) {
        showNotice('Failed to play voice sample. Please try again.', 'error');
      }
    } finally {
      setIsLoadingAudio(false);
    }
  }, [getVoicePreviewUrl, handleStopVoice, showNotice]);

  // Handle completion of the step
  const handleComplete = useCallback(() => {
    // Prevent multiple completion attempts
    if (completingRef.current) {
      console.log('Already completing, skipping duplicate call');
      return;
    }
    
    // Validate required fields
    if (!answers.provider || !answers.voice) {
      if (showNotice) {
        showNotice('Please select a voice before continuing', 'warning');
      }
      return;
    }
    
    try {
      completingRef.current = true;
      console.log('Saving and completing voice selection:', answers);
      
      // Stop any playing audio
      handleStopVoice();
      
      // Save final configuration to Redux
      dispatch(updateGlowStep({
        stepId: 'glow_102',
        data: answers,
        status: true
      }));
      
      // Mark as completed in local state to prevent redundant API calls
      configSavedToReduxRef.current = true;
      
      // Proceed to next step
      if (onComplete) {
        onComplete();
      }
    } catch (err) {
      console.error('Error completing voice narration step:', err);
      if (showNotice) {
        showNotice('Failed to save voice selection', 'error');
      }
    } finally {
      completingRef.current = false;
    }
  }, [answers, dispatch, handleStopVoice, onComplete, showNotice]);
  
  // Handle language change
  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang);
    setAnswers(prev => ({
      ...prev,
      language: lang,
      provider: '', // Reset provider
      voice: '',   // Reset voice
      voice_name: '' // Reset voice name
    }));
    
    // Move to step 2 after selecting a language
    setCurrentStep(2);
    
    // Remove scrolling behavior to avoid animation
    // setTimeout(() => {
    //   voiceSelectionRef.current?.scrollIntoView({ 
    //     behavior: 'smooth',
    //     block: 'start'
    //   });
    // }, 100);
  };
  
  // Handle voice selection
  const handleVoiceSelect = useCallback((provider, voiceId) => {
    console.log('🎯 handleVoiceSelect called with:', { provider, voiceId });
    
    const selectedProviderVoices = config?.data?.providers?.[provider];
    if (!selectedProviderVoices) {
      console.error('❌ Provider not found in config:', provider);
      return;
    }

    let voice;
    
    // Handle different data structures
    if (Array.isArray(selectedProviderVoices)) {
      // If provider is directly an array of voices
      voice = selectedProviderVoices.find(v => v.id === voiceId || v.name === voiceId);
      console.log('🎯 Looking for voice in array provider, found:', voice ? 'yes' : 'no');
    } else if (selectedProviderVoices.voice_by && Array.isArray(selectedProviderVoices.voice_by.options)) {
      // If provider has a voice_by.options array structure
      voice = selectedProviderVoices.voice_by.options.find(v => v.id === voiceId || v.name === voiceId);
      console.log('🎯 Looking for voice in voice_by.options, found:', voice ? 'yes' : 'no', 'Voice data:', voice);
    } else {
      // If we can't find the voice in any of the expected structures
      console.error('❌ Unable to find voice in provider data structure:', selectedProviderVoices);
      return;
    }
    
    if (!voice) {
      console.error('❌ Voice not found with ID:', voiceId);
      return;
    }
    
    // Create new answers object with all the necessary properties
    const newAnswers = {
      ...answers,
      provider,
      voice: voiceId,
      voice_name: voice.name || voice.description || '',
      language: selectedLanguage || '',
      speed: answers.speed || 1,
    };
    
    console.log('🎯 Updating answers with:', newAnswers);
    
    // Update local state
    setAnswers(newAnswers);
    
    // Explicitly update Redux 
    dispatch(updateGlowStep({
      stepId: 'glow_102',
      data: newAnswers
    }));
    
    // Note: We don't call onComplete() here because 
    // that's handled by the ViewFooter when the user clicks Continue
    // This gives users time to listen to the voice before proceeding
    
    // Show a notification to inform the user to click Continue after listening
    if (showNotice) {
      showNotice('Voice selected. Click Continue after listening to proceed.', 'info');
    }
  }, [config, answers, dispatch, selectedLanguage, showNotice]);

  // Save the voice selection to Redux when it changes
  useEffect(() => {
    // Skip if no config or if we're in initial loading phase
    if (!config || loading) return;
    
    // Skip if this effect has already saved to Redux
    if (configSavedToReduxRef.current) {
      console.log('Config already saved to Redux, skipping');
      return;
    }
    
    // Skip if there are no answers to save
    if (!answers.provider || !answers.voice) {
      console.log('No voice selection to save');
      return;
    }
    
    console.log('Saving voice choice to Redux:', answers);
    dispatch(updateGlowStep({
      stepId: 'glow_102',
      data: answers
    }));
    
    configSavedToReduxRef.current = true;
    
  }, [config, answers, loading, dispatch]);
  
  // Handle voice speed change
  const handleSpeedChange = (event, newValue) => {
    setAnswers(prev => ({
      ...prev,
      speed: newValue
    }));
  };
  
  // Generate speed slider marks based on configuration
  const getSpeedMarks = useMemo(() => {
    if (!config || !config.data || !config.data.providers || !config.data.providers.kokoro || 
        !config.data.providers.kokoro.speed || config.data.providers.kokoro.speed.type !== 'range') {
      return speedMarks; // Use default marks
    }
    
    const speedConfig = config.data.providers.kokoro.speed;
    const { min, max, step } = speedConfig;
    
    // Generate marks at reasonable intervals
    const marks = [];
    for (let value = min; value <= max; value += (max - min) / 4) {
      marks.push({
        value: parseFloat(value.toFixed(1)),
        label: `${value.toFixed(1)}x`
      });
    }
    
    return marks;
  }, [config]);
  
  // Handle gender filter change
  const handleGenderFilterChange = (gender) => {
    setGenderFilter(gender);
  };

  // DEBUG - Track renders and state changes
  useEffect(() => {
    // Increment render count
    renderCountRef.current += 1;
    
    // Log only on significant count changes to avoid spamming console
    if (renderCountRef.current === 1 || 
        renderCountRef.current === 5 || 
        renderCountRef.current === 10 || 
        renderCountRef.current === 20 || 
        renderCountRef.current === 50 || 
        renderCountRef.current % 100 === 0) {
      console.log(`[DEBUG] Render count: ${renderCountRef.current}`);
      
      // Log important state values
      console.log('[DEBUG] Component state:', {
        config: config ? 'Loaded' : 'Not loaded',
        loading,
        currentPlayingVoice: currentPlayingVoice || 'None',
        savedToRedux: configSavedToReduxRef.current,
        savedInState: Boolean(savedState),
        stepCompleted: savedState?.status || false,
        apiCalled: apiCalledRef.current,
        renderCount: renderCountRef.current
      });
    }
    
    // Only log at severe render counts that indicate a likely infinite loop
    if (renderCountRef.current > 1000) {
      console.error(`[CRITICAL] Extremely high render count detected: ${renderCountRef.current}. This indicates a severe rendering loop.`);
    }
  }, [config, loading, currentPlayingVoice, savedState]);

  // Add a useEffect to log when currentPlayingVoice changes
  useEffect(() => {
    console.log('🔊 currentPlayingVoice changed to:', currentPlayingVoice);
  }, [currentPlayingVoice]);

  // Start preloading voice samples once the voice options are available
  useEffect(() => {
    if (getVoiceOptions && getVoiceOptions.length > 0) {
      console.log('🔊 Starting preload of voice samples');
      // Preload the first few voices for each language to improve responsiveness
      const voicesToPreload = getVoiceOptions.slice(0, 5);
      voicesToPreload.forEach(voice => {
        preloadVoiceAudio(voice.id || voice.name);
      });
    }
  }, [getVoiceOptions, preloadVoiceAudio]);

  // Function to help debug config structure
  const debugConfigStructure = () => {
    if (!config) return 'Config is null or undefined';
    
    try {
      const result = {
        hasData: !!config.data,
        hasProviders: !!(config.data && config.data.providers),
        providerCount: config.data && config.data.providers ? Object.keys(config.data.providers).length : 0,
        providers: {}
      };
      
      if (config.data && config.data.providers) {
        Object.keys(config.data.providers).forEach(provider => {
          const providerData = config.data.providers[provider];
          result.providers[provider] = {
            hasVoiceBy: !!(providerData && providerData.voice_by),
            voiceByType: providerData && providerData.voice_by ? providerData.voice_by.type : null,
            optionsCount: providerData && providerData.voice_by && providerData.voice_by.options ? 
              providerData.voice_by.options.length : 0
          };
        });
      }
      
      return JSON.stringify(result, null, 2);
    } catch (error) {
      return `Error analyzing config: ${error.message}`;
    }
  };

  // Render voice cards
  const renderVoiceCards = () => {
    if (!getVoiceOptions || getVoiceOptions.length === 0) {
      return (
        <Grid item xs={12}>
          <Typography variant="body1" align="center" color="textSecondary">
            No voices available for the selected language.
          </Typography>
        </Grid>
      );
    }
    
    return getVoiceOptions.map((voice) => {
      // Log card selection status to help debug
      const isSelected = answers.voice === (voice.id || voice.name);
      const isPlaying = currentPlayingVoice === (voice.id || voice.name);
      
      if (isSelected) {
        console.log('📋 Rendered selected voice card:', voice.id || voice.name, voice.description);
      }
      
      return (
        <Grid item xs={6} sm={4} md={3} lg={2} key={voice.id || voice.name}>
          <VoiceOption
            selected={isSelected}
            onClick={() => {
              console.log('📋 Clicked on voice card:', voice.id || voice.name, 'Description:', voice.description, 'Provider:', voice.provider);
              // Play the voice preview when the card is clicked
              handleVoicePreview(voice.id || voice.name);
              // Select the voice
              handleVoiceSelect(voice.provider, voice.id || voice.name);
            }}
            sx={{ 
              transition: 'all 0.2s',
              '&:hover': { 
                boxShadow: 3 
              }
            }}
          >
            <CardContent sx={{ 
              py: 1.2,
              px: 2,
              display: 'flex', 
              flexDirection: 'column',
              height: '100%',
              minHeight: '70px',
              position: 'relative'
            }}>
              {/* Show loading indicator when audio is being loaded */}
              {isLoadingAudio && currentPlayingVoice === (voice.id || voice.name) && (
                <Box sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  zIndex: 2,
                  borderRadius: 1
                }}>
                  <CircularProgress size={24} />
                </Box>
              )}

              {/* Add stop button when audio is playing */}
              {isPlaying && (
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent card click
                    handleStopVoice();
                  }}
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    zIndex: 3,
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    }
                  }}
                >
                  <StopIcon fontSize="small" />
                </IconButton>
              )}

              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                {voice.gender && (
                  <Box sx={{ mr: 0.7, display: 'flex', alignItems: 'center' }}>
                    {voice.gender.toLowerCase() === 'male' ? 
                      <Man2Icon fontSize="small" sx={{ width: 20, height: 20 }} /> : 
                      <WomanIcon fontSize="small" sx={{ width: 20, height: 20 }} />}
                  </Box>
                )}
                
                <Typography variant="subtitle2" sx={{ flex: 1, textAlign: 'left' }}>
                  {voice.description || getVoiceDisplayName(voice.name)}
                </Typography>
              </Box>
              
              {voice.language && voice.language !== getLanguageDisplayNameFromConfig(selectedLanguage) && (
                <Typography variant="caption" component="span" sx={{ 
                  display: 'inline-block',
                  bgcolor: 'background.default',
                  p: 0.5,
                  borderRadius: 1,
                  fontSize: '0.7rem',
                  mb: 1
                }}>
                  {voice.language}
                </Typography>
              )}
              
              <Box sx={{ 
                mt: 'auto', 
                mb: 0,
                position: 'relative',
                width: '100%',
                textAlign: 'center'
              }}>
                <Typography 
                  variant="caption" 
                  color="text.secondary" 
                  sx={{ 
                    display: 'block',
                    width: '100%',
                    bgcolor: 'background.default',
                    p: 0.5,
                    borderRadius: 1,
                    fontSize: '0.7rem',
                    textAlign: 'center'
                  }}
                >
                  {voice.providerName}
                </Typography>
              </Box>
            </CardContent>
          </VoiceOption>
        </Grid>
      );
    });
  };

  return (
    <>
      <ViewerHeader
        title={process?.info?.title || "Voice Narration"}
        subtitle={process?.info?.description || "Choose a voice that will narrate your video"}
        processId="glow_102"
        showRerun={false}
      />
      
      {/* Reduced top padding to decrease space between header and content */}
      <Box sx={{ px: 3, pt: 1, pb: 3 }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 8 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error" sx={{ mb: 3 }}>
            Failed to load voice options. Please refresh the page or try again later.
            {error && <Typography variant="caption" display="block" sx={{ mt: 1 }}>{error}</Typography>}
            
            {/* Debug information (can be removed in production) */}
            <Box sx={{ mt: 2, p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
              <Typography variant="caption" component="pre" sx={{ whiteSpace: 'pre-wrap', fontSize: '0.7rem' }}>
                {debugConfigStructure()}
              </Typography>
            </Box>
            
            <Button 
              variant="outlined" 
              size="small" 
              sx={{ mt: 1 }}
              onClick={() => {
                // Reset API called ref to allow retrying
                apiCalledRef.current = false;
                // Clear error
                setError(null);
                // Retry initializing config using the stored reference
                if (initializeConfigRef.current) {
                  initializeConfigRef.current();
                }
              }}
            >
              Retry
            </Button>
          </Alert>
        ) : !config || !config.data || !config.data.providers ? (
          <Alert severity="warning" sx={{ mb: 3 }}>
            No voice configuration data available. Please refresh the page or try again later.
            
            {/* Debug information about received config */}
            <Box sx={{ mt: 2, p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
              <Typography variant="caption" component="pre" sx={{ whiteSpace: 'pre-wrap', fontSize: '0.7rem' }}>
                Config structure: {debugConfigStructure()}
              </Typography>
            </Box>
            
            <Button 
              variant="outlined" 
              size="small" 
              sx={{ mt: 1 }}
              onClick={() => {
                // Reset API called ref to allow retrying
                apiCalledRef.current = false;
                // Retry initializing config using the stored reference
                if (initializeConfigRef.current) {
                  initializeConfigRef.current();
                }
              }}
            >
              Retry
            </Button>
          </Alert>
        ) : (
          <Paper sx={{ p: 2, mb: 4 }}>
            {/* Step Indicator */}
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Step {currentStep}: {currentStep === 1 ? 'Select Language' : 'Choose Voice'}
              </Typography>
            </Box>

            {/* Language Selection - keeping only the chips, removing redundant headers */}
            {getAvailableLanguages.length > 0 && (
              <Box sx={{ mb: 2 }}>
                <Grid container spacing={1}>
                  {getAvailableLanguages.map(langCode => (
                    <Grid item key={langCode}>
                      <Chip
                        icon={<Globe size={16} />}
                        label={getLanguageDisplayNameFromConfig(langCode)}
                        variant={selectedLanguage === langCode ? "filled" : "outlined"}
                        color={selectedLanguage === langCode ? "primary" : "default"}
                        onClick={() => handleLanguageChange(langCode)}
                        sx={{ cursor: 'pointer' }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
            
            {/* Only show voice selection if we're on step 2 */}
            {currentStep === 2 && (
              <>
                {/* Filters section - side by side with reduced margins */}
                <Box sx={{ mb: 2, mt: 4 }}>
                  <Grid container spacing={2}>
                    {/* Gender Filter - Left side */}
                    <Grid item xs={12} md={6}>
                      <Typography variant="subtitle2" gutterBottom>
                        Filter by Gender
                      </Typography>
                      
                      <Grid container spacing={1}>
                        <Grid item>
                          <Chip
                            label="All Voices"
                            variant={genderFilter === 'all' ? "filled" : "outlined"}
                            color={genderFilter === 'all' ? "primary" : "default"}
                            onClick={() => handleGenderFilterChange('all')}
                            sx={{ cursor: 'pointer' }}
                          />
                        </Grid>
                        <Grid item>
                          <Chip
                            icon={<Man2Icon fontSize="small" />}
                            label="Male"
                            variant={genderFilter === 'male' ? "filled" : "outlined"}
                            color={genderFilter === 'male' ? "primary" : "default"}
                            onClick={() => handleGenderFilterChange('male')}
                            sx={{ cursor: 'pointer' }}
                          />
                        </Grid>
                        <Grid item>
                          <Chip
                            icon={<WomanIcon fontSize="small" />}
                            label="Female"
                            variant={genderFilter === 'female' ? "filled" : "outlined"}
                            color={genderFilter === 'female' ? "primary" : "default"}
                            onClick={() => handleGenderFilterChange('female')}
                            sx={{ cursor: 'pointer' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* Change language option */}
                    <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <Button 
                        variant="outlined" 
                        startIcon={<Globe size={16} />}
                        onClick={() => setCurrentStep(1)}
                        size="small"
                      >
                        Change Language
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                
                {/* Voice Selection */}
                <Box sx={{ mb: 2 }} ref={voiceSelectionRef}>
                  <Grid container spacing={1}>
                    {getVoiceOptions.length > 0 ? (
                      <Grid container spacing={1}>
                        {renderVoiceCards()}
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Typography variant="body1" align="center" color="textSecondary">
                          No voices available for the selected language.
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Box>
                
                {/* Speed settings */}
                {answers.voice && (
                  <Box sx={{ mt: 4 }} ref={speedSelectionRef}>
                    <Typography variant="h6" gutterBottom>
                      Voice Settings
                    </Typography>
                    <Box sx={{ my: 3 }}>
                      <Typography gutterBottom>Speech Speed</Typography>
                      <Box sx={{ px: 2 }}>
                        <Slider
                          value={answers.speed}
                          onChange={handleSpeedChange}
                          step={config?.data?.providers?.kokoro?.speed?.step || 0.1}
                          marks={getSpeedMarks}
                          min={config?.data?.providers?.kokoro?.speed?.min || 0.5}
                          max={config?.data?.providers?.kokoro?.speed?.max || 1.5}
                          valueLabelDisplay="auto"
                          aria-labelledby="voice-speed-slider"
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Paper>
        )}
      </Box>
      
      <ViewFooter
        processId="glow_102"
        onComplete={handleComplete}
        isComplete={isComplete}
        isCompleteDisabled={loading || !!error}
        completeButtonText="Continue to Video Style"
        loadingStates={{ 'glow_102': loading }}
      />
    </>
  );
};

export default Glow102ProcessorVoiceNarration; 