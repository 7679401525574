import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import { CheckCircle, AlertTriangle, ArrowRight } from 'lucide-react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  Cell
} from 'recharts';

const StartupPathsComparison = () => {
  const navigate = useNavigate();
  const [selectedPath, setSelectedPath] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const pathData = {
    waterfall: [
      { phase: 'Requirements', duration: 3, description: 'Comprehensive business & technical requirements', cost: '30k' },
      { phase: 'Design', duration: 4, description: 'Complete system architecture & design', cost: '40k' },
      { phase: 'Development', duration: 8, description: 'Full product development cycle', cost: '120k' },
      { phase: 'Testing', duration: 3, description: 'System-wide testing and QA', cost: '30k' },
      { phase: 'Deployment', duration: 2, description: 'Market launch and operations', cost: '30k' }
    ],
    leanStartup: [
      { phase: 'Build MVP', duration: 2, description: 'Minimum viable product development', cost: '20k' },
      { phase: 'Measure', duration: 2, description: 'Key metrics collection and analysis', cost: '15k' },
      { phase: 'Learn', duration: 1, description: 'Customer feedback integration', cost: '10k' },
      { phase: 'Pivot/Persevere', duration: 3, description: 'Strategic direction adjustment', cost: '25k' }
    ],
    agileScrum: [
      { phase: 'Sprint 0', duration: 1, description: 'Initial backlog and planning', cost: '15k' },
      { phase: 'MVP Sprints', duration: 3, description: 'Core feature development', cost: '45k' },
      { phase: 'Market Testing', duration: 2, description: 'User testing and feedback', cost: '20k' },
      { phase: 'Iteration', duration: 3, description: 'Product refinement cycles', cost: '40k' }
    ],
    glowtest: [
      { phase: 'Market Discovery', duration: 0.5, description: 'AI-powered market analysis & segmentation', cost: '2k' },
      { phase: 'Rapid Testing', duration: 0.5, description: 'Multi-variant audience testing', cost: '2k' },
      { phase: 'Data Analysis', duration: 0.5, description: 'ML-driven insight generation', cost: '2k' },
      { phase: 'Validated Build', duration: 2.5, description: 'Market-verified development', cost: '19k' }
    ]
  };

  const pathInfo = {
    waterfall: {
      name: 'Traditional Waterfall',
      color: '#EF4444',
      totalTime: '20 months',
      risk: 'High',
      cost: '$250k+',
      successRate: '15%',
      description: 'Sequential, phase-based development methodology following a strict linear path.',
      pros: [
        'Clear project structure',
        'Detailed documentation',
        'Predictable phases',
        'Suitable for regulated industries'
      ],
      cons: [
        'High upfront costs',
        'Inflexible to changes',
        'Late market feedback',
        'High risk of market misalignment'
      ]
    },
    leanStartup: {
      name: 'Lean Startup',
      color: '#10B981',
      totalTime: '8 months',
      risk: 'Medium',
      cost: '$70k+',
      successRate: '45%',
      description: 'Build-Measure-Learn methodology focused on validated learning through rapid experimentation.',
      pros: [
        'Customer-centric approach',
        'Data-driven decisions',
        'Reduced waste',
        'Faster market learning'
      ],
      cons: [
        'Requires metric expertise',
        'Multiple pivot cycles',
        'Resource intensive testing',
        'Complex feedback analysis'
      ]
    },
    agileScrum: {
      name: 'Agile/Scrum',
      color: '#8B5CF6',
      totalTime: '9 months',
      risk: 'Medium',
      cost: '$120k+',
      successRate: '50%',
      description: 'Iterative development with fixed-length sprints and continuous stakeholder feedback.',
      pros: [
        'Regular deliverables',
        'Flexible to changes',
        'Team collaboration',
        'Continuous improvement'
      ],
      cons: [
        'Resource commitment',
        'Sprint overhead',
        'Team dependency',
        'Scope uncertainty'
      ]
    },
    glowtest: {
      name: 'AI-Driven Validation',
      color: '#F59E0B',
      totalTime: '4 months',
      risk: 'Low',
      cost: '$25k+',
      successRate: '85%',
      description: 'ML-powered market validation and development methodology using predictive analytics.',
      pros: [
        'Data-driven validation',
        'Rapid market insights',
        'Multi-segment testing',
        'Predictive analytics'
      ],
      cons: [
        'New methodology',
        'AI dependency',
        'Requires data literacy',
        'Market skepticism'
      ]
    }
  };

  const chartData = Object.entries(pathData).map(([key, phases]) => ({
    name: key,
    total: Number(phases.reduce((sum, phase) => sum + phase.duration, 0).toFixed(2)),
    totalCost: phases.reduce((sum, phase) => sum + parseInt(phase.cost.replace('k', ''), 10), 0),
    phases: phases
  }));

  return (
    <section className="py-12 md:py-16 mb-12 md:mb-20 px-4" aria-labelledby="startup-paths-title">
      <div className="text-center mb-8 md:mb-12">
        <h2 id="startup-paths-title" className="text-2xl md:text-3xl font-bold mb-3 md:mb-4">
          Startup Path Comparison
        </h2>
        <p className="text-zinc-300 max-w-2xl mx-auto text-sm md:text-base">
          See how early validation dramatically reduces time to market and risk
        </p>
      </div>

      <div className="relative max-w-full">
        <ResponsiveContainer width="100%" height={isMobile ? 180 : 200}>
          <BarChart
            layout="vertical"
            data={chartData}
            margin={{ top: 20, right: isMobile ? 10 : 30, left: isMobile ? 100 : 120, bottom: 5 }}
            onClick={(data) => {
              if (data && data.activePayload && data.activePayload[0]) {
                setSelectedPath(data.activePayload[0].payload.name);
              }
            }}
          >
            <CartesianGrid strokeDasharray="3 3" horizontal={false} />
            <XAxis 
              type="number" 
              domain={[0, 24]}
              tickCount={isMobile ? 4 : 7}
              tick={{ fontSize: isMobile ? 10 : 12 }}
            >
              <Label value="Months" offset={-5} position="insideBottom" style={{ fontSize: isMobile ? 10 : 12 }} />
            </XAxis>
            <YAxis 
              dataKey="name" 
              type="category"
              width={isMobile ? 90 : 120}
              tick={{ fontSize: isMobile ? 10 : 12 }}
              tickFormatter={(value) => {
                const names = {
                  waterfall: 'Traditional Waterfall',
                  leanStartup: 'Lean Startup',
                  agileScrum: 'Agile/Scrum',
                  glowtest: 'AI-Driven Validation'
                };
                return names[value];
              }}
            />
            <Tooltip 
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const data = payload[0].payload;
                  return (
                    <div className="bg-zinc-800 p-3 md:p-4 rounded-lg shadow-lg border border-zinc-700" style={{ backgroundColor: '#18181B' }}>
                      <h3 className="font-bold mb-1 md:mb-2 text-white text-sm md:text-base">
                        {data.name === 'waterfall' ? 'Traditional Waterfall' :
                         data.name === 'leanStartup' ? 'Lean Startup' :
                         data.name === 'agileScrum' ? 'Agile/Scrum' :
                         'AI-Driven Validation'}
                      </h3>
                      <div className="space-y-1 text-xs md:text-sm text-white">
                        <p>Total Time: {data.total} months</p>
                        <p>Total Cost: ${data.totalCost}k</p>
                        <p className="text-zinc-200 text-xs mt-1 md:mt-2">Tap for details</p>
                      </div>
                    </div>
                  );
                }
                return null;
              }}
            />
            <Bar 
              dataKey="total"
              animationDuration={1000}
              fill="#8884d8"
            >
              {chartData.map((entry) => (
                <Cell 
                  key={`cell-${entry.name}`}
                  fill={pathInfo[entry.name].color}
                  opacity={selectedPath === entry.name ? 1 : 0.7}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>

        <div className="mt-8 md:mt-12 space-y-6 md:space-y-8">
          <div className="overflow-x-auto -mx-4 px-4">
            <div className="min-w-[600px] md:min-w-0">
              <table className="w-full text-sm">
                <thead>
                  <tr className="border-b border-zinc-700">
                    <th className="px-3 md:px-4 py-2 md:py-3 text-left">Method</th>
                    <th className="px-3 md:px-4 py-2 md:py-3 text-center">Time to Market</th>
                    <th className="px-3 md:px-4 py-2 md:py-3 text-center">Initial Cost</th>
                    <th className="px-3 md:px-4 py-2 md:py-3 text-center">Risk Level</th>
                    <th className="px-3 md:px-4 py-2 md:py-3 text-center">Success Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(pathInfo).map(([key, info]) => (
                    <tr 
                      key={key}
                      className={`border-b border-zinc-800 hover:bg-zinc-800/30 transition-colors
                        ${selectedPath === key ? 'bg-zinc-800/50' : ''}`}
                      onClick={() => setSelectedPath(key)}
                    >
                      <td className="px-3 md:px-4 py-2 md:py-3">
                        <div className="flex items-center gap-2">
                          <div 
                            className="w-2 md:w-3 h-2 md:h-3 rounded-full"
                            style={{ backgroundColor: info.color }}
                          />
                          <span className="text-xs md:text-sm">{info.name}</span>
                        </div>
                      </td>
                      <td className="px-3 md:px-4 py-2 md:py-3 text-center text-xs md:text-sm">{info.totalTime}</td>
                      <td className="px-3 md:px-4 py-2 md:py-3 text-center text-xs md:text-sm">{info.cost}</td>
                      <td className="px-3 md:px-4 py-2 md:py-3 text-center">
                        <span 
                          className={`px-2 py-1 rounded-full text-xs
                            ${key === 'waterfall' ? 'bg-red-500/20 text-red-300' :
                              key === 'leanStartup' ? 'bg-yellow-500/20 text-yellow-300' :
                              key === 'agileScrum' ? 'bg-purple-500/20 text-purple-300' :
                              'bg-green-500/20 text-green-300'}`}
                        >
                          {info.risk}
                        </span>
                      </td>
                      <td className="px-3 md:px-4 py-2 md:py-3 text-center text-xs md:text-sm">{info.successRate}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {selectedPath && (
            <div className="bg-zinc-800/30 rounded-lg p-4 md:p-6 mt-4 md:mt-6">
              <div className="flex justify-between items-start mb-3 md:mb-4">
                <div>
                  <h3 className="text-lg md:text-xl font-bold mb-1 md:mb-2" style={{ color: pathInfo[selectedPath].color }}>
                    {pathInfo[selectedPath].name}
                  </h3>
                  <p className="text-zinc-300 text-sm md:text-base">{pathInfo[selectedPath].description}</p>
                </div>
                <button 
                  onClick={() => setSelectedPath(null)}
                  className="text-zinc-400 hover:text-white p-2 touch-manipulation"
                  aria-label="Close details"
                >
                  <Close fontSize={isMobile ? "small" : "medium"} />
                </button>
              </div>

              <div className="grid md:grid-cols-2 gap-4 md:gap-6 mt-3 md:mt-4">
                <div className="space-y-1 md:space-y-2">
                  <h4 className="font-semibold text-green-400 mb-2 md:mb-3 text-sm md:text-base">Advantages</h4>
                  {pathInfo[selectedPath].pros.map((pro, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <CheckCircle className="text-green-400 flex-shrink-0" size={isMobile ? 14 : 16} />
                      <span className="text-xs md:text-sm">{pro}</span>
                    </div>
                  ))}
                </div>

                <div className="space-y-1 md:space-y-2">
                  <h4 className="font-semibold text-red-400 mb-2 md:mb-3 text-sm md:text-base">Considerations</h4>
                  {pathInfo[selectedPath].cons.map((con, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <AlertTriangle className="text-red-400 flex-shrink-0" size={isMobile ? 14 : 16} />
                      <span className="text-xs md:text-sm">{con}</span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-5 md:mt-6">
                <h4 className="font-semibold mb-3 text-sm md:text-base">Phase Breakdown</h4>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-4">
                  {pathData[selectedPath].map((phase, index) => (
                    <div 
                      key={index}
                      className="bg-zinc-800/50 p-3 md:p-4 rounded-lg"
                    >
                      <div className="font-medium mb-1 md:mb-2 text-sm md:text-base">{phase.phase}</div>
                      <div className="text-xs md:text-sm text-zinc-400">Duration: {phase.duration} {phase.duration === 1 ? 'month' : 'months'}</div>
                      <div className="text-xs md:text-sm text-zinc-400">Cost: ${phase.cost}</div>
                      <div className="text-xs md:text-sm mt-1 md:mt-2">{phase.description}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className="text-center mt-6 md:mt-8">
            <button
              className="bg-orange-500 text-white px-5 md:px-6 py-2 md:py-3 rounded-lg font-semibold inline-flex items-center gap-1 md:gap-2 hover:bg-orange-600 transition-colors touch-manipulation"
              onClick={() => navigate('/journey')}
            >
              Start Your Validation Journey
              <ArrowRight size={isMobile ? 18 : 20} />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StartupPathsComparison; 