import React from 'react';
import { DollarSign, Clock, TrendingUp } from 'lucide-react';
import { 
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Cell
} from 'recharts';

const BusinessROIMetrics = () => {
  const costData = [
    { name: 'Traditional', value: 100000, label: '$100,000+' },
    { name: 'Glowtest', value: 5000, label: '$5,000' }
  ];

  const timeData = [
    { name: 'Traditional', value: 5, label: '5+ months' },
    { name: 'Glowtest', value: 0.8, label: '24 days' }
  ];

  const riskData = [
    { name: 'Traditional', value: 75, label: '75%' },
    { name: 'Glowtest', value: 15, label: '15%' }
  ];

  const ROIData = [
    { 
      stage: 'Initial Investment',
      traditional: 100000,
      glowtest: 5000
    },
    { 
      stage: 'Development Cost',
      traditional: 150000,
      glowtest: 75000
    },
    { 
      stage: 'Post-Launch Fixes',
      traditional: 75000,
      glowtest: 15000
    },
    { 
      stage: 'Marketing & Sales',
      traditional: 50000,
      glowtest: 35000
    }
  ];

  const renderMetricCard = (icon, title, description, chartData, color, valueFormatter, label) => (
    <div className="bg-zinc-800/30 p-6 rounded-xl">
      <div className="flex items-center mb-4">
        {icon}
        <div className="ml-3">
          <h3 className="text-xl font-bold">{title}</h3>
          <p className="text-sm text-zinc-400">{description}</p>
        </div>
      </div>
      
      <div className="h-56">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 30 }}
          >
            <XAxis dataKey="name" />
            <YAxis 
              tickFormatter={valueFormatter}
              label={{ 
                value: label, 
                angle: -90, 
                position: 'insideLeft',
                style: { textAnchor: 'middle', fill: '#9CA3AF' }
              }}
            />
            <Tooltip 
              formatter={(value) => [
                chartData.find(item => item.value === value)?.label || valueFormatter(value),
                ''
              ]}
              labelFormatter={(value) => value === 'Traditional' ? 'Traditional Approach' : 'Glowtest AI Validation'}
            />
            <Bar dataKey="value" fill={color}>
              {chartData.map((entry, index) => (
                <Cell 
                  key={`cell-${index}`} 
                  fill={index === 0 ? '#EF4444' : '#F59E0B'} 
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  return (
    <section className="py-16 mb-20">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold mb-4">
          Validation ROI Metrics
        </h2>
        <p className="text-zinc-300 max-w-2xl mx-auto">
          How early validation with Glowtest saves time, money, and reduces risk
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
        {renderMetricCard(
          <DollarSign className="w-8 h-8 text-orange-500" />,
          'Cost Savings',
          'Average development costs before product-market fit',
          costData,
          '#F59E0B',
          (value) => `$${(value/1000).toFixed(0)}k`,
          'Cost (USD)'
        )}
        
        {renderMetricCard(
          <Clock className="w-8 h-8 text-orange-500" />,
          'Time to Market',
          'Average time to launch with validated product',
          timeData,
          '#F59E0B',
          (value) => `${value.toFixed(1)} months`,
          'Time (months)'
        )}
        
        {renderMetricCard(
          <TrendingUp className="w-8 h-8 text-orange-500" />,
          'Risk Reduction',
          'Failure rate of new product launches',
          riskData,
          '#F59E0B',
          (value) => `${value}%`,
          'Failure Rate (%)'
        )}
      </div>

      <div className="bg-zinc-800/30 p-6 rounded-xl">
        <h3 className="text-xl font-bold mb-6">Total Project Cost Comparison</h3>
        <div className="h-96">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={ROIData}
              margin={{ top: 20, right: 30, left: 20, bottom: 30 }}
              layout="vertical"
            >
              <XAxis type="number" tickFormatter={(value) => `$${(value/1000).toFixed(0)}k`} />
              <YAxis dataKey="stage" type="category" width={150} />
              <Tooltip 
                formatter={(value) => [`$${value.toLocaleString()}`, '']}
              />
              <Legend />
              <Bar 
                dataKey="traditional" 
                name="Traditional Approach" 
                fill="#EF4444"
                barSize={30} 
              />
              <Bar 
                dataKey="glowtest" 
                name="Glowtest AI Validation" 
                fill="#F59E0B"
                barSize={30} 
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="mt-6 bg-zinc-900/50 p-4 rounded-lg">
          <div className="flex items-center">
            <div className="w-4 h-4 rounded-full bg-orange-500 mr-2"></div>
            <p className="text-zinc-300"><span className="font-bold">Key Insight:</span> Early validation with Glowtest reduces total project costs by an average of 65% by identifying market needs before full development.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessROIMetrics; 