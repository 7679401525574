import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent as MuiCardContent,
  CardMedia,
  Typography,
  Box,
  Modal,
  IconButton,
  Skeleton,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme, useMediaQuery } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'hasDescription'
})(({ theme, selected, hasDescription }) => ({
  cursor: 'pointer',
  width: '320px',
  height: hasDescription ? '380px' : '360px',
  display: 'flex',
  flexDirection: 'column',
  border: selected ? `2px solid ${theme.palette.primary.main}` : '1px solid rgba(0, 0, 0, 0.12)',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
  // Mobile styles
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 'auto',
    marginBottom: theme.spacing(2)
  }
}));

const ImageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasDescription'
})(({ theme, hasDescription }) => ({
  position: 'relative',
  width: '100%',
  height: hasDescription ? '280px' : '300px',
  overflow: 'hidden',
  backgroundColor: '#f5f5f5',
  // Mobile styles
  [theme.breakpoints.down('sm')]: {
    height: hasDescription ? '320px' : '340px',
  }
}));

const StyledCardMedia = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  imageRendering: 'crisp-edges'
});

const ZoomIconOverlay = styled(IconButton)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  opacity: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  padding: '8px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  }
});

const PreviewButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  left: 8,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  borderRadius: '4px',
  padding: '4px 8px',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  // Hide by default on desktop
  display: 'none',
  // Show on mobile
  [theme.breakpoints.down('sm')]: {
    display: 'flex'
  }
}));

const StyledCardContent = styled(MuiCardContent, {
  shouldForwardProp: (prop) => prop !== 'hasDescription'
})(({ hasDescription }) => ({
  flexGrow: 1,
  height: hasDescription ? '80px' : '60px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '16px',
  overflow: 'hidden',
  '&:last-child': {
    paddingBottom: '16px'
  }
}));

const LoadingSkeleton = styled(Skeleton)({
  width: '100%',
  height: '160px'
});

// Add new styled component for cost indicator
const CostIndicator = styled(Box)(({ theme, variant }) => ({
  position: 'absolute',
  top: 8,
  left: 8,
  borderRadius: '4px',
  padding: '4px 8px',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  fontSize: '0.75rem',
  fontWeight: 500,
  backgroundColor: variant === 'increase' 
    ? 'rgba(211, 47, 47, 0.1)' 
    : variant === 'decrease' 
      ? 'rgba(46, 125, 50, 0.1)'
      : 'transparent',
  color: variant === 'increase' 
    ? theme.palette.error.main 
    : variant === 'decrease' 
      ? theme.palette.success.main
      : 'inherit',
}));

const SelectionCard = ({
  title,
  description,
  selected,
  onClick,
  thumbnailSrc,
  fullImageSrc,
  disabled = false,
  costComparison, // New prop: { type: 'provider' | 'model', value: number }
  labelText = '' // New prop for displaying status labels like "(Enabled for Testing)"
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFullImageLoaded, setIsFullImageLoaded] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCardClick = (e) => {
    if (disabled) return;
    onClick && onClick();
  };

  const handleZoomClick = (e) => {
    e.stopPropagation(); // Prevent card selection
    if (!disabled) {
      setIsModalOpen(true);
      console.log('Opening modal with image:', fullImageSrc);
    }
  };

  const renderCostIndicator = () => {
    if (!costComparison || !['provider', 'model'].includes(costComparison.type)) {
      return null;
    }

    const value = costComparison.value;
    if (value === 0) return null;

    const isIncrease = value > 0;
    const formattedValue = `${isIncrease ? '+' : ''}${value}%`;
    const costText = isIncrease ? 'More expensive' : 'Cheaper';

    return (
      <CostIndicator variant={isIncrease ? 'increase' : 'decrease'}>
        {isIncrease ? (
          <TrendingUpIcon sx={{ fontSize: '1rem' }} />
        ) : (
          <TrendingDownIcon sx={{ fontSize: '1rem' }} />
        )}
        {formattedValue} • {costText}
      </CostIndicator>
    );
  };

  return (
    <>
      <StyledCard 
        selected={selected}
        hasDescription={Boolean(description)}
        onClick={handleCardClick}
        sx={{ 
          opacity: disabled ? 0.6 : 1,
          position: 'relative',
        }}
      >
        <ImageContainer hasDescription={Boolean(description)}>
          {renderCostIndicator()}
          {thumbnailSrc ? (
            <>
              <StyledCardMedia
                src={thumbnailSrc}
                alt={title}
              />
              {/* Desktop preview overlay */}
              {!isMobile && (
                <ZoomIconOverlay
                  onClick={handleZoomClick}
                  sx={{
                    opacity: 0,
                    '.MuiCard-root:hover &': {
                      opacity: 1
                    },
                    transform: 'translate(-50%, -50%) !important',
                    '&:hover': {
                      transform: 'translate(-50%, -50%) !important'
                    }
                  }}
                >
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center',
                    transform: 'none !important'
                  }}>
                    <ZoomInIcon />
                    <Typography variant="caption" sx={{ color: 'white', mt: 0.5, fontSize: '0.75rem' }}>
                      Preview
                    </Typography>
                  </Box>
                </ZoomIconOverlay>
              )}
              
              {/* Mobile preview button */}
              {isMobile && (
                <PreviewButton onClick={handleZoomClick}>
                  <ZoomInIcon sx={{ fontSize: '1.2rem' }} />
                  <Typography variant="caption">
                    Preview
                  </Typography>
                </PreviewButton>
              )}
            </>
          ) : (
            <LoadingSkeleton variant="rectangular" />
          )}
        </ImageContainer>
        
        {selected && (
          <Box
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              bgcolor: 'primary.main',
              color: 'white',
              borderRadius: '4px',
              px: 1,
              py: 0.5,
              fontSize: '0.75rem',
            }}
          >
            Selected
          </Box>
        )}

        <StyledCardContent hasDescription={Boolean(description)}>
          <Typography 
            variant="subtitle2"
            component="h3"
            sx={{ 
              fontSize: isMobile ? '1.1rem' : '1rem',
              fontWeight: 500,
              lineHeight: 1.2,
              mb: description ? 0.75 : 0
            }}
          >
            {title}
            {labelText && (
              <Box component="span" sx={{ 
                ml: 1, 
                fontSize: '0.75rem', 
                color: 'primary.main',
                fontWeight: 'normal'
              }}>
                {labelText}
              </Box>
            )}
          </Typography>
          {description && (
            <Typography 
              variant="caption"
              color="text.secondary"
              sx={{ 
                fontSize: isMobile ? '1rem' : '0.875rem',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden'
              }}
            >
              {description}
            </Typography>
          )}
        </StyledCardContent>
      </StyledCard>

      {/* Modal with mobile-friendly styles */}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="image-modal"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: isMobile ? 1 : 2
        }}
        onClick={() => setIsModalOpen(false)}
      >
        <Box
          sx={{
            position: 'relative',
            width: isMobile ? '100%' : 'auto',
            maxWidth: '90vw',
            maxHeight: isMobile ? '100vh' : '90vh',
            bgcolor: 'background.paper',
            borderRadius: isMobile ? 0 : 1,
            p: 1,
            boxShadow: 24,
            '&:focus': { outline: 'none' },
            cursor: 'pointer'
          }}
        >
          {!isFullImageLoaded && (
            <CircularProgress 
              sx={{ 
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }} 
            />
          )}
          <img
            src={fullImageSrc}
            alt={title}
            onLoad={() => setIsFullImageLoaded(true)}
            onError={() => setIsFullImageLoaded(false)}
            style={{
              maxWidth: '100%',
              maxHeight: '80vh',
              display: isFullImageLoaded ? 'block' : 'none',
              objectFit: 'contain',
              cursor: 'pointer'
            }}
          />
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setIsModalOpen(false);
            }}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.7)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>
    </>
  );
};

export default SelectionCard;