import React, { useState, useEffect } from 'react';
import { Card, CardHeader,  CardContent } from '@mui/material';
import { Box } from '@mui/material';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import './glow_21_market-comp-matrix.css';
import processConfig from '../../config/processIndex.json';
import { Checkbox, FormControlLabel } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { SAMPLE_GLOW_21 } from '../../data/sampleGlowData';

const MarketMatrixViewer = ({ data }) => {
  const markets = (Array.isArray(data?.markets) && data.markets.length) ? data.markets : [];
  
  // Add state to track if initial animation has completed for the whole component
  const [initialAnimationComplete, setInitialAnimationComplete] = useState(false);
  
  // Run effect once on initial mount to mark animations as completed after delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialAnimationComplete(true);
    }, 1500); // Enough time for initial animations
    
    return () => clearTimeout(timer);
  }, []);
  
  // Add visibility state
  const [visibleLayers, setVisibleLayers] = useState({
    tam: true,
    sam: true,
    som: true
  });

  // Add tooltip state
  const [toast, setToast] = useState({ show: false, text: '', x: 0, y: 0 });

  // Add tooltip descriptions (same as glow_10)
  const tooltips = {
    tam: "Total Addressable Market (TAM) represents the total market demand for your product or service category. It's the maximum amount of revenue a business could generate if it captured 100% of the market share.",
    sam: "Serviceable Addressable Market (SAM) is the segment of TAM that your company can realistically target with its specific business model and capabilities. It represents the portion of the market that aligns with your product offering and distribution channels.",
    som: "Serviceable Obtainable Market (SOM) is the portion of SAM that you can realistically capture in the near term. It represents your actual target market share based on your company's resources, competition, and market constraints.",
    cagr: "Compound Annual Growth Rate (CAGR) measures the annual growth rate of an investment over a specified time period. It represents the steady rate at which the market would have grown if it increased at the same rate each year.",
    tamCagr: "This bar shows the annual growth rate (CAGR) for the Total Addressable Market. Longer bars indicate faster market growth. Please note that the min and max is adjusted within each TAM, SAM, SOM for the min and max values of all markets visible here.",
    samCagr: "This bar shows the annual growth rate (CAGR) for the Serviceable Addressable Market. Longer bars indicate faster market growth. Please note that the min and max is adjusted within each TAM, SAM, SOM for the min and max values of all markets visible here. ",
    somCagr: "This bar shows the annual growth rate (CAGR) for the Serviceable Obtainable Market. Longer bars indicate faster market growth. Please note that the min and max is adjusted within each TAM, SAM, SOM for the min and max values of all markets visible here.",
    industry: "The specific market sector or business domain that this analysis focuses on. This defines the scope and boundaries of the market segment.",
    description: "A detailed explanation of what this market segment encompasses and its key characteristics."
  };

  // Handler for checkbox changes
  const handleLayerToggle = (layer) => {
    setVisibleLayers(prev => ({
      ...prev,
      [layer]: !prev[layer]
    }));
  };

  // Add mouse move handler for tooltip
  const handleMouseMove = (e) => {
    if (toast.show) {
      setToast(prev => ({
        ...prev,
        x: e.clientX + 15,
        y: e.clientY + 15
      }));
    }
  };

  // Add mouse enter/leave handlers for tooltip
  const handleMouseEnter = (key, e) => {
    setToast({
      show: true,
      text: tooltips[key],
      x: e.clientX + 15,
      y: e.clientY + 15
    });
  };

  const handleMouseLeave = () => {
    setToast(prev => ({ ...prev, show: false }));
  };

  // Calculate maximum values for each layer
  const maxValues = {
    tam: Math.max(...markets.map(market => market.tam.value)),
    sam: Math.max(...markets.map(market => market.sam.value)),
    som: Math.max(...markets.map(market => market.som.value))
  };

  // Function to get current maximum value based on visible layers
  const getCurrentMaxValue = (visibleLayers) => {
    const visibleValues = Object.entries(visibleLayers)
      .filter(([_, isVisible]) => isVisible)
      .map(([key]) => maxValues[key]);
    
    return Math.max(...visibleValues);
  };

  // Update the CAGR values calculation to consider visible layers
  const getVisibleCagrValues = (markets, visibleLayers) => {
    return markets.flatMap(market => [
      visibleLayers.tam ? market.tam.cagr : null,
      visibleLayers.sam ? market.sam.cagr : null,
      visibleLayers.som ? market.som.cagr : null
    ]).filter(value => value !== null);
  };

  // Update CAGR calculations to use visible layers
  const cagrValues = getVisibleCagrValues(markets, visibleLayers);
  const maxCagr = Math.max(...cagrValues);
  const minCagr = Math.min(...cagrValues);
  
  // Function to normalize CAGR value between 0-100%
  const normalizeCAGR = (value) => {
    if (maxCagr === minCagr) return 50; // Default to middle if all values are the same
    return ((value - minCagr) / (maxCagr - minCagr)) * 100;
  };

  // Show empty state if no markets data
  if (!markets.length) {
    return (
      <Card className="market-matrix-viewer">
        <CardContent>
          <div style={{ textAlign: 'center', padding: '2rem' }}>
            No market comparison data available. Please ensure the market analysis has been completed.
          </div>
        </CardContent>
      </Card>
    );
  }

  const MarketCircleWithCagr = ({ market, marketKey, index, visibleLayers, onMouseEnter, onMouseLeave, initialAnimationComplete }) => {
    const [activeSection, setActiveSection] = useState(null);
    const maxRadius = 100;
    
    // Define color schemes
    const colorSchemes = {
      primary: {
        som: '#FD9800',
        sam: '#FDB544',
        tam: '#FED38C'
      },
      secondary: {
        som: '#666666',
        sam: '#999999',
        tam: '#CCCCCC'
      }
    };
    
    // Use primary colors for first market (index 0), secondary for others
    const colors = index === 0 ? colorSchemes.primary : colorSchemes.secondary;
    
    // Get current maximum value based on visible layers
    const currentMaxValue = getCurrentMaxValue(visibleLayers);
    
    // Calculate radii relative to the current maximum value
    const tamRadius = visibleLayers.tam ? 
      maxRadius * Math.sqrt(market.tam.value / currentMaxValue) : 0;
    const samRadius = visibleLayers.sam ? 
      maxRadius * Math.sqrt(market.sam.value / currentMaxValue) : 0;
    const somRadius = visibleLayers.som ? 
      maxRadius * Math.sqrt(market.som.value / currentMaxValue) : 0;

    const svgHeight = maxRadius * 2;
    const centerY = svgHeight / 2;
    const baseline = centerY + maxRadius;
    const tamY = baseline - tamRadius;
    const samY = baseline - samRadius;
    const somY = baseline - somRadius;

    // Add formatter for billions/millions
    const formatValue = (value) => {
      if (value >= 1e9) {
        return `$${(value / 1e9).toFixed(1)}B`;
      }
      if (value >= 1e6) {
        return `$${(value / 1e6).toFixed(1)}M`; 
      }
      return `$${value.toFixed(1)}K`;
    };

    // Create circles data array with colors
    const circlesData = [
      { key: 'tam', y: tamY, r: tamRadius, color: colors.tam, value: market.tam.value },
      { key: 'sam', y: samY, r: samRadius, color: colors.sam, value: market.sam.value },
      { key: 'som', y: somY, r: somRadius, color: colors.som, value: market.som.value }
    ];

    return (
      <div className="market-container">
        <div className="bubble-visualization">
          <svg viewBox={`0 0 220 ${svgHeight}`} preserveAspectRatio="xMidYMid meet">
            <AnimatePresence>
              {circlesData
                .filter(({ key }) => visibleLayers[key])
                .map(({ key, y, r, color, value }, index) => {
                  return (
                    <motion.g 
                      key={`motion_${key}_${index}`}
                      onMouseEnter={(e) => onMouseEnter(key, e)}
                      onMouseLeave={onMouseLeave}
                    >
                      <motion.circle
                        cx="110"
                        cy={y}
                        r={r}
                        fill={color}
                        opacity={0.8}
                      />
                      
                      <motion.line 
                        x1="110"
                        y1={y}
                        x2="160"
                        y2={y}
                        stroke={color}
                        strokeWidth="1"
                        strokeDasharray="2,2"
                      />
                      
                      <motion.text
                        x="165"
                        y={y}
                        fill="var(--color-text)"
                        fontSize="12"
                        dominantBaseline="middle"
                        className="market-value-label"
                      >
                        {formatValue(value)}
                      </motion.text>
                    </motion.g>
                  );
                })}
            </AnimatePresence>
          </svg>
        </div>

        <div 
          className="market-title"
          onMouseEnter={(e) => onMouseEnter('industry', e)}
          onMouseLeave={handleMouseLeave}
        >
          {market.name}
        </div>

        {/* CAGR bars */}
        <div className="cagr-container">
          <div className="cagr-heading"
            onMouseEnter={(e) => handleMouseEnter('cagr', e)}
            onMouseLeave={handleMouseLeave}
          >
            Growth Rate (CAGR)
          </div>
          {visibleLayers.tam && (
            <div 
              className="cagr-row"
              onMouseEnter={(e) => handleMouseEnter('tam', e)}
              onMouseLeave={handleMouseLeave}
            >
              <span className="cagr-label">TAM</span>
              <div className="cagr-bar-container"
                onMouseEnter={(e) => handleMouseEnter('tamCagr', e)}
                onMouseLeave={handleMouseLeave}
              >
                <motion.div 
                  key={`tam-bar-${marketKey}`}
                  className={`cagr-bar ${index === 0 ? 'tam-bar' : 'tam-bar-secondary'}`}
                  style={{ 
                    backgroundColor: colors.tam,
                    width: initialAnimationComplete ? `${normalizeCAGR(market.tam.cagr)}%` : 0
                  }}
                  initial={false}
                  animate={!initialAnimationComplete ? { width: `${normalizeCAGR(market.tam.cagr)}%` } : {}}
                  transition={{ duration: 0.5 }}
                />
              </div>
              <span className="cagr-value">{market.tam.cagr}%</span>
            </div>
          )}
          
          {visibleLayers.sam && (
            <div 
              className="cagr-row"
              onMouseEnter={(e) => handleMouseEnter('sam', e)}
              onMouseLeave={handleMouseLeave}
            >
              <span className="cagr-label">SAM</span>
              <div className="cagr-bar-container"
                onMouseEnter={(e) => handleMouseEnter('samCagr', e)}
                onMouseLeave={handleMouseLeave}
              >
                <motion.div 
                  key={`sam-bar-${marketKey}`}
                  className={`cagr-bar ${index === 0 ? 'sam-bar' : 'sam-bar-secondary'}`}
                  style={{ 
                    backgroundColor: colors.sam,
                    width: initialAnimationComplete ? `${normalizeCAGR(market.sam.cagr)}%` : 0
                  }}
                  initial={false}
                  animate={!initialAnimationComplete ? { width: `${normalizeCAGR(market.sam.cagr)}%` } : {}}
                  transition={{ duration: 0.5 }}
                />
              </div>
              <span className="cagr-value">{market.sam.cagr}%</span>
            </div>
          )}

          {visibleLayers.som && (
            <div 
              className="cagr-row"
              onMouseEnter={(e) => handleMouseEnter('som', e)}
              onMouseLeave={handleMouseLeave}
            >
              <span className="cagr-label">SOM</span>
              <div className="cagr-bar-container"
                onMouseEnter={(e) => handleMouseEnter('somCagr', e)}
                onMouseLeave={handleMouseLeave}
              >
                <motion.div 
                  key={`som-bar-${marketKey}`}
                  className={`cagr-bar ${index === 0 ? 'som-bar' : 'som-bar-secondary'}`}
                  style={{ 
                    backgroundColor: colors.som,
                    width: initialAnimationComplete ? `${normalizeCAGR(market.som.cagr)}%` : 0
                  }}
                  initial={false}
                  animate={!initialAnimationComplete ? { width: `${normalizeCAGR(market.som.cagr)}%` } : {}}
                  transition={{ duration: 0.5 }}
                />
              </div>
              <span className="cagr-value">{market.som.cagr}%</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Card 
      className="market-matrix-viewer" 
      sx={{backgroundColor: 'var(--color-surface)'}}
      onMouseMove={handleMouseMove}
    >
      <CardContent>
        <div className="visibility-controls">
          <FormControlLabel
            control={
              <Checkbox
                checked={visibleLayers.tam}
                onChange={() => handleLayerToggle('tam')}
                onMouseEnter={(e) => handleMouseEnter('tam', e)}
                onMouseLeave={handleMouseLeave}
                sx={{
                  color: '#FED38C',
                  '&.Mui-checked': {
                    color: '#FED38C',
                  },
                }}
              />
            }
            label="TAM"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={visibleLayers.sam}
                onChange={() => handleLayerToggle('sam')}
                onMouseEnter={(e) => handleMouseEnter('sam', e)}
                onMouseLeave={handleMouseLeave}
                sx={{
                  color: '#FDB544',
                  '&.Mui-checked': {
                    color: '#FDB544',
                  },
                }}
              />
            }
            label="SAM"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={visibleLayers.som}
                onChange={() => handleLayerToggle('som')}
                onMouseEnter={(e) => handleMouseEnter('som', e)}
                onMouseLeave={handleMouseLeave}
                sx={{
                  color: '#FD9800',
                  '&.Mui-checked': {
                    color: '#FD9800',
                  },
                }}
              />
            }
            label="SOM"
          />
        </div>

        <div className="markets-grid">
          {markets.map((market, index) => {
            return (
              <MarketCircleWithCagr
                key={`market_${index}`}
                marketKey={`market_${index}`}
                market={market}
                index={index}
                visibleLayers={visibleLayers}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                initialAnimationComplete={initialAnimationComplete}
              />
            )
          })}
        </div>

        <div className="legend-container">
          <div className="market-size-legend">
            <div 
              className="legend-item"
              onMouseEnter={(e) => handleMouseEnter('tam', e)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="legend-circle tam-legend"></div>
              <span>TAM</span>
            </div>
            <div 
              className="legend-item"
              onMouseEnter={(e) => handleMouseEnter('sam', e)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="legend-circle sam-legend"></div>
              <span>SAM</span>
            </div>
            <div 
              className="legend-item"
              onMouseEnter={(e) => handleMouseEnter('som', e)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="legend-circle som-legend"></div>
              <span>SOM</span>
            </div>
          </div>

          <div 
            className="cagr-legend"
            onMouseEnter={(e) => handleMouseEnter('cagr', e)}
            onMouseLeave={handleMouseLeave}
          >
            CAGR bars show relative growth rates (min: {minCagr.toFixed(1)}% to max: {maxCagr.toFixed(1)}%)
            Bar length indicates relative growth rate. Longer bars = higher growth.
          </div>
        </div>

        {/* Cursor Toast */}
        {toast.show && (
          <div 
            className="cursor-toast"
            style={{
              position: 'fixed',
              left: toast.x,
              top: toast.y,
              transform: `translate(0, -50%)`,
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              padding: '8px 12px',
              borderRadius: '4px',
              fontSize: '14px',
              maxWidth: '300px',
              zIndex: 1000,
              pointerEvents: 'none'
            }}
          >
            {toast.text}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

const Glow21Processor = ({ data, onComplete, sessionId }) => {
  const process = processConfig.processes.find(p => p.id === 'glow_21');
  
  // console.log('Glow21Processor received data:', data);

  // Data comes in directly, not wrapped
  const marketData = data?.glow_21_response || data?.market_analysis || data;
  const hasRealData = marketData?.markets;  // Check for markets array
  const displayData = hasRealData ? { glow_21_response: marketData } : SAMPLE_GLOW_21;
  const isDemo = !hasRealData;

  return (
    <Box>
      {isDemo && (
        <Box sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) rotate(-45deg)',
          color: 'rgba(255, 140, 0, 0.45)',
          fontSize: '48px',
          fontWeight: 'bold',
          pointerEvents: 'none',
          zIndex: 9999,
          width: '100%',
          textAlign: 'center',
          userSelect: 'none',
          textTransform: 'uppercase'
        }}>
          Sample (Glowtest)
        </Box>
      )}
      <ViewerHeader 
        title={process.info.title}
        subtitle={process.info.description}
        processId="glow_21"
        sessionId={sessionId}
        showRerun={!!process.endpoints.run}
      />
      <MarketMatrixViewer data={displayData.glow_21_response} />
      <ViewFooter
        isDisabled={!sessionId} 
        onComplete={onComplete}
        buttonText={process?.footer?.continueText || "Continue"}
        processId="glow_21"
      />
    </Box>
  );
};

export default Glow21Processor;
