import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Card, 
  CardContent, 
  CardMedia, 
  Grid, 
  Chip, 
  LinearProgress, 
  TextField, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Divider,
  IconButton,
  Tooltip,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert
} from '@mui/material';
import axios from 'axios';
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import { 
  RefreshCw, 
  Search, 
  ArrowRight, 
  Video, 
  Zap, 
  DollarSign, 
  CheckCircle, 
  X,
  Info,
  ExternalLink,
  Check,
  Send
} from 'lucide-react';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import processConfig from '../../config/processIndex.json';
import { isFeatureEnabled } from '../../config/featureFlags';
import { 
  API_BASE_URL, 
  NEWS_API_PRICE_PER_REQUEST, 
  VIDEO_PRODUCTION_BASE_PRICE, 
  VIDEO_PRODUCTION_LENGTH_MULTIPLIER,
  GNEWS_API_SEARCH_URL,
  GNEWS_API_HEADLINES_URL,
  GNEWS_API_KEY,
  DEFAULT_FREE_TIER_REQUESTS
} from '../../config/constants';
import { VIDEO_INDEX } from '../../config/videoIndex';

// Import this to fix NODE_ENV not defined issue
const isDevelopment = process.env.NODE_ENV === 'development';

const Glow38ProcessorNewsVideo = ({ data, onComplete, sessionId, showNotice, trackProcessApiStatus }) => {
  // Check if the Auto Video Farm feature is enabled
  const isAutoVideoFarmEnabled = isFeatureEnabled('ENABLE_AUTO_VIDEO_FARM', true);
  
  const [newsArticles, setNewsArticles] = useState([]);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [isLoadingNews, setIsLoadingNews] = useState(false);
  const [newsCategory, setNewsCategory] = useState('general');
  const [searchQuery, setSearchQuery] = useState('');
  const [lastRefreshed, setLastRefreshed] = useState(null);
  const [remainingRequests, setRemainingRequests] = useState(DEFAULT_FREE_TIER_REQUESTS); // Free tier default
  const [processingArticle, setProcessingArticle] = useState(null);
  const [videoPreviewOpen, setVideoPreviewOpen] = useState(false);
  const [videoPreviewURL, setVideoPreviewURL] = useState('');
  const [estimatedCosts, setEstimatedCosts] = useState({
    newsApi: 0,
    videoProduction: 0,
    total: 0
  });
  const [isGeneratingVideo, setIsGeneratingVideo] = useState(false);
  const [generatedVideos, setGeneratedVideos] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedVideoType, setSelectedVideoType] = useState('ii'); // Default to Industry Insights
  const [selectedCategory, setSelectedCategory] = useState('jou'); // Default to Journalistic
  const [isSubmittingToLLM, setIsSubmittingToLLM] = useState(false);
  const [llmResponse, setLlmResponse] = useState(null);
  
  const process = processConfig.processes.find(p => p.id === 'glow_38');
  
  // Get available video types from VIDEO_INDEX
  const videoCategories = Object.entries(VIDEO_INDEX.categories).map(([id, category]) => ({
    id,
    name: category.name
  }));
  
  const getVideoTypesForCategory = (categoryId) => {
    const category = VIDEO_INDEX.categories[categoryId];
    if (!category) return [];
    
    return Object.entries(category.types)
      .filter(([_, type]) => type.status === 2) // Only show available types
      .map(([id, type]) => ({
        id,
        name: type.name,
        duration: type.dur
      }));
  };
  
  const videoTypes = getVideoTypesForCategory(selectedCategory);
  
  // Categories available in GNews API
  const categories = [
    { value: 'general', label: 'General' },
    { value: 'world', label: 'World' },
    { value: 'nation', label: 'Nation' },
    { value: 'business', label: 'Business' },
    { value: 'technology', label: 'Technology' },
    { value: 'entertainment', label: 'Entertainment' },
    { value: 'sports', label: 'Sports' },
    { value: 'science', label: 'Science' },
    { value: 'health', label: 'Health' }
  ];

  // Fetch news on component mount
  useEffect(() => {
    // Only fetch news if the Auto Video Farm feature is enabled
    if (isAutoVideoFarmEnabled) {
      fetchNews();
      
      // Track API as complete for this processor
      if (trackProcessApiStatus) {
        trackProcessApiStatus('glow_38', 'initialLoad', true, true);
      }
    } else {
      // Show a notification if the feature is disabled
      showNotice('Auto Video Farm feature is currently disabled.', 'warning');
    }
  }, [isAutoVideoFarmEnabled]);
  
  // Update cost estimates when selected articles change
  useEffect(() => {
    updateCostEstimates();
  }, [selectedArticles]);
  
  // Update video types when category changes
  useEffect(() => {
    const types = getVideoTypesForCategory(selectedCategory);
    if (types.length > 0) {
      setSelectedVideoType(types[0].id);
    }
  }, [selectedCategory]);
  
  // Function to fetch news from GNews API
  const fetchNews = async (category = newsCategory, query = searchQuery) => {
    // Check if the feature is enabled
    if (!isAutoVideoFarmEnabled) {
      showNotice('Auto Video Farm feature is currently disabled.', 'warning');
      return;
    }
    
    setIsLoadingNews(true);
    setErrorMessage('');
    
    try {
      // Use the API key directly from constants which references the environment variable
      const apiKey = GNEWS_API_KEY;
      
      // Check if we have a valid API key
      if (!apiKey || apiKey === 'YOUR_API_KEY_HERE' || apiKey === 'YOUR_GNEWS_API_KEY_HERE') {
        console.warn('No valid GNews API key found. Using mock data.');
        // Use mock data
        const mockResponse = {
          data: {
            articles: generateMockNewsArticles(category, query),
            totalArticles: 10
          }
        };
        
        const articles = mockResponse.data.articles.map((article, index) => ({
          id: `gnews-${Date.now()}-${index}`,
          title: article.title,
          description: article.description,
          url: article.url,
          image: article.image,
          publishedAt: article.publishedAt,
          category: [category],
          author: article.source?.name || 'Unknown'
        }));
        
        setNewsArticles(articles);
        setLastRefreshed(new Date());
        return;
      }
      
      // Build the GNews API URL according to documentation
      let url;
      if (query) {
        // Use search endpoint if query is provided
        url = `${GNEWS_API_SEARCH_URL}?q=${encodeURIComponent(query)}&lang=en&apikey=${apiKey}`;
      } else {
        // Use top-headlines endpoint with category
        url = `${GNEWS_API_HEADLINES_URL}?category=${category || 'general'}&lang=en&apikey=${apiKey}`;
      }
      
      // Add max parameter to limit results
      url += '&max=10';
      
      console.log('Fetching news from URL:', url.replace(apiKey, '********')); // Log URL with hidden API key
      
      // Make the actual API call
      const response = await axios.get(url);
      
      if (response.data && response.data.articles) {
        // Transform GNews response to our internal format
        const articles = response.data.articles.map((article, index) => ({
          id: `gnews-${Date.now()}-${index}`,
          title: article.title,
          description: article.description,
          url: article.url,
          image: article.image,
          publishedAt: article.publishedAt,
          category: [category || 'general'],
          author: article.source?.name || 'Unknown'
        }));
        
        setNewsArticles(articles);
        setLastRefreshed(new Date());
        setRemainingRequests(prev => Math.max(0, prev - 1));
      } else {
        throw new Error('Invalid API response format');
      }
    } catch (error) {
      console.error('Error fetching news:', error);
      setErrorMessage(`Failed to fetch news: ${error.message || 'Unknown error'}`);
      showNotice('Failed to fetch news data.', 'error');
      
      // Fallback to mock data on error
      const mockArticles = generateMockNewsArticles(category, query).map((article, index) => ({
        id: `gnews-${Date.now()}-${index}`,
        title: article.title,
        description: article.description,
        url: article.url,
        image: article.image,
        publishedAt: article.publishedAt,
        category: [category],
        author: article.source?.name || 'Unknown'
      }));
      
      setNewsArticles(mockArticles);
      setLastRefreshed(new Date());
    } finally {
      setIsLoadingNews(false);
    }
  };
  
  // Handle article selection
  const handleArticleSelect = (article) => {
    setSelectedArticles(prev => {
      const isSelected = prev.some(a => a.id === article.id);
      
      if (isSelected) {
        return prev.filter(a => a.id !== article.id);
      } else {
        return [...prev, article];
      }
    });
  };
  
  // Submit selected articles to glow_38 API
  const submitToLLM = async () => {
    // Check if the feature is enabled
    if (!isAutoVideoFarmEnabled) {
      showNotice('Auto Video Farm feature is currently disabled.', 'warning');
      return;
    }
    
    if (selectedArticles.length === 0) {
      showNotice('Please select at least one news article', 'warning');
      return;
    }
    
    setIsSubmittingToLLM(true);
    setErrorMessage('');
    
    try {
      // Get selected video type information
      const videoCategory = VIDEO_INDEX.categories[selectedCategory];
      const videoType = videoCategory?.types[selectedVideoType];
      
      // Create the data structure with all the details
      const promptData = {
        articles: selectedArticles.map(article => ({
          title: article.title,
          description: article.description,
          url: article.url,
          source: article.author,
          publishedAt: article.publishedAt
        })),
        videoType: {
          category: selectedCategory,
          categoryName: videoCategory?.name || '',
          type: selectedVideoType,
          typeName: videoType?.name || '',
          duration: videoType?.dur || '3m'
        }
      };
      
      // Convert the data to a JSON string for input_prompt
      const inputPrompt = JSON.stringify(promptData);
      
      // Get the session ID from cookies if not provided through props
      const sessionIdFromCookie = sessionId || Cookies.get('session_id') || '';
      
      // Prepare the simplified payload for glow_38 API
      const payload = {
        session_id: sessionIdFromCookie,
        input_prompt: inputPrompt
      };
      
      console.log('Submitting to glow_38 API with payload:', payload);
      
      if (!sessionIdFromCookie) {
        console.warn('No session ID available. This may cause the API call to fail.');
        showNotice('Missing session ID. Please reload the page or create a new session.', 'warning');
      }
      
      // Call the backend API
      const response = await axios.post(`${API_BASE_URL}/api/v1/glow_dash/glow_38_news_video`, payload);
      
      if (response.data) {
        setLlmResponse(response.data);
        showNotice('Successfully processed news articles', 'success');
        
        // Add the generated video to the list
        const newVideo = {
          id: `video-${Date.now()}`,
          title: `${videoType?.name} - ${selectedArticles[0].title}`,
          description: `Generated from ${selectedArticles.length} news articles`,
          sourceArticles: selectedArticles,
          createdAt: new Date().toISOString(),
          videoUrl: response.data.videoUrl || 'https://example.com/sample-video.mp4',
          thumbnailUrl: selectedArticles[0].image || 'https://via.placeholder.com/400x200?text=Video+Thumbnail',
          duration: videoType?.dur || '3m',
          status: 'completed',
          llmResponse: response.data
        };
        
        setGeneratedVideos(prev => [newVideo, ...prev]);
        
        // Clear selected articles
        setSelectedArticles([]);
        
        // Track API as complete
        if (trackProcessApiStatus) {
          trackProcessApiStatus('glow_38', 'primaryLoad', true, true);
        }
      } else {
        throw new Error('Invalid API response');
      }
    } catch (error) {
      console.error('Error submitting to LLM:', error);
      
      // More detailed error message based on the error type
      let errorMsg = 'Failed to process with LLM. Please try again later.';
      
      if (error.code === 'ERR_NETWORK') {
        errorMsg = 'Network error: Backend server might be unavailable. Please ensure the backend service is running.';
      } else if (error.response) {
        // Server responded with an error status code
        if (error.response.status === 401) {
          errorMsg = 'Authentication error: Your session may have expired. Please reload the page.';
        } else if (error.response.status === 400) {
          errorMsg = `Bad request: ${error.response.data?.message || 'Please check your input.'}`;
        } else {
          errorMsg = `Server error (${error.response.status}): ${error.response.data?.message || 'Unknown server error.'}`;
        }
      }
      
      setErrorMessage(errorMsg);
      showNotice(errorMsg, 'error');
      
      // Track API as complete even on error
      if (trackProcessApiStatus) {
        trackProcessApiStatus('glow_38', 'primaryLoad', true, true);
      }
    } finally {
      setIsSubmittingToLLM(false);
    }
  };
  
  // Handle video preview
  const handlePreviewVideo = (videoUrl) => {
    setVideoPreviewURL(videoUrl);
    setVideoPreviewOpen(true);
  };
  
  // Update cost estimates
  const updateCostEstimates = () => {
    // News API cost (already incurred)
    const newsCost = NEWS_API_PRICE_PER_REQUEST;
    
    // Video production cost (based on selected articles)
    let videoCost = 0;
    selectedArticles.forEach(article => {
      // Base cost plus length multiplier (estimated length based on article length)
      const estimatedLength = Math.max(1, Math.min(5, Math.ceil(article.description.length / 500)));
      videoCost += VIDEO_PRODUCTION_BASE_PRICE + (estimatedLength * VIDEO_PRODUCTION_LENGTH_MULTIPLIER);
    });
    
    setEstimatedCosts({
      newsApi: newsCost,
      videoProduction: videoCost,
      total: newsCost + videoCost
    });
  };
  
  // Helper function to generate a mock response
  const generateMockNewsArticles = (category, query) => {
    const baseArticles = [
      {
        title: 'SpaceX Successfully Launches Starship Prototype',
        description: 'SpaceX has successfully launched and landed the latest Starship prototype, marking a significant milestone in the development of the spacecraft designed to carry humans to the Moon and Mars.',
        url: 'https://example.com/spacex-news',
        image: 'https://via.placeholder.com/400x200?text=SpaceX+Launch',
        publishedAt: '2023-08-15T14:30:00Z',
        source: { name: 'Tech Reporter' }
      },
      {
        title: 'Global Markets React to Economic Report',
        description: 'Global markets showed mixed reactions today following the release of a major economic report that indicated slower than expected growth in several key economies.',
        url: 'https://example.com/market-news',
        image: 'https://via.placeholder.com/400x200?text=Global+Markets',
        publishedAt: '2023-08-15T10:15:00Z',
        source: { name: 'Financial Analyst' }
      },
      {
        title: 'New AI Research Breakthrough in Natural Language Processing',
        description: 'Researchers have announced a significant breakthrough in natural language processing that could revolutionize how AI systems understand and generate human language.',
        url: 'https://example.com/ai-news',
        image: 'https://via.placeholder.com/400x200?text=AI+Research',
        publishedAt: '2023-08-14T16:45:00Z',
        source: { name: 'Tech Journal' }
      },
      {
        title: 'Climate Summit Reaches New Agreement on Emissions',
        description: 'World leaders at the latest climate summit have reached a new agreement on reducing carbon emissions, with ambitious targets set for the next decade.',
        url: 'https://example.com/climate-news',
        image: 'https://via.placeholder.com/400x200?text=Climate+Summit',
        publishedAt: '2023-08-14T09:30:00Z',
        source: { name: 'Environmental Reporter' }
      },
      {
        title: 'New Health Study Reveals Benefits of Mediterranean Diet',
        description: 'A comprehensive new study has revealed significant health benefits associated with following a Mediterranean diet, including reduced risk of heart disease and improved longevity.',
        url: 'https://example.com/health-news',
        image: 'https://via.placeholder.com/400x200?text=Health+Study',
        publishedAt: '2023-08-13T11:20:00Z',
        source: { name: 'Health Correspondent' }
      }
    ];
    
    // Filter by category if specified
    let filteredArticles = baseArticles;
    
    // Filter by search query if specified
    if (query) {
      const lowerQuery = query.toLowerCase();
      filteredArticles = filteredArticles.filter(article => 
        article.title.toLowerCase().includes(lowerQuery) || 
        article.description.toLowerCase().includes(lowerQuery)
      );
    }
    
    return filteredArticles;
  };
  
  return (
    <Box>
      <ViewerHeader 
        title={process?.info?.title || "Auto Video Production"}
        subtitle={process?.info?.description || "Turn trending news into engaging videos automatically"}
        processId="glow_38"
        sessionId={sessionId}
      />
      
      {!isAutoVideoFarmEnabled ? (
        <Alert 
          severity="warning" 
          sx={{ mb: 3, mt: 2 }}
          action={
            <Button 
              color="inherit" 
              size="small"
              onClick={() => onComplete()}
            >
              Continue
            </Button>
          }
        >
          The Auto Video Farm feature is currently disabled. Please proceed to the next step.
        </Alert>
      ) : (
        <Grid container spacing={3}>
          {/* News API Section */}
          <Grid item xs={12}>
            <Paper 
              elevation={0} 
              sx={{ 
                p: 3, 
                mb: 3, 
                border: '1px solid var(--color-border)',
                backgroundColor: 'var(--color-background)'
              }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">Trending News</Typography>
                <Box>
                  <Chip 
                    icon={<Zap size={16} />}
                    label={`${remainingRequests} requests remaining today`}
                    variant="outlined"
                    sx={{ mr: 1 }}
                  />
                  <Tooltip title="Refresh news feed">
                    <IconButton 
                      onClick={() => fetchNews(newsCategory, searchQuery)}
                      disabled={isLoadingNews}
                    >
                      <RefreshCw size={20} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              
              <Box display="flex" flexWrap="wrap" gap={2} mb={3}>
                <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
                  <InputLabel>Category</InputLabel>
                  <Select
                    value={newsCategory}
                    onChange={(e) => setNewsCategory(e.target.value)}
                    label="Category"
                  >
                    {categories.map((category) => (
                      <MenuItem key={category.value} value={category.value}>
                        {category.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                
                <TextField
                  placeholder="Search news..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  size="small"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButton 
                        size="small" 
                        onClick={() => fetchNews(newsCategory, searchQuery)}
                      >
                        <Search size={20} />
                      </IconButton>
                    )
                  }}
                  sx={{ flexGrow: 1 }}
                />
              </Box>
              
              {lastRefreshed && (
                <Typography variant="caption" display="block" mb={2}>
                  Last refreshed: {format(new Date(lastRefreshed), 'MMM d, yyyy HH:mm:ss')}
                </Typography>
              )}
              
              {isLoadingNews && <LinearProgress sx={{ mb: 2 }} />}
              
              {errorMessage && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {errorMessage}
                </Alert>
              )}
              
              <Grid container spacing={2}>
                {newsArticles.length === 0 ? (
                  <Grid item xs={12}>
                    <Box 
                      display="flex" 
                      alignItems="center" 
                      justifyContent="center" 
                      height="200px"
                      border="1px dashed var(--color-border)"
                      borderRadius={1}
                    >
                      <Typography color="textSecondary">
                        {isLoadingNews ? 'Loading news...' : 'No news articles found'}
                      </Typography>
                    </Box>
                  </Grid>
                ) : (
                  newsArticles.map((article) => {
                    const isSelected = selectedArticles.some(a => a.id === article.id);
                    const isProcessing = processingArticle === article.id;
                    
                    return (
                      <Grid item xs={12} sm={6} md={4} key={article.id}>
                        <Card 
                          sx={{ 
                            height: '100%', 
                            display: 'flex', 
                            flexDirection: 'column',
                            border: isSelected ? '2px solid var(--color-primary)' : '1px solid var(--color-border)',
                            bgcolor: 'var(--color-background)',
                            position: 'relative',
                            cursor: 'pointer',
                            '&:hover': {
                              boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                            }
                          }}
                          onClick={() => handleArticleSelect(article)}
                        >
                          {isSelected && (
                            <Tooltip title="Selected for video creation">
                              <CheckCircle 
                                style={{ 
                                  position: 'absolute', 
                                  top: 8, 
                                  right: 8, 
                                  color: 'var(--color-primary)',
                                  zIndex: 1
                                }} 
                              />
                            </Tooltip>
                          )}
                          
                          <CardMedia
                            component="img"
                            height="140"
                            image={article.image || 'https://via.placeholder.com/400x200?text=News'}
                            alt={article.title}
                          />
                          
                          <CardContent sx={{ flexGrow: 1 }}>
                            <Typography gutterBottom variant="h6" component="div" sx={{ fontSize: '1rem' }}>
                              {article.title}
                            </Typography>
                            
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                              {article.description.length > 120 
                                ? `${article.description.substring(0, 120)}...` 
                                : article.description}
                            </Typography>
                            
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                              <Typography variant="caption" color="text.secondary">
                                {format(new Date(article.publishedAt), 'MMM d, yyyy')}
                              </Typography>
                              
                              <Box>
                                <Tooltip title="Visit article">
                                  <IconButton 
                                    size="small" 
                                    href={article.url} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    onClick={(e) => e.stopPropagation()} // Prevent card selection when clicking link
                                  >
                                    <ExternalLink size={16} />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                            
                            <Box mt={2}>
                              <Button
                                variant={isSelected ? "outlined" : "contained"}
                                color={isSelected ? "error" : "primary"}
                                size="small"
                                fullWidth
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent double handling
                                  handleArticleSelect(article);
                                }}
                                startIcon={isSelected ? <X size={16} /> : <Video size={16} />}
                                disabled={isProcessing}
                              >
                                {isSelected ? "Deselect" : "Select for Video"}
                              </Button>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })
                )}
              </Grid>
            </Paper>
          </Grid>
          
          {/* Selected Articles & Video Type Section */}
          <Grid item xs={12} md={6}>
            <Paper 
              elevation={0} 
              sx={{ 
                p: 3, 
                height: '100%',
                border: '1px solid var(--color-border)',
                backgroundColor: 'var(--color-background)'
              }}
            >
              <Typography variant="h6" mb={2}>Selected Articles ({selectedArticles.length})</Typography>
              
              {selectedArticles.length === 0 ? (
                <Box 
                  display="flex" 
                  alignItems="center" 
                  justifyContent="center" 
                  height="200px"
                  border="1px dashed var(--color-border)"
                  borderRadius={1}
                >
                  <Typography color="textSecondary">
                    Select news articles to create videos
                  </Typography>
                </Box>
              ) : (
                <>
                  <Box sx={{ maxHeight: 300, overflow: 'auto', mb: 2 }}>
                    {selectedArticles.map((article) => (
                      <Box 
                        key={article.id} 
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          mb: 2,
                          p: 1,
                          border: '1px solid var(--color-border)',
                          borderRadius: 1
                        }}
                      >
                        <Box 
                          component="img" 
                          src={article.image || 'https://via.placeholder.com/80x60?text=News'} 
                          sx={{ width: 80, height: 60, mr: 2, borderRadius: 1 }}
                        />
                        
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="body2" fontWeight="medium">
                            {article.title.length > 60 
                              ? `${article.title.substring(0, 60)}...` 
                              : article.title}
                          </Typography>
                          
                          <Typography variant="caption" color="text.secondary">
                            {article.description.length > 80 
                              ? `${article.description.substring(0, 80)}...` 
                              : article.description}
                          </Typography>
                        </Box>
                        
                        <IconButton 
                          size="small" 
                          onClick={() => handleArticleSelect(article)}
                        >
                          <X size={16} />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                  
                  {/* Video Type Selection */}
                  <Box mb={3}>
                    <Typography variant="subtitle2" mb={1}>Video Category & Type</Typography>
                    
                    <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                      <InputLabel>Video Category</InputLabel>
                      <Select
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        label="Video Category"
                      >
                        {videoCategories.map((category) => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    
                    <FormControl fullWidth size="small">
                      <InputLabel>Video Type</InputLabel>
                      <Select
                        value={selectedVideoType}
                        onChange={(e) => setSelectedVideoType(e.target.value)}
                        label="Video Type"
                      >
                        {videoTypes.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.name} ({type.duration})
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={selectedArticles.length === 0 || isSubmittingToLLM}
                    onClick={submitToLLM}
                    startIcon={<Send />}
                  >
                    {isSubmittingToLLM ? 'Processing...' : 'Process with Glow 38'}
                  </Button>
                </>
              )}
            </Paper>
          </Grid>
          
          {/* Cost Estimation Section */}
          <Grid item xs={12} md={6}>
            <Paper 
              elevation={0} 
              sx={{ 
                p: 3, 
                height: '100%',
                border: '1px solid var(--color-border)',
                backgroundColor: 'var(--color-background)'
              }}
            >
              <Typography variant="h6" mb={2}>Cost Estimation</Typography>
              
              <Box sx={{ mb: 3 }}>
                <Box display="flex" justifyContent="space-between" mb={1}>
                  <Typography variant="body2">News API Request:</Typography>
                  <Typography variant="body2">${NEWS_API_PRICE_PER_REQUEST.toFixed(5)}</Typography>
                </Box>
                
                <Box display="flex" justifyContent="space-between" mb={1}>
                  <Typography variant="body2">
                    Video Production ({selectedArticles.length} video{selectedArticles.length !== 1 ? 's' : ''}):
                  </Typography>
                  <Typography variant="body2">${estimatedCosts.videoProduction.toFixed(2)}</Typography>
                </Box>
                
                <Divider sx={{ my: 2 }} />
                
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="subtitle1" fontWeight="bold">Total Estimated Cost:</Typography>
                  <Typography variant="subtitle1" fontWeight="bold" color="primary">
                    ${estimatedCosts.total.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
              
              <Alert severity="info" sx={{ mb: 2 }}>
                <Box display="flex" alignItems="center">
                  <Info size={20} style={{ marginRight: 8 }} />
                  <Typography variant="body2">
                    Video production costs are based on length and complexity. Longer articles typically result in longer videos and higher costs.
                  </Typography>
                </Box>
              </Alert>
              
              <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 1 }}>
                <Typography variant="subtitle2" gutterBottom>Cost Breakdown:</Typography>
                <Typography variant="body2">• ${VIDEO_PRODUCTION_BASE_PRICE.toFixed(2)} base cost per video</Typography>
                <Typography variant="body2">• ${VIDEO_PRODUCTION_LENGTH_MULTIPLIER.toFixed(2)} per estimated minute of content</Typography>
                <Typography variant="body2">• ${NEWS_API_PRICE_PER_REQUEST.toFixed(5)} per news API request (GNews)</Typography>
              </Box>
            </Paper>
          </Grid>
          
          {/* Generated Videos Section */}
          <Grid item xs={12}>
            <Paper 
              elevation={0} 
              sx={{ 
                p: 3, 
                border: '1px solid var(--color-border)',
                backgroundColor: 'var(--color-background)'
              }}
            >
              <Typography variant="h6" mb={2}>Generated Videos</Typography>
              
              {generatedVideos.length === 0 ? (
                <Box 
                  display="flex" 
                  alignItems="center" 
                  justifyContent="center" 
                  height="200px"
                  border="1px dashed var(--color-border)"
                  borderRadius={1}
                >
                  <Typography color="textSecondary">
                    No videos generated yet
                  </Typography>
                </Box>
              ) : (
                <Grid container spacing={2}>
                  {generatedVideos.map((video) => (
                    <Grid item xs={12} sm={6} md={4} key={video.id}>
                      <Card sx={{ 
                        height: '100%', 
                        display: 'flex', 
                        flexDirection: 'column',
                        border: '1px solid var(--color-border)',
                        bgcolor: 'var(--color-background)'
                      }}>
                        <Box position="relative">
                          <CardMedia
                            component="img"
                            height="140"
                            image={video.thumbnailUrl}
                            alt={video.title}
                            sx={{ cursor: 'pointer' }}
                            onClick={() => handlePreviewVideo(video.videoUrl)}
                          />
                          <Box
                            position="absolute"
                            top="50%"
                            left="50%"
                            sx={{
                              transform: 'translate(-50%, -50%)',
                              bgcolor: 'rgba(0,0,0,0.5)',
                              borderRadius: '50%',
                              width: 50,
                              height: 50,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              cursor: 'pointer'
                            }}
                            onClick={() => handlePreviewVideo(video.videoUrl)}
                          >
                            <ArrowRight size={24} color="#fff" />
                          </Box>
                        </Box>
                        
                        <CardContent sx={{ flexGrow: 1 }}>
                          <Typography gutterBottom variant="h6" component="div" sx={{ fontSize: '1rem' }}>
                            {video.title}
                          </Typography>
                          
                          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                            {video.description.length > 120 
                              ? `${video.description.substring(0, 120)}...` 
                              : video.description}
                          </Typography>
                          
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Chip 
                              icon={<Check size={16} />}
                              label="Completed"
                              size="small"
                              color="success"
                              variant="outlined"
                            />
                            
                            <Typography variant="caption" color="text.secondary">
                              {format(new Date(video.createdAt), 'MMM d, yyyy')}
                            </Typography>
                          </Box>
                          
                          <Box mt={2}>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              fullWidth
                              startIcon={<Video size={16} />}
                              onClick={() => handlePreviewVideo(video.videoUrl)}
                            >
                              Preview Video
                            </Button>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      )}
      
      {/* Video Preview Dialog */}
      <Dialog
        open={videoPreviewOpen}
        onClose={() => setVideoPreviewOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Video Preview
          <IconButton
            onClick={() => setVideoPreviewOpen(false)}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <X size={20} />
          </IconButton>
        </DialogTitle>
        
        <DialogContent>
          <Box 
            component="video"
            controls
            autoPlay
            width="100%"
            sx={{ minHeight: 400 }}
          >
            <source src={videoPreviewURL} type="video/mp4" />
            Your browser does not support the video tag.
          </Box>
        </DialogContent>
      </Dialog>
      
      <ViewFooter
        onComplete={() => onComplete()}
        buttonText="Complete Auto Video Production"
        isDisabled={false}
      />
    </Box>
  );
};

export default Glow38ProcessorNewsVideo; 