import React from 'react';
import { Helmet } from 'react-helmet-async';

/**
 * SEO component for managing document head tags
 * 
 * @param {Object} props
 * @param {string} props.title - Page title
 * @param {string} props.description - Page description
 * @param {string} props.canonical - Canonical URL
 * @param {Array} props.meta - Additional meta tags
 * @param {Object} props.openGraph - OpenGraph metadata
 * @param {string} props.lang - Page language
 */
const SEO = ({
  title = 'Glowtest - AI Video Creator & Business Idea Validation Platform',
  description = 'Create professional videos from text and validate business ideas before investing. No filming needed, instant results for startups, content creators, and product teams.',
  canonical = '',
  meta = [],
  openGraph = {},
  lang = 'en'
}) => {
  const defaultOpenGraph = {
    title: title,
    description: description,
    type: 'website',
    url: canonical || 'https://glowtest.it',
    image: 'https://glowtest.it/images/og-image.png',
    ...openGraph
  };

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      link={canonical ? [{ rel: 'canonical', href: canonical }] : []}
      meta={[
        { name: 'description', content: description },
        { name: 'robots', content: 'index,follow' },
        { name: 'keywords', content: 'AI video creator, text to video, business idea validation, product validation, startup validation, content testing, social media validation, market fit analysis, video without filming, AI content generator, business prototype, startup idea testing, product feature testing, quick video creation' },
        
        // OpenGraph tags
        { property: 'og:title', content: defaultOpenGraph.title },
        { property: 'og:description', content: defaultOpenGraph.description },
        { property: 'og:type', content: defaultOpenGraph.type },
        { property: 'og:url', content: defaultOpenGraph.url },
        { property: 'og:image', content: defaultOpenGraph.image },
        
        // Twitter Card tags
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: title },
        { name: 'twitter:description', content: description },
        { name: 'twitter:image', content: defaultOpenGraph.image },
        
        // Additional meta tags
        ...meta
      ]}
    />
  );
};

export default SEO; 