import React from 'react';
import { CheckCircle, AlertTriangle } from 'lucide-react';

const ValidationMethodsTable = ({ handleShowPricing }) => {
  const methods = [
    {
      name: 'Traditional Surveys',
      description: 'Basic questionnaire-based feedback collection',
      pros: ['Simple to implement', 'Direct feedback', 'Cost-effective'],
      cons: ['Low response rates', 'Limited insights', 'Time-consuming analysis'],
      time: '2-4 weeks',
      cost: '$500-2k',
      success: '30%'
    },
    {
      name: 'Focus Groups',
      description: 'In-depth group discussions with target audience',
      pros: ['Rich qualitative data', 'Interactive feedback', 'Detailed insights'],
      cons: ['Expensive', 'Limited sample size', 'Group bias'],
      time: '3-6 weeks',
      cost: '$3k-8k',
      success: '45%'
    },
    {
      name: 'Market Research Reports',
      description: 'Pre-existing industry analysis and trends',
      pros: ['Comprehensive data', 'Industry context', 'Historical trends'],
      cons: ['Outdated information', 'Generic insights', 'High cost'],
      time: '1-2 weeks',
      cost: '$2k-5k',
      success: '40%'
    },
    {
      name: 'AI-Powered Validation',
      description: 'Machine learning-based market analysis and prediction',
      pros: ['Rapid insights', 'Data-driven decisions', 'Cost-effective'],
      cons: ['Requires data literacy', 'AI dependency', 'Market skepticism'],
      time: '1-3 days',
      cost: '$200-1k',
      success: '85%'
    }
  ];

  return (
    <section className="py-16 mb-20">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold mb-4">
          Validation Methods Comparison
        </h2>
        <p className="text-zinc-300 max-w-2xl mx-auto">
          Choose the right validation approach for your business
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {methods.map((method, index) => (
          <div 
            key={index}
            className="bg-zinc-800/30 rounded-lg p-6 flex flex-col"
          >
            <h3 className="text-xl font-bold mb-2">{method.name}</h3>
            <p className="text-zinc-400 text-sm mb-4">{method.description}</p>

            <div className="space-y-2 mb-4">
              <h4 className="font-semibold text-green-400">Advantages</h4>
              {method.pros.map((pro, idx) => (
                <div key={idx} className="flex items-center gap-2">
                  <CheckCircle className="text-green-400" size={16} />
                  <span className="text-sm">{pro}</span>
                </div>
              ))}
            </div>

            <div className="space-y-2 mb-4">
              <h4 className="font-semibold text-red-400">Limitations</h4>
              {method.cons.map((con, idx) => (
                <div key={idx} className="flex items-center gap-2">
                  <AlertTriangle className="text-red-400" size={16} />
                  <span className="text-sm">{con}</span>
                </div>
              ))}
            </div>

            <div className="mt-auto">
              <div className="grid grid-cols-3 gap-4 mb-4">
                <div>
                  <p className="text-sm text-zinc-400">Time</p>
                  <p className="font-semibold">{method.time}</p>
                </div>
                <div>
                  <p className="text-sm text-zinc-400">Cost</p>
                  <p className="font-semibold">{method.cost}</p>
                </div>
                <div>
                  <p className="text-sm text-zinc-400">Success Rate</p>
                  <p className="font-semibold">{method.success}</p>
                </div>
              </div>

              <button
                onClick={() => handleShowPricing()}
                className="w-full bg-zinc-700/50 hover:bg-zinc-700 text-white py-2 rounded transition-colors text-sm"
              >
                Get Started
              </button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ValidationMethodsTable; 