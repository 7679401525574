// Import the provider configuration and utils
import defaultProviderConfig from '../../config/fallback_text_to_video_config.json';
import { loadProviderConfig } from '../../utils/configLoaderUtils';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import axios from 'axios';
import Cookies from 'js-cookie';

// Components
import ViewerHeader from '../common/ViewerHeader';
import SelectionCard from './glow_61_selection_card';
import VideoMetricsDisplay from './VideoMetricsDisplay';
import MonetizationDialog from '../ui/monetization'; // Add this import

// Redux and Configuration
import { updateGlowStep } from '../../components/session/sessionSlide';
import { selectAllGlowSteps, selectGlowStep } from '../../components/session/sessionSlide';
import { selectAccountInfo } from '../../auth/authenticateSlide'; // Add this import
import processConfig from '../../config/processIndex.json';
import { VIDEO_INDEX } from '../../config/videoIndex';

// Material UI
import {
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Chip,
  Tooltip,
  Paper,
  Grid,
  Stack,
  Container,
  InputAdornment,
  Slider,
  Alert,
  AlertTitle,
  Snackbar,
  useTheme,
  Divider,
  FormControl,
  FormLabel,
  InputLabel
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import QuestionMarkIcon from '@mui/icons-material/HelpOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Info } from 'lucide-react';

// Lucide Icons
import {
  Eye,
  Filter,
  ArrowUpDown,
  Info as InfoSquare,
  Terminal,
  ChevronUp,
  ChevronDown,
  Video,
  FileVideo,
  FileText,
  Palette,
  Settings,
  Building,
  Globe,
  Play,
  RefreshCw,
  Clock,
  CheckCircle,
  AlertCircle,
  Layers
} from 'lucide-react';

// Utils
import { generateImagePath, DEFAULT_THUMBNAIL, DEFAULT_FULL_IMAGE } from '../../utils/imagePathUtils';

// Constants
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://api.example.com";
const S3_BASE_URL = `https://${process.env.REACT_APP_DEV_AWS_BUCKET_NAME || 'chat2movie'}.s3.${process.env.REACT_APP_DEV_AWS_REGION || 'us-east-1'}.amazonaws.com`;

const VIDEO_TYPES = Object.entries(VIDEO_INDEX.categories)
  .flatMap(([categoryId, category]) => 
    Object.entries(category.types)
      .filter(([_, type]) => type.status === 2)
      .map(([typeId, type]) => ({
        id: typeId.toUpperCase(),
        name: type.name,
        duration: type.dur,
        category: category.name,
        abbr: type.abbr
      }))
  );

// Add this constant for workflow types
const WORKFLOW_TYPES = {
  CW12: {
    id: 'cw12_recraft',
    name: 'High Speed Premium Pipeline',
    description: 'Recraft API & SD1.1',
    image: `${S3_BASE_URL}/pipelines/high-speed-pipeline.webp`,
    placeholderImage: `${S3_BASE_URL}/placeholders/high-speed-placeholder.webp`
  },
  CW11: {
    id: 'cw11_sd',
    name: 'Slow Pipeline',
    description: 'SD3.5 & SD1.1',
    image: `${S3_BASE_URL}/pipelines/slow-pipeline.webp`,
    placeholderImage: `${S3_BASE_URL}/placeholders/slow-pipeline-placeholder.webp`
  }
};

const STATUS_BADGES = {
  pending: { color: 'bg-yellow-100 p-1 text-yellow-800', icon: Clock },
  processing: { color: 'bg-blue-100 p-1 text-blue-800', icon: RefreshCw },
  completed: { color: 'bg-green-100 p-1 text-green-800', icon: CheckCircle },
  failed: { color: 'bg-red-100 p-1 text-red-800', icon: AlertCircle },
};

// Add voice options with preview functionality
const VOICE_OPTIONS = [
  { 
    id: 'morgan_freeman',
    name: 'Morgan Freeman',
    description: 'Deep, authoritative, trustworthy narration',
    previewUrl: `${S3_BASE_URL}/voice_samples/morgan_freeman_sample.mp3`,
    thumbnail: `${S3_BASE_URL}/voice_samples/morgan_freeman_thumbnail.webp` 
  },
  { 
    id: 'female_ref',
    name: 'Emma Wilson',
    description: 'Warm, engaging female narration',
    previewUrl: `${S3_BASE_URL}/voice_samples/female_sample.mp3`,
    thumbnail: `${S3_BASE_URL}/voice_samples/female_thumbnail.webp`
  },
  { 
    id: 'british_male',
    name: 'James Bennett',
    description: 'Sophisticated British male accent',
    previewUrl: `${S3_BASE_URL}/voice_samples/british_male_sample.mp3`,
    thumbnail: `${S3_BASE_URL}/voice_samples/british_male_thumbnail.webp`
  },
  { 
    id: 'american_female',
    name: 'Sarah Miller',
    description: 'Clear, professional American female voice',
    previewUrl: `${S3_BASE_URL}/voice_samples/american_female_sample.mp3`,
    thumbnail: `${S3_BASE_URL}/voice_samples/american_female_thumbnail.webp`
  }
];

// Define format options (copied from glow_61_processor_video_format.js)
const formatOptions = [
  { 
    value: 0, 
    label: "1:2", 
    ratio: 0.5,
  },
  { 
    value: 1, 
    label: "9:16", 
    ratio: 0.5625,
  },
  { 
    value: 2, 
    label: "3:4", 
    ratio: 0.75,
  },
  { 
    value: 3, 
    label: "4:5", 
    ratio: 0.8,
  },
  { 
    value: 4, 
    label: "1:1", 
    ratio: 1,
  },
  { 
    value: 5, 
    label: "5:4", 
    ratio: 1.25,
  },
  { 
    value: 6, 
    label: "4:3", 
    ratio: 1.33,
  },
  { 
    value: 7, 
    label: "16:9", 
    ratio: 1.77,
  },
  { 
    value: 8, 
    label: "2:1", 
    ratio: 2,
  },
  { 
    value: 9, 
    label: "21:9", 
    ratio: 2.33,
  }
];

const VideoRequestManager = ({ sessionId, showNotice }) => {  
  const process = processConfig.processes.find(p => p.id === 'glow_91');
  const theme = useTheme();
  const [newRequest, setNewRequest] = useState({
    input_prompt: '',
    session_id: sessionId,
    company_name: '',
    video_type: 'PV',
    target_video_duration: 30,
    seconds_per_shot: 2,
    shots_per_scene: 3,
    scenes_per_voiceover: 0.5,
    audience_number: 1,
    audience_list: [],
    fps: 12,
    augmentation_level: 0.02,
    width: 1280,
    height: 720,
    workflow_type: 'cw12_recraft',
    voiceover_config: {
      provider: 'kokoro',
      voice_by: 'af_heart',
      lang_code: 'en',
      speed: 1
    },
    cw11_sd_config: {
      video_sampler_steps: 30,
      video_sampler_cfg: 3.5,
      video_sampler_sampler_name: "euler_ancestral",
      video_sampler_scheduler: "karras",
      video_sampler_denoise: 1,
      sampler_steps: 40,
      sampler_cfg: 7,
      sampler_name: "dpmpp_2s_ancestral",
      sampler_scheduler: "normal",
      sampler_denoise: 1
    },
    cw12_recraft_config: {
      model: "recraftv3",
      size: "1820x1024",
      quality: "standard",
      style: "realistic_image",
      extra_body: {
        substyle: "",
        artistic_level: null,
        no_text: true
      },
      video_sampler_steps: 30,
      video_sampler_cfg: 3.5,
      video_sampler_sampler_name: "euler_ancestral",
      video_sampler_scheduler: "karras",
      video_sampler_denoise: 1
    }
  });
  
  // Add state for credit metrics and monetization dialog
  const [creditMetrics, setCreditMetrics] = useState({ userCostCredits: 0 });
  const [monetizationOpen, setMonetizationOpen] = useState(false);
  const [insufficientCreditDialog, setInsufficientCreditDialog] = useState({ open: false, required: 0, available: 0 });
  
  // Get user account info from Redux
  const accountInfo = useSelector(selectAccountInfo);
  
  // Add state for voice preview
  const [currentPlayingVoice, setCurrentPlayingVoice] = useState(null);
  const audioRef = React.useRef(null);

  // Add state for visual summary
  const [summaryData, setSummaryData] = useState({
    glow_61: null,
    glow_62: null,
    glow_63: null,
    glow_04: null,
    glow_33: null,
    glow_102: null
  });

  const [showAdvanced, setShowAdvanced] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [previewURL, setPreviewURL] = useState(null);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });
  const [successPopup, setSuccessPopup] = useState({ open: false, message: '', sessionId: '' });

  // Get Redux state
  const glowSteps = useSelector(selectAllGlowSteps) || {};
  const sessionData = useSelector(state => state.session?.data);
  const dispatch = useDispatch();

  // Add state for provider configuration
  const [providerConfig, setProviderConfig] = useState(null);
  const [fetchingConfig, setFetchingConfig] = useState(false);

  const formatDuration = (seconds) => {
    return `${seconds} seconds`;
  };

  const formatDateTime = (dateString) => {
    return format(new Date(dateString), 'MMM d, h:mm a');
  };

  const calculateVideoProgress = (video) => {
    if (!video) return 0;
    if (video.request_status === "pending") return 0;
    if (video.request_status === "completed") return 100;
    return Math.min(Math.floor(Math.random() * 100), 99); // Mocking progress for now
  };

  // Enhanced useEffect to gather data from all previous steps
  useEffect(() => {
    console.log('Glow91: Loading data from all previous steps:', glowSteps);
    
    // Load data from glow_61 (Video Format) - handle both formats
    // The data might be directly on glowSteps.glow_61 or nested under glowSteps.glow_61.glow_61_response
    const glow61DataDirect = glowSteps?.glow_61 || null;
    const glow61DataNested = glow61DataDirect?.glow_61_response || null;
    
    // Combine direct and nested properties, with direct properties taking precedence
    const glow61Data = {
      ...(glow61DataNested || {}),
      ...(glow61DataDirect || {}),
      // Make sure specific required fields are present
      provider: glow61DataDirect?.provider || glow61DataNested?.provider || null,
      model: glow61DataDirect?.model || glow61DataNested?.model || null,
      style: glow61DataDirect?.style || glow61DataNested?.style || null,
      substyle: glow61DataDirect?.substyle || glow61DataNested?.substyle || null
    };
    
    console.log('Glow91: Using glow_61 data:', glow61Data);
    
    // Load data from glow_62 (Video Type)
    const glow62Data = glowSteps?.glow_62 || null;
    
    // Load data from glow_63 (Style Selection)
    const glow63Data = glowSteps?.glow_63 || null;
    
    // Load data from glow_04 (Video Script)
    const glow04Data = glowSteps?.glow_04 || null;
    
    // Load data from glow_33 (Target Groups)
    const glow33Data = glowSteps?.glow_33 || null;
    
    // Load data from glow_102 (Voice Narration)
    const glow102Data = glowSteps?.glow_102 || null;

    // Load data from glow_91 (our own component's data)
    const glow91Data = glowSteps?.glow_91 || null;
    
    // Get company name from session data
    const companyName = sessionData?.companyName || '';
    const inputPrompt = sessionData?.inputPrompt || '';
    
    // Update summary data
    setSummaryData({
      glow_61: glow61Data,
      glow_62: glow62Data,
      glow_63: glow63Data,
      glow_04: glow04Data,
      glow_33: glow33Data,
      glow_102: glow102Data
    });
    
    // Update request data based on previous steps
    setNewRequest(prev => {
      // Prepare voiceover config from glow_102
      const voiceoverConfig = {
        provider: glow102Data?.provider || 'kokoro',
        voice_by: glow102Data?.voice_name || glow102Data?.voice || 'af_heart',
        lang_code: glow102Data?.language || 'en',
        speed: glow102Data?.speed || 1
      };
      
      // Get video duration in seconds from minutes
      let targetDuration = prev.target_video_duration;
      
      // First check if we already have a custom duration set in glow_91
      if (glow91Data?.target_video_duration) {
        // If glow_91 already has a duration value, use that as the master
        targetDuration = glow91Data.target_video_duration;
        console.log('Using existing duration from glow_91:', targetDuration);
      } 
      // Otherwise, get duration from glow_62 if available
      else if (glow62Data?.selectedType?.dur) {
        const durStr = glow62Data.selectedType.dur;
        if (durStr.endsWith('m')) {
          targetDuration = parseInt(durStr.replace('m', '')) * 60;
        } else if (durStr.endsWith('s')) {
          targetDuration = parseInt(durStr.replace('s', ''));
        } else {
          targetDuration = parseInt(durStr) * 60; // Assume minutes by default
        }
        console.log('Using duration from glow_62:', targetDuration);
      }
      
      return {
        ...prev,
        // Basic info
        input_prompt: inputPrompt || glow04Data?.script || prev.input_prompt,
        company_name: companyName,
        
        // Video config from glow_61
        audience_number: glow33Data?.answers?.amount || 1,
        audience_list: glow33Data?.answers?.groupNames || [],
        
        // Voice narration from glow_102
        voiceover_config: voiceoverConfig,
        
        // Video style from glow_61
        cw12_recraft_config: {
          ...prev.cw12_recraft_config,
          model: glow61Data?.model || prev.cw12_recraft_config.model,
          style: glow61Data?.style || prev.cw12_recraft_config.style,
          extra_body: {
            ...prev.cw12_recraft_config.extra_body,
            substyle: glow61Data?.substyle || prev.cw12_recraft_config.extra_body.substyle,
            artistic_level: null,
            no_text: true
          }
        },
        
        // Video type from glow_62
        video_type: glow62Data?.selectedType?.id?.toUpperCase() || prev.video_type,
        
        // Duration - prioritize our custom setting if it exists
        target_video_duration: targetDuration,
      };
    });
  }, [glowSteps, sessionId]);

  // Update the fetch provider configuration function to use the configLoaderUtils
  useEffect(() => {
    const fetchProviderConfig = async () => {
      setFetchingConfig(true);
      try {
        // Load configuration with robust fallbacks
        const config = await loadProviderConfig({
          // You can also provide a CDN URL for a secondary source
          cdnUrl: process.env.REACT_APP_PROVIDER_CONFIG_CDN_URL || null
        });
        
        setProviderConfig(config);
        
        // Display version and last updated info
        if (config.version && config.last_updated) {
          console.log(`Loaded provider config version ${config.version} (Last updated: ${config.last_updated})`);
        }
      } catch (error) {
        console.error('Error loading provider configuration:', error);
        setProviderConfig(defaultProviderConfig);
      } finally {
        setFetchingConfig(false);
      }
    };

    fetchProviderConfig();
  }, []);

  const handleAddRequestToQueue = async (requestBody) => {
    try {
      console.log('Sending request to queue:', JSON.stringify(requestBody, null, 2));
      
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/pipeline/add_text_to_img_to_anim_to_video_queue`, 
        requestBody,
        { headers: { Authorization: `Bearer ${Cookies.get('token')}` }}
      );

      // Check if response has data property
      const data = response.data;
      
      if (data.status && (data.message === "Request added to queue successfully" || data.status_code === 200)) {
        console.log('Request added to queue:', data);
        showNotice('Request added to queue!');
        setSuccessPopup({
          open: true,
          sessionId: data.data?.session_id || sessionId,
          message: data.message || 'Video request has been added to the processing queue'
        });
        
        // Navigate to glow_92 after a short delay to ensure the user sees the success message
        setTimeout(() => {
          window.dispatchEvent(new CustomEvent('navigate-to-process', { 
            detail: { processId: 'glow_92' } 
          }));
        }, 1500); // 1.5 second delay
        
        return data;
      } else {
        console.error('Failed to add request to queue:', data);
        showNotice('Failed to add request to queue: ' + (data.message || 'Unknown error'), 'error');
        throw new Error(data.message || 'Failed to add request to queue');
      }
    } catch (error) {
      console.error('Error adding request to queue:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Error adding request to queue';
      showNotice(errorMessage, 'error');
      throw error;
    }
  };

  // Function to handle metrics update from VideoMetricsDisplay component
  const handleMetricsUpdate = (metrics) => {
    if (metrics && typeof metrics.userCostCredits === 'number') {
      setCreditMetrics(metrics);
    }
  };

  const isAbleToGenerate = () => {
    // Get current user credit balance
    const userCredit = accountInfo?.credit || 0;
    
    // Get required credits for video generation
    const requiredCredits = creditMetrics?.userCostCredits || 0;
    
    // If metrics haven't been calculated yet, let the process continue
    // The backend will do a final check before processing
    if (requiredCredits === 0) return true;
    
    // Check if user has enough credits
    if (userCredit >= requiredCredits) {
      return true;
    }
    
    // If not enough credits, show the insufficient credit dialog
    setInsufficientCreditDialog({
      open: true,
      required: requiredCredits,
      available: userCredit
    });
    
    // Return false to stop the submission process
    return false;
  };
  
  // Function to open monetization dialog
  const handleOpenMonetization = () => {
    setInsufficientCreditDialog({ open: false, required: 0, available: 0 });
    setMonetizationOpen(true);
  };

  const handleSubmit = (e) => {
    if (!isAbleToGenerate()) {
      return;
    }
    if (e) e.preventDefault();
    
    // Don't proceed if there's no input prompt
    if (!newRequest.input_prompt?.trim()) {
      setNotification({
        open: true,
        message: 'Please provide a video content description before generating.',
        severity: 'warning'
      });
      return;
    }
    
    setIsLoading(true);
    
    // Update Redux store with our final configuration
    dispatch(updateGlowStep({
      stepId: 'glow_91',
      data: {
        ...summaryData,
        prompt: newRequest.input_prompt,
        company_name: newRequest.company_name,
        audienceSize: newRequest.audience_number,
        audienceList: newRequest.audience_list,
        // Include custom duration in the stored data
        target_video_duration: newRequest.target_video_duration,
        // Include shot configuration in the stored data for reference
        shotConfiguration: {
          secondsPerShot: newRequest.seconds_per_shot,
          shotsPerScene: newRequest.shots_per_scene,
          scenesPerVoiceover: newRequest.scenes_per_voiceover
        },
        requestConfig: { ...newRequest }
      }
    }));

    // Get style data from glow_61
    const styleData = summaryData?.glow_61 || glowSteps?.glow_61 || {};
    const {
      provider = 'stability',
      model = 'stable-diffusion-3-5-l-turbo',
      style = 'realistic_image',
      substyle = 'null',
      format = '16:9'
    } = styleData;

    // Get video type from glow_62
    const videoTypeData = summaryData?.glow_62 || glowSteps?.glow_62 || {};
    const videoType = videoTypeData?.selectedType?.id?.toUpperCase() || newRequest.video_type || 'NEWS';

    // Get voice settings from glow_102
    const voiceData = summaryData?.glow_102 || glowSteps?.glow_102 || {};
    
    // Log the format value for debugging
    console.log('Format value before creating request:', {
      formatValue: format,
      formatType: typeof format,
      formattedValue: typeof format === 'number' ? formatOptions[format]?.label || "16:9" : format
    });
    
    // Build new API request payload with required structure
    const requestBody = {
      session_id: sessionId,
      glow_data: {
        input_prompt: newRequest.input_prompt,
        video_type: videoType,
        audience_number: newRequest.audience_number,
        // Use our custom duration value
        target_video_duration: newRequest.target_video_duration,
        audience_list: newRequest.audience_list || [],
        // Include shot configuration in API request for backend processing
        shot_config: {
          seconds_per_shot: newRequest.seconds_per_shot,
          shots_per_scene: newRequest.shots_per_scene, 
          scenes_per_voiceover: newRequest.scenes_per_voiceover
        }
      },
      image_settings: {
        hosting: "inhouse",
        provider: provider,
        model: model,
        image_extension: ".png",
        quality: "standard",
        size: `${newRequest.width}x${newRequest.height}`,
        style: style,
        extra_body: {
          artistic_level: "null",
          cfg_value: 1,
          negative_prompt: "null",
          no_text: "null",
          substyle: substyle || "null"
        }
      },
      animation_settings: {
        hosting: "inhouse",
        provider: provider,
        model: "stable-video-diffusion-img2vid-xt-1-1",
        video_extension: ".mp4",
        screen_format: typeof format === 'number' ? formatOptions[format]?.label || "16:9" : format,
        video_resolution: `${newRequest.width}x${newRequest.height}`,
        fps: newRequest.fps,
        duration: Math.ceil(newRequest.target_video_duration / 10), // Convert to segments
        quality_options: {
          augmentation_level: newRequest.augmentation_level,
          cfg_scale: 3.5,
          guidance_scale: 1.8,
          motion_bucket_id: "auto",
          prompt_extension: false,
          sampler_name: "euler_ancestral",
          scheduler: "karras",
          steps: 30
        }
      },
      voiceover_settings: {
        hosting: "inhouse",
        provider: voiceData?.provider || newRequest.voiceover_config.provider || "kokoro",
        voice_by: voiceData?.voice_name || voiceData?.voice || newRequest.voiceover_config.voice_by || "af_heart",
        lang_code: voiceData?.language || newRequest.voiceover_config.lang_code || "a",
        speed: voiceData?.speed || newRequest.voiceover_config.speed || 1
      }
    };

    console.log('Submitting request with new API format:', requestBody);

    // Add request to processing queue
    handleAddRequestToQueue(requestBody)
      .then(() => {
        setIsLoading(false);
        setNotification({
          open: true,
          message: 'Video request submitted successfully!',
          severity: 'success'
        });
      })
      .catch(error => {
        console.error('Error submitting video request:', error);
        setIsLoading(false);
        setNotification({
          open: true,
          message: 'Error submitting video request. Please try again.',
          severity: 'error'
        });
      });
  };

  // Build request params object for metrics calculation
  const buildMetricsRequestParams = useMemo(() => {
    // Common parameters used across all services
    // These shot configuration values are now managed automatically by the system
    // and are not exposed to users in the UI
    const commonParams = {
      duration_seconds: newRequest.target_video_duration,
      seconds_per_shot: newRequest.seconds_per_shot, // Fixed at 2 seconds per shot
      shots_per_scene: newRequest.shots_per_scene,   // Fixed at 3 shots per scene
      scenes_per_voiceover: newRequest.scenes_per_voiceover, // Fixed at 0.5 scene per voiceover
      fps: newRequest.fps
    };

    // Build text-to-image config
    const textToImageParams = {
      provider: 'stability',
      model: 'stable-diffusion-3-5-l', 
      generation_mode: 'text_to_image',
      hosting: 'inhouse',
      text: newRequest.input_prompt,
      // Number of images based on shot calculation
      number_of_images: Math.ceil(newRequest.target_video_duration / newRequest.seconds_per_shot),
      ...commonParams
    };

    // Build image-to-video config
    const imageToVideoParams = {
      provider: 'stability',
      model: 'stable-video-diffusion-img2vid-xt-1-1',
      hosting: 'inhouse',
      quality_options: {
        fps: newRequest.fps,
        motion_bucket_id: 'auto'
      },
      ...commonParams
    };

    // Build text-to-speech config
    const textToSpeechParams = {
      provider: 'kokoro',
      voice_by: newRequest.voiceover_config.voice_by,
      speed: newRequest.voiceover_config.speed,
      text: newRequest.input_prompt,
      voiceover_per_scene: true,
      ...commonParams
    };

    return {
      text_to_image: textToImageParams,
      image_to_video: imageToVideoParams,
      text_to_speech: textToSpeechParams,
      ...commonParams  // Add common params at top level too
    };
  }, [
    newRequest.target_video_duration, 
    newRequest.seconds_per_shot,
    newRequest.shots_per_scene,
    newRequest.scenes_per_voiceover,
    newRequest.fps,
    newRequest.input_prompt,
    newRequest.voiceover_config.voice_by,
    newRequest.voiceover_config.speed
  ]);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Video Request Manager
      </Typography>
      
      <Paper sx={{ p: 3, mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Generate a New Video
        </Typography>
        
        <Grid container spacing={3}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            {/* PipelineDisplay Component */}
            {/* UserInputDisplay Component */}
            {/* ContentTypeDisplay Component */}
          </Grid>
          
          {/* Right Column */}
          <Grid item xs={12} md={6}>
            {/* VoiceNarrationCard Component */}
            {/* VideoStyleDisplay Component */}
          </Grid>
        </Grid>
      </Paper>

      {/* Video Metrics Display */}
      {providerConfig && (
        <Box sx={{ 
          transition: 'all 0.3s ease-in-out',
          mb: 3
        }}>
          <VideoMetricsDisplay 
            providerConfig={providerConfig}
            requestParams={buildMetricsRequestParams}
            isLoading={false}
            onMetricsUpdate={handleMetricsUpdate} // Add this prop
          />
        </Box>
      )}

      {/* Notification Snackbar */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setNotification(prev => ({ ...prev, open: false }))} 
          severity={notification.severity || 'info'}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>

      {/* Submit Button */}
      <Box sx={{ mt: 4, mb: 6, display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<FileVideo size={20} />}
          disabled={isLoading || !newRequest.input_prompt?.trim()}
          onClick={handleSubmit}
          sx={{ py: 1.5, px: 4, borderRadius: 2 }}
        >
          {isLoading ? 'Processing...' : 'Generate Video'}
        </Button>
      </Box>
      
      {/* Add Insufficient Credit Dialog */}
      <Dialog
        open={insufficientCreditDialog.open}
        onClose={() => setInsufficientCreditDialog({ open: false, required: 0, available: 0 })}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Insufficient Credits</DialogTitle>
        <DialogContent>
          <Alert severity="warning" sx={{ mb: 2 }}>
            <AlertTitle>Credit Balance Too Low</AlertTitle>
            You don't have enough credits to generate this video.
          </Alert>
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Typography variant="body1">Available Credits:</Typography>
            <Typography variant="body1" fontWeight="bold">
              {insufficientCreditDialog.available.toFixed(2)}
            </Typography>
          </Box>
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
            <Typography variant="body1">Required Credits:</Typography>
            <Typography variant="body1" fontWeight="bold" color="error.main">
              {insufficientCreditDialog.required.toFixed(2)}
            </Typography>
          </Box>
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
            <Typography variant="body1">Missing Credits:</Typography>
            <Typography variant="body1" fontWeight="bold" color="error.main">
              {(insufficientCreditDialog.required - insufficientCreditDialog.available).toFixed(2)}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInsufficientCreditDialog({ open: false, required: 0, available: 0 })}>
            Cancel
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleOpenMonetization}
            startIcon={<Layers size={16} />}
          >
            Add More Credits
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Add Monetization Dialog */}
      <MonetizationDialog 
        open={monetizationOpen}
        onClose={() => setMonetizationOpen(false)}
      />
    </Container>
  );
};

export default VideoRequestManager;