import React, { useState } from 'react';
import { Play, PauseCircle, ChevronRight } from 'lucide-react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const VideoProductionCapabilities = () => {
  const [activeTab, setActiveTab] = useState('styles');
  const [videoModal, setVideoModal] = useState({ open: false, videoId: null });

  const videoStyles = [
    {
      name: 'Corporate Explainer',
      thumbnail: 'https://img.youtube.com/vi/jD1wjE9XDKM/mqdefault.jpg',
      videoId: 'jD1wjE9XDKM',
      description: 'Professional business explainer videos with clean graphics and clear messaging',
      metrics: {
        production: '2-3 hours',
        traditionalTime: '2-3 weeks',
        costSaving: '90%'
      }
    },
    {
      name: 'Social Media',
      thumbnail: 'https://img.youtube.com/vi/p2mQXawxMXk/mqdefault.jpg',
      videoId: 'p2mQXawxMXk',
      description: 'Engaging, short-form content optimized for social platforms with fast-paced editing',
      metrics: {
        production: '1-2 hours',
        traditionalTime: '1-2 days',
        costSaving: '75%'
      }
    },
    {
      name: 'Educational Tutorial',
      thumbnail: 'https://img.youtube.com/vi/vY23tTY3-Hc/mqdefault.jpg',
      videoId: 'vY23tTY3-Hc',
      description: 'Step-by-step guides with clear demonstrations and instructional elements',
      metrics: {
        production: '2-4 hours',
        traditionalTime: '3-5 days',
        costSaving: '85%'
      }
    },
    {
      name: 'Product Showcase',
      thumbnail: 'https://img.youtube.com/vi/K9QYvUk8Jag/mqdefault.jpg',
      videoId: 'K9QYvUk8Jag',
      description: 'Highlight product features with detailed visuals and benefit-focused messaging',
      metrics: {
        production: '3-4 hours',
        traditionalTime: '4-7 days',
        costSaving: '80%'
      }
    }
  ];

  const videoFormats = [
    {
      name: 'Landscape (16:9)',
      description: 'Standard format for YouTube, websites, and presentations',
      examples: ['Corporate videos', 'Tutorials', 'Webinar content'],
      icon: '🖥️'
    },
    {
      name: 'Vertical (9:16)',
      description: 'Optimized for mobile viewing on TikTok, Instagram Reels, and YouTube Shorts',
      examples: ['Short-form content', 'Stories', 'Mobile-first viewing'],
      icon: '📱'
    },
    {
      name: 'Square (1:1)',
      description: 'Perfect for social media feeds on Instagram, Facebook, and LinkedIn',
      examples: ['Social media posts', 'Advertisements', 'Platform-neutral content'],
      icon: '◼️'
    },
    {
      name: 'Custom Ratios',
      description: 'Flexible aspect ratios for specific platform requirements',
      examples: ['Banner videos', 'Digital signage', 'Special applications'],
      icon: '📐'
    }
  ];

  const useCases = [
    {
      name: 'Product Marketing',
      description: 'Showcase features and benefits without physical product photography',
      examples: ['Product demos', 'Feature highlights', 'Comparison videos'],
      icon: '🛍️'
    },
    {
      name: 'Educational Content',
      description: 'Create engaging tutorials and courses without filming equipment',
      examples: ['How-to guides', 'Course modules', 'Skill training'],
      icon: '🎓'
    },
    {
      name: 'Internal Communications',
      description: 'Create professional updates for team members and stakeholders',
      examples: ['Updates', 'Training videos', 'Process guides'],
      icon: '📢'
    },
    {
      name: 'Social Media Content',
      description: 'Produce regular, high-quality content for multiple platforms',
      examples: ['Platform-optimized videos', 'Engagement content', 'Viral formats'],
      icon: '📲'
    }
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 'styles':
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {videoStyles.map((style, index) => (
              <div key={index} className="bg-zinc-800/40 rounded-xl overflow-hidden">
                <div 
                  className="relative cursor-pointer h-48"
                  onClick={() => setVideoModal({ open: true, videoId: style.videoId })}
                >
                  <img 
                    src={style.thumbnail} 
                    alt={style.name}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-black/40 flex items-center justify-center hover:bg-black/60 transition-all">
                    <div className="bg-orange-500 rounded-full p-3">
                      <Play className="w-6 h-6 text-white" />
                    </div>
                  </div>
                </div>
                <div className="p-5">
                  <h3 className="text-xl font-bold mb-2">{style.name}</h3>
                  <p className="text-sm text-zinc-400 mb-4">{style.description}</p>
                  
                  <div className="grid grid-cols-3 gap-2 mt-4 border-t border-zinc-700/50 pt-4">
                    <div>
                      <p className="text-xs text-zinc-500">Production Time</p>
                      <p className="text-sm font-medium">{style.metrics.production}</p>
                    </div>
                    <div>
                      <p className="text-xs text-zinc-500">Traditional</p>
                      <p className="text-sm font-medium">{style.metrics.traditionalTime}</p>
                    </div>
                    <div>
                      <p className="text-xs text-zinc-500">Cost Saving</p>
                      <p className="text-sm font-medium text-green-400">{style.metrics.costSaving}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      
      case 'formats':
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {videoFormats.map((format, index) => (
              <div key={index} className="bg-zinc-800/40 rounded-xl p-6">
                <div className="text-3xl mb-4">{format.icon}</div>
                <h3 className="text-xl font-bold mb-2">{format.name}</h3>
                <p className="text-sm text-zinc-400 mb-4">{format.description}</p>
                <div>
                  <p className="text-xs text-zinc-500 mb-2">Example Use Cases:</p>
                  <ul className="text-sm">
                    {format.examples.map((example, i) => (
                      <li key={i} className="flex items-center gap-2 mb-1">
                        <ChevronRight className="w-4 h-4 text-orange-500" />
                        {example}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        );
      
      case 'usecases':
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {useCases.map((useCase, index) => (
              <div key={index} className="bg-zinc-800/40 rounded-xl p-6">
                <div className="text-3xl mb-4">{useCase.icon}</div>
                <h3 className="text-xl font-bold mb-2">{useCase.name}</h3>
                <p className="text-sm text-zinc-400 mb-4">{useCase.description}</p>
                <div>
                  <p className="text-xs text-zinc-500 mb-2">Example Content:</p>
                  <ul className="text-sm">
                    {useCase.examples.map((example, i) => (
                      <li key={i} className="flex items-center gap-2 mb-1">
                        <ChevronRight className="w-4 h-4 text-orange-500" />
                        {example}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        );
      
      default:
        return null;
    }
  };

  return (
    <section className="py-16 mb-20">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold mb-4">
          Video Production Capabilities
        </h2>
        <p className="text-zinc-300 max-w-2xl mx-auto">
          Create professional videos in various styles and formats without filming
        </p>
      </div>

      <div className="mb-8 bg-zinc-800/20 rounded-lg p-1 inline-flex mx-auto">
        <button
          className={`px-5 py-2 rounded-md text-sm font-medium ${
            activeTab === 'styles'
              ? 'bg-orange-500 text-white'
              : 'text-zinc-400 hover:text-white'
          }`}
          onClick={() => setActiveTab('styles')}
        >
          Video Styles
        </button>
        <button
          className={`px-5 py-2 rounded-md text-sm font-medium ${
            activeTab === 'formats'
              ? 'bg-orange-500 text-white'
              : 'text-zinc-400 hover:text-white'
          }`}
          onClick={() => setActiveTab('formats')}
        >
          Format Options
        </button>
        <button
          className={`px-5 py-2 rounded-md text-sm font-medium ${
            activeTab === 'usecases'
              ? 'bg-orange-500 text-white'
              : 'text-zinc-400 hover:text-white'
          }`}
          onClick={() => setActiveTab('usecases')}
        >
          Use Cases
        </button>
      </div>

      {renderContent()}

      <Dialog 
        open={videoModal.open} 
        onClose={() => setVideoModal({ open: false, videoId: null })}
        maxWidth="md"
        fullWidth
      >
        <DialogContent sx={{ padding: 0, bgcolor: '#121212', position: 'relative' }}>
          <IconButton
            onClick={() => setVideoModal({ open: false, videoId: null })}
            sx={{ position: 'absolute', top: 8, right: 8, color: 'white', bgcolor: 'rgba(0,0,0,0.5)' }}
          >
            <Close />
          </IconButton>
          {videoModal.videoId && (
            <div className="aspect-video">
              <iframe
                src={`https://www.youtube.com/embed/${videoModal.videoId}?autoplay=1`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-full h-full"
              ></iframe>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </section>
  );
};

export default VideoProductionCapabilities; 