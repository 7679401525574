import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Typography, Button } from '@mui/material';
import MonetizationDialog from './monetization';
import { selectAccountInfo } from '../../auth/authenticateSlide';
import './PremiumFeatureOverlay.css';

const PremiumFeatureOverlay = ({ 
  title = "Plan Feature", 
  message = "You've reached the limit of free target groups. Upgrade your subscription to create multiple target audience groups.", 
  buttonText = "Upgrade Now", 
  onButtonClick = null
}) => {

  const [monetizationOpen, setMonetizationOpen] = useState(false);
  const toggleMonetizationDialog = () => {
    setMonetizationOpen(!monetizationOpen);
  };
  const accountInfo = useSelector(selectAccountInfo);

  return (
    <>
        <div className="glass-overlay">
        <div className="overlay-content">
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {title}
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
            {message}
            </Typography>
            <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={onButtonClick !== null ? onButtonClick : toggleMonetizationDialog}
            >
            {buttonText}
            </Button>
        </div>
        </div>
        <MonetizationDialog
            accountInfo={accountInfo}
            open={monetizationOpen}
            onClose={toggleMonetizationDialog}
        />
    </>
  );
};

export default PremiumFeatureOverlay;
