import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { useEffect } from 'react';
import { initializeApp } from "firebase/app";

import { history } from "./helpers/history"
import LandingPage from "./pages/LandingPage"
import Journey from "./pages/Journey"
import AdminLayout from "./pages/Admin/layout";

import "./App.css";
import RegistrationResult from "./pages/RegistrationResult";
import { initAnalytics, trackPageView } from "./utils/analyticsUtils";
import { trackGTMPageView } from "./utils/gtmUtils";
import { initErrorMonitoring } from "./utils/errorMonitoring";
import { initPerformanceMonitoring } from "./utils/performanceMonitoring";

// Analytics tracker component to handle route changes
function AnalyticsTracker() {
  const location = useLocation();
  
  useEffect(() => {
    // Track page view on route change
    const pageName = getPageNameFromPath(location.pathname);
    
    // Track in Firebase Analytics
    trackPageView(pageName, location.pathname, {
      referrer: document.referrer
    });
    
    // Track in Google Tag Manager
    trackGTMPageView(pageName, location.pathname, {
      referrer: document.referrer
    });
  }, [location]);
  
  // Helper to get a readable page name from path
  const getPageNameFromPath = (path) => {
    switch (path) {
      case '/':
        return 'Landing Page';
      case '/journey':
        return 'Journey Dashboard';
      case '/payment-success':
        return 'Registration Result';
      case '/admin':
        return 'Admin Dashboard';
      default:
        // Extract the last part of the path as page name
        const parts = path.split('/').filter(Boolean);
        return parts.length ? `${parts[parts.length - 1]} Page` : 'Unknown Page';
    }
  };
  
  return null; // This is a utility component with no UI
}

function App() {
  // Initialize Firebase Analytics once at the app root
  useEffect(() => {
    // Firebase configuration
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    };
    
    // Initialize Firebase only if needed
    let firebaseApp;
    try {
      firebaseApp = initializeApp(firebaseConfig);
      // Initialize analytics with the Firebase app
      initAnalytics(firebaseApp);
      // Initialize error monitoring
      initErrorMonitoring();
      // Initialize performance monitoring only once
      if (!window.__PERFORMANCE_MONITORING_INITIALIZED__) {
        initPerformanceMonitoring();
        window.__PERFORMANCE_MONITORING_INITIALIZED__ = true;
      }
      console.log("Firebase Analytics, Error and Performance Monitoring initialized at app root");
    } catch (error) {
      console.log("Firebase already initialized:", error.message);
    }
  }, []);

  // Initialize Hotjar once for the entire application
  useEffect(() => {
    // Hotjar Tracking Code for Site 5349923
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:5349923,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  }, []);
  
  return (
    <HelmetProvider>
      <Router history={history}>
        <AnalyticsTracker />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/journey" element={<Journey />} />
          <Route path="/payment-success" element={<RegistrationResult />} />
          <Route path="/admin" element={<AdminLayout />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;