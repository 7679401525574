import axios from 'axios';
import Cookies from 'js-cookie';
import defaultProviderConfig from '../config/fallback_text_to_video_config.json';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://api.example.com";

/**
 * Loads provider configuration from multiple sources with fallbacks
 * 1. Try API endpoint
 * 2. Try CDN/external URL if provided
 * 3. Fall back to local JSON file
 */
export const loadProviderConfig = async (options = {}) => {
  const {
    apiEndpoint = `${API_BASE_URL}/api/v1/providers/config`,
    cdnUrl = null,
    useToken = true,
    timeout = 5000 // 5 second timeout for API calls
  } = options;

  try {
    // First attempt API endpoint
    const headers = {};
    if (useToken) {
      const token = Cookies.get('token');
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
    }

    const apiResponse = await axios.get(apiEndpoint, {
      headers,
      timeout
    });

    if (apiResponse.data && apiResponse.data.providers) {
      console.log('Provider configuration loaded from API');
      return apiResponse.data;
    }
  } catch (apiError) {
    console.log('API provider config load failed:', apiError.message);
  }

  // If API failed and CDN URL is provided, try that next
  if (cdnUrl) {
    try {
      const cdnResponse = await axios.get(cdnUrl, { timeout });
      
      if (cdnResponse.data && cdnResponse.data.providers) {
        console.log('Provider configuration loaded from CDN');
        return cdnResponse.data;
      }
    } catch (cdnError) {
      console.log('CDN provider config load failed:', cdnError.message);
    }
  }

  // If all else fails, use local configuration
  console.log('Using local provider configuration file');
  return defaultProviderConfig;
};

/**
 * Updates the timestamp in the local provider configuration
 * (This is just for demonstration - in a real app you'd save to a server)
 */
export const updateLocalConfigTimestamp = () => {
  const updatedConfig = {
    ...defaultProviderConfig,
    last_updated: new Date().toISOString().split('T')[0]
  };
  
  console.log('Updated local config timestamp:', updatedConfig.last_updated);
  return updatedConfig;
};

export default {
  loadProviderConfig,
  updateLocalConfigTimestamp
}; 