import React, { Component } from 'react';
import {
  Box,
  Typography,
  Button,
  Chip,
  Tooltip,
  IconButton,
  Paper,
  Card,
  CardHeader,
  CardContent,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Cookies from 'js-cookie';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { RefreshCw, ExternalLink, Download, AlertCircle, X } from 'react-feather';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import processConfig from '../../config/processIndex.json';
import { VIDEO_INDEX } from '../../config/videoIndex';
import { API_BASE_URL } from '../../config/constants';
import { FaVideo, FaPlay } from 'react-icons/fa';
import { Play } from 'react-feather';
import PremiumFeatureOverlay from '../ui/PremiumFeatureOverlay';
import { useSelector, connect } from 'react-redux';
import { selectAccountInfo } from '../../auth/authenticateSlide';

// Update the model mapping
const AI_MODEL_DISPLAY_NAMES = {
  'STABLE-DIFFUSION-3-5-L-TURBO': 'SD 3.5L Turbo',
  'STABLE-DIFFUSION-3-5-L': 'SD 3.5Large',  // Added this mapping
  'STABLE-DIFFUSION-3': 'SD 3.0',
  'STABLE-DIFFUSION-XL': 'SDXL 1.0',
  'STABLE-DIFFUSION-2-1': 'SD 2.1',
  'STABLE-DIFFUSION-1-5': 'SD 1.5',
  'KANDINSKY-2-2': 'Kandinsky 2.2',
  'DALL-E-3': 'DALL·E 3',
  'DALL-E-2': 'DALL·E 2',
  // Add more mappings as needed
};

// Add a helper function to get the display name
const getModelDisplayInfo = (modelName) => {
  if (!modelName || modelName === '-') return { display: '-', full: '-' };
  
  const upperModelName = modelName.toUpperCase();
  return {
    display: AI_MODEL_DISPLAY_NAMES[upperModelName] || modelName,
    full: modelName
  };
};

// Update the helper function to get both name and tooltip info
const getVideoTypeInfo = (abbreviation) => {
  for (const category of Object.values(VIDEO_INDEX.categories)) {
    for (const typeInfo of Object.values(category.types)) {
      if (typeInfo.abbr === abbreviation) {
        return {
          name: typeInfo.name.replace(/\sVideo(?!\s*[a-z])/gi, ''),
          tooltip: `${category.name} - ${typeInfo.name}${typeInfo.dur === 'custom' ? ' (Auto-adjusted duration)' : ''}`
        };
      }
    }
  }
  return {
    name: abbreviation,
    tooltip: 'Video type information not available'
  };
};

class VideoQueueManager extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      videos: [],
      loading: false,
      error: null,
      sortConfig: { key: 'created_at', direction: 'desc' },
      filterType: 'all',
      videoModalOpen: false,
      selectedVideoUrl: null,
      paginationModel: {
        pageSize: 50,
        page: 0,
      },
    };
    
    this.process = processConfig.processes.find(p => p.id === 'glow_92');
    
    this.fetchVideoRequests = this.fetchVideoRequests.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.renderStatusChip = this.renderStatusChip.bind(this);
    this.handleVideoOpen = this.handleVideoOpen.bind(this);
    this.handleVideoClose = this.handleVideoClose.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }
  
  componentDidMount() {
    this.fetchVideoRequests();
  }
  
  componentWillUnmount() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
    }
  }
  
  formatDateTime(dateString, compact = false) {
    if (!dateString) return 'N/A';
    try {
      // Parse the date string and ensure it's treated as UTC
      const date = new Date(dateString + 'Z'); // Append Z to force UTC interpretation
      const now = new Date();
      
      // Get UTC timestamps
      const utcDate = date.getTime();
      const utcNow = now.getTime();
      
      const diffInHours = (utcNow - utcDate) / (1000 * 60 * 60);
      
      // If less than 24 hours ago, show relative time
      if (diffInHours < 24) {
        const hours = Math.floor(diffInHours);
        const minutes = Math.floor((diffInHours - hours) * 60);
        
        if (hours > 0) {
          return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
          return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else {
          return 'just now';
        }
      }
      
      // Otherwise show date and time in UTC
      return format(date, "MMM d, HH:mm 'UTC'");
    } catch (e) {
      console.error('Error formatting date:', e);
      return dateString || 'N/A';
    }
  }
  
  formatTimeString(timeValue) {
    if (!timeValue || timeValue === '-') return timeValue;
    
    // If it's a number (seconds), convert it to human readable format
    if (typeof timeValue === 'number') {
      const hours = Math.floor(timeValue / 3600);
      const minutes = Math.floor((timeValue % 3600) / 60);
      const seconds = timeValue % 60;
      
      let formattedTime = '';
      if (hours > 0) formattedTime += `${hours} hr `;
      if (minutes > 0) formattedTime += `${minutes} min `;
      if (seconds > 0) formattedTime += `${seconds} sec`;
      
      return formattedTime.trim() || '0 sec';
    }
    
    // If it's a string, use the original replace logic
    if (typeof timeValue === 'string') {
      return timeValue
        .replace(/ minutes?/g, ' min')
        .replace(/ hours?/g, ' hr')
        .replace(/ seconds?/g, ' sec')
        .replace(/ days?/g, ' day');
    }
    
    return '-';
  }
  
  handleSort(key) {
    console.log('Sorting by key:', key);
    this.setState(prevState => {
      const direction = 
        prevState.sortConfig.key === key && prevState.sortConfig.direction === 'asc' 
          ? 'desc' 
          : 'asc';
      
      console.log('Sort direction:', direction);
      const sortedVideos = this.sortData([...prevState.videos], key, direction);
      console.log('First few sorted videos:', sortedVideos.slice(0, 3));
      
      return {
        sortConfig: { key, direction },
        videos: sortedVideos
      };
    });
  }
  
  sortData(data, sortKey, sortDirection) {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return [];
    }
    
    if (!sortKey) {
      return data;
    }
    
    try {
      return [...data].sort((a, b) => {
        if (!a || !b) return 0;
        
        let aValue = a[sortKey];
        let bValue = b[sortKey];
        
        // Handle null/undefined values
        if (aValue === null || aValue === undefined) {
          return sortDirection === 'asc' ? -1 : 1;
        }
        
        if (bValue === null || bValue === undefined) {
          return sortDirection === 'asc' ? 1 : -1;
        }
        
        // Special handling for queue numbers
        if (sortKey === 'queue_number') {
          aValue = parseInt(aValue) || 0;
          bValue = parseInt(bValue) || 0;
        }
        
        // Handle dates
        else if (['request_created_at', 'process_start_time', 'process_end_time'].includes(sortKey)) {
          aValue = aValue ? new Date(aValue).getTime() : 0;
          bValue = bValue ? new Date(bValue).getTime() : 0;
        }
        
        // Handle durations (like process_duration, queue_duration etc)
        else if (sortKey.includes('duration') || sortKey.includes('time_dur')) {
          // Extract numbers from strings like "1 hr 30 min" or "90 sec"
          const extractSeconds = (str) => {
            if (!str || str === '-') return 0;
            if (typeof str === 'number') return str;
            
            try {
              let total = 0;
              const hrs = str.match(/(\d+)\s*hr/);
              const mins = str.match(/(\d+)\s*min/);
              const secs = str.match(/(\d+)\s*sec/);
              
              if (hrs) total += parseInt(hrs[1]) * 3600;
              if (mins) total += parseInt(mins[1]) * 60;
              if (secs) total += parseInt(secs[1]);
              
              return total;
            } catch (e) {
              return 0;
            }
          };
          
          aValue = extractSeconds(aValue);
          bValue = extractSeconds(bValue);
        }
        
        // Handle strings
        else if (typeof aValue === 'string' && typeof bValue === 'string') {
          try {
            return sortDirection === 'asc' 
              ? aValue.localeCompare(bValue) 
              : bValue.localeCompare(aValue);
          } catch (e) {
            return 0;
          }
        }
        
        // Default numeric comparison
        try {
          return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
        } catch (e) {
          return 0;
        }
      });
    } catch (error) {
      console.error('Error sorting data:', error);
      return data;
    }
  }
  
  async fetchVideoRequests() {
    try {
      this.setState({ loading: true });

      const response = await axios.post(
              `${API_BASE_URL}/api/v1/pipeline/get_video_queue_list`,
              {},
              {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
              }
            );
      
      if (response && response.data && response.data.status) {
        const responseData = response.data.data;
        
        // Validate that the response data is an array and not empty
        if (Array.isArray(responseData)) {
          // Map video data with careful error handling for each item
          const videoList = responseData
            .filter(video => video !== null && video !== undefined) // Skip null/undefined items
            .map((video, index) => {
              try {
                const requestData = video.request_data || {};
                const glowData = requestData.glow_data || {};
                const imageSettings = requestData.image_settings || {};
                const timeData = video.time_data || {};
                const actualTimeData = timeData.actual || {};
                const estimatedTimeData = timeData.estimated || {};
                const urlData = video.url_data || {};
                
                // Create a unique ID by combining multiple fields
                const uniqueId = `${video.session_id || 'unknown'}_${video.queue_number || index}_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`;
                
                return {
                  id: uniqueId,
                  own_session: !!video.own_session,
                  video_type: glowData.video_type || '-',
                  input_prompt: glowData.input_prompt || '-',
                  status: (video.request_status || 'pending').toLowerCase(),
                  queue_number: video.queue_number || 0,
                  target_duration: glowData.target_video_duration || '-',
                  ai_model: (imageSettings.model || '-').toUpperCase(),
                  ai_provider: (imageSettings.provider || '-').toUpperCase(),
                  request_created_at: video.requested_created_at || null,
                  process_start_time: actualTimeData.process_start_time || null,
                  process_end_time: actualTimeData.process_end_time || null,
                  process_duration: this.formatTimeString(actualTimeData.process_duration) || '-',
                  total_queue_time_dur: this.formatTimeString(estimatedTimeData.total_queue_time_dur) || '-',
                  s3_video_url: urlData.s3_video_url || null,
                  request_remark: video.request_remark || null,
                  time_data: timeData,
                };
              } catch (err) {
                console.error('Error processing video data:', err, video);
                // Return a placeholder row instead of null to maintain array structure
                return {
                  id: `error-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`,
                  status: 'error',
                  input_prompt: 'Error processing this video data',
                  video_type: '-',
                  queue_number: 0,
                  ai_model: '-',
                  ai_provider: '-',
                  request_created_at: null,
                  process_duration: '-',
                  total_queue_time_dur: '-',
                  process_end_time: null,
                  // Add default values for all required fields
                  s3_video_url: null,
                  request_remark: 'Data processing error',
                  own_session: false,
                  target_duration: '-',
                  process_start_time: null,
                  time_data: {},
                };
              }
            });
            
          const sortedVideos = this.sortData(videoList, this.state.sortConfig.key, this.state.sortConfig.direction);
          this.setState({ videos: sortedVideos, error: null });
        } else {
          console.warn('Response data is not an array:', responseData);
          this.setState({ videos: [], error: 'No video data available' });
        }
      } else {
        const errorMessage = response?.data?.message || 'Failed to fetch video requests';
        console.warn('API response error:', errorMessage);
        this.setState({ 
          error: errorMessage, 
          videos: [] 
        });
      }
    } catch (err) {
      console.error('Error fetching video requests:', err);
      this.setState({ 
        error: err.message || 'Error fetching video requests',
        videos: [] 
      });
      if (typeof this.props.showNotice === 'function') {
        this.props.showNotice('Failed to fetch video requests', 'error');
      }
    } finally {
      this.setState({ loading: false });
    }
  }
  
  renderStatusChip(status, remark, row) {
    // Ensure status is a string and has a value
    const statusText = status ? String(status).toLowerCase() : 'pending';
    
    let color;
    switch(statusText) {
      case 'completed':
        color = "success";
        break;
      case 'failed':
      case 'rejected':
        color = "error";
        break;
      case 'processing':
        color = "info";
        break;
      case 'pending':
        color = "warning";
        break;
      case 'rescheduled':
        color = "secondary";
        break;
      default:
        color = "default";
    }
    
    const chipContent = (
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 0.5,
        '& .MuiSvgIcon-root': {
          fontSize: '14px'
        }
      }}>
        {status || 'Pending'}
        {remark && (
          <AlertCircle 
            size={14} 
            style={{ 
              color: ['error', 'warning', 'secondary'].includes(color) ? 'white' : 'inherit',
              opacity: 0.9
            }} 
          />
        )}
      </Box>
    );

    const chip = (
      <Chip 
        label={chipContent}
        color={color}
        size="small"
        sx={{ 
          minWidth: '90px',
          justifyContent: 'center',
          cursor: remark ? 'help' : 'default',
          '& .MuiChip-label': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: 1
          }
        }}
      />
    );

    // If we have a completed video with a URL, display action buttons
    const showActionButtons = statusText === 'completed' && row && row.s3_video_url;
    
    // Create combined component with chip and possible action buttons
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
        {remark ? (
          <Tooltip title={remark || ''}>
            {chip}
          </Tooltip>
        ) : chip}
        
        {showActionButtons && (
          <Box sx={{ display: 'flex', gap: 1, mt: 0.5 }}>
            <Tooltip title="Preview Video">
              <IconButton
                size="small"
                onClick={() => this.handleVideoOpen(row.s3_video_url)}
                sx={{
                  color: 'primary.main',
                  '&:hover': { backgroundColor: 'rgba(75, 112, 226, 0.1)' },
                  padding: '4px'
                }}
              >
                <Play size={14} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download Video">
              <IconButton
                size="small"
                onClick={() => this.handleDownload(
                  row.s3_video_url,
                  `video_${row.video_type || 'unknown'}_${row.id || 'unknown'}.mp4`
                )}
                sx={{
                  color: 'primary.main',
                  '&:hover': { backgroundColor: 'rgba(75, 112, 226, 0.1)' },
                  padding: '4px'
                }}
              >
                <Download size={14} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    );
  }
  
  filterVideos = (videos) => {
    // Handle case when videos array is null or undefined
    if (!videos || !Array.isArray(videos)) {
      return [];
    }
    
    if (this.state.filterType === 'mine') {
      return videos.filter(video => video && video.own_session === true);
    }
    return videos;
  }

  handleFilterChange = (event) => {
    this.setState({ filterType: event.target.value });
  }
  
  handleVideoOpen = (url) => {
    this.setState({
      videoModalOpen: true,
      selectedVideoUrl: url
    });
  };

  handleVideoClose = () => {
    this.setState({
      videoModalOpen: false,
      selectedVideoUrl: null
    });
  };
  
  formatEstimatedCompletion(video) {
    if (!video || !video.time_data || !video.time_data.estimated || !video.time_data.estimated.request_finish_est) {
      return '-';
    }
    
    try {
      const estimatedDate = parseISO(video.time_data.estimated.request_finish_est);
      
      // Show relative time if it's within 24 hours
      const now = new Date();
      const diffInHours = (estimatedDate - now) / (1000 * 60 * 60);
      
      if (diffInHours <= 24) {
        return `In ${formatDistanceToNow(estimatedDate)}`;
      }
      
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return format(estimatedDate, `MMM d, h:mm 'a' (${userTimeZone})`);
    } catch (e) {
      return '-';
    }
  }
  
  // Add download handler function
  handleDownload = async (url, filename = 'video.mp4') => {
    try {
      // Fetch the video file
      const response = await fetch(url);
      const blob = await response.blob();
      
      // Create a temporary link element
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = filename; // Set suggested filename
      
      // Append to document, click, and cleanup
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error downloading video:', error);
      if (typeof this.props.showNotice === 'function') {
        this.props.showNotice('Failed to download video', 'error');
      }
    }
  };
  
  getColumns = () => {
    // Bind formatDateTime to preserve 'this' context
    const formatDateTime = this.formatDateTime.bind(this);
    
    return [
    {
      field: 'queue_number',
      headerName: '#',
      width: 60,
      hideable: true,
      renderCell: (params) => (
        <Tooltip title={`Duration: ${params?.row?.target_duration || '0'}s`}>
          <Typography>{params?.value || '-'}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'video_type',
      headerName: 'Video Type',
      width: 120,
      hideable: true,
      renderCell: (params) => (
        <Tooltip title={getVideoTypeInfo(params?.value || '').tooltip}>
          <Chip 
            label={getVideoTypeInfo(params?.value || '').name}
            variant="outlined" 
            color="primary" 
            size="small"
          />
        </Tooltip>
      ),
    },
    {
      field: 'input_prompt',
      headerName: 'Prompt',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Tooltip title={params?.value || '-'}>
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              lineHeight: '1.2em',
              maxHeight: '2.4em',
            }}
          >
            {params?.value || '-'}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'ai_model',
      headerName: 'AI Model',
      width: 150,
      hideable: true,
      renderCell: (params) => {
        const modelValue = params?.value || '';
        return (
          <Tooltip title={`Provider: ${params?.row?.ai_provider || '-'}`}>
            <Chip 
              label={getModelDisplayInfo(modelValue).display}
              variant="outlined" 
              color="primary" 
              size="small"
            />
          </Tooltip>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => this.renderStatusChip(params?.value || 'pending', params?.row?.request_remark, params?.row),
    },
    {
      field: 'request_created_at',
      headerName: 'Created',
      width: 180,
      hideable: true,
      renderCell: (params) => {
        if (!params || !params.row) return <Typography>-</Typography>;
        
        if (params.row.request_created_at) {
          try {
            // Parse the date string and ensure it's treated as UTC
            const date = new Date(params.row.request_created_at + 'Z'); // Append Z to force UTC interpretation
            const now = new Date();
            
            // Get UTC timestamps
            const utcDate = date.getTime();
            const utcNow = now.getTime();
            
            const diffInHours = (utcNow - utcDate) / (1000 * 60 * 60);
            
            // Calculate relative time based on UTC difference
            let relativeTime;
            if (diffInHours < 24) {
              const hours = Math.floor(diffInHours);
              const minutes = Math.floor((diffInHours - hours) * 60);
              
              if (hours > 0) {
                relativeTime = `${hours} hour${hours !== 1 ? 's' : ''} ago`;
              } else if (minutes > 0) {
                relativeTime = `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
              } else {
                relativeTime = 'just now';
              }
            } else {
              relativeTime = format(date, "MMM d, HH:mm 'UTC'");
            }
            
            // Show UTC time in tooltip
            const utcTime = format(date, "MMM d, HH:mm");
            
            return (
              <Tooltip title={utcTime}>
                <Typography>
                  {relativeTime}
                </Typography>
              </Tooltip>
            );
          } catch (e) {
            console.error('Error formatting date:', e);
            return <Typography>-</Typography>;
          }
        }
        
        return <Typography>-</Typography>;
      }
    },
    {
      field: 'total_queue_time_dur',
      headerName: 'Queue Time',
      width: 120,
      hideable: true,
      renderCell: (params) => {
        if (!params || !params.row) return <Typography>-</Typography>;
        const estimatedQueueTime = params.row.total_queue_time_dur || '-';
        
        let actualQueueTime = '-';
        let queueTimeDiff = '';
        
        if (params.row.request_created_at && params.row.process_start_time) {
          try {
            const createdAt = new Date(params.row.request_created_at);
            const startedAt = new Date(params.row.process_start_time);
            const diffInSeconds = Math.floor((startedAt - createdAt) / 1000);
            
            actualQueueTime = this.formatTimeString(diffInSeconds);
            
            const estimatedSeconds = this.extractTimeInSeconds(params.row.total_queue_time_dur);
            if (estimatedSeconds > 0) {
              const diffPercentage = Math.round(((diffInSeconds - estimatedSeconds) / estimatedSeconds) * 100);
              queueTimeDiff = diffPercentage >= 0 ? 
                `(+${diffPercentage}%)` : 
                `(${diffPercentage}%)`;
            }
          } catch (e) {
            console.error('Error calculating actual queue time:', e);
          }
        }
        
        const tooltipText = `Estimated: ${estimatedQueueTime}${actualQueueTime !== '-' ? `\nActual: ${actualQueueTime}` : ''}
                             ${queueTimeDiff ? `\nDiff: ${queueTimeDiff}` : ''}`;
        
        return (
          <Tooltip title={tooltipText}>
            <Box>
              <Typography>{estimatedQueueTime}</Typography>
              {actualQueueTime !== '-' && actualQueueTime !== estimatedQueueTime && (
                <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                  {actualQueueTime}
                </Typography>
              )}
            </Box>
          </Tooltip>
        );
      }
    },
    {
      field: 'process_duration',
      headerName: 'Duration',
      width: 140,
      hideable: true,
      renderCell: (params) => {
        if (!params || !params.row) return <Typography>-</Typography>;
        const actualDuration = params.row.process_duration || '-';
        
        let estimatedDuration = '-';
        let durationDiff = '';
        
        if (params.row.time_data && params.row.time_data.estimated && params.row.time_data.estimated.process_duration) {
          estimatedDuration = this.formatTimeString(params.row.time_data.estimated.process_duration);
          
          if (params.row.process_duration && params.row.process_duration !== '-') {
            try {
              const estimatedSeconds = this.extractTimeInSeconds(params.row.time_data.estimated.process_duration);
              const actualSeconds = this.extractTimeInSeconds(params.row.process_duration);
              
              if (estimatedSeconds > 0 && actualSeconds > 0) {
                const diffPercentage = Math.round(((actualSeconds - estimatedSeconds) / estimatedSeconds) * 100);
                durationDiff = diffPercentage >= 0 ? 
                  `(+${diffPercentage}%)` : 
                  `(${diffPercentage}%)`;
              }
            } catch (e) {
              console.error('Error calculating duration difference:', e);
            }
          }
        }
        
        const tooltipText = `Actual: ${actualDuration}
                             Estimated: ${estimatedDuration}
                             ${durationDiff ? `\nDiff: ${durationDiff}` : ''}
                             ${params.row.process_start_time ? `\nStarted: ${formatDateTime(params.row.process_start_time)}` : ''}
                             ${params.row.process_end_time ? `\nFinished: ${formatDateTime(params.row.process_end_time)}` : ''}`;
        
        return (
          <Tooltip title={tooltipText}>
            <Box>
              <Typography>{actualDuration}</Typography>
              {params.row.process_start_time && (
                <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                  {formatDateTime(params.row.process_start_time, true)}
                </Typography>
              )}
            </Box>
          </Tooltip>
        );
      }
    },
    {
      field: 'process_end_time',
      headerName: 'Completed',
      width: 180,
      hideable: true,
      renderCell: (params) => {
        if (!params || !params.row) return <Typography>-</Typography>;
        const actualTime = params.row.process_end_time ? formatDateTime(params.row.process_end_time) : '-';
        
        let estimatedTime = '-';
        let completionDiff = '';
        
        if (params.row.time_data && params.row.time_data.estimated && params.row.time_data.estimated.request_finish_est) {
          estimatedTime = formatDateTime(params.row.time_data.estimated.request_finish_est);
          
          if (params.row.process_end_time) {
            try {
              const estimatedDate = new Date(params.row.time_data.estimated.request_finish_est);
              const actualDate = new Date(params.row.process_end_time);
              const diffInSeconds = Math.floor((actualDate - estimatedDate) / 1000);
              
              if (diffInSeconds > 0) {
                completionDiff = `(+${this.formatTimeString(diffInSeconds)})`;
              } else {
                completionDiff = `(${this.formatTimeString(Math.abs(diffInSeconds))})`;
              }
            } catch (e) {
              console.error('Error calculating completion difference:', e);
            }
          }
        }
        
        const tooltipText = `Actual: ${actualTime}
                             Estimated: ${estimatedTime}
                             ${completionDiff ? `\nDiff: ${completionDiff}` : ''}`;
        
        return (
          <Tooltip title={tooltipText}>
            <Box>
              <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {actualTime}
              </Typography>
              {estimatedTime !== '-' && estimatedTime !== actualTime && (
                <Typography variant="caption" color="text.secondary" sx={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  Est: {estimatedTime.replace(/ \([^)]+\)/, '')}
                </Typography>
              )}
            </Box>
          </Tooltip>
        );
      }
    }
  ]};
  
  // Helper to extract seconds from time strings like "1 hr 30 min"
  extractTimeInSeconds = (timeString) => {
    if (!timeString || timeString === '-') return 0;
    if (typeof timeString === 'number') return timeString;
    
    try {
      let total = 0;
      const hrs = timeString.match(/(\d+)\s*hr/);
      const mins = timeString.match(/(\d+)\s*min/);
      const secs = timeString.match(/(\d+)\s*sec/);
      
      if (hrs) total += parseInt(hrs[1]) * 3600;
      if (mins) total += parseInt(mins[1]) * 60;
      if (secs) total += parseInt(secs[1]);
      
      return total;
    } catch (e) {
      return 0;
    }
  }
  // Calculate estimated wait time based on queue data
  getEstimatedWaitTime = (videos) => {
    try {
      // Get pending videos
      const pendingVideos = videos.filter(v => v.status === 'pending');
      if (pendingVideos.length === 0) return '-';
      
      // Sort by queue number to find the last one
      const sortedPending = [...pendingVideos].sort((a, b) => {
        const aNum = parseInt(a.queue_number) || 0;
        const bNum = parseInt(b.queue_number) || 0;
        return bNum - aNum; // Descending order
      });
      
      // Get the last pending video in the queue
      const lastPending = sortedPending[0];
      
      // If the video has an estimated completion, use that
      if (lastPending.time_data && 
          lastPending.time_data.estimated && 
          lastPending.time_data.estimated.request_finish_est) {
        
        const estDate = parseISO(lastPending.time_data.estimated.request_finish_est);
        const now = new Date();
        
        // If the date is in the past, return "processing soon"
        if (estDate < now) return 'Soon';
        
        // If it's within the next hour
        const diffMs = estDate - now;
        const diffMin = Math.round(diffMs / (1000 * 60));
        
        if (diffMin < 60) {
          return `~${diffMin} min`;
        }
        
        // If it's within 24 hours, show relative time
        const diffHours = diffMs / (1000 * 60 * 60);
        if (diffHours < 24) {
          return `~${Math.round(diffHours)} hr`;
        }
        
        // If it's more than 24 hours away
        return formatDistanceToNow(estDate, { addSuffix: false });
      }
      
      // If it doesn't have an estimated completion but has queue time
      if (lastPending.total_queue_time_dur && lastPending.total_queue_time_dur !== '-') {
        return lastPending.total_queue_time_dur;
      }
      
      // If we can't determine, show a reasonable message
      const processingCount = videos.filter(v => v.status === 'processing').length;
      if (processingCount > 0) {
        return 'Calculating...';
      }
      
      return 'Unknown';
    } catch (e) {
      console.error('Error calculating estimated wait time:', e);
      return 'Unknown';
    }
  }

  render() {
    const { videos, loading, error, filterType, paginationModel } = this.state;
    const filteredVideos = this.filterVideos(videos) || [];
    const isMobile = window.innerWidth < 600;
    // Get account info from props
    const { accountInfo } = this.props;

    // Custom overlay for when there are no rows
    const NoRowsOverlay = () => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          p: 2,
        }}
      >
        <AlertCircle size={24} style={{ marginBottom: 8, opacity: 0.6 }} />
        <Typography variant="body2" color="text.secondary">
          {loading ? 'Loading video data...' : 'No video requests found'}
        </Typography>
      </Box>
    );

    return (
      <>
        <ViewerHeader 
          title={this.process?.info?.title || "Video Queue Manager"}
          subtitle={this.process?.info?.description || "Manage and track your video generation requests"}
          processId="glow_92"
        />
        
        <Card className={'premium-locked'}>
          {
            accountInfo?.userToken === '' && <PremiumFeatureOverlay
              buttonText='Sign In'
              message='Please sign in or create an account to access this feature'
              title='Account Required'
              onButtonClick={() => {
                this.props.toggleAuthForm(true);
                if (typeof this.props.toggleAuthForm === 'function') {
                }
              }}
            />
          }
          <CardContent>
            <Box 
              sx={{ 
                mb: 3, 
                display: 'flex', 
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between', 
                alignItems: isMobile ? 'stretch' : 'center',
                gap: 2
              }}
            >
              <Box sx={{ 
                display: 'flex', 
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: isMobile ? 'stretch' : 'center', 
                gap: 2 
              }}>
                <Button 
                  startIcon={<RefreshCw size={18} />}
                  onClick={this.fetchVideoRequests}
                  variant="outlined"
                  color="primary"
                  disabled={loading}
                  size="small"
                  fullWidth={isMobile}
                >
                  {loading ? 'Refreshing...' : 'Refresh Queue'}
                </Button>

                <FormControl size="small" sx={{ minWidth: isMobile ? '100%' : 120 }}>
                  <InputLabel>Sessions</InputLabel>
                  <Select
                    value={filterType}
                    label="Sessions"
                    onChange={this.handleFilterChange}
                  >
                    <MenuItem value="all">All Sessions</MenuItem>
                    <MenuItem value="mine">My Sessions</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              
              {/* Queue Statistics Summary */}
              <Box sx={{ 
                display: 'flex', 
                flexWrap: 'wrap',
                alignItems: 'center', 
                gap: 1,
                py: 0.5,
                px: 1.5,
                borderRadius: 1,
                bgcolor: 'background.paper',
                boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.05)',
                ml: isMobile ? 0 : 'auto'
              }}>
                {!loading && filteredVideos.length > 0 && (
                  <>
                    <Tooltip title="Total videos in queue">
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        <Typography variant="caption" color="text.secondary">Total:</Typography>
                        <Typography variant="caption" fontWeight="bold">{filteredVideos.length}</Typography>
                      </Box>
                    </Tooltip>
                    
                    <Box sx={{ height: 16, borderLeft: '1px solid', borderColor: 'divider', mx: 0.5 }} />
                    
                    <Tooltip title="Videos currently processing">
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: 'info.main' }} />
                        <Typography variant="caption" fontWeight="bold">
                          {filteredVideos.filter(v => v.status === 'processing').length}
                        </Typography>
                      </Box>
                    </Tooltip>
                    
                    <Tooltip title="Videos pending in queue">
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: 'warning.main' }} />
                        <Typography variant="caption" fontWeight="bold">
                          {filteredVideos.filter(v => v.status === 'pending').length}
                        </Typography>
                      </Box>
                    </Tooltip>
                    
                    <Tooltip title="Completed videos">
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: 'success.main' }} />
                        <Typography variant="caption" fontWeight="bold">
                          {filteredVideos.filter(v => v.status === 'completed').length}
                        </Typography>
                      </Box>
                    </Tooltip>
                    
                    {filteredVideos.filter(v => v.status === 'failed' || v.status === 'rejected').length > 0 && (
                      <Tooltip title="Failed or rejected videos">
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: 'error.main' }} />
                          <Typography variant="caption" fontWeight="bold">
                            {filteredVideos.filter(v => v.status === 'failed' || v.status === 'rejected').length}
                          </Typography>
                        </Box>
                      </Tooltip>
                    )}
                    
                    {/* Show estimated wait time if there are pending videos */}
                    {filteredVideos.filter(v => v.status === 'pending').length > 0 && (
                      <>
                        <Box sx={{ height: 16, borderLeft: '1px solid', borderColor: 'divider', mx: 0.5 }} />
                        <Tooltip title="Estimated wait time for last queue position">
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <Typography variant="caption" color="text.secondary">Est. wait:</Typography>
                            <Typography variant="caption" fontWeight="bold">
                              {this.getEstimatedWaitTime(filteredVideos)}
                            </Typography>
                          </Box>
                        </Tooltip>
                      </>
                    )}
                  </>
                )}
                
                {!loading && filteredVideos.length === 0 && (
                  <Typography variant="caption" color="text.secondary">
                    No videos in queue
                  </Typography>
                )}
                
                {loading && (
                  <Typography variant="caption" color="text.secondary">
                    Loading queue statistics...
                  </Typography>
                )}
              </Box>
            </Box>
            
            {loading && <LinearProgress sx={{ mb: 2 }} />}
            
            {error && (
              <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>
            )}
              <DataGrid
                rows={filteredVideos || []}
                columns={this.getColumns()}
                paginationModel={paginationModel}
                onPaginationModelChange={(model) => this.setState({ paginationModel: model })}
                pageSizeOptions={[10, 25, 50, 100]}
                disableRowSelectionOnClick
                loading={loading}
                getRowId={(row) => row?.id || `row-${Math.random()}`}
                slots={{
                  noRowsOverlay: NoRowsOverlay,
                }}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 50 },
                  },
                  sorting: {
                    sortModel: [{ field: 'request_created_at', sort: 'desc' }],
                  },
                  columns: {
                    columnVisibilityModel: isMobile ? {
                      queue_number: false,
                      video_type: true,
                      input_prompt: true,
                      ai_model: false,
                      status: true,
                      request_created_at: false,
                      total_queue_time_dur: false,
                      process_duration: true,
                      process_end_time: false,
                    } : {},
                  },
                }}
                slotProps={{
                  headerRow: {
                    className: 'header-row-sticky'
                  }
                }}
                density="standard"
                headerHeight={56}
                getRowHeight={() => 'auto'}
                getEstimatedRowHeight={() => 100}
                sx={{
                  height: 650,
                  maxHeight: '70vh',
                  width: '100%',
                  '& .MuiDataGrid-cell': {
                    py: 1
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    position: 'sticky',
                    top: 0,
                    bgcolor: 'background.paper',
                    zIndex: 5,
                    borderBottom: '1px solid rgba(224, 224, 224, 1)'
                  },
                  '& .MuiDataGrid-row': {
                    '&.Mui-error': {
                      bgcolor: 'rgba(255, 0, 0, 0.1)',
                    }
                  }
                }}
              />
          </CardContent>
        </Card>
        
        <ViewFooter 
          prevDisabled={false}
          nextDisabled={false}
          onNext={() => window.dispatchEvent(new CustomEvent('navigate-to-process', { detail: { processId: 'glow_91' } }))}
          onPrev={() => {}}
          nextText="Back to Video Creator"
          hideButtons={false}
        />

        <Dialog
          open={this.state.videoModalOpen}
          onClose={this.handleVideoClose}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 2,
              overflow: 'hidden'
            }
          }}
        >
          <DialogTitle sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            p: 2
          }}>
            <Typography variant="h6">Video Preview</Typography>
            <IconButton 
              onClick={this.handleVideoClose}
              size="small"
              sx={{
                color: 'text.secondary',
                '&:hover': {
                  backgroundColor: 'action.hover'
                }
              }}
            >
              <X size={18} />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ p: 0 }}>
            <Box sx={{ 
              position: 'relative',
              paddingTop: '56.25%', // 16:9 Aspect Ratio
              width: '100%',
              height: 0,
              backgroundColor: 'black',
              '& video': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }
            }}>
              {this.state.selectedVideoUrl && (
                <video
                  controls
                  autoPlay
                  playsInline
                  src={this.state.selectedVideoUrl}
                  style={{ 
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain'
                  }}
                >
                  Your browser does not support the video tag.
                </video>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  accountInfo: selectAccountInfo(state)
});

export default connect(mapStateToProps)(VideoQueueManager);