import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './CreditFlag.css'; // Optional: for custom styles
import { Box, Typography, Paper, IconButton } from '@mui/material';
import { IoWarning } from 'react-icons/io5';
import MonetizationDialog from './monetization';
import { SUBSCRIPTION_ICONS } from './subscription_setting';
import { useSelector } from 'react-redux';
import { selectRemainingCredits } from '../../auth/authenticateSlide';

const ANONYMOUS_USER = 'user01_unreg';
const PAYING_USER = 'user03_paying';

const CreditFlag = ({ accountInfo }) => {
  const userType = accountInfo.userType;
  const subscriptionPlan = accountInfo.subscription_type;
  const subscriptionPlanIcon = {icon: SUBSCRIPTION_ICONS[subscriptionPlan]};
  const remainingCredits = useSelector(state => selectRemainingCredits(state));
  
  // Ensure credit is correctly parsed as a number and properly formatted
  // Use a fallback of 0 if credit is undefined, null, or NaN
  const creditValue = typeof accountInfo.credit === 'number' ? accountInfo.credit : 
                     (parseFloat(accountInfo.credit) || null);
  const credit = typeof accountInfo.credit === 'number' ? creditValue.toFixed(2) : null;
  
  // console.log('Credit value from API:', accountInfo.credit);
  // console.log('Parsed credit value:', creditValue);
  // console.log('Formatted credit display:', credit);
  
  const [monetizationOpen, setMonetizationOpen] = useState(false);
  const [showLowCreditWarning, setShowLowCreditWarning] = useState(false);

  useEffect(() => {
    // Show warning if credits are below 3
    if (accountInfo && accountInfo.credit !== undefined && accountInfo.credit < 3) {
      setShowLowCreditWarning(true);
    } else {
      setShowLowCreditWarning(false);
    }
  }, [accountInfo]);

  useEffect(() => {
    if (accountInfo && accountInfo.credit !== undefined && accountInfo.credit < 0) {
      setMonetizationOpen(true);
    }
  }, [remainingCredits])

  const toggleMonetizationDialog = () => {
    setMonetizationOpen(!monetizationOpen);
  };

  return (
    <>
      {showLowCreditWarning && (
        <Box
          sx={{
            position: 'fixed',
            top: '70px',
            right: '20px',
            zIndex: 1000,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              p: 1.5,
              backgroundColor: '#FFF3CD',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              borderLeft: '4px solid #FFC107'
            }}
          >
            <IoWarning color="#FFC107" size={20} />
            <Box>
              <Typography variant="body2" sx={{ color: '#856404', fontWeight: 'bold' }}>
                Low Credit Warning
              </Typography>
              <Typography variant="caption" sx={{ color: '#856404' }}>
                {accountInfo?.credit < 0 
                  ? 'You have no credits left. Please add more to continue.' 
                  : 'Your credits are running low. Consider upgrading your plan.'}
              </Typography>
            </Box>
            <IconButton size="small" onClick={toggleMonetizationDialog} sx={{ ml: 1 }}>
              <IoWarning color="#FFC107" size={14} />
            </IconButton>
          </Paper>
        </Box>
      )}
      
      {/* Original credit flag that opens monetization dialog */}
      <div id="subscriptions-center" className={`credit-flag ${subscriptionPlan}`} onClick={toggleMonetizationDialog}>
        <span id="subscriptions-info">
          {userType === PAYING_USER && (
            <>
              {subscriptionPlanIcon.icon && <subscriptionPlanIcon.icon />}
              {accountInfo?.mySubscription?.your_plan?.current_plan}
            </>
          )}
          {userType !== PAYING_USER && 'Credits'}
        </span>
        <span id="credit-balance">{credit || ''}</span>
      </div>

      {/* Monetization Dialog */}
      <MonetizationDialog
        accountInfo={accountInfo}
        open={monetizationOpen}
        onClose={toggleMonetizationDialog}
      />
    </>
  );
};

CreditFlag.propTypes = {
  accountInfo: PropTypes.object.isRequired,
};

export default CreditFlag;